import React, { useEffect, useReducer } from 'react';
import { additionalLearningTypeIcons } from '../additional-learning/additional-learning.models';
import { FetchStatus, FetchType } from '../../../enums';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { ReflectionFocusPanelLayout } from './reflection-focus-panel.layout';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import * as store from './reflections.store';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { Anchor, Button, formatDate, ListItemLayout, ListLayout, PageHeader, Panel, Searchfield, useMatchScreenWidth } from '@keplerco/core';
import { useDataSyncReaction } from '../../../library/hooks/useDataSyncReaction';
import styles from './reflections.module.css';
import classNames from 'classnames';

export function ReflectionsPage(): JSX.Element {
  const navigate = useNavigate();

  const { fetchState } = useAppState();
  const actions = useAppActions();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const isMobile = useMatchScreenWidth('mobile');

  async function getData(useLoader: boolean = true) {
    if (useLoader) actions.startLoader({ path: PagePath.learningJourneyCourseReflections, type: FetchType.PageFetching });

    const response = await actions.searchReflectionList(state.searchTerm);
    dispatch({ type: store.ReflectionsPageActionTypes.SetReflections, payload: response });

    if (useLoader) actions.stopLoader(PagePath.learningJourneyCourseReflections);
  }

  useDataSyncReaction(async () => {
    getData(false);
  });

  useEffect(() => {
    getData();
  }, []);

  async function onInputHandler(value: string) {
    actions.startLoader({ path: PagePath.learningJourneyCourseReflections, type: FetchType.Custom });

    dispatch({ type: store.ReflectionsPageActionTypes.SetSearchTerm, payload: value });
    const response = await actions.searchReflectionList(value);
    dispatch({ type: store.ReflectionsPageActionTypes.SetReflections, payload: response });

    actions.stopLoader(PagePath.learningJourneyCourseReflections);
  }

  async function onClickHandler(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, reflectionId: number) {
    event.stopPropagation();
    dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: reflectionId });
  }

  return (
    <>
      <PageLoaderLayer path={PagePath.learningJourneyCourseReflections}>
        <div className="pageWrapper wrapper">
          <PageHeader
            breadcrumbs={[
              {
                name: 'Learning Journey',
                url: PagePath.learningJourneyBase,
              },
              {
                name: 'Reflections',
                url: `${PagePath.learningJourneyBase}${PagePath.learningJourneyCourseReflections}`,
              },
            ]}
            title="Reflections"
            subtitle="Reflection is an essential element of learning in your continuous professional development. Reflecting enables you to build on your prior learning, as well as validate or challenge your existing skills and knowledge."
            divider
          />

          <div className="row">
            <div className="column">
              <Searchfield label="Search by skill" loading={fetchState[PagePath.learningJourneyCourseReflections].status === FetchStatus.Active && fetchState[PagePath.learningJourneyCourseReflections].type === FetchType.Custom} onInput={onInputHandler} />
            </div>
          </div>

          {!state.reflections?.length ? (
            <div style={{ display: 'grid', alignItems: 'center', padding: '60px 0' }}>
              <EmptyState
                title="No reflections found"
                subtitle="You need to complete some courses before you can reflect on them"
                buttonElements={[
                  <Button key="View Learning Journey" type="button" onClick={() => navigate(PagePath.learningJourneyBase)}>
                    View Learning Journey
                  </Button>,
                ]}
              />
            </div>
          ) : (
            <React.Fragment>
              {!!state.pendingReflections && state.pendingReflections.length > 0 && (
                <React.Fragment>
                  <h3 style={{ marginTop: 30, marginBottom: 10 }}>Pending</h3>

                  <ListLayout>
                    {state.pendingReflections.map(reflection => {
                      const imageUrl = reflection.lessonImage || additionalLearningTypeIcons.find(item => item.type === reflection.offPlatformLearningType)?.listUrl;

                      return (
                        <ListItemLayout key={reflection.reflectionId} onClick={() => dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: reflection.reflectionId })}>
                          <div className="card" style={{ borderLeftColor: 'var(--secondary)', borderLeftWidth: 10 }}>
                            {!isMobile && (
                              <div className={styles.image}>
                                <img src={imageUrl} alt="" draggable="false" />
                              </div>
                            )}

                            <div className="cardListItemContentLayout" style={{ paddingLeft: isMobile ? 0 : 'max(20%, 200px)' }}>
                              <div className={classNames('cardListItemBodyLayout', styles.body)}>
                                {!!reflection.skillName && <p>{reflection.skillName}</p>}
                                <h4>{reflection.lessonName}</h4>
                              </div>

                              <footer className="cardListItemFooterLayout">
                                <Anchor style={{ whiteSpace: 'nowrap' }} block variant="tiny" onClick={event => onClickHandler(event, reflection.reflectionId)}>
                                  Capture reflection
                                </Anchor>
                              </footer>
                            </div>
                          </div>
                        </ListItemLayout>
                      );
                    })}
                  </ListLayout>
                </React.Fragment>
              )}

              {!!state.completeReflections && state.completeReflections.length > 0 && (
                <React.Fragment>
                  <h3 style={{ marginTop: 30, marginBottom: 10 }}>Complete</h3>

                  <ListLayout>
                    {state.completeReflections.map(reflection => {
                      const imageUrl = reflection.lessonImage || additionalLearningTypeIcons.find(item => item.type === reflection.offPlatformLearningType)?.listUrl;

                      return (
                        <ListItemLayout key={reflection.reflectionId} onClick={() => dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: reflection.reflectionId })}>
                          <div className="card" style={{ borderLeftColor: 'var(--secondary)', borderLeftWidth: 10 }}>
                            {!isMobile && (
                              <div className={styles.image}>
                                <img src={imageUrl} alt="" draggable="false" />
                              </div>
                            )}

                            <div className="cardListItemContentLayout" style={{ paddingLeft: isMobile ? 0 : 'max(200px, 20%)' }}>
                              <div className={classNames('cardListItemBodyLayout', styles.body)}>
                                {!!reflection.skillName && <p>{reflection.skillName}</p>}
                                <h4>{reflection.lessonName}</h4>
                              </div>

                              <footer className="cardListItemFooterLayout">
                                <span className="body">Completed: {formatDate(new Date(reflection.dateCompleted))}</span>
                              </footer>
                            </div>
                          </div>
                        </ListItemLayout>
                      );
                    })}
                  </ListLayout>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </PageLoaderLayer>

      <Panel open={!!state.selectedReflectionId} onClose={() => dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: undefined })}>
        {!!state.selectedReflectionId && (
          <ReflectionFocusPanelLayout
            reflectionId={state.selectedReflectionId}
            path={PagePath.learningJourneyCourseReflections}
            onCancel={() => dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: undefined })}
            onSubmit={() => {
              getData();
              dispatch({ type: store.ReflectionsPageActionTypes.SetSelectedReflectionId, payload: undefined });
            }}
          />
        )}
      </Panel>
    </>
  );
}
