import React from 'react';
import { FieldController, NameValidator, TextField, DescriptionValidator, TextArea, Anchor, Button } from '@keplerco/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { useAppActions, useAppState } from '../../../../overmind';
import { CompanySkillListItemResponse } from '../../../../models/overmind/company-entity';

export function SaveSkillWidget({ selectedSkill, onCancel, onSave }: { selectedSkill: CompanySkillListItemResponse | undefined; onCancel: () => void; onSave: () => void }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { control, handleSubmit } = useForm<any>({ mode: 'onBlur', defaultValues: { name: selectedSkill?.name, description: selectedSkill?.description } });

  // TODO: Implement once AI Geneerate component is ready
  // const [regenerating, setRegenerating] = useState<boolean>(false);
  // const [instructionType, setInstructionType] = useState<string>('');
  // const regenerateOptions: DropdownSelectItem[] = [
  //   { value: RegenerateOption.Expand, onClick: () => setInstructionType(RegenerateOption.Expand) },
  //   { value: RegenerateOption.Shorten, onClick: () => setInstructionType(RegenerateOption.Shorten) },
  //   { value: RegenerateOption.Simplify, onClick: () => setInstructionType(RegenerateOption.Simplify) },
  //   { value: RegenerateOption.Improve, onClick: () => setInstructionType(RegenerateOption.Improve) },
  // ];

  // useEffect(() => {
  //   if (!instructionType) return;

  //   const values = getValues();
  //   async function regenerateDescription() {
  //     const isValid = trigger(['name']);
  //     if (!isValid) return;

  //     setRegenerating(true);

  //     const response = await actions.aiGenerateSkillDescription({
  //       name: values.name,
  //       description: values.description,
  //       instructionType,
  //     });

  //     setValue('description', response?.description);
  //     setRegenerating(false);
  //   }

  //   regenerateDescription();
  // }, [instructionType]);

  async function handleSaveSkill(values: any): Promise<void> {
    setIsLoading(true);

    const request = {
      companySlug: companyVariables.slug!,
      name: values.name,
      description: values.description,
    };
    const response = selectedSkill ? await actions.updateCompanySkill({ ...request, entitySlug: selectedSkill.slug! }) : await actions.createCompanySkill(request);
    if (!response.error) onSave();

    setIsLoading(false);
  }

  if (isLoading) return <SkeletonLoader height={'250px'} />;

  return (
    <form id="skill-details" className="panelForm" onSubmit={handleSubmit(handleSaveSkill)}>
      <div>
        <div className="card">
          <FieldController
            name="name"
            control={control}
            rules={new NameValidator()}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="text"
                label="Skill name"
                responsive
                validation={{
                  dirty: fieldState.isDirty || !!fieldState.error,
                  invalid: !!fieldState.error,
                  message: fieldState.error?.message ?? 'Enter a name',
                }}
              />
            )}
          />

          <FieldController
            name="description"
            control={control}
            rules={new DescriptionValidator()}
            render={({ field, fieldState }) => (
              <TextArea
                {...field}
                label="Skill description"
                validation={{
                  dirty: fieldState.isDirty || !!fieldState.error,
                  invalid: fieldState.invalid,
                  message: fieldState.error?.message ?? 'Enter a description',
                }}
                responsive
              />
            )}
          />
        </div>
      </div>

      {/*TODO: Implement once new component is ready  
          <div style={{ display: 'grid', justifyContent: 'flex-end' }}>
            <FieldController control={control} name="generate" rules={new RequiredValidator()} render={({ field }) => <DropdownSelect {...field} label="Generate" items={regenerateOptions} responsive={isMobile} />} />
          </div> */}
      <footer className="panelFooter" style={{ marginTop: 'auto' }}>
        <Anchor onClick={onCancel}>Cancel</Anchor>

        <Button type="button">Save</Button>
      </footer>
    </form>
  );
}
