import React, { useEffect, useState } from 'react';
import { IFieldsOfPracticeSelectorProps } from './fields-of-practice.models';
import { useParams } from 'react-router-dom';
import { EmptyState } from '../../../../components/general/empty-state/empty-state';
import { FetchType } from '../../../../enums';
import { CareerPath, SkillAssessment } from '../../../../models';
import { PagePath } from '../../../../navigation/navigation.enums';
import { useAppActions } from '../../../../overmind';
import { Anchor, CheckboxCard, Chip, ChipWrapper, ListItemLayout, ListLayout, safeCallback } from '@keplerco/core';
import { FuzzySearch } from '../../../../components/inputs/fuzzy-search/fuzzy-search';
import { FuzzySearchParams } from '../../../../components/inputs/fuzzy-search/fuzzy-search.models';
import { FocusPanelLoaderLayer } from '../../../../components/general/loading-state/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';

const CUSTOM_THEME = { backgroundColor: 'background', borderColor: 'baby-blue', color: 'text' };

export function FieldsOfPracticeSelector(props: IFieldsOfPracticeSelectorProps): JSX.Element {
  const { companySlug } = useParams<any>();
  const actions = useAppActions();

  const [searchResults, setSearchResults] = useState<FuzzySearchParams>({ query: '', filterOption: 'All' });

  const [careerPaths, setAvailableCareerPaths] = useState<CareerPath[]>();
  const [careerPathSlugs, setSelectedCareerPaths] = useState<string[]>([]);
  const [assessment, setAssessment] = useState<SkillAssessment>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    async function getData() {
      if (!companySlug) return;
      actions.startLoader({ path: PagePath.assessmentManagementAllAssessments, type: FetchType.DialogFetching });
      const paths = await actions.getCompanyCareerPaths(companySlug);
      const skillAssessment = await actions.getSkillAssessment({ companySlug: companySlug!, assessmentSlug: props.assessmentSlug });

      setAssessment(skillAssessment);
      setSelectedCareerPaths(skillAssessment?.careerPathSlugs ?? []);
      if (!!paths) setAvailableCareerPaths(paths);
      actions.stopLoader(PagePath.assessmentManagementAllAssessments);
    }

    if (!careerPaths) getData();
  }, [careerPaths]);

  function filterCareerPaths(path: CareerPath) {
    if (!!searchResults.query) {
      const query = searchResults.query.toLowerCase();
      return path.careerPathName.toLowerCase().includes(query) || path.careerPathSlug.toLowerCase().includes(query);
    }

    return true;
  }

  function onChangeHandler(input: HTMLInputElement, path: CareerPath): void {
    setError(void 0);
    const isChecked = input.checked;

    if (isChecked) {
      setSelectedCareerPaths([...careerPathSlugs, path.careerPathSlug]);
    } else setSelectedCareerPaths([...careerPathSlugs.filter(i => i !== path.careerPathSlug)]);
  }

  async function onSaveHandler(): Promise<void> {
    if (!careerPathSlugs.length) {
      setError('Please select at least one field of practice to continue');
      return void 0;
    }

    if (!!assessment) {
      setError(void 0);
      actions.startLoader({ path: PagePath.assessmentManagementAllAssessments, type: FetchType.DialogFetching });

      const newAssessment: SkillAssessment = {
        ...assessment,
        careerPathSlugs: careerPathSlugs,
        companySlug: companySlug!,
        slug: props.assessmentSlug,
      };

      await actions.updateSkillAssessment(newAssessment);

      safeCallback(props.onStepComplete);
      actions.stopLoader(PagePath.assessmentManagementAllAssessments);
    }
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.assessmentManagementAllAssessments}>
      {!!careerPaths && careerPaths.length === 0 && <EmptyState title={'No career paths found'} />}

      {!!careerPaths && careerPaths.length > 0 && (
        <div className="row">
          <div className="column">
            <FuzzySearch errorMessage={error} defaultValue={searchResults} placeholder="Search career paths" onFuzzySearch={result => setSearchResults({ ...result })}>
              <ListLayout>
                {careerPaths
                  .filter(careerPath => filterCareerPaths(careerPath))
                  .map(careerPath => (
                    <ListItemLayout key={careerPath.careerPathSlug}>
                      <CheckboxCard id={careerPath.careerPathName} name={careerPath.careerPathName} value={careerPath.careerPathSlug} checked={careerPathSlugs.includes(careerPath.careerPathSlug)} onChange={({ target }) => onChangeHandler(target, careerPath)}>
                        <div className="card">
                          <div className="toggleCardHorizontalContentLayout" style={{ gridAutoColumns: '2fr auto' }}>
                            <label className="toggleCardLabel" htmlFor={careerPath.careerPathName}>
                              {careerPath.careerPathName}
                            </label>

                            <ChipWrapper className="ChipWrapper" style={{ justifyContent: 'flex-end' }}>
                              {!!careerPath.skillSets?.length && <Chip customTheme={CUSTOM_THEME}>{careerPath.skillSets?.length ?? 0} skills</Chip>}

                              {!!careerPath.jobsIds?.length && <Chip customTheme={CUSTOM_THEME}>{careerPath.jobsIds?.length ?? 0} Jobs</Chip>}

                              {!careerPath.jobsIds?.length && !careerPath.skillSets?.length && <Chip customTheme={{ ...CUSTOM_THEME, borderColor: 'borders' }}>Empty</Chip>}
                            </ChipWrapper>
                          </div>
                        </div>
                      </CheckboxCard>
                    </ListItemLayout>
                  ))}
              </ListLayout>
            </FuzzySearch>
          </div>
        </div>
      )}

      <footer style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 20 }}>
        <Anchor arrow onClick={() => onSaveHandler()}>
          Next
        </Anchor>
      </footer>
    </FocusPanelLoaderLayer>
  );
}
