import React from 'react';
import { IExplainerFocusPanelProps } from './explainer-focus-panel.models';
import { Button, PanelHeader } from '@keplerco/core';
import styles from './explainer-focus-panel.module.css';

export function ExplainerFocusPanelLayout(props: IExplainerFocusPanelProps): JSX.Element {
  return (
    <div className="panelContent">
      <PanelHeader supertitle={props.supertitle} title={props.title} divider />

      <p className={styles.textWrap}>{props.description}</p>

      <footer className="panelFooter" style={{ justifyContent: 'flex-end' }}>
        <Button type="button" filled arrow={false} onClick={props.onClose}>
          Close
        </Button>
      </footer>
    </div>
  );
}
