import { AddTwoToneIcon, Anchor, Button, Checkbox, PanelHeader } from '@keplerco/core';
import React, { Fragment, useEffect, useState } from 'react';
import { CompanyEntitySearchParams } from '../../../../models/overmind/search-params';
import { OrganizationLevelType, SortField } from '../../../../enums';
import { useAppActions, useAppState } from '../../../../overmind';
import { extractHighestOrganizationLevel } from '../../../../library/helpers/permissions/extract-highest-organization-level';
import { IEditSkillsLayoutProps, IPerson } from './people-and-skills.models';
import { SearchList } from '../../../../components/lists/search-list';
import { ListItem } from '../../../../components/lists/list-item';
import { CompanySkillListResponse } from '../../../../models/overmind/company-entity';

const pageSize = 5;

export function EditPersonLayout({ personToEdit, sync, allowManagerReview, allowPeerReview, onImport, onBack, onDone }: IEditSkillsLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();
  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleSkillManagement?.organizationLevels);

  const [selectedPerson, setSelectedPerson] = useState<IPerson>(personToEdit);
  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<CompanyEntitySearchParams>({
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: false,
  });
  const [skills, setSkills] = useState<CompanySkillListResponse>();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const response = await actions.getCompanySkills(request);
      setSkills(response);
      setLoading(false);
    }

    getData();
  }, [request, sync, companyVariables.slug]);

  return (
    <div className="panelContent">
      <PanelHeader supertitle="Assessments" title={`${selectedPerson.firstName} ${selectedPerson.lastName}`} subtitle="Edit the skills you would like to be assessed" divider />

      <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', gap: 30 }}>
        {(allowManagerReview || allowPeerReview) && (
          <Checkbox
            id="manager"
            checked={selectedPerson.manager}
            onChange={() =>
              setSelectedPerson(currentState => {
                const nextState = structuredClone(currentState);
                nextState.manager = !nextState.manager;
                return nextState;
              })
            }
          >
            Manager for the assessment
          </Checkbox>
        )}

        {(!allowManagerReview || allowPeerReview || !selectedPerson.manager) && (
          <div className="card">
            <SearchList
              loading={loading}
              onInput={search => setRequest(currentState => ({ ...currentState, search, page: 1 }))}
              // button={{
              //   icon: <AddTwoToneIcon />,
              //   text: 'Import skill',
              //   onClick: onImport,
              // }}
              actions={
                <div>
                  <Button type="button" theme="dark" square onClick={onImport}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                      <AddTwoToneIcon />
                      Import skill
                    </div>
                  </Button>
                </div>
              }
              paging={{
                activePageNumber: request.page ?? 1,
                pageCount: skills?.totalPages ?? 0,
                onPageChange: page => setRequest(currentState => ({ ...currentState, page })),
              }}
            >
              {skills?.entities.map(entity => {
                const selected = selectedPerson.skills.some(skill => skill.companyEntityId === entity.companyEntityId);
                return (
                  <ListItem
                    key={entity.slug}
                    selected={selected}
                    onClick={() =>
                      setSelectedPerson(currentState => {
                        const nextState: IPerson = structuredClone(currentState);
                        if (selected) {
                          nextState.skills = nextState.skills.filter(skill => skill.companyEntityId !== entity.companyEntityId);
                        } else {
                          nextState.skills.push(entity);
                        }
                        return nextState;
                      })
                    }
                  >
                    <Fragment>
                      <span className="body" style={{ color: 'var(--accent-2)' }}>
                        {entity.name}
                      </span>

                      <small>{entity.description ?? 'No description provided'}</small>
                    </Fragment>
                  </ListItem>
                );
              })}
            </SearchList>
          </div>
        )}
      </div>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button type="button" onClick={() => onDone(selectedPerson)}>
          Done
        </Button>
      </footer>
    </div>
  );
}
