import React, { useEffect, useState } from 'react';
import { CompletionStatus, FetchType } from '../../../../enums';
import { LearnerCareerPathSkillSet } from '../../../../models/view/learner-career-path-skill-set';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppActions } from '../../../../overmind';
import classNames from 'classnames';
import { PagePath } from '../../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { Anchor, Button, CheckIcon, Chip, ChipWrapper, GridItemLayout, GridLayout, PageHeader, useMatchScreenWidth } from '@keplerco/core';
import { getSubdomain } from '../../../../library/helpers/get-subdomain';
import layoutStyles from '../../../../design/layout.module.css';
import styles from './rating-career-paths.module.css';

export function RatingCareerPathsLandingPage(): JSX.Element {
  const actions = useAppActions();
  const navigate = useNavigate();
  const [careerPaths, setPaths] = useState<LearnerCareerPathSkillSet[]>();

  const isMobile = useMatchScreenWidth('mobile');

  useEffect(() => {
    actions.startLoader({ path: PagePath.analysisRatingCareerPaths, type: FetchType.PageFetching });

    actions.getLearnerCareerPathSkillSets().then(paths => {
      setPaths(paths);
      actions.stopLoader(PagePath.analysisRatingCareerPaths);
    });
  }, []);

  const allAreComplete = careerPaths?.every(u => u.completionStatus === CompletionStatus.Completed);
  const subdomain = getSubdomain();

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.analysisRatingCareerPaths}>
        <div className="wrapper pageWrapper" style={{ gap: 30 }}>
          <PageHeader
            breadcrumbs={[
              { name: 'Analysis', url: PagePath.analysisBase },
              { name: subdomain.includes('lpi') ? 'Capability Map' : 'Career Paths', url: `${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}` },
            ]}
            title={subdomain.includes('lpi') ? 'Capability Map' : 'Career Paths'}
            subtitle={`This assessment will determine which skills you need to focus on${subdomain.includes('lpi') ? '' : ', and will populate your custom learning journey'}`}
          />

          {!!careerPaths && (
            <div
              style={{
                overflowY: 'auto',
                paddingBottom: '120px',
              }}
            >
              <GridLayout columnCount={3}>
                {careerPaths.map(path => {
                  const done = path.completionStatus === CompletionStatus.Completed;

                  return (
                    <GridItemLayout key={path.skillSetSlug}>
                      <NavLink to={done ? `${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}` : `${PagePath.analysisBase}${PagePath.analysisRatingCareerPath.replace(':ratingSlug', path.skillSetSlug)}`} style={{ textDecoration: 'none' }}>
                        <div className={classNames('card', styles.card, { [styles.done]: done })}>
                          {done && (
                            <div className={styles.checkIconBackground}>
                              <CheckIcon tone="contrast-text" />
                            </div>
                          )}

                          <ChipWrapper>
                            <Chip customTheme={{ backgroundColor: 'background', borderColor: 'baby-blue', color: 'text' }}>{path.skillCount} skill(s)</Chip>
                            {done && <Chip themeColour="a">Done</Chip>}
                          </ChipWrapper>

                          <h3 style={{ paddingTop: 5, paddingBottom: 5 }}>{path.skillSetName}</h3>

                          <p>
                            {path.skillSetDescription ?? (
                              <React.Fragment>
                                No skill description was found in the api for <strong>{path.skillSetName}</strong>. Don't worry, you can still rate your abilities in this skill set
                              </React.Fragment>
                            )}
                          </p>

                          {!done && (
                            <div style={{ marginTop: 'auto', marginLeft: 'auto', paddingTop: 15 }}>
                              <Anchor>Rate your skills</Anchor>
                            </div>
                          )}
                        </div>
                      </NavLink>
                    </GridItemLayout>
                  );
                })}
              </GridLayout>
            </div>
          )}
        </div>
      </PageLoaderLayer>

      <footer className={layoutStyles.footer}>
        <div className={layoutStyles.footerContent}>
          <Anchor arrow reverse block onClick={() => navigate(PagePath.analysisBase)}>
            Back
          </Anchor>

          <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
            {!allAreComplete && !isMobile && <span style={{ color: 'var(--accent-3)' }}>To continue, rate your skills</span>}

            <Button
              type="button"
              disabled={!allAreComplete}
              onClick={async () => {
                navigate(PagePath.analysisBase);
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
