import React from 'react';
import styles from './skills-assigned-widget.module.css';
import classNames from 'classnames';
import { CircleIcon } from './circle.icon';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';

interface ISkillsAssignedWidgetProps {
  isLoading: boolean;
  skillCount: number;
}

export default function SkillsAssignedWidget({ skillCount, isLoading }: ISkillsAssignedWidgetProps) {
  return (
    <SkeletonOverlay isLoading={isLoading} width="100%" height="100%">
      <div className={classNames('card', styles.card)}>
        <div style={{ position: 'relative' }}>
          <CircleIcon />
          <h4 className={styles.skillCount}>{skillCount}</h4>
        </div>

        <div>Skills included</div>
      </div>
    </SkeletonOverlay>
  );
}
