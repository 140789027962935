import React from 'react';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Button } from '@keplerco/core';
import { useNavigate } from 'react-router-dom';

interface VariationQuestionReadyModalProps {
  title: string;
  slug: string;
  onAction: () => void;
}

export function VariationQuestionReadyModalLayout({ title, slug, onAction }: VariationQuestionReadyModalProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <div className="modalContent">
      <div className="badgeIconContainer">
        <object data="/badges/badge_Planet.svg" type="image/svg+xml">
          <img src="/badges/badge_Planet.svg" alt="KP" />
        </object>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'center', textAlign: 'center' }}>
        <h2>It's time to test your skills!</h2>

        <h3>
          You've recently completed your <strong style={{ color: 'var(--text)' }}>{title}</strong> learning journey. Let's see what you've learned.
        </h3>

        <Button
          chubby
          type="button"
          filled
          arrow={false}
          onClick={async () => {
            onAction();
            navigate(`${PagePath.learningJourneyBase}${PagePath.learningJourneySkill.replace(':skillSlug', slug)}`);
          }}
        >
          Take the Quiz
        </Button>
      </div>
    </div>
  );
}
