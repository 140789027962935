import React, { useEffect, useState } from 'react';
import { useForm, Control, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAppActions } from '../../../overmind';
import { SaveCourseDto, SaveLessonDto } from '../../../models/overmind/course-management';
import { CourseMappingsListItem } from '../../../models/overmind/learning-management';
import { FetchType, OrganizationLevelType, SortField } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import {
  Anchor,
  Button,
  PanelHeader,
  SearchBar,
  FieldController,
  TextField,
  TextArea,
  Checkbox,
  Table,
  TableRow,
  TableColumn,
  NameValidator,
  DescriptionValidator,
  PillButtonGroup
} from '@keplerco/core';
import { FocusPanelLoaderLayer } from '../../../components/general/loading-state/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { BaseSearchRequest } from '../../../models';
import classNames from 'classnames';
import { Counter } from '../../../components/inputs/raters/rater/counter/counter';
import { TertiaryButton } from '../../../components/general/tertiary-button/tertiary-button';

enum PillButtonId {
  SearchLessons = 0,
  SelectedLessons = 1
}

enum PillButtonDescriptions {
  SearchLessons = 'Search Lessons',
  SelectedLessons = 'Selected Lessons'
}

interface CourseFormValues {
  name: string;
  description: string;
  lessonOrderLocked: boolean;
  allowAssignedLearning: boolean;
}

export function CourseFormPage(): JSX.Element {
  const { companySlug, courseSlug } = useParams<{ companySlug: string; courseSlug: string }>();
  const actions = useAppActions();
  const keplerNavigate = useKeplerNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<CourseMappingsListItem[]>([]);
  const [selectedLessons, setSelectedLessons] = useState<SaveLessonDto[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedPillButton, setSelectedPillButton] = useState<PillButtonId>(PillButtonId.SearchLessons);
  const [selectedLessonsCount, setSelectedLessonsCount] = useState<number>(0);
  const [courseSlugValue, setCourseSlugValue] = useState<string>('');

  const { control, handleSubmit, setValue } = useForm<CourseFormValues>({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      description: '',
      lessonOrderLocked: false,
      allowAssignedLearning: false
    }
  });

  // Load course data if in edit mode
  useEffect(() => {
    async function loadCourse() {
      if (courseSlug && courseSlug !== 'new') {
        setIsEditMode(true);
        setIsLoading(true);
        actions.startLoader({ path: PagePath.learningManagementCourseMappingCourse, type: FetchType.PageFetching });

        const courseData = await actions.getCustomCourse({ companySlug: companySlug!, courseSlug });

        if (courseData) {
          setValue('name', courseData.name);
          setValue('description', courseData.description);
          setValue('lessonOrderLocked', courseData.lessonOrderLocked);
          setValue('allowAssignedLearning', courseData.allowAssignedLearning);
          setSelectedLessons(courseData.courseLessons);
          setSelectedLessonsCount(courseData.courseLessons.length);
          setCourseSlugValue(courseSlug);
        }

        setIsLoading(false);
        actions.stopLoader(PagePath.learningManagementCourseMappingCourse);
      }
    }

    loadCourse();
  }, [courseSlug, companySlug]);

  // Search for lessons
  useEffect(() => {
    async function searchLessons() {
      if (!searchQuery.trim()) {
        setSearchResults([]);
        return;
      }

      setIsLoading(true);
      const searchParams: BaseSearchRequest = {
        search: searchQuery,
        page: 1,
        pageSize: 10,
        companySlug: companySlug!,
        sortAscending: false,
        sortField: SortField.Name,
        organizationLevel: OrganizationLevelType.Company,
        departmentSlug: '',
        teamSlug: '',
        learnerSlug: ''
      };

      const results = await actions.searchLessons(searchParams);
      if (results) {
        setSearchResults(results.learningObjectList);
      }
      setIsLoading(false);
    }

    const delayDebounceFn = setTimeout(() => {
      searchLessons();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, companySlug]);

  // Add lesson to selected lessons
  const addLesson = (lesson: CourseMappingsListItem) => {
    const isAlreadySelected = selectedLessons.some(l => l.lessonId === lesson.lessonId);

    if (!isAlreadySelected) {
      const newLesson: SaveLessonDto = {
        lessonId: lesson.lessonId,
        lessoneName: lesson.name,
        lessonOrder: selectedLessons.length + 1
      };

      const updatedLessons = [...selectedLessons, newLesson];
      setSelectedLessons(updatedLessons);
      setSelectedLessonsCount(updatedLessons.length);
    }
  };

  // Remove lesson from selected lessons
  const removeLesson = (lessonId: number) => {
    const updatedLessons = selectedLessons.filter(l => l.lessonId !== lessonId);
    setSelectedLessons(updatedLessons);
    setSelectedLessonsCount(updatedLessons.length);
  };


  // Update lesson order
  const updateLessonOrder = (lessonId: number, newOrder: number) => {
    if (newOrder < 1) return;
    const lesson = selectedLessons.filter(lesson => lesson.lessonId === lessonId)[0];
    lesson.lessonOrder = newOrder;
  };
  // Get sorted lessons

  // Save course
  const onSubmit = async (data: CourseFormValues) => {
    setIsLoading(true);
    actions.startLoader({ path: PagePath.learningManagementCourseMappingCourse, type: FetchType.Sending });

    const courseDto: SaveCourseDto = {
      slug: courseSlugValue,
      name: data.name,
      description: data.description,
      lessonOrderLocked: data.lessonOrderLocked,
      allowAssignedLearning: data.allowAssignedLearning,
      courseLessons: selectedLessons
    };

    const result = await actions.saveCourse({
      companySlug: companySlug!,
      courseDto
    });

    actions.stopLoader(PagePath.learningManagementCourseMappingCourse);
    setIsLoading(false);

    if (result && typeof result === 'string') {
      keplerNavigate(`${PagePath.learningManagementBase}`);
    }
  };

  // Handle cancel
  const handleCancel = () => {
    keplerNavigate(`${PagePath.learningManagementBase}${PagePath.learningManagementCourseMapping.replace(':companySlug', companySlug!)}`);
  };

  return (
    <FocusPanelLoaderLayer path={PagePath.learningManagementCourseMappingCourse}>
      <div className="panelContent">
        <PanelHeader
          title={isEditMode ? 'Edit Course' : 'Create Course'}
          subtitle='Create or edit a custom course for your company'
          divider
        />

        <form onSubmit={handleSubmit(onSubmit)} className="panelForm">
          <div className="card" style={{ marginBottom: '20px' }}>
            <h4 className="subtitle">Course Details</h4>

            <FieldController
              name="name"
              control={control as unknown as Control<FieldValues>}
              rules={new NameValidator('Course name is required')}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  label="Course Name"
                  responsive
                  autoFocus
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: !!fieldState.error,
                    message: fieldState.error?.message ?? 'Enter a course name',
                  }}
                />
              )}
            />

            <FieldController
              name="description"
              control={control as unknown as Control<FieldValues>}
              rules={new DescriptionValidator()}
              render={({ field, fieldState }) => (
                <TextArea
                  {...field}
                  label="Description"
                  responsive
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: !!fieldState.error,
                    message: fieldState.error?.message ?? 'Enter a description',
                  }}
                />
              )}
            />

            <div style={{ display: 'flex', gap: '20px', marginTop: '10px' }}>
              <FieldController
                name="lessonOrderLocked"
                control={control as unknown as Control<FieldValues>}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    id="lessonOrderLocked"
                    checked={field.value}
                  >Lock Lesson Order</Checkbox>
                )}
              />

              <FieldController
                name="allowAssignedLearning"
                control={control as unknown as Control<FieldValues>}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    id="allowAssignedLearning"
                    checked={field.value}
                  >Allow Assigned Learning</Checkbox>
                )}
              />
            </div>
          </div>

          <div className="card" style={{ marginBottom: '20px' }}>
            <h4 className="subtitle">Course Lessons</h4>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
              <SearchBar
                loading={isLoading}
                label="Search"
                onInput={event => setSearchQuery((event.target as HTMLInputElement).value)}
                responsive
              />

              <div style={{ display: 'flex', gap: 15, flexDirection: 'row', alignItems: 'center' }}>
                <PillButtonGroup
                  backgroundColour="borders_2"
                  activeTextColour='text'
                  buttons={[
                    {
                      id: PillButtonId.SearchLessons,
                      label: `${PillButtonDescriptions.SearchLessons}`,
                      active: selectedPillButton === PillButtonId.SearchLessons
                    },
                    {
                      id: PillButtonId.SelectedLessons,
                      label: `${PillButtonDescriptions.SelectedLessons} (${selectedLessonsCount})`,
                      active: selectedPillButton === PillButtonId.SelectedLessons
                    },
                  ]}
                  onClick={buttons => setSelectedPillButton(buttons[0].id ?? PillButtonId.SearchLessons)}
                />
              </div>

              {/* Search Results */}
              <div className={classNames({ hidden: selectedPillButton !== PillButtonId.SearchLessons })}>
                {searchResults.length > 0 ? (
                  <Table>
                    {searchResults.map(lesson => (
                      <TableRow key={lesson.slug} id={lesson.slug}>
                        <TableColumn id="name" label="Name">
                          {lesson.name}
                        </TableColumn>
                        <TableColumn id="actions" label="">
                          <TertiaryButton
                            type="button"
                            onClick={() => addLesson(lesson)}
                            disabled={selectedLessons.some(l => l.lessonId === lesson.lessonId)}
                          >
                            Add
                          </TertiaryButton>
                        </TableColumn>
                      </TableRow>
                    ))}
                  </Table>
                ) : (
                  <EmptyState title="No lessons found" subtitle="Try a different search term" />
                )}
              </div>

              {/* Selected Lessons */}
              <div className={classNames({ hidden: selectedPillButton !== PillButtonId.SelectedLessons })}>
                {selectedLessons.length > 0 ? (
                  <Table>
                    {selectedLessons.map((lesson) => (
                      <TableRow key={lesson.lessonId} id={lesson.lessonId}>
                        <TableColumn id="lessoneName" label="Lesson">
                          {lesson.lessoneName}
                        </TableColumn>
                        <TableColumn id="order" label="Order">
                          <Counter
                            max={100}
                            count={lesson.lessonOrder}
                            onChange={value => {
                              // Add a small delay to allow the Counter to update its UI before reordering
                              setTimeout(() => {
                                updateLessonOrder(lesson.lessonId, value || 1);
                              }, 100);
                            }}
                          />
                        </TableColumn>
                        <TableColumn id="actions" label="">
                          <TertiaryButton
                            type="button"
                            onClick={() => removeLesson(lesson.lessonId)}
                          >
                            Remove
                          </TertiaryButton>
                        </TableColumn>
                      </TableRow>
                    ))}
                  </Table>
                ) : (
                  <EmptyState title="No lessons selected" subtitle="Search and add lessons to your course" />
                )}
              </div>
            </div>
          </div>

          <footer className="panelFooter">
            <Anchor onClick={handleCancel}>Cancel</Anchor>
            <Button type='button' disabled={isLoading}>
              {isEditMode ? 'Update Course' : 'Create Course'}
            </Button>
          </footer>
        </form>
      </div>
    </FocusPanelLoaderLayer>
  );
}
