import { DropdownSearch, Filters } from '@keplerco/core';
import React, { PropsWithChildren } from 'react';
import { FilteringSearchParams } from '../../../models/overmind/search-params';
import { IAnalyticsFiltersProps } from './analytics-filters.models';

// TODO: add to consts
export const MAX_PAGE_SIZE = 99999;
export const DEFAULT_FILTERING_SEARCH_PARAMS: FilteringSearchParams = {
  departmentSlugs: undefined,
  teamSlugs: undefined,
  learnerSlugs: undefined,
  roleSlugs: undefined,
  skillSlugs: undefined,
  countryIds: undefined,
};

export function AnalyticsFilters({ departmentItems, teamItems, roleItems, skillItems, countryItems, filteringSearchParams, setFilteringSearchParams, applyFilters, clearFilters, children }: PropsWithChildren<IAnalyticsFiltersProps>): React.ReactElement {
  async function updateFilteringSearchParams(filter: keyof FilteringSearchParams, value: string) {
    setFilteringSearchParams({
      ...filteringSearchParams,
      [filter]: value?.includes(',') ? value.split(',') : value,
    });
  }

  return (
    // Ensures that the filters button doesn't stretch on mobile
    <div>
      <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
        {!!departmentItems && <DropdownSearch label="Departments" items={departmentItems} value={filteringSearchParams.departmentSlugs} multiple onChange={event => updateFilteringSearchParams('departmentSlugs', event.target.value)} />}

        {!!teamItems && <DropdownSearch label="Teams" items={teamItems} value={filteringSearchParams.teamSlugs} multiple onChange={event => updateFilteringSearchParams('teamSlugs', event.target.value)} />}

        {!!roleItems && <DropdownSearch label="Roles" items={roleItems} value={filteringSearchParams.roleSlugs} multiple onChange={event => updateFilteringSearchParams('roleSlugs', event.target.value)} />}

        {!!skillItems && <DropdownSearch label="Skills" items={skillItems} value={filteringSearchParams.skillSlugs} multiple onChange={event => updateFilteringSearchParams('skillSlugs', event.target.value)} />}

        {!!countryItems && <DropdownSearch label="Countries" items={countryItems} value={filteringSearchParams.countryIds} multiple onChange={event => updateFilteringSearchParams('countryIds', event.target.value)} />}

        {children}
      </Filters>
    </div>
  );
}
