import { Context } from '../..';
import { DepartmentSearchResponse } from '../../../models/view/department-search-response';
import { Department } from '../../../models/view/department';
import { KeyValue } from '../../../models/overmind/key-value';
import { BaseSearchRequest } from '../../../models/overmind/search-request';
import * as base from '../base';
import { SortField } from '../../../enums/sort-field';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';
import { UniqueEntity } from '../../../models';

export async function getDepartmentKeyValues(): Promise<KeyValue[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(`department`, `get-department-key-values`), authenticated: true };
  const response: base.IResponse<KeyValue[]> = await base.request(request);
  return response.data;
}

export async function getDepartmentKeyValuesByCompany(_: Context, companySlug: string): Promise<KeyValue[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(`department`, `get-department-key-values`, companySlug), authenticated: true };
  const response: base.IResponse<KeyValue[]> = await base.request(request);
  return response.data;
}

export async function getAdministrationDepartments(context: Context, companySlug: string): Promise<Department[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(`department`, `get-departments`, companySlug), authenticated: true };
  const response: base.IResponse<Department[]> = await base.request(request);

  return response.data;
}

export async function getAnalyticsDepartments(context: Context, companySlug: string): Promise<Department[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(`analytics`, `departments${base.params({ companySlug })}`), authenticated: true };
  const response: base.IResponse<Department[]> = await base.request(request);
  return response.data;
}

export async function getAssessmentsDepartments(context: Context, companySlug: string): Promise<Department[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(`assessments`, `get-departments`, companySlug), authenticated: true };
  const response: base.IResponse<Department[]> = await base.request(request);
  return response.data;
}

export const searchDepartments = async (context: Context, SearchRequest: BaseSearchRequest): Promise<DepartmentSearchResponse | undefined> => {
  const request: base.IRequest = { url: base.apiURL(`department`, `search-departments`, `${context.state.companyVariables.slug}${base.params(SearchRequest)}`) };
  const response: base.IResponse<DepartmentSearchResponse> = await base.request(request);
  return response.data;
};

export async function getAllDepartments(context: Context, companySlug: string) {
  const request: BaseSearchRequest = {
    companySlug,
    sortField: SortField.Name,
    sortAscending: true,
    page: 1,
    pageSize: 1000,
  };

  return await searchDepartments(context, request);
}

export async function saveDepartment(context: Context, payload: { department: UniqueEntity; companySlug: string }): Promise<Department | undefined> {
  const request: base.IRequest = { url: base.apiURL(`department`, `save-department`, payload.companySlug), authenticated: true, method: 'POST', body: JSON.stringify(payload.department) };
  const response: base.IResponse<Department> = await base.request(request);

  return response.data;
}

export const getDepartment = async (context: Context, data: { departmentSlug: string; companySlug: string }): Promise<Department | undefined> => {
  const request: base.IRequest = { url: base.apiURL(`department`, `get-department`, data.companySlug, data.departmentSlug), authenticated: true };
  const response: base.IResponse<Department> = await base.request(request);

  return response.data;
};

export const archiveDepartment = async (context: Context, departmentSlug: string) => {
  const url = base.apiURL(`department`, `archive`, departmentSlug);
  const request: base.IRequest = { url: url, authenticated: true, method: 'PUT' };
  const response: base.IResponse<Department> = await base.request(request);

  if (!!response.error) {
    context.actions.addNotification({
      id: departmentSlug,
      type: NotificationType.Error,
      priority: NotificationPriority.Toast,
      title: '',
      message: `Cannot archive department. Please contact support`,
      active: true,
    });
  } else
    context.actions.addNotification({
      id: departmentSlug,
      type: NotificationType.Success,
      priority: NotificationPriority.Toast,
      title: '',
      message: `Department archived successfully`,
      active: true,
    });
};
