import { Anchor, Pager, Table, TableColumn, TableRow } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { OrganizationLevelType, ScoreType } from '../../../enums';
import { useAppState, useAppActions } from '../../../overmind';
import { ScoresSearchParams } from '../../../models/overmind/search-params';
import { HeatmapType, IHeatmapWidgetProps } from './heatmap.models';
import { transformScores } from './heatmap.helpers';
import { PagePath } from '../../../navigation/navigation.enums';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { PermissionsEntity } from '../permissions-entity-dropdown/permissions-entity-dropdown.models';
import { GlobalDateRangeFilter } from '../../../overmind/actions/functions/daterange';
import { CompanyVariables } from '../../../models/view/company-variables';
import { ScoresResponse } from '../../../models/overmind/analytics';
import { DataPoint } from '../../../components/charts/recharts.models';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';

// TODO: rewrite to be more concise (have less repetition)
export function HeatmapWidget({ entity }: IHeatmapWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, dateRange } = useAppState();

  const keplerNavigate = useKeplerNavigate();

  const [initializing, setInitializing] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const [heatmapType, setHeatmapType] = useState<HeatmapType>(entity?.organizationLevel === OrganizationLevelType.Company ? 'Department' : entity?.organizationLevel === OrganizationLevelType.Department ? 'Team' : 'People');
  const [scoreTypeSlugs, setScoreTypeSlugs] = useState<string[]>();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [scores, setScores] = useState<DataPoint[]>();
  const [totalPages, setTotalPages] = useState<number>(0);

  const [averageScores, setAverageScores] = useState<DataPoint[]>();

  async function getScores(entity: PermissionsEntity, dateRange: GlobalDateRangeFilter | undefined, sortAscending: boolean, companyVariables: CompanyVariables, currentPageNumber: number, scoreTypeSlugs: string[] | undefined): Promise<ScoresResponse | undefined> {
    const startDate = dateRange?.from?.toJSON();
    const endDate = dateRange?.to?.toJSON();
    const organizationLevel = entity.organizationLevel;
    const slug = entity.entity?.slug;

    const searchParams: ScoresSearchParams = {
      sortAscending,
      page: currentPageNumber,
      pageSize: 10,
      startDate,
      endDate,
      organizationLevel: organizationLevel === OrganizationLevelType.Company ? OrganizationLevelType.Department : organizationLevel === OrganizationLevelType.Department ? OrganizationLevelType.Team : OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      scoreType: organizationLevel === OrganizationLevelType.Company || organizationLevel === OrganizationLevelType.Department ? ScoreType.SkillSubType : ScoreType.Skill,
      scoreTypeSlugs,
      scoreComparison: undefined,
    };
    if (organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = slug;
    if (organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = slug;
    if (organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug === slug;
    const response = await actions.analyticsGetScoresByOrganizationLevel(searchParams);

    return response;
  }

  useEffect(() => {
    async function getScoreTypes(entity: PermissionsEntity, dateRange: GlobalDateRangeFilter | undefined, sortAscending: boolean, companyVariables: CompanyVariables): Promise<string[]> {
      const startDate = dateRange?.from?.toJSON();
      const endDate = dateRange?.to?.toJSON();
      const organizationLevel = entity.organizationLevel;
      const slug = entity.entity?.slug;

      const searchParams: ScoresSearchParams = {
        sortAscending,
        page: 1,
        pageSize: 99999,
        startDate,
        endDate,
        organizationLevel: organizationLevel === OrganizationLevelType.Company ? OrganizationLevelType.Department : organizationLevel === OrganizationLevelType.Department ? OrganizationLevelType.Team : OrganizationLevelType.Learner,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        scoreType: organizationLevel === OrganizationLevelType.Company || organizationLevel === OrganizationLevelType.Department ? ScoreType.SkillSubType : ScoreType.Skill,
        scoreTypeSlugs: undefined,
        scoreComparison: undefined,
      };
      if (organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = slug;
      if (organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = slug;
      if (organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug === slug;
      const response = await actions.analyticsGetAvailableScoreTypes(searchParams);

      const scoreTypeSlugs = response?.results.map(result => result.slug!) ?? [];
      return scoreTypeSlugs;
    }

    async function getData() {
      setInitializing(true);
      setLoading(true);

      if (!entity) {
        setLoading(false);
        setInitializing(false);
        return;
      }

      const tempHeatmapType = entity?.organizationLevel === OrganizationLevelType.Company ? 'Department' : entity?.organizationLevel === OrganizationLevelType.Department ? 'Team' : 'People';
      setHeatmapType(tempHeatmapType);

      const tempScoreTypeSlugs = await getScoreTypes(entity, dateRange, sortAscending, companyVariables);
      setScoreTypeSlugs(tempScoreTypeSlugs);

      setInitializing(false);
    }

    getData();
  }, [entity, dateRange, companyVariables.slug]);

  useEffect(() => {
    async function getAverageScores(entity: PermissionsEntity, dateRange: GlobalDateRangeFilter | undefined, sortAscending: boolean, companyVariables: CompanyVariables, scoreTypeSlugs: string[] | undefined): Promise<ScoresResponse | undefined> {
      const startDate = dateRange?.from?.toJSON();
      const endDate = dateRange?.to?.toJSON();
      const organizationLevel = entity.organizationLevel;
      const slug = entity.entity?.slug;

      const searchParams: ScoresSearchParams = {
        sortAscending,
        page: 1,
        pageSize: 10,
        startDate,
        endDate,
        organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        scoreType: organizationLevel === OrganizationLevelType.Company || organizationLevel === OrganizationLevelType.Department ? ScoreType.SkillSubType : ScoreType.Skill,
        scoreTypeSlugs,
        scoreComparison: undefined,
      };
      if (organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = slug;
      if (organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = slug;
      if (organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug = slug;
      const response = await actions.analyticsGetScoresByOrganizationLevel(searchParams);

      return response;
    }

    async function getData() {
      if (!entity || initializing) {
        setLoading(false);
        setInitializing(false);
        return;
      }

      setLoading(true);

      const scoresResponse = await getScores(entity, dateRange, sortAscending, companyVariables, currentPageNumber, scoreTypeSlugs);
      setScores(!!scoresResponse?.results ? transformScores(scoresResponse.results, companyVariables.useLevels, heatmapType) : undefined);
      setTotalPages(scoresResponse?.totalPages ?? 0);

      const averageScoresResponse = await getAverageScores(entity, dateRange, sortAscending, companyVariables, scoreTypeSlugs);
      setAverageScores(!!averageScoresResponse?.results ? transformScores(averageScoresResponse.results, companyVariables.useLevels, heatmapType, true) : undefined);

      setLoading(false);
    }

    getData();
  }, [initializing, heatmapType, scoreTypeSlugs]);

  async function onSortHandler() {
    let tempSortAscending = sortAscending;

    setSortAscending(currentState => {
      tempSortAscending = !currentState;
      return tempSortAscending;
    });

    if (!entity) return;

    setLoading(true);

    const scoresResponse = await getScores(entity, dateRange, tempSortAscending, companyVariables, currentPageNumber, scoreTypeSlugs);
    setScores(!!scoresResponse?.results ? transformScores(scoresResponse.results, companyVariables.useLevels, heatmapType) : undefined);
    setTotalPages(scoresResponse?.totalPages ?? 0);

    setLoading(false);
  }

  async function onPageChangeHandler(page: number) {
    if (page === currentPageNumber) return;
    setCurrentPageNumber(page);

    if (!entity) return;

    setLoading(true);

    const scoresResponse = await getScores(entity, dateRange, sortAscending, companyVariables, page, scoreTypeSlugs);
    setScores(!!scoresResponse?.results ? transformScores(scoresResponse.results, companyVariables.useLevels, heatmapType) : undefined);
    setTotalPages(scoresResponse?.totalPages ?? 0);

    setLoading(false);
  }

  function navigateTo(slug: string) {
    const organizationLevel = entity?.organizationLevel;
    if (organizationLevel === OrganizationLevelType.Company) keplerNavigate(`${PagePath.analyticsBase}${PagePath.analyticsDepartment.replace(':companySlug', companyVariables.slug!).replace(':departmentSlug', slug)}`);
    if (organizationLevel === OrganizationLevelType.Department) keplerNavigate(`${PagePath.analyticsBase}${PagePath.analyticsTeam.replace(':companySlug', companyVariables.slug!).replace(':teamSlug', slug)}`);
    if (organizationLevel === OrganizationLevelType.Team) keplerNavigate(`${PagePath.analyticsBase}${PagePath.analyticsPerson.replace(':companySlug', companyVariables.slug!).replace(':personSlug', slug)}`);
  }

  return (
    <SkeletonOverlay isLoading={loading || initializing} width='100%'>
      <div className="card">
        <h3 style={{ marginBottom: 30 }}>{`${heatmapType} ${companyVariables.useLevels ? 'levels' : 'percentages'}`}</h3>
        <Table emptyState={<EmptyState title='No heatmap data available' />} onSort={() => onSortHandler()} currentSortDirection={sortAscending ? 'Ascending' : 'Descending'} currentSortBy={heatmapType}>
          {[...(scores ?? []), ...(averageScores ?? [])].map((score, index) => (
            <TableRow key={score.name} id={score.name!}>
              {Object.entries(score)
                .filter(([key]) => key !== 'slug')
                .map(([key, value]) => (
                  <TableColumn
                    key={score.slug}
                    id={key}
                    locked={key === heatmapType}
                    sortable={key === heatmapType}
                    label={key}
                    heatmap={
                      key !== heatmapType
                        ? {
                          score: typeof value === 'number' ? Number(value) : String(value), // TODO
                          min: companyVariables.useLevels ? companyVariables.minLevel : undefined,
                          max: companyVariables.useLevels ? companyVariables.maxLevel : undefined,
                        }
                        : undefined
                    }
                    fontWeight={index === scores?.length ? '700' : 'inherit'}
                    width={220}
                  >
                    {key === heatmapType && !!score.slug && index !== scores?.length ? (
                      <Anchor
                        hovertype="opacity"
                        textTransform="none"
                        onClick={event => {
                          event.stopPropagation();
                          navigateTo(score.slug as string);
                        }}
                      >
                        {value}
                      </Anchor>
                    ) : (
                      value
                    )}
                  </TableColumn>
                ))}
            </TableRow>
          ))}
        </Table>

        {totalPages > 1 && (
          <div style={{ marginTop: 30 }}>
            <Pager activePageNumber={currentPageNumber} pageCount={totalPages} onPageChange={onPageChangeHandler} />
          </div>
        )}
      </div>
    </SkeletonOverlay>
  );
}
