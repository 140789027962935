import React from 'react';
import { IQuestionnaireStepHeaderProps } from './create-skill-assessment.models';

export function QuestionnaireStepHeader(props: IQuestionnaireStepHeaderProps): JSX.Element {
  return (
    <React.Fragment>
      <h3>{props.title}</h3>
      <p>{props.descriptions}</p>
    </React.Fragment>
  );
}
