export async function downloadCSV(data: string, fileName: string): Promise<void> {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${fileName}.csv`;
  return new Promise(resolve =>
    setTimeout(() => {
      anchor.click();
      resolve();
    })
  );
}
