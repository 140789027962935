import React from 'react';
import { SkeletonLoader } from '../../loaders/skeleton-loader/skeleton-loader';

export function PageSkeletonLoader({ skeletonBackgroundColor }: { skeletonBackgroundColor?: string }): JSX.Element {
  return (
    <div className="skeleton-loader-container" style={{ marginTop: 24 }}>
      <SkeletonLoader height="130px" backgroundColor={skeletonBackgroundColor} />

      <SkeletonLoader height="calc(100vh - 290px)" backgroundColor={skeletonBackgroundColor} />
    </div>
  );
}
