import React from 'react';
import { ScoreOverviewWidget } from '../../../../../widgets/analytics/score-overview/score-overview.widget';
import { AssessmentType } from '../../../../../enums/assessment-type';
import { CompetencyMatrixWidget } from '../../../../../widgets/analytics/competency-matrix/competency-matrix.widget';
import PathwaysSeniorityWidget from '../../../../../widgets/analytics/pathways-seniority/pathways-seniority.widget';
import { PerformanceOverviewWidget } from '../performance-overview/performance-overview.widget';
import { IAssessmentResultsLayoutProps } from './assessment-results.models';
import { YearsExperienceWidget } from '../../versus-score/years-experience.widget';
import { FrequencyUseWidget } from '../../versus-score/frequency-use.widget';
import analyticsStyles from '../../../analytics.module.css';
import AverageScoreSkillWidget from '../../../../../widgets/analytics/average-score-skill/average-score-per-skill.widget';

export function AssessmentResultsLayout({ filtersItems, baseSearchParams }: IAssessmentResultsLayoutProps): JSX.Element {
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 24 }}>
      <ScoreOverviewWidget baseSearchParams={baseSearchParams} />

      <AverageScoreSkillWidget baseSearchParams={baseSearchParams} filtersItems={filtersItems} />

      <CompetencyMatrixWidget baseSearchParams={baseSearchParams} filtersItems={filtersItems} />

      {baseSearchParams.assessmentType === AssessmentType.FieldsOfPractice && <PathwaysSeniorityWidget baseSearchParams={baseSearchParams} departmentItems={filtersItems.departmentItems} teamItems={filtersItems.teamItems} countryItems={filtersItems.countryItems} />}

      <PerformanceOverviewWidget baseSearchParams={baseSearchParams} departmentItems={filtersItems.departmentItems} teamItems={filtersItems.teamItems} roleItems={filtersItems.roleItems} countryItems={filtersItems.countryItems} />

      {baseSearchParams.assessmentType === AssessmentType.PeerEndorsement && (
        <div className={analyticsStyles.oneOneGrid}>
          <YearsExperienceWidget baseSearchParams={baseSearchParams} />
          <FrequencyUseWidget baseSearchParams={baseSearchParams} />
        </div>
      )}
    </div>
  );
}
