import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { BaseSearchRequest, Department, Employee, SkillAssessmentAssignees, Team } from '../../../../models';
import { useAppActions, useAppState } from '../../../../overmind';
import { FetchType, SkillAssessmentAssignee, FetchStatus, ActivationStatus } from '../../../../enums';
import { useParams } from 'react-router-dom';
import { CommonEntityProps, EntityAssignmentWidgetProps } from './entity-assignment-widget.models';
import { WidgetSkeleton } from '../widget.skeleton';
import { KeplerState } from '../../../../models/kepler-state';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Anchor, Autocomplete, AvatarIcon, FormControl, IconButton, ListLayout, RadioButtonCard, XIcon, colourString, search } from '@keplerco/core';
import styles from './entity-assignment.module.css';

export function EntityAssignmentWidget({ assessmentSlug, onStepComplete, assessmentAssignees }: EntityAssignmentWidgetProps) {
  const { control, setValue } = useForm<any>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [selectedEntity, setSelectedEntity] = useState('');

  const [availableTeamOptions, setAvailableTeamOptions] = useState<Team[]>();
  const [selectedTeamOptions, setSelectedTeamOptions] = useState<Team[]>([]);
  const [teams, setTeams] = useState<Array<any>>([]);

  const [availableEmployeeOptions, setAvailableEmployeeOptions] = useState<Employee[]>();
  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState<Employee[]>([]);
  const [employees, setEmployees] = useState<Array<Employee>>([]);

  const [availableDepartmentOptions, setAvailableDepartmentOptions] = useState<Department[] | undefined>();
  const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState<Department[]>([]);
  const [departments, setDepartments] = useState<Array<Department>>([]);

  const { fetchState } = useAppState<KeplerState>();

  React.useEffect(() => {
    setErrorMessage('');
  }, [selectedEntity]);

  const actions = useAppActions();
  const params = useParams();

  useEffect(() => {
    actions.startLoader({ path: PagePath.assessmentManagementCreate, type: FetchType.Custom });

    function populatePeople(): Promise<Employee[] | undefined> {
      const searchRequest: BaseSearchRequest = {
        companySlug: params.companySlug!,
        pageSize: 99999, // ridiculously large pageSize to fetch ALL Users
      };

      return actions.searchPeople(searchRequest).then(employeeSearchResponse => {
        if (!employeeSearchResponse) {
          return;
        }

        const employeeList: Employee[] = employeeSearchResponse.employees.map(person => ({
          id: person.id,
          firstName: person.firstName,
          lastName: person.lastName,
          email: person.email,
          jobTitle: '',
          periodOfExperience: 0,
          departmentId: person.department?.id || 0,
          teamId: person.team?.id || 0,
          countryId: person.country?.id || 0,
          isDepartmentManager: false,
          isTeamChampion: false,
          learningManagerId: '',
          learnerSlug: person.learnerSlug,
          departmentName: person.department?.name || '',
          teamName: person.team?.name || '',
          activationStatus: person.activationStatus,
          departmentSlug: '',
          teamSlug: '',
          dateCreated: new Date(person.dateCreated),
          skills: [],
          country: person.country?.name || '',
          languages: [],
        }));

        return employeeList;
      });
    }

    Promise.all([actions.getAssessmentsTeams(params.companySlug!), populatePeople(), actions.getAssessmentsDepartments(params.companySlug!)]).then(([teamsList, employeeList, departmentsList]) => {
      setTeams(teamsList ?? []);
      setEmployees(employeeList?.filter(employee => employee.activationStatus !== ActivationStatus.Archived) ?? []);
      setDepartments(departmentsList ?? []);

      if (assessmentAssignees) {
        if (assessmentAssignees.assessmentGroup === SkillAssessmentAssignee.Company) {
          setSelectedEntity('company');
        } else if (assessmentAssignees.departmentSlugs?.length) {
          setSelectedDepartmentOptions((departmentsList ?? []).filter(dept => assessmentAssignees.departmentSlugs?.includes(dept.slug)));
          setSelectedEntity('department');
        } else if (assessmentAssignees?.teamSlugs?.length) {
          const validTeamSlugs = assessmentAssignees.teamSlugs.filter((slug: any) => slug !== undefined) as string[];
          setSelectedTeamOptions(
            (teamsList ?? []).filter(team => {
              return team.slug !== undefined && validTeamSlugs.includes(team.slug);
            })
          );
          setSelectedEntity('team');
        } else if (assessmentAssignees.userIds?.length) {
          const validUserIds = assessmentAssignees.userIds.filter((id: any) => id !== undefined) as string[];
          setSelectedEmployeeOptions((employeeList ?? []).filter(emp => validUserIds.includes(emp.id ?? '')));
          setSelectedEntity('individual');
        }
      }

      actions.stopLoader(PagePath.assessmentManagementCreate);
    });
  }, [assessmentAssignees]);

  const searchEmployees = (query: string) => {
    return search(employees, query, 'firstName').concat(search(employees, query, 'lastName'));
  };

  const searchDepartments = (query: string) => {
    return search(departments, query, 'name');
  };

  const departmentLabel = (department: Department) => {
    return department.name;
  };

  const employeeLabel = (employee: Employee) => {
    return `${employee.firstName} ${employee.lastName}`;
  };

  const searchTeams = (query: string) => {
    return search(teams, query, 'teamName');
  };

  const teamLabel = (team: Team) => {
    return team.teamName;
  };

  async function handleSubmitForm() {
    setErrorMessage(null);

    if (!selectedEntity) {
      setErrorMessage('Please select an entity.');
      return;
    }

    if (selectedEntity === 'department' && selectedDepartmentOptions.length === 0) {
      setErrorMessage('At least one department needs to be selected.');
      return;
    }

    if (selectedEntity === 'team' && selectedTeamOptions.length === 0) {
      setErrorMessage('At least one team needs to be selected.');
      return;
    }

    if (selectedEntity === 'individual' && selectedEmployeeOptions.length === 0) {
      setErrorMessage('At least one individual needs to be selected.');
      return;
    }

    const assigneeModel: SkillAssessmentAssignees = {
      assessmentSlug: assessmentSlug ?? params.slug,
      assessmentGroup: SkillAssessmentAssignee.Company,
      includeLearnersWithNoDepartment: false,
      departmentSlugs: [],
      teamSlugs: [],
      userIds: [],
    };

    switch (selectedEntity) {
      case 'company':
        assigneeModel.assessmentGroup = SkillAssessmentAssignee.Company;
        break;
      case 'department':
        assigneeModel.assessmentGroup = SkillAssessmentAssignee.Department;
        assigneeModel.departmentSlugs = selectedDepartmentOptions.map(dept => dept.slug);
        break;

      case 'team':
        assigneeModel.assessmentGroup = SkillAssessmentAssignee.Team;
        assigneeModel.teamSlugs = selectedTeamOptions.map(team => team.slug as string);
        break;

      case 'individual':
        assigneeModel.assessmentGroup = SkillAssessmentAssignee.Learner;
        assigneeModel.userIds = selectedEmployeeOptions.map(emp => emp.id as string);
        break;

      default:
        return;
    }

    actions.startLoader({ path: PagePath.assessmentManagementCreate, type: FetchType.Custom });
    await actions.saveSkillAssessmentAssignees({ assessmentAssignee: assigneeModel, companySlug: params.companySlug! });
    actions.stopLoader(PagePath.assessmentManagementCreate);
    onStepComplete();
  }

  return fetchState[PagePath.assessmentManagementCreate].status === FetchStatus.Active && fetchState[PagePath.assessmentManagementCreate].type === FetchType.Custom ? (
    <WidgetSkeleton />
  ) : (
    <Fragment>
      <form>
        <FormControl
          name="entityType"
          control={control}
          render={({ field }) => (
            <ListLayout wrap>
              {['Company', 'Department', 'Team', 'Individual'].map(entity => (
                <RadioButtonCard
                  key={entity}
                  id={entity.toLowerCase()}
                  {...field}
                  value={entity.toLowerCase()}
                  checked={selectedEntity === entity.toLowerCase()}
                  onClick={() => {
                    if (selectedEntity !== entity.toLowerCase()) {
                      switch (selectedEntity) {
                        case 'department':
                          setSelectedDepartmentOptions([]);
                          break;
                        case 'team':
                          setSelectedTeamOptions([]);
                          break;
                        case 'individual':
                          setSelectedEmployeeOptions([]);
                          break;
                        default:
                          break;
                      }
                    }
                    if (selectedEntity === entity.toLowerCase()) {
                      setSelectedEntity('');
                      setValue('entityType', '');
                    } else {
                      setSelectedEntity(entity.toLowerCase());
                      setValue('entityType', entity.toLowerCase());
                    }
                  }}
                >
                  <div className="card">
                    <label htmlFor={entity.toLowerCase()} className="subtitle" style={{ color: 'var(--accent-3)' }}>
                      {entity}
                    </label>
                  </div>
                </RadioButtonCard>
              ))}
            </ListLayout>
          )}
        />
      </form>

      {errorMessage && (
        <div className="formErrorMessage" style={{ marginBottom: 20 }}>
          {errorMessage}
        </div>
      )}

      {selectedEntity === 'department' && (
        <CommonEntityComponent
          availableOptions={availableDepartmentOptions?.sort((a, b) => a.name.localeCompare(b.name))}
          selectedOptions={selectedDepartmentOptions}
          setAvailableOptions={setAvailableDepartmentOptions}
          setSelectedOptions={setSelectedDepartmentOptions}
          allOptions={departments}
          searchResults={searchDepartments}
          optionLabel={departmentLabel}
          name="department"
          label="Select Departments"
        />
      )}

      {selectedEntity === 'individual' && (
        <CommonEntityComponent
          availableOptions={availableEmployeeOptions}
          selectedOptions={selectedEmployeeOptions}
          setAvailableOptions={setAvailableEmployeeOptions}
          setSelectedOptions={setSelectedEmployeeOptions}
          allOptions={employees}
          searchResults={searchEmployees}
          optionLabel={employeeLabel}
          name="employee"
          label="Select Employees"
        />
      )}

      {selectedEntity === 'team' && (
        <CommonEntityComponent
          availableOptions={availableTeamOptions?.sort((a, b) => a.teamName.localeCompare(b.teamName))}
          selectedOptions={selectedTeamOptions}
          setAvailableOptions={setAvailableTeamOptions}
          setSelectedOptions={setSelectedTeamOptions}
          allOptions={teams}
          searchResults={searchTeams}
          optionLabel={teamLabel}
          name="team"
          label="Select Teams"
        />
      )}

      <footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Anchor arrow onClick={handleSubmitForm}>
          Next
        </Anchor>
      </footer>
    </Fragment>
  );
}

function CommonEntityComponent({ availableOptions, selectedOptions, setAvailableOptions, setSelectedOptions, optionLabel, name, label, searchResults }: CommonEntityProps<any>) {
  function handleDeselectAll() {
    setSelectedOptions([]);
  }

  return (
    <div style={{ marginBottom: 30 }}>
      <h4 style={{ color: 'var(--accent-3)' }}>{label}</h4>

      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        {/* TODO: remove 'Autocomplete'from Core once this is removed */}
        <Autocomplete
          zIndex={999}
          responsive
          fixDropdownPosition={true}
          borderColor={'borders'}
          backgroundColor={'background'}
          name={name}
          onSearch={query => {
            if (!!query) {
              setAvailableOptions(searchResults(query));
            } else setAvailableOptions(void 0);
          }}
          label={`Search for ${name}`}
        >
          {!!availableOptions &&
            (() => {
              if (!availableOptions.length) return <h3 style={{ padding: '0 15px', color: colourString('default') }}>No results</h3>;

              return (
                <div className="autocompleteDropdownList">
                  {availableOptions.map(option => {
                    const isSelected = !!selectedOptions.find(o => o.id === option.id);

                    return (
                      <div
                        key={option.id}
                        className={classNames('autocompleteDropdownListItem', styles.dropdownListItem, { selected: isSelected })}
                        onClick={() => {
                          setSelectedOptions(isSelected ? selectedOptions.filter(selectedOption => selectedOption.id !== option.id) : [...selectedOptions, option]);
                        }}
                      >
                        <div className="autocompleteDropdownListItemCheck">
                          <span />
                        </div>

                        <div className={styles.avatar}>
                          <AvatarIcon name={{ preferredName: optionLabel(option) }} />
                        </div>

                        <div className="autocompleteDropdownListItemLabel">
                          {optionLabel(option)} {option.email && option.email}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </Autocomplete>
      </div>

      {!!selectedOptions.length && (
        <React.Fragment>
          <div className={styles.selectedOptionsTitle}>
            <h4 style={{ color: 'var(--accent-3)' }}>Selected Options: ({selectedOptions.length})</h4>

            {selectedOptions.length > 1 && (
              <Anchor variant="tiny" style={{ paddingBottom: 0 }} onClick={() => handleDeselectAll()}>
                Deselect All
              </Anchor>
            )}
          </div>

          <ListLayout>
            <div className="card">
              {selectedOptions.map(option => {
                return (
                  <div className={styles.listItem} key={option.id}>
                    <div className={styles.listItemContent}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                        <div className={styles.avatar}>
                          <AvatarIcon name={{ preferredName: optionLabel(option) }} />
                        </div>

                        <div>{optionLabel(option)}</div>
                      </div>

                      <IconButton
                        iconType="fill"
                        onClick={() => {
                          const nextSelectedOptions = selectedOptions.filter(selectedOption => selectedOption.id !== option.id);
                          setSelectedOptions(
                            nextSelectedOptions.length === selectedOptions.length
                              ? [...selectedOptions, option] // add option
                              : nextSelectedOptions // remove option
                          );
                        }}
                      >
                        <XIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </div>
          </ListLayout>
        </React.Fragment>
      )}
    </div>
  );
}
