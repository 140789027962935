import { IconProps } from '@keplerco/core';
import React from 'react';

export function CircleIcon({ size = 80 }: IconProps): React.ReactElement {
  return (
    <svg width={size} height={size} viewBox="0 0 81 81" fill="none">
      <circle cx="40.8522" cy="40.4385" r="39.3921" stroke="var(--baby-blue)" strokeWidth="1.21586" />
      <circle cx="40.6803" cy="40.2667" r="32.8282" fill="var(--baby-blue)" />
    </svg>
  );
}
