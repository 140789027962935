import React from 'react';
import { PageHeader, ListLayout, ListItemLayout, ChevronIcon, useMatchScreenWidth } from '@keplerco/core';
import { PageLoaderLayer } from '../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../../../navigation/navigation.enums';
import { useAppState } from '../../../../overmind';
import CustomRoleIcon from '../../../../design/icons/custom-role';
import AIRoleIcon from '../../../../design/icons/ai-role.icon';
import ImportRoleIcon from '../../../../design/icons/import-role.icon';
import { RoleCreationWizardStep } from './roles-wizard.models';
import KeplerNavlink from '../../../../navigation/guards/kepler-navlink';
import classNames from 'classnames';
import styles from './roles-wizard.module.css';

export function RolesWizardPage(): JSX.Element {
  const { companyVariables } = useAppState();

  const isMobile = useMatchScreenWidth('mobile');

  const roleCreationWizardSteps: RoleCreationWizardStep[] = [
    {
      icon: <AIRoleIcon size={isMobile ? 80 : 100} />,
      title: 'Build a role with Kepler AI',
      description: 'Get AI-driven suggestions to quickly define skills, responsibilities and requirements for any role.',
      link: `${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizardKeplerAI.replace(':companySlug', companyVariables.slug!)}`,
    },
    {
      icon: <CustomRoleIcon size={isMobile ? 80 : 100} />,
      title: 'Create a custom role',
      description: `Start with a clean slate to design a fully customised role profile, tailored to your organisation's unique needs.`,
      link: `${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizardCustomRole.replace(':companySlug', companyVariables.slug!)}`,
    },
    {
      icon: <ImportRoleIcon size={isMobile ? 80 : 100} />,
      title: 'Import a role from an established framework',
      description: 'Choose from structured role templates based on industry standards to save time and ensure accuracy.',
      link: `${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizardImportRole.replace(':companySlug', companyVariables.slug!)}`,
    },
  ];

  return (
    <PageLoaderLayer path={PagePath.roleSkillManagementWizard}>
      <div className="wrapper stack" style={{ gap: 24 }}>
        <PageHeader
          breadcrumbs={[
            { name: 'Role & Skill Management', url: `${PagePath.roleSkillManagementBase}` },
            { name: 'Role wizard', url: `${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizard.replace(':companySlug', companyVariables.slug!)}` },
          ]}
          title="Role wizard"
          subtitle="Define job roles, assign essential skills, and set performance standards."
        />

        <ListLayout gap={12}>
          {roleCreationWizardSteps.map(card => (
            <ListItemLayout key={card.title}>
              <KeplerNavlink to={card.link} className={classNames('card', 'action-card', 'interactive', styles.content)}>
                {card.icon}
                <div className="action-card-content">
                  <h3>{card.title}</h3>
                  <p>{card.description}</p>
                </div>
                <ChevronIcon tone="primary" strokeWidth={1} size={40} />
              </KeplerNavlink>
            </ListItemLayout>
          ))}
        </ListLayout>
      </div>
    </PageLoaderLayer>
  );
}
