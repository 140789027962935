import React, { useEffect, useState } from 'react';
import { OrganizationLevelType } from '../../../enums';
import { ISkillsBreakdownWidgetProps, SkillsBreakdownSkill } from './skills-breakdown.models';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../overmind';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { SkillScoresSearchParams } from '../../../models/overmind/search-params';
import { SkillScoresRequest, SkillScoresSkillTypeResponse } from '../../../models/overmind/analytics';
import { ResponseGroup } from '../../../enums/analytics';
import { HelpIcon, IPillButton, IconButton, OrderButton, OrderType, Pager, PillButton, SkillLevel, SkillPercentage, scorePercentageRAGColour, scoreLevelRAGColour, Panel, useMatchScreenWidth } from '@keplerco/core';
import { SkillLevelsExplainerPanelLayout } from '../../layouts/focus-panel-layouts/skill-levels-explainer-panel/skill-levels-explainer-panel.layout';
import styles from './skills-breakdown.module.css';
import classNames from 'classnames';
import KeplerNavlink from '../../../navigation/guards/kepler-navlink';
import { comparisonScoreTitle } from '../../../library/helpers/comparison-score-title';
import { ScoreComparison } from '../../../enums/score-comparison';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';

const sortTypes: Map<OrderType, string> = new Map<OrderType, string>([
  [OrderType.Ascending, 'Low to High'],
  [OrderType.Descending, 'High to Low'],
  [OrderType.Organic, 'Alphabetical'],
]);
const pageSize = 10;

export function SkillsBreakdownWidget({ entity, path }: ISkillsBreakdownWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, dateRange } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [skillTypes, setSkillTypes] = useState<SkillScoresSkillTypeResponse[]>([]);

  const [filters, setFilters] = useState<IPillButton[]>([]);
  const [activeFilter, setActiveFilter] = useState<IPillButton>();
  const [filteredSkills, setFilteredSkills] = useState<SkillsBreakdownSkill[]>([]);

  const [activeSortType, setActiveSortType] = useState<OrderType>(OrderType.Organic);
  const [sortedSkills, setSortedSkills] = useState<SkillsBreakdownSkill[]>([]);

  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [pagedSkills, setPagedSkills] = useState<SkillsBreakdownSkill[]>([]);

  const [open, setOpen] = useState<boolean>(false);

  const isMobile = useMatchScreenWidth('mobile');

  useEffect(() => {
    async function getData() {

      if (!entity) {
        setLoading(false);
        return;
      }

      setLoading(true);

      const searchParams: SkillScoresSearchParams = {
        startDate: path === PagePath.dashboard ? undefined : dateRange?.from?.toJSON(),
        endDate: path === PagePath.dashboard ? undefined : dateRange?.to?.toJSON(),
        organizationLevel: entity.organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        learnerSlug: undefined,
        teamSlug: undefined,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
        includeLearnerCount: false,
        scoreComparison: companyVariables.scoreComparison,
      };
      if (entity.organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug = entity.entity.slug;

      const request: SkillScoresRequest = {
        responseGroup: ResponseGroup.Skill,
        searchParams: searchParams,
      };

      const tempData = await actions.analyticsGetSkillScores(request);

      const tempSkillTypes: SkillScoresSkillTypeResponse[] = !tempData || !tempData[0] || !tempData[0].skillTypes ? [] : tempData[0].skillTypes;

      tempSkillTypes.forEach(skillType => skillType.skillSubTypes?.forEach(skillSubType => skillSubType.skills?.sort((a, b) => a.name.localeCompare(b.name))));
      setSkillTypes(tempSkillTypes);

      const tempFilters: IPillButton[] = [];
      tempSkillTypes.forEach(skillType => {
        const hasValidSkills = skillType.skillSubTypes.some(skillSubType => skillSubType.skills && skillSubType.skills.length > 0);

        if (hasValidSkills) {
          tempFilters.push({ id: skillType.type, label: `${skillType.name} skills` });
        }
      });

      setFilters(tempFilters);

      setLoading(false);
    }

    getData();
  }, [companyVariables.slug, dateRange, entity]);

  function filterSkills(tempActiveFilter: IPillButton) {
    if (!skillTypes) return setFilteredSkills([]);

    const tempSkillType = skillTypes.find(skillType => skillType.type === tempActiveFilter.id);
    const tempSkills: SkillsBreakdownSkill[] = [];
    tempSkillType?.skillSubTypes?.forEach(skillSubType => {
      skillSubType.skills?.forEach(skill =>
        tempSkills.push({
          name: skill.name,
          slug: skill.slug,
          score: skill.score,
          comparisonScore: skill.comparisonScore,
          peopleCount: skill.peopleCount,
          skillSubTypeSlug: skillSubType.slug,
        })
      );
    });
    setFilteredSkills(tempSkills);
  }

  // filter
  useEffect(() => {
    setActiveFilter(filters[0]);
    filterSkills(filters[0]);
  }, [skillTypes]);

  function sortSkills(tempActiveSortType: OrderType) {
    if (!filteredSkills) return setSortedSkills([]);

    const tempSkills: SkillsBreakdownSkill[] = structuredClone(filteredSkills);
    tempSkills.sort((a, b) => {
      if (tempActiveSortType === OrderType.Organic) return a.name.localeCompare(b.name);
      if (tempActiveSortType === OrderType.Ascending) return (a.score?.percentage ?? 0) - (b.score?.percentage ?? 0);
      if (tempActiveSortType === OrderType.Descending) return (b.score?.percentage ?? 0) - (a.score?.percentage ?? 0);
      return 0;
    });
    setSortedSkills(tempSkills);
  }

  // sort
  useEffect(() => {
    sortSkills(OrderType.Organic);
  }, [filteredSkills]);

  function pageSkills(tempStart: number, tempEnd: number) {
    if (!sortedSkills) return setPagedSkills([]);

    let tempSkills: SkillsBreakdownSkill[] = structuredClone(sortedSkills);
    tempSkills = tempSkills.slice(tempStart, tempEnd);
    setPagedSkills(tempSkills);
  }

  // page
  useEffect(() => {
    setActivePageNumber(1);
    pageSkills(0, pageSize);
  }, [sortedSkills]);

  function onFilterChangeHandler(filter: IPillButton) {
    setActiveFilter(filter);
    filterSkills(filter);
  }

  function onSortChangeHandler(sortType: OrderType) {
    setActiveSortType(sortType);
    sortSkills(sortType);
  }

  function onPageChangeHandler(pageNumber: number) {
    setActivePageNumber(pageNumber);
    const end = pageNumber * pageSize;
    const start = end - pageSize;
    pageSkills(start, end);
  }

  return (
    <SkeletonOverlay isLoading={loading}>
      <div className="card">
        {!pagedSkills.length ? (
          <EmptyState title="No skills found" />
        ) : (
          <React.Fragment>
            <div className={styles.container}>
              <header className={styles.header}>
                <h3>
                  Skills breakdown
                  {path !== PagePath.dashboard && companyVariables.useLevels && (
                    <IconButton iconType="fill" onClick={() => setOpen(true)}>
                      <HelpIcon />
                    </IconButton>
                  )}
                </h3>

                <OrderButton labelMap={sortTypes} defaultOrderType={activeSortType} onChange={onSortChangeHandler} />
              </header>

              {filters.length > 1 && (
                <div className="pillButtonsContainer">
                  {filters.map(filter => (
                    <PillButton key={filter.id} label={filter.label} active={activeFilter?.id === filter.id} backgroundColour="borders_2" activeTextColour="text" square onClick={() => onFilterChangeHandler(filter)} />
                  ))}
                </div>
              )}

              <div
                className={styles.body}
                style={{
                  gridTemplateColumns: isMobile
                    ? `minmax(0px, 1fr) 90px ${path === PagePath.dashboard && companyVariables.scoreComparison !== ScoreComparison.None ? '90px' : ''}`
                    : `minmax(0px, 1fr) 145px ${path === PagePath.dashboard && companyVariables.scoreComparison !== ScoreComparison.None ? '145px' : ''}`,
                }}
              >
                <div>
                  <div className={classNames(styles.column, styles.label)}>Skill name</div>

                  {pagedSkills.map(skill => {
                    const partialPath = !!skill.skillSubTypeSlug
                      ? PagePath.analyticsSkillSubType.replace(':companySlug', companyVariables.slug!).replace(':skillSubTypeSlug', skill.skillSubTypeSlug).replace(':skillSlug', skill.slug)
                      : PagePath.analyticsSkill.replace(':companySlug', companyVariables.slug!).replace(':skillSlug', skill.slug);

                    return (
                      <div key={`${skill.skillSubTypeSlug}-${skill.slug}`} className={classNames(styles.column, styles.name)} title={skill.name}>
                        {path !== PagePath.dashboard ? (
                          <KeplerNavlink to={`${PagePath.analyticsBase}${partialPath}`} className={styles.link}>
                            {skill.name}
                          </KeplerNavlink>
                        ) : (
                          <React.Fragment>{skill.name}</React.Fragment>
                        )}
                      </div>
                    );
                  })}
                </div>

                {companyVariables.useLevels ? (
                  <div>
                    <div className={classNames(styles.column, styles.label)}>Skill level</div>

                    {pagedSkills.map(skill => (
                      <div key={`${skill.skillSubTypeSlug}-${skill.slug}`} className={styles.column}>
                        <SkillLevel
                          level={skill.score?.level ?? companyVariables.minLevel}
                          minLevel={companyVariables.minLevel}
                          maxLevel={companyVariables.maxLevel}
                          noLevel={!skill.score}
                          dotColour={path !== PagePath.dashboard ? scoreLevelRAGColour(skill.score?.level ?? 0, companyVariables.minLevel, companyVariables.maxLevel) : 'baby-blue'}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <div className={classNames(styles.column, styles.label)}>Skill percentage</div>

                    {pagedSkills.map(skill => (
                      <div key={`${skill.skillSubTypeSlug}-${skill.slug}`} className={styles.column}>
                        <SkillPercentage percentage={skill.score?.percentage ?? 0} noPercentage={!skill.score} barColour={path !== PagePath.dashboard ? scorePercentageRAGColour(skill.score?.percentage ?? 0) : 'baby-blue'} />
                      </div>
                    ))}
                  </div>
                )}

                {path === PagePath.dashboard && companyVariables.scoreComparison !== ScoreComparison.None && (
                  <React.Fragment>
                    {companyVariables.useLevels ? (
                      <div>
                        <div className={classNames(styles.column, styles.label)}>{comparisonScoreTitle(companyVariables.scoreComparison)}</div>

                        {pagedSkills.map(skill => (
                          <div key={`${skill.skillSubTypeSlug}-${skill.slug}`} className={styles.column}>
                            <SkillLevel
                              level={skill.comparisonScore?.level ?? companyVariables.minLevel}
                              minLevel={companyVariables.minLevel}
                              maxLevel={companyVariables.maxLevel}
                              noLevel={!skill.comparisonScore}
                              dotColour={path !== PagePath.dashboard ? scoreLevelRAGColour(skill.comparisonScore?.level ?? 0, companyVariables.minLevel, companyVariables.maxLevel) : 'baby-blue'}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        <div className={classNames(styles.column, styles.label)}>{comparisonScoreTitle(companyVariables.scoreComparison)}</div>

                        {pagedSkills.map(skill => (
                          <div key={`${skill.skillSubTypeSlug}-${skill.slug}`} className={styles.column}>
                            <SkillPercentage percentage={skill.comparisonScore?.percentage ?? 0} noPercentage={!skill.comparisonScore} barColour={path !== PagePath.dashboard ? scorePercentageRAGColour(skill.comparisonScore?.percentage ?? 0) : 'baby-blue'} />
                          </div>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>

              {sortedSkills.length > pageSize && <Pager activePageNumber={activePageNumber} pageCount={Math.ceil(sortedSkills.length / pageSize)} onPageChange={onPageChangeHandler} />}
            </div>

            {path !== PagePath.dashboard && companyVariables.useLevels && (
              <Panel open={open} onClose={() => setOpen(false)}>
                <SkillLevelsExplainerPanelLayout onClose={() => setOpen(false)} />
              </Panel>
            )}
          </React.Fragment>
        )}
      </div>
    </SkeletonOverlay>
  );
}
