import React, { Fragment } from 'react';
import { HorizontalBarChart } from '../../../../components/charts/horizontal-bar-chart/horizontal-bar-chart';
import { EmptyState } from '../../../../components/general/empty-state/empty-state';
import { IRoleFitContentProps } from './role-fit-content.models';
import { AstronautLoader } from '../../../../components/general/loading-state/loaders/astronaut-loader/astronaut-loader';

export function RoleFitContent({ roleSlug, peopleSlugs, data, isLoading, title, fill }: IRoleFitContentProps): JSX.Element {
  function renderContent() {
    if (!roleSlug) return <EmptyState title="Please select a role" />;

    if (!peopleSlugs.length) return <EmptyState title="Please select at least one person" />;

    if (!data?.length) return <EmptyState />;

    return <HorizontalBarChart dataPoints={data} fill={fill} showLegend />;
  }

  return (
    <div className="card">
      {isLoading ? (
        <AstronautLoader />
      ) : (
        <Fragment>
          <h3>{title}</h3>
          {renderContent()}
        </Fragment>
      )}
    </div>
  );
}
