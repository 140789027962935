import React from 'react';
import styles from './score-overview-card.module.css';
import { SkillLevel, SkillPercentage, scorePercentageRAGColour, scoreLevelRAGColour } from '@keplerco/core';
import { useAppState } from '../../../../overmind';
import { IScoreOverviewCardProps } from './score-overview-card.models';
import { EmptyState } from '../../../../components/general/empty-state/empty-state';

export function ScoreOverviewCard({ score, scoreTitle, cardTitle, cardSubtitle }: IScoreOverviewCardProps) {
  const { companyVariables } = useAppState();

  return (
    <div className="card" style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      <div>
        <h5 className={styles.cardTitle}>{cardTitle}</h5>
        <small>{cardSubtitle}</small>
      </div>

      <div className={styles.cardContent}>
        {!score ?
          (<EmptyState title="Hang tight!" subtitle="We are waiting for data." height={150} />) :
          (companyVariables.useLevels ? (
            <SkillLevel
              level={score?.level ?? companyVariables.minLevel}
              minLevel={companyVariables.minLevel}
              maxLevel={companyVariables.maxLevel}
              noLevel={!score}
              dotColour={scoreLevelRAGColour(score?.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel)}
              title={scoreTitle}
            />
          ) : (
            <SkillPercentage percentage={score?.percentage ?? 0} noPercentage={!score} barColour={scorePercentageRAGColour(score?.percentage ?? 0)} title={scoreTitle} />
          ))}
      </div>
    </div>
  );
}
