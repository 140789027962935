import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../../overmind';
import { FetchStatus, FetchType } from '../../../../enums';
import { KeplerState } from '../../../../models/kepler-state';
import { WidgetSkeleton } from '../widget.skeleton';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Anchor, RadioButtonCard, TextField, ToggleCard, useMatchScreenWidth } from '@keplerco/core';
import styles from './preferences.module.css';
import classNames from 'classnames';
import { AssessmentPreferencesResponse } from '../../../../models/overmind/assessment-preferences';
import { AssessmentType } from '../../../../enums/assessment-type';
import { defaultPreferences } from './preferences.helpers';

export function PreferencesWidget({ assessmentSlug, onNext: onNext, assessmentType }: { assessmentSlug: string; onNext: (preferences: AssessmentPreferencesResponse) => void; assessmentType: AssessmentType }): JSX.Element {
  const actions = useAppActions();
  const { fetchState, companyVariables } = useAppState<KeplerState>();

  const isMobile = useMatchScreenWidth('mobile');

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [preferences, setPreferences] = useState<AssessmentPreferencesResponse>(defaultPreferences);

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.assessmentManagementCreate, type: FetchType.Custom });
      const response = await actions.getAssessmentPreferences({ companySlug: companyVariables.slug!, assessmentSlug: assessmentSlug });
      setPreferences(response ?? defaultPreferences);
      actions.stopLoader(PagePath.assessmentManagementCreate);
    }
    getData();
  }, [assessmentSlug, companyVariables.slug]);

  return fetchState[PagePath.assessmentManagementCreate].status === FetchStatus.Active && fetchState[PagePath.assessmentManagementCreate].type === FetchType.Custom ? (
    <WidgetSkeleton />
  ) : (
    <React.Fragment>
      <div>
        {/* reassessment */}
        {assessmentType !== AssessmentType.PeerEndorsement && (
          <div className={styles.preferenceGroup}>
            <h4>Reassessment</h4>

            <div className="row">
              <ToggleCard
                id="allowReassessment"
                value={preferences?.allowReassessment ?? false}
                onChange={(_, value) => {
                  setPreferences(currentState => {
                    const nextState = structuredClone(currentState);
                    nextState.allowReassessment = value;
                    return nextState;
                  });
                }}
              >
                <label htmlFor="allowReassessment" className="h3">
                  Allow reassessment
                </label>

                {!isMobile && (
                  <span className="body" style={{ color: 'var(--accent-2' }}>
                    Allow the learners to reset and retake the assessment by themselves
                  </span>
                )}
              </ToggleCard>
            </div>
          </div>
        )}

        {/* assessment scores */}
        <div className={styles.preferenceGroup}>
          <h4>Assessment scores</h4>

          <div className="row">
            <div className="column">
              <TextField
                label="Minimum score"
                type="number"
                defaultValue={preferences.minScore}
                min={0}
                onChange={event => {
                  setPreferences(currentState => {
                    const nextState = structuredClone(currentState);
                    nextState.minScore = parseInt(event.target.value);
                    return nextState;
                  });
                }}
                responsive
                validation={{
                  dirty: isDirty,
                  invalid: preferences.minScore === undefined || preferences.minScore >= preferences.maxScore || preferences.minScore < 0,
                  message: 'Minimum score is required and must be less than maximum score and greater than 0',
                }}
              />
            </div>

            <div className="column">
              <TextField
                label="Maximum score"
                type="number"
                defaultValue={preferences.maxScore}
                max={100}
                onChange={event => {
                  setPreferences(currentState => {
                    const nextState = structuredClone(currentState);
                    nextState.maxScore = parseInt(event.target.value);
                    return nextState;
                  });
                }}
                responsive
                validation={{
                  dirty: isDirty,
                  invalid: preferences.maxScore === undefined || preferences.minScore >= preferences.maxScore || preferences.maxScore > 100,
                  message: 'Maximum score is required and must be greater than minimum score and less than 100',
                }}
              />
            </div>
          </div>
        </div>

        {/* force assessment */}
        <div className={styles.preferenceGroup}>
          <h4>Do you want to force completion of this assessment once activated?</h4>

          {!isMobile && (
            <span className="body" style={{ color: 'var(--accent-2)', marginTop: 10 }}>
              If you force the completion of an assessment, the next time someone logs in they will be required to complete the assessment before continuing
            </span>
          )}

          <div className="row">
            <div className="column">
              <RadioButtonCard
                id="forceAssessmentTrue"
                checked={!!preferences?.forceAssessment}
                onClick={() => {
                  setPreferences(currentState => {
                    const nextState = structuredClone(currentState);
                    nextState.forceAssessment = true;
                    return nextState;
                  });
                }}
              >
                <div className="card">Yes, force assessment</div>
              </RadioButtonCard>
            </div>

            <div className="column">
              <RadioButtonCard
                id="forceAssessmentFalse"
                checked={!preferences?.forceAssessment}
                onClick={() => {
                  setPreferences(currentState => {
                    const nextState = structuredClone(currentState);
                    nextState.forceAssessment = false;
                    return nextState;
                  });
                }}
              >
                <div className="card">No, make optional</div>
              </RadioButtonCard>
            </div>
          </div>

          <span className={classNames('body', { invisible: !preferences.forceAssessment })} style={{ color: 'var(--accent-2)' }}>
            Great! We'll let people know to complete the assessment.
          </span>
        </div>
      </div>

      {/* reviewers */}
      {assessmentType === AssessmentType.PeerEndorsement && (
        <div className={styles.preferenceGroup}>
          <h4>Reviewers</h4>

          {!isMobile && (
            <span className="body" style={{ color: 'var(--accent-2)', marginTop: 10 }}>
              Choose who should review each other for this assessment
            </span>
          )}

          <div className="row">
            <ToggleCard
              id="allowSelfReview"
              value={preferences?.allowSelfReview ?? false}
              onChange={(_, value) => {
                setPreferences(currentState => {
                  const nextState = structuredClone(currentState);
                  nextState.allowSelfReview = value;
                  return nextState;
                });
              }}
            >
              <label htmlFor="allowSelfReview" className="h3">
                Self Review
              </label>

              {!isMobile && (
                <span className="body" style={{ color: 'var(--accent-2' }}>
                  Allow the assignees to complete a self review
                </span>
              )}
            </ToggleCard>
          </div>

          <div className="row">
            <ToggleCard
              id="allowManagerReview"
              value={preferences?.allowManagerReview ?? false}
              onChange={(_, value) => {
                setPreferences(currentState => {
                  const nextState = structuredClone(currentState);
                  nextState.allowManagerReview = value;
                  return nextState;
                });
              }}
            >
              <label htmlFor="allowManagerReview" className="h3">
                Manager Review
              </label>

              {!isMobile && (
                <span className="body" style={{ color: 'var(--accent-2' }}>
                  Allow the users' manager to complete a review
                </span>
              )}
            </ToggleCard>
          </div>

          <div className="row">
            <ToggleCard
              id="allowPeerReview"
              value={preferences?.allowPeerReview ?? false}
              onChange={(_, value) => {
                setPreferences(currentState => {
                  const nextState = structuredClone(currentState);
                  nextState.allowPeerReview = value;
                  return nextState;
                });
              }}
            >
              <label htmlFor="allowPeerReview" className="h3">
                Peer Review
              </label>

              {!isMobile && (
                <span className="body" style={{ color: 'var(--accent-2' }}>
                  Allow the user to review their team members
                </span>
              )}
            </ToggleCard>
          </div>

          <div className={classNames('fieldErrorMessage', { invisible: !isDirty || preferences.allowSelfReview || preferences.allowManagerReview || preferences.allowPeerReview })}>Activate at least one reviewer toggle</div>
        </div>
      )}

      <div className={styles.submitContainer}>
        <Anchor
          arrow
          onClick={async () => {
            setIsDirty(true);

            if (preferences.minScore === undefined || preferences.maxScore === undefined || preferences.minScore >= preferences.maxScore || preferences.minScore < 0 || preferences.maxScore > 100) return;
            if (assessmentType === AssessmentType.PeerEndorsement && !preferences.allowSelfReview && !preferences.allowManagerReview && !preferences.allowPeerReview) return;

            await actions.updateAssessmentPreferences({
              ...preferences,
              companySlug: companyVariables.slug!,
              assessmentSlug: assessmentSlug,
            });
            onNext(preferences);
          }}
        >
          Next
        </Anchor>
      </div>
    </React.Fragment>
  );
}
