import React from 'react';
import { IEmptyStateProps } from './empty-state.models';
import styles from './empty-state.module.css';
import { EmptyStateImage } from './empty-state.image';

export function EmptyState({ hideImage, imageSource, imageElement, titleColor, title, subtitle, buttonElements, height }: IEmptyStateProps): React.ReactElement {
  return (
    <div className={styles.container} style={{ height }}>
      {!hideImage && (
        <React.Fragment>
          {!!imageSource ? (
            <object data={imageSource} type="image/svg+xml" className={styles.image} aria-label="Empty state icon">
              <img src={imageSource} alt="" className={styles.image} />
            </object>
          ) : !!imageElement ? (
            <React.Fragment>{imageElement}</React.Fragment>
          ) : (
            <EmptyStateImage />
          )}
        </React.Fragment>
      )}

      <div>
        <h4 style={{ color: `var(--${titleColor ?? 'text'}` }}>{title ?? 'No results'}</h4>
        {!!subtitle && <p style={{ color: 'var(--text_1)' }}>{subtitle}</p>}
      </div>

      {!!buttonElements?.length && <div className={styles.buttons}>{buttonElements}</div>}
    </div>
  );
}
