export function generateTicks(min: number, max: number, isCustom?: boolean): number[] {
  let step: number;
  if (isCustom) {
    if (max <= 50) step = 5;
    else if (max <= 100) step = 10;
    else step = 20;
  } else step = max === 100 ? 10 : 1;

  return Array.from({ length: Math.floor((max - min) / step) + 1 }, (_, i) => min + i * step);
}

