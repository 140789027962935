import React from 'react';
import { UniqueEntity } from '../../../models/view/unique-entity';
import { additionalLearningTypeIcons, AdditionalLearningListViewModel } from '../../../pages/learning-journey/additional-learning/additional-learning.models';
import { DeleteIcon, EditIcon, formatDate, IconButton, KeplerPoints } from '@keplerco/core';
import styles from './additional-learning-card.module.css';
import classNames from 'classnames';

export function AdditionalLearningCard(props: { additionalLearning: AdditionalLearningListViewModel; onClickDelete: (additionalLearning: UniqueEntity) => void; onClickEdit: (additionalLearning: UniqueEntity) => void }): JSX.Element {
  const iconUrl = additionalLearningTypeIcons.find(item => item.type === props.additionalLearning.type)?.iconUrl;

  return (
    <div className={classNames('card', styles.card)}>
      <header className={styles.header}>
        <object data={iconUrl} type="image/svg+xml">
          <img src={iconUrl} alt="" />
        </object>
      </header>

      <section className={styles.body}>
        <small>
          <KeplerPoints trimWhiteSpace points={props.additionalLearning.keplerPoints} /> earned
        </small>

        <h3>{props.additionalLearning.name}</h3>

        {!!props.additionalLearning.completedOn && (
          <span className="body" style={{ color: 'var(--default)', marginTop: 'auto' }}>
            Added: {formatDate(new Date(props.additionalLearning.completedOn))}
          </span>
        )}
      </section>

      <footer className={styles.footer}>
        <IconButton iconType="fill" onClick={() => props.onClickDelete({ slug: props.additionalLearning.slug, name: props.additionalLearning.name })}>
          <DeleteIcon />
        </IconButton>

        <IconButton iconType="stroke" onClick={() => props.onClickEdit({ slug: props.additionalLearning.slug, name: props.additionalLearning.name })}>
          <EditIcon size={30} />
        </IconButton>
      </footer>
    </div>
  );
}
