import React from 'react';
import { IAssessmentsOverviewTableProps } from '../all-assessments/all-assessments.models';
import { formatDate, KebabMenu, MenuItem, Pager, Table, TableColumn, TableRow } from '@keplerco/core';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { AssessmentType } from '../../../enums/assessment-type';
import { SkillAssessmentResponse } from '../../../models/view/skill-assessments-search-response';
import { AssessmentStatusChip } from '../../../components/chips/assessment-status.chip';

export function SkillAssessmentView(props: IAssessmentsOverviewTableProps): JSX.Element {
  function generateType(skillAssessment: SkillAssessmentResponse): string {
    if (skillAssessment.assessmentType === AssessmentType.PeerEndorsement) return 'Peer Endorsement';
    if (skillAssessment.assessmentType === AssessmentType.FieldsOfPractice) return 'Fields of Practice';
    return 'Questionnaire';
  }

  function generateKebabMenuItems(skillAssessment: SkillAssessmentResponse): MenuItem[] {
    const isActive = skillAssessment.isActive;
    const isArchived = skillAssessment.isArchived;
    const isComplete = skillAssessment.isComplete;

    const kebabMenuItems: MenuItem[] = [
      {
        label: 'Archive Assessment',
        onClick: () => props.onClickArchiveDraftAssessmentHandler(skillAssessment),
        disabled: isArchived || isActive,
      },
      {
        label: 'Edit Assessment',
        onClick: () => props.onClickManageAssessmentDraftHandler(skillAssessment),
        disabled: isActive || isComplete,
      },
      {
        label: 'Close',
        onClick: () => props.onClickCompleteHandler(skillAssessment),
        disabled: !isActive || isComplete,
      },
      {
        label: 'View Assignees',
        onClick: () => props.onClickViewAssigneesHandler(skillAssessment),
        disabled: !isActive && !isComplete,
      },
    ];

    return kebabMenuItems;
  }

  return (
    <Table
      currentSortBy={String(props.request?.sortField)}
      onSort={props.onSortHandler}
      currentSortDirection={props.request?.sortAscending ? 'Ascending' : 'Descending'}
      onConfigClicked={props.onConfigClicked}
      footerContent={
        <PagerConnector onPageChange={props.onPageChangeHandler} defaultPageNumber={props.request?.page ?? 1} pageCount={props.totalPages}>
          {connector => {
            return <Pager {...connector} />;
          }}
        </PagerConnector>
      }
    >
      {props.skillAssessments.map((skillAssessment, index) => {
        const isLoading = props.loadingSkillAssessments.includes(String(skillAssessment.id!));

        return (
          <TableRow
            configCell={() => {
              return <span style={{ visibility: isLoading ? 'hidden' : undefined, pointerEvents: isLoading ? 'none' : undefined }}>{generateKebabMenuItems(skillAssessment).length > 0 && <KebabMenu items={generateKebabMenuItems(skillAssessment)} />}</span>;
            }}
            id={index}
            key={index}
            isLoading={isLoading}
          >
            <TableColumn id="name" label="Skill assessment name">
              {skillAssessment.name}
            </TableColumn>

            <TableColumn hidden={!props.columnConfiguration.find(i => i.key === 'assessmentType')?.selected} id="assessmentType" label="Skill assessment type">
              {generateType(skillAssessment)}
            </TableColumn>

            <TableColumn hidden={!props.columnConfiguration.find(i => i.key === 'dateCreated')?.selected} id="dateCreated" label="Date created">
              {!!skillAssessment.dateCreated && formatDate(new Date(skillAssessment.dateCreated))}
            </TableColumn>

            <TableColumn hidden={!props.columnConfiguration.find(i => i.key === 'Status')?.selected} id="status" label="Status">
              <AssessmentStatusChip assessment={skillAssessment} />
            </TableColumn>
          </TableRow>
        );
      })}
    </Table>
  );
}
