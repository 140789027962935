import React, { Fragment, useEffect, useState } from 'react';
import { Chip, ListLayout, Pager, scoreLevelRAGColour, SkillLevel } from '@keplerco/core';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';
import { ListItem } from '../../../../../components/lists/list-item';
import { useAppActions, useAppState } from '../../../../../overmind';
import { OrganizationLevelType, SortField } from '../../../../../enums';
import { extractHighestOrganizationLevel } from '../../../../../library/helpers/permissions/extract-highest-organization-level';
import { EmptyState } from '../../../../../components/general/empty-state/empty-state';
import classNames from 'classnames';
import { CompanySkillListItemResponse, CompanySkillListResponse } from '../../../../../models/overmind/company-entity';

export function SkillsWidget({
  showRoleSkills,
  learnerSlug,
  loading,
  setLoading,
  query,
  personSkills,
  roleSkills,
  setAllSkillsCount,
  selectedSkills,
  setSelectedSkills,
  searchGlobally = false,
}: {
  showRoleSkills: boolean;
  learnerSlug: string | undefined;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  query: string;
  personSkills: CompanySkillListItemResponse[];
  roleSkills: CompanySkillListItemResponse[];
  setAllSkillsCount?: (allSkillsCount: number) => void;
  selectedSkills: CompanySkillListItemResponse[];
  setSelectedSkills: (selectedSkills: CompanySkillListItemResponse[]) => void;
  searchGlobally?: boolean;
}): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();
  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleSkillManagement?.organizationLevels);

  const [page, setPage] = useState<number>(1);
  const [allSkills, setAllSkills] = useState<CompanySkillListResponse>();

  async function getData(page: number) {
    setLoading(true);

    const request: CompanyEntitySearchParams = {
      search: query,
      sortAscending: true,
      sortField: SortField.Name,
      page,
      pageSize: 5,
      organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      searchGlobally: searchGlobally,
    };
    const response = await actions.getCompanySkills(request);
    if (!!personSkills?.length) {
      response?.entities.forEach(allSkill => {
        const personSkill = personSkills.find(personSkill => personSkill.slug === allSkill.slug);
        allSkill.level = personSkill?.level;
      });
    }
    setAllSkills(response);
    setAllSkillsCount && setAllSkillsCount(response?.totalCount ?? 0);

    setLoading(false);
  }

  useEffect(() => {
    setPage(1);
    getData(1);
  }, [query, companyVariables.slug]);

  if (loading) return <Fragment />;

  if (!allSkills?.entities.length) return <EmptyState />;

  return (
    <Fragment>
      <ListLayout>
        {allSkills.entities.map(allSkill => {
          const roleSkill = roleSkills.some(roleSkill => roleSkill.slug === allSkill.slug);
          const selectedSkill = selectedSkills.some(selectedSkill => selectedSkill.slug === allSkill.slug);
          return (
            <ListItem
              key={allSkill.slug}
              selected={selectedSkill}
              onClick={() => {
                let nextSelectedSkills: CompanySkillListItemResponse[] = structuredClone(selectedSkills);
                selectedSkill ? (nextSelectedSkills = nextSelectedSkills.filter(selectedSkill => (!!selectedSkill.slug && !!allSkill.slug ? selectedSkill.slug !== allSkill.slug : selectedSkill.name !== allSkill.name))) : nextSelectedSkills.push(allSkill);
                setSelectedSkills(nextSelectedSkills);
              }}
            >
              <div style={{ display: 'grid', gridTemplateColumns: `1fr ${!!learnerSlug ? '140px' : ''} ${showRoleSkills ? 'auto' : ''}`, gap: 15, alignItems: 'center' }}>
                <div>
                  <div className="body" style={{ color: 'var(--accent-2)' }}>
                    {allSkill.name}
                  </div>

                  <small>{allSkill.description ?? 'No description provided'}</small>
                </div>

                {/* TODO: chat to Harry to get percentage */}
                {!!learnerSlug && (
                  <SkillLevel
                    level={allSkill.level ?? companyVariables.minLevel}
                    minLevel={companyVariables.minLevel}
                    maxLevel={companyVariables.maxLevel}
                    dotColour={scoreLevelRAGColour(allSkill.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel)}
                    noLevel={!allSkill.level}
                    title="Proficiency"
                  />
                )}

                {!!showRoleSkills && (
                  <div className={classNames({ invisible: !roleSkill })}>
                    <Chip customTheme={{ backgroundColor: 'background', borderColor: 'baby-blue', color: 'text' }}>Role skill</Chip>
                  </div>
                )}
              </div>
            </ListItem>
          );
        })}
      </ListLayout>

      {allSkills.totalPages > 1 && (
        <Pager
          activePageNumber={page}
          pageCount={allSkills.totalPages}
          onPageChange={page => {
            setPage(page);
            getData(page);
          }}
        />
      )}
    </Fragment>
  );
}
