import { Anchor, Button } from '@keplerco/core';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { AstronautLoader } from '../../components/general/loading-state/loaders/astronaut-loader/astronaut-loader';
import { ListItem } from '../../components/lists/list-item';
import { SearchList } from '../../components/lists/search-list';
import { SortField, OrganizationLevelType } from '../../enums';
import { CompanySkillListResponse, CompanySkillListItemResponse } from '../../models/overmind/company-entity';
import { CompanyEntitySearchParams } from '../../models/overmind/search-params';
import { useAppActions, useAppState } from '../../overmind';

export function ImportSkillWidget({ onCancel, onImport }: { onCancel: () => void; onImport: () => void }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [skills, setSkills] = useState<CompanySkillListResponse>();
  const [selectedSkill, setSelectedSkill] = useState<CompanySkillListItemResponse>();
  const [hasError, setHasError] = useState<boolean>(false);

  async function getData() {
    const searchParams: CompanyEntitySearchParams = {
      search: search,
      page: activePageNumber,
      pageSize: 5,
      sortAscending: true,
      sortField: SortField.Name,
      departmentSlug: undefined,
      learnerSlug: undefined,
      teamSlug: undefined,
      companySlug: companyVariables.slug,
      organizationLevel: OrganizationLevelType.Company,
      searchGlobally: true,
    };

    const response = await actions.getCompanySkills(searchParams);
    setSkills(response);
  }

  useEffect(() => {
    async function initWidget() {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    }

    initWidget();
  }, []);

  useEffect(() => {
    async function updateWidget() {
      setIsSearching(true);
      await getData();
      setIsSearching(false);
    }

    if (!!skills) updateWidget();
  }, [search, activePageNumber]);

  async function handleImportSkill(): Promise<void> {
    if (!selectedSkill) return setHasError(true);
    setIsLoading(true);

    const response = await actions.createCompanySkill({
      companySlug: companyVariables.slug!,
      name: selectedSkill.name,
      description: selectedSkill.description ?? '',
    });

    if (!response.error) onImport();
    setIsLoading(false);
  }

  return (
    <React.Fragment>
      <section>
        <div className="card">
          {isLoading ? (
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <AstronautLoader />
            </div>
          ) : (
            <SearchList
              loading={isSearching}
              emptyState
              onInput={search => {
                setSearch(search);
                setActivePageNumber(1);
              }}
              paging={{
                activePageNumber: activePageNumber ?? 1,
                pageCount: skills?.totalPages ?? 0,
                onPageChange: page => setActivePageNumber(page),
              }}
            >
              {skills?.entities.map(entity => {
                return (
                  <ListItem
                    key={entity.slug}
                    selected={selectedSkill?.slug === entity.slug}
                    onClick={() => {
                      setSelectedSkill(prev => (prev?.slug === entity.slug ? undefined : entity));
                      setHasError(false);
                    }}
                  >
                    <div className="body" style={{ color: 'var(--accent-2)' }}>
                      {entity.name}
                    </div>

                    <p>{entity.description}</p>
                  </ListItem>
                );
              })}
            </SearchList>
          )}
        </div>

        <div className={classNames('formErrorMessage', { invisible: !hasError })} style={{ marginTop: 12 }}>
          Please select a skill.
        </div>
      </section>

      <footer className="panelFooter">
        <Anchor onClick={onCancel}>Cancel</Anchor>

        <Button type="button" onClick={handleImportSkill}>
          Import
        </Button>
      </footer>
    </React.Fragment>
  );
}
