import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { FetchStatus, FetchType } from '../../../../../enums';
import { KeplerState } from '../../../../../models/kepler-state';
import { useAppState } from '../../../../../overmind';
import classNames from 'classnames';
import { LoaderIcon } from '@keplerco/core';
import styles from './app-loader-layer.module.css';

export function AppLoaderLayer(): JSX.Element {
  const { fetchState } = useAppState<KeplerState>();

  const [status, setStatus] = useState<FetchStatus>();
  const [show, setShow] = useState<boolean>(false);
  const [syncing, setSyncing] = useState<boolean>(false);

  const time = useRef<NodeJS.Timeout | undefined>(undefined);
  const [forceUpdate, setForceUpdate] = useState<number>(0);

  useEffect(() => {
    const values = Object.values(fetchState);
    const activeValues = values.filter(value => value.status === FetchStatus.Active);
    const sendingValues = activeValues.filter(value => value.type === FetchType.Sending);

    const syncingValues = activeValues.filter(value => value.type === FetchType.Syncing);
    setSyncing(syncingValues.length > 0);

    if ((status !== undefined && status !== FetchStatus.Inactive) || (sendingValues.length === 0 && syncingValues.length === 0)) return setStatus(FetchStatus.Inactive);

    time.current = setTimeout(() => {
      time.current = undefined;
      setForceUpdate(latest => latest + 1);
    }, 1000);
    setForceUpdate(latest => latest + 1);
    setShow(true);
    setStatus(FetchStatus.Active);
  }, [fetchState]);

  useEffect(() => {
    if (status === FetchStatus.Inactive && !time.current) setShow(false);
  }, [status, time, forceUpdate]);

  useEffect(() => {
    window.document.body.classList.toggle('appLoaderOpen', show);
  }, [show]);

  return ReactDOM.createPortal(
    <dialog className={classNames(styles.dialog, { [styles.syncing]: syncing })} open={show}>
      <div className={styles.content}>
        <LoaderIcon size={65} />

        <h5 style={{ color: 'var(--text)' }}>{syncing ? `Syncing...` : `Loading...`}</h5>
      </div>
    </dialog>,
    window.document.body
  );
}
