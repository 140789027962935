import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FocusPanelLoaderLayer } from '../../../../components/general/loading-state/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { FetchType, ActivationStatus } from '../../../../enums';
import { TagType } from '../../../../enums/tag';
import { Employee, Department, Team, UniqueEntity } from '../../../../models';
import { useAppActions } from '../../../../overmind';
import { Assignee } from '../../../../models/tag';
import { ICreateTagProps as ICreateTagFocusPanelLayoutProps } from './create-tag-focus-panel.models';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Anchor, Button, DropdownSearch, FieldController, PanelHeader, LettersValidator, TextField } from '@keplerco/core';

export function CreateTagFocusPanelLayout(props: ICreateTagFocusPanelLayoutProps): JSX.Element {
  const params = useParams();

  const actions = useAppActions();

  const { control, handleSubmit } = useForm<any>({ mode: 'onBlur' });

  const [departments, setDepartments] = useState<Department[]>();
  const [teams, setTeams] = useState<Team[]>();
  const [people, setPeople] = useState<Employee[]>();

  const [selectedDepartments, setSelectedDepartments] = useState<UniqueEntity[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<UniqueEntity[]>([]);
  const [selectedPeople, setSelectedPeople] = useState<UniqueEntity[]>([]);

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.administrationTags, type: FetchType.DialogFetching });

      const departmentsData = await actions.getAdministrationDepartments(params.companySlug!);
      setDepartments(departmentsData);

      const teamsData = await actions.getAdministrationTeams(params.companySlug!);
      setTeams(teamsData);

      const peopleData = await actions.getEmployeesByCompanySlugAndActivationStatus({ activationStatus: ActivationStatus.Active, companySlug: params.companySlug! });
      setPeople(peopleData);

      actions.stopLoader(PagePath.administrationTags);
    }

    getData();
  }, [params.companySlug]);

  async function onSubmitHandler(values: any) {
    actions.startLoader({ path: PagePath.administrationTags, type: FetchType.Sending });

    const assignees: Assignee[] = [];

    selectedDepartments.forEach(department => {
      if (!!department.slug) assignees.push({ entitySlug: department.slug, tagType: TagType.Department });
    });

    selectedTeams.forEach(team => {
      if (!!team.slug) assignees.push({ entitySlug: team.slug, tagType: TagType.Team });
    });

    selectedPeople.forEach(person => {
      if (person.slug) assignees.push({ entitySlug: person.slug, tagType: TagType.User });
    });

    await actions.addAssignees({ assignees: assignees, companySlug: params.companySlug!, tagName: values.name });
    props.onSubmit();
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.administrationTags}>
      <div className="panelContent panelForm">
        <PanelHeader title="Create tag" />

        <form id="createTag" className="panelForm" onSubmit={handleSubmit(onSubmitHandler)}>
          <div>
            <div className="row">
              <div className="column">
                <FieldController
                  control={control}
                  rules={new LettersValidator('Enter a tag name')}
                  name="name"
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        {...field}
                        label="Tag name"
                        type="text"
                        responsive
                        validation={{
                          dirty: !!fieldState.isDirty || !!fieldState.error,
                          invalid: !!fieldState.invalid || !!fieldState.error,
                          message: fieldState.error?.message ?? 'Enter a tag name',
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <DropdownSearch
                  label="Assign to departments"
                  items={
                    departments?.map(department => ({
                      value: department.slug!,
                      label: department.name,
                      onClick: () => {
                        setSelectedDepartments(currentState => {
                          const nextState: UniqueEntity[] = structuredClone(currentState);
                          const index = nextState.findIndex(selectedDepartment => selectedDepartment.slug === department.slug);
                          index !== -1 ? nextState.splice(index, 1) : nextState.push(department);
                          return nextState;
                        });
                      },
                    })) ?? []
                  }
                  value={selectedDepartments.map(selectedDepartment => selectedDepartment.slug!)}
                  multiple
                  responsive
                  dialogContained
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <DropdownSearch
                  label="Assign to teams"
                  items={
                    teams?.map(team => ({
                      value: team.slug!,
                      label: team.teamName,
                      onClick: () => {
                        setSelectedTeams(currentState => {
                          const nextState: UniqueEntity[] = structuredClone(currentState);
                          const index = nextState.findIndex(selectedTeam => selectedTeam.slug === team.slug);
                          index !== -1 ? nextState.splice(index, 1) : nextState.push({ slug: team.slug, name: team.teamName });
                          return nextState;
                        });
                      },
                    })) ?? []
                  }
                  value={selectedTeams.map(selectedTeam => selectedTeam.slug!)}
                  multiple
                  responsive
                  dialogContained
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <DropdownSearch
                  label="Assign to people"
                  items={
                    people?.map(person => ({
                      value: person.learnerSlug!,
                      label: `${person.firstName} ${person.lastName}`,
                      onClick: () => {
                        setSelectedPeople(currentState => {
                          const nextState: UniqueEntity[] = structuredClone(currentState);
                          const index = nextState.findIndex(selectedPerson => selectedPerson.slug === person.learnerSlug);
                          index !== -1 ? nextState.splice(index, 1) : nextState.push({ slug: person.learnerSlug, name: `${person.firstName} ${person.lastName}` });
                          return nextState;
                        });
                      },
                    })) ?? []
                  }
                  value={selectedPeople.map(selectedPerson => selectedPerson.slug!)}
                  multiple
                  responsive
                  dialogContained
                />
              </div>
            </div>
          </div>

          <footer className="panelFooter" style={{ justifyContent: 'space-between' }}>
            <Anchor onClick={props.onCancel}>Cancel</Anchor>

            <Button type="button">Submit</Button>
          </footer>
        </form>
      </div>
    </FocusPanelLoaderLayer>
  );
}
