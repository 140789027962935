import React, { useEffect, useState } from 'react';
import { useAppActions } from '../../../overmind';
import { Chip, ListItemLayout, ListLayout } from '@keplerco/core';
import classNames from 'classnames';
import styles from './assessment-included-questionnaires.module.css';
import { AssessmentQuestionnairesResponse } from '../../../models/overmind/assessment-analytics';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';
import { WidgetCard } from '../../../pages/analytics/analytics-filters/widget-card';

export function AssessmentIncludedQuestionnairesWidget({ assessmentSlug }: { assessmentSlug: string }): JSX.Element {
  const actions = useAppActions();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [questionnaires, setQuestionnaires] = useState<AssessmentQuestionnairesResponse[]>();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const response = await actions.getAssessmentIncludedQuestionnaires(assessmentSlug);
      setQuestionnaires(response);
      setIsLoading(false);
    }

    getData();
  }, [assessmentSlug]);

  return (
    <SkeletonOverlay isLoading={isLoading}>
      <WidgetCard title="Included questionnaires">
        {!!questionnaires?.length ? (
          <div className={styles.scrollContainer}>
            <ListLayout>
              {questionnaires.map(questionnaire => (
                <ListItemLayout key={questionnaire.name}>
                  <div className={classNames('card', styles.card)}>
                    <h4 style={{ gridArea: 'name' }}>{questionnaire.name}</h4>
                    <p className={styles.description}>{questionnaire.description}</p>
                    <div style={{ gridArea: 'chip' }}>
                      <Chip customTheme={{ backgroundColor: 'background', borderColor: 'baby-blue', color: 'text' }}>{questionnaire.skillCount} skills</Chip>
                    </div>
                  </div>
                </ListItemLayout>
              ))}
            </ListLayout>
          </div>
        ) : (
          <EmptyState />
        )}
      </WidgetCard>
    </SkeletonOverlay>
  );
}
