import { DataPoint } from '../../../components/charts/recharts.models';
import { AssessmentParticipantsResponse } from '../../../models/overmind/assessment-analytics';

export function transformToDataPoints(groupedData: AssessmentParticipantsResponse[]): DataPoint[] {
  return groupedData.map(item => {
    const dataPoint: DataPoint = { name: item.name };
    item.counts.forEach(count => {
      dataPoint[count.label] = count.count;
    });
    return dataPoint;
  });
}

export function calculateDomainMax(data: AssessmentParticipantsResponse[] | undefined, defaultMax = 100): number {
  if (!data || data.length === 0) {
    return defaultMax;
  }

  const max = Math.max(...data.map(item => item.total));
  const roundedMax = Math.ceil(max / 5) * 5;
  
  return roundedMax > 0 ? roundedMax : defaultMax;
}
