import React, { Fragment } from 'react';
import { IPersonDetailsProps } from './person-details.models';
import { AvatarIcon, KeplerPoints, useMatchScreenWidth } from '@keplerco/core';
import styles from './person-details.module.css';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { PDFReport } from '../../../../../components/general/pdf-report/pdf-report';
import { EmailIcon } from '../../../../../design/icons/email.icon';
import classNames from 'classnames';

function generateLastName(firstName: string, lastName: string): string {
  const totalLength = firstName.length + lastName.length;
  return totalLength > 15 ? lastName.charAt(0) : lastName;
}

export function PersonDetails({ firstName, lastName, jobTitle, department, email, path, keplerPoints }: IPersonDetailsProps): JSX.Element {
  const isMobile = useMatchScreenWidth('mobile');

  return (
    <div id="employee-detail-section" className={styles.container}>
      <div className={styles.person}>
        <div className={styles.avatar}>
          <AvatarIcon name={{ firstName, lastName }} />
        </div>

        <div>
          <h1>{path === PagePath.dashboard && !isMobile ? `${firstName} ${generateLastName(firstName ?? '', lastName ?? '')}` : `${firstName} ${lastName}`}</h1>

          {path === PagePath.dashboard && (
            <Fragment>
              {!!jobTitle && <span style={{ marginTop: 5, whiteSpace: 'nowrap' }}>{`Job title: ${jobTitle}`}</span>}

              {!!department && <span style={{ whiteSpace: 'nowrap' }}>{`Department: ${department}`}</span>}

              {!!email && (
                <span style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: 3 }}>
                  <EmailIcon />
                  {email}
                </span>
              )}
            </Fragment>
          )}
        </div>
      </div>

      <div className={styles.actions}>
        {path === PagePath.dashboard && <PDFReport path={PagePath.dashboard} />}

        {!!keplerPoints && (
          <div className={classNames('card', styles.keplerPoints)}>
            {!isMobile && <h3 style={{ whiteSpace: 'nowrap' }}>Kepler Points: </h3>}

            <h3>
              {isMobile && 'Kepler Points: '}
              <KeplerPoints trimWhiteSpace points={keplerPoints} />
            </h3>
          </div>
        )}
      </div>
    </div>
  );
}
