import { AssessmentType } from '../../../../enums/assessment-type';
import { AssessmentPreferencesResponse } from '../../../../models/overmind/assessment-preferences';

export enum StepIds {
  Details = 'Details',
  People = 'People',
  Skills = 'Skills',
  Activate = 'Activate',
}

export const DEFAULT_PREFERENCES: AssessmentPreferencesResponse = {
  forceAssessment: false,
  minScore: 0,
  maxScore: 0,
};

export interface Details {
  name: string;
  keplerPoints: number;
}

export interface DetailsRequest {
  companySlug: string;
  slug: string | undefined;
  assessmentType: AssessmentType;
  name: string;
  keplerPointsAvailable: number;
}

export interface PeopleRequest {
  assessmentSlug: string;
  assignedTeamChampionId?: string | undefined;
  userIds: string[];
}
