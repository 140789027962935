import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Questionnaire } from '../../../models/questionnaire';
import { useAppActions } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { FetchType } from '../../../enums';
import { KeplerExplainerFocusPanelLayout } from '../../../widgets/layouts/focus-panel-layouts/kepler-explainer-focus-panel/kepler-explainer-focus-panel.layout';
import { QuestionnaireCard } from './questionnaire.card';
import { PagePath } from '../../../navigation/navigation.enums';
import { Button, Chip, Modal, PageHeader, Panel, UnorderedList, UnorderedListItem, useMatchScreenWidth } from '@keplerco/core';
import styles from './questionnaires.module.css';
import { Loader } from '../../../components/general/loading-state/loaders/loader/loader';

export function QuestionnairesPage(): JSX.Element {
  const actions = useAppActions();

  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>();
  const [open, setOpen] = useState<boolean>(false);

  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const scrollCardRef = useRef<HTMLElement>(null);

  const isTablet = useMatchScreenWidth('tablet');
  const isMobile = useMatchScreenWidth('mobile');

  useEffect(() => {
    if (!questionnaires)
      actions.getAssessmentQuestionnaires().then(data => {
        setQuestionnaires(data);
      });
  });

  const hasQuestionnaires = !!questionnaires && questionnaires.length > 0;
  const hasCompleted = hasQuestionnaires && questionnaires.filter(d => !d.isComplete).length === 0;
  const hasAnyComplete = hasQuestionnaires && !!questionnaires.some(d => d.totalAnsweredQuestions >= 1);
  const [shouldAnimate, setShouldAnimate] = useState(!hasAnyComplete);

  useEffect(() => {
    if (!hasAnyComplete) {
      setIsMobileOpen(true);
    } else {
      setIsCollapsed(true);
    }
  }, [isTablet, isMobile, questionnaires]);

  async function continueAction() {
    actions.startLoader({ path: PagePath.analysisBehavioural, type: FetchType.Sending });
    navigate(PagePath.analysisBase);

    window.scrollTo(0, 0);
    actions.stopLoader(PagePath.analysisBehavioural);
  }

  function ExplainerCard() {
    return (
      <React.Fragment>
        <Chip themeColour="primary">{isMobile ? `Skill analysis` : `Behavioural skills analysis`}</Chip>

        <div style={{ height: 5 }} />

        <h3 style={{ marginTop: 15, marginBottom: 15 }}>Unlock and develop your behavioural strengths</h3>

        <UnorderedList>
          <UnorderedListItem bulletTop="10px">
            Read each question carefully and choose the answer that <strong>best describes how you may respond in the situation.</strong>
          </UnorderedListItem>

          <UnorderedListItem bulletTop="10px">
            Select the answer that is the <strong>most accurate</strong>, even if it doesn't perfectly describe how you would react.
          </UnorderedListItem>
        </UnorderedList>

        <p style={{ marginTop: 15 }}>
          <strong>Please note:</strong> This analysis is not used for official performance evaluation. It is a way to determine where the start of your Learning Journey will be, so that your learning experience is efficient and useful.
        </p>

        {!isCollapsed && !hasAnyComplete && (
          <div style={{ width: '100%', textAlign: 'center', marginTop: 30 }}>
            <Button
              filled
              type="button"
              isLoading={!questionnaires}
              onClick={() => {
                setShouldAnimate(true);
                setIsCollapsed(true);
                if (isTablet) setIsMobileOpen(false);
              }}
              arrow={false}
            >
              Got it, thanks
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="wrapper stack">
        <PageHeader
          breadcrumbs={[
            { name: 'Analysis', url: PagePath.analysisBase },
            { name: 'Questionnaires', url: `${PagePath.analysisBase}${PagePath.analysisBehavioural}` },
          ]}
          title="Behavioural Skills"
          subtitle="These skills determine how well you interact with others and manage your work and social connections."
        />

        {questionnaires ? (
          <div className={classNames(styles.body, { [styles.collapsed]: isCollapsed })} style={{ overflowY: 'auto' }}>
            {isTablet ? (
              <Modal
                onClose={() => {
                  setIsMobileOpen(false);
                  if (hasCompleted) continueAction();
                }}
                open={isMobileOpen}
              >
                <div id="behavioural-page-explainer-card" style={{ position: 'static' }} className={classNames('modalContent', styles.explainerCardContainer)}>
                  <ExplainerCard />
                </div>
              </Modal>
            ) : (
              <div id="behavioural-page-explainer-card" className={classNames(styles.explainerCardContainer, { [styles.animate]: shouldAnimate })}>
                <div className={classNames('card', styles.explainerCard)} ref={scrollCardRef as any}>
                  <ExplainerCard />
                </div>
              </div>
            )}

            {!hasCompleted && !!questionnaires && (
              <div className={classNames(styles.list, { [styles.collapsed]: isCollapsed || hasAnyComplete, [styles.animate]: shouldAnimate })}>
                {questionnaires.map((questionnaire, index) => (
                  <QuestionnaireCard key={questionnaire.slug} {...questionnaire} index={index} />
                ))}
              </div>
            )}
          </div>
        ) : (
          <div style={{ marginTop: '100px' }}>
            <Loader />
          </div>
        )}
      </div>

      <Panel open={open} onClose={() => setOpen(false)}>
        <KeplerExplainerFocusPanelLayout onClick={() => setOpen(false)} />
      </Panel>
    </React.Fragment>
  );
}
