import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../../../overmind';
import { DataPoint } from '../../../../../components/charts/recharts.models';
import { transformScore } from '../../../../../widgets/analytics/ranked-entities/ranked-entities.helpers';
import styles from './performance-overview.module.css';
import { IPerformanceOverviewWidgetProps } from './performance-overview.models';
import { AssessmentSearchParams, PermissionSearchParams, FilteringSearchParams, PagingSearchParams } from '../../../../../models/overmind/search-params';
import { HorizontalBarChart } from '../../../../../components/charts/horizontal-bar-chart/horizontal-bar-chart';
import { EmptyState } from '../../../../../components/general/empty-state/empty-state';
import { AnalyticsFilters, DEFAULT_FILTERING_SEARCH_PARAMS } from '../../../analytics-filters/analytics-filters';
import { SkeletonOverlay } from '../../../../../components/general/loading-state/skeleton-overlay';
import { WidgetCard } from '../../../analytics-filters/widget-card';

const DEFAULT_SEARCH_PARAMS: PagingSearchParams = {
  page: 1,
  pageSize: 5,
};

export function PerformanceOverviewWidget({ baseSearchParams, departmentItems, teamItems, roleItems, countryItems }: IPerformanceOverviewWidgetProps) {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filteringSearchParams, setFilteringSearchParams] = useState<FilteringSearchParams>(DEFAULT_FILTERING_SEARCH_PARAMS);
  const [highestDataPoints, setHighestDataPoints] = useState<DataPoint[]>([]);
  const [lowestDataPoints, setLowestDataPoints] = useState<DataPoint[]>([]);

  async function getData(request: AssessmentSearchParams & PermissionSearchParams & FilteringSearchParams & PagingSearchParams) {
    setIsLoading(true);
    const response = await actions.getAssessmentPerformanceOverview(request);
    setHighestDataPoints(transformScore(response?.highest ?? [], companyVariables));
    setLowestDataPoints(transformScore(response?.lowest ?? [], companyVariables));
    setIsLoading(false);
  }

  useEffect(() => {
    getData({ ...baseSearchParams, ...DEFAULT_SEARCH_PARAMS, ...filteringSearchParams });
  }, [baseSearchParams]);

  return (
    <SkeletonOverlay isLoading={isLoading}>
      <WidgetCard title="Assessment performance overview" subtitle="An overview of assessment performance, highlighting individuals who achieved the highest scores and those with opportunities for improvement.">
        <AnalyticsFilters
          teamItems={teamItems}
          departmentItems={departmentItems}
          roleItems={roleItems}
          countryItems={countryItems}
          filteringSearchParams={filteringSearchParams}
          setFilteringSearchParams={setFilteringSearchParams}
          applyFilters={() => {
            getData({ ...baseSearchParams, ...DEFAULT_SEARCH_PARAMS, ...filteringSearchParams });
          }}
          clearFilters={() => {
            setFilteringSearchParams(DEFAULT_FILTERING_SEARCH_PARAMS);
            getData({ ...baseSearchParams, ...DEFAULT_SEARCH_PARAMS, ...DEFAULT_FILTERING_SEARCH_PARAMS });
          }}
        />

        <div className={styles.charts}>
          <div className={styles.chart}>
            <h4>Highest scoring participants</h4>
            {!!highestDataPoints.length ? <HorizontalBarChart fill="grape" dataPoints={highestDataPoints} /> : <EmptyState title="Hang tight!" subtitle="We are waiting for data." />}
          </div>

          <div className={styles.chart}>
            <h4>Lowest scoring participants</h4>
            {!!lowestDataPoints.length ? <HorizontalBarChart fill="primary" dataPoints={lowestDataPoints} /> : <EmptyState title="Hang tight!" subtitle="We are waiting for data." />}
          </div>
        </div>
      </WidgetCard>
    </SkeletonOverlay>
  );
}
