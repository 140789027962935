import classNames from 'classnames';
import React, { useEffect } from 'react';
import { safeCallback } from '@keplerco/core';
import { checkRules, suggestion } from './password-strength.helpers';
import styles from './password-strength.module.css';

export function PasswordStrength(props: { password: string; onPercentage?: (percentageCorrect: number, message: string) => void }): JSX.Element {
  const { password = '' } = props;

  const ruled = checkRules(password);

  const countCorrect = ruled.filter(i => i.value === true).length;

  const percent = (countCorrect / ruled.length) * 100;

  const firstSuggestion = ruled.find(r => !r.value);

  useEffect(() => {
    safeCallback(props.onPercentage, percent, suggestion(firstSuggestion?.name ?? ''));
  }, [percent, firstSuggestion]);

  return (
    <div style={{ paddingTop: 5, display: 'flex', flexDirection: 'column', gap: 5, textAlign: 'end' }}>
      <small>Password strength:</small>

      <ul className={classNames(styles.list, { [styles.red]: percent > 0 && percent <= 25, [styles.yellow]: percent > 25 && percent < 100, [styles.green]: percent === 100 })}>
        {ruled.map(rule => {
          return <li data-suggestion={suggestion(rule.name)} key={rule.name} className={classNames(styles.listItem, { [styles.filled]: rule.value })}></li>;
        })}
      </ul>

      {!!firstSuggestion && !!password.length && <small>{suggestion(firstSuggestion.name)}</small>}
    </div>
  );
}
