import React from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { RechartsTooltip } from '../recharts-tooltip/recharts-tooltip';
import { RechartsLegend } from '../recharts-legend/recharts-legend';
import { generateFill } from '../recharts.helpers';
import { IDonutChartLabelProps, IDonutChartProps } from './donut.models';
import styles from './donut.module.css';

function DonutChartLabel({ viewBox, total, label }: IDonutChartLabelProps) {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text x={cx} y={cy} dy={-12} fill="var(--text)" className="heading3" textAnchor="middle" dominantBaseline="middle">
        {total}
      </text>

      <text x={cx} y={cy} dy={12} fill="var(--text)" className="caption1" textAnchor="middle" dominantBaseline="middle">
        {label}
      </text>
    </g>
  );
}

export function DonutChart({ dataPoints, fill, showLegend, label, total, isPercentageData }: IDonutChartProps): React.ReactElement {
  const innerRadius = 64;
  const outerRadius = 80;
  const diameter = outerRadius * 2;

  return (
    <div className={styles.container}>
      <div className={styles.chartContainer}>
        <ResponsiveContainer aspect={1}>
          <PieChart>
            <Pie data={dataPoints} dataKey="value" innerRadius={innerRadius} outerRadius={outerRadius} stroke="none">
              {dataPoints.map((dataPoint, index) => (
                <Cell key={dataPoint.name} fill={generateFill(index, fill)} />
              ))}
              {label && <Label width={30} position="center" content={<DonutChartLabel viewBox={{ cx: diameter, cy: diameter }} total={total ?? dataPoints.reduce((a, b) => a + (b.value as number), 0)} label={label} />} />}
            </Pie>

            <Tooltip cursor={false} filterNull={false} content={<RechartsTooltip hideName total={total} isPercentageData={isPercentageData} />} />
          </PieChart>
        </ResponsiveContainer>
      </div>

      {showLegend && (
        <div className={styles.legendContainer}>
          <RechartsLegend
            verticalLegend
            payload={dataPoints.map((dataPoint, index) => ({
              value: dataPoint.name,
              color: generateFill(index, fill),
            }))}
          />
        </div>
      )}
    </div>
  );
}
