export enum ActivationStatus {
  Active,
  Inactive,
  Archived,
  NotInvited,
}

export enum ActivationStatusDescription {
  Active = 'Account active',
  Inactive = 'Activation required',
  Archived = 'Archived',
  NotInvited = 'Invite pending',
}
