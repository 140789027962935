import { isInViewport } from '@keplerco/core';
import { IStepProps } from './step/step.models';

// TODO: add to Core
export function checkOverflowingHorizontally(el: HTMLElement | undefined) {
  if (!el) return false;
  const isOverflowing = el.clientWidth < el.scrollWidth;
  return isOverflowing;
}

export function canGoTo(step: IStepProps, previousStep: IStepProps | undefined) {
  return !step.disabled && (previousStep?.completed || !previousStep?.required);
}

export function scrollTo(stepsElement: HTMLDivElement | null, stepIndex: number) {
  if (!stepsElement) return;

  const stepElement = stepsElement.children[stepIndex];
  if (!stepElement) return;

  stepElement.scrollIntoView(false);
}

export function scrollLeft(stepsElement: HTMLDivElement | null) {
  if (!stepsElement) return;

  const visibleSteps: boolean[] = [];
  for (const stepElement of stepsElement.children) {
    visibleSteps.push(isInViewport(stepElement as HTMLElement));
  }

  const firstVisibleStepIndex = visibleSteps.indexOf(true);
  if (firstVisibleStepIndex !== -1) scrollTo(stepsElement, firstVisibleStepIndex - 1);
}

export function scrollRight(stepsElement: HTMLDivElement | null) {
  if (!stepsElement) return;

  const visibleSteps: boolean[] = [];
  for (const stepElement of stepsElement.children) {
    visibleSteps.push(isInViewport(stepElement as HTMLElement));
  }

  const lastVisibleStepIndex = visibleSteps.lastIndexOf(true);
  if (lastVisibleStepIndex !== -1) scrollTo(stepsElement, lastVisibleStepIndex + 1);
}
