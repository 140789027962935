import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppActions } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { FetchType } from '../../../enums';
import { ResetPassword } from '../../../models/view/reset-password';
import { AccountPageLoaderLayer } from '../../../components/general/loading-state/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { PagePath } from '../../../navigation/navigation.enums';
import { Button, EmailValidator, FormControl, Textfield, useMatchScreenWidth } from '@keplerco/core';
import accountStyles from '../account.module.css';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import classNames from 'classnames';
import { TertiaryButton } from '../../../components/general/tertiary-button/tertiary-button';

export function ForgotPasswordPage(): JSX.Element {
  const navigate = useNavigate();

  const actions = useAppActions();

  const { control, handleSubmit } = useForm({ reValidateMode: 'onChange' });

  const isMobile = useMatchScreenWidth('mobile');

  const [allowCreateAccount, setAllowCreateAccount] = useState<boolean>();
  const [sent, setSent] = useState<boolean>(false);

  useEffect(() => {
    async function getData() {
      const response = await actions.getAllowCreateAccount();
      setAllowCreateAccount(response);
    }

    getData();
  }, []);

  return (
    <div className={accountStyles.accountBackground} style={{ backgroundImage: `url(${themedAssetUrl('backgrounds/pattern.background.svg')})` }}>
      <div className={accountStyles.accountPage}>
        <div className={classNames('card', accountStyles.accountCard)}>
          <AccountPageLoaderLayer path={PagePath.accountForgotPassword}>
            {!isMobile && (
              <div className={accountStyles.accountNavigationToggler}>
                {allowCreateAccount ? (
                  <Fragment>
                    <span className="body">Not registered?</span>
                    {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                    <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountRegister}`)}>
                      Create an account
                    </TertiaryButton>
                  </Fragment>
                ) : (
                  <Fragment>
                    <span className="body">Not registered?</span>
                    {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                    <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountActivate}`)}>
                      Activate your account
                    </TertiaryButton>
                  </Fragment>
                )}
              </div>
            )}

            <h1>Forgot your password?</h1>

            {!sent && (
              <React.Fragment>
                <div style={{ height: 5 }} />

                <h4>It happens... to the best of us.</h4>

                <div style={{ height: 30 }} />

                <h3 style={{ margin: 'auto', maxWidth: 490 }}>Enter your email address, and we'll send you an email with a link to reset your password.</h3>

                <form
                  id="forgotPassword"
                  className={accountStyles.accountForm}
                  autoComplete="off"
                  onSubmit={handleSubmit(async fieldValues => {
                    actions.startLoader({ path: PagePath.accountForgotPassword, type: FetchType.PageFetching });
                    setSent(await actions.forgotPassword(fieldValues as ResetPassword));
                    actions.stopLoader(PagePath.accountForgotPassword);
                  })}
                >
                  <FormControl paddingBottom={0} name="email" control={control} rules={new EmailValidator('Enter your email')} render={({ field, fieldState }) => <Textfield {...field} autoFocus haserror={!!fieldState.error} label="Email" responsive />} />

                  <Button type="button" fullWidthMobile>
                    Send Reset Instructions
                  </Button>

                  {isMobile && (
                    <div className={classNames(accountStyles.accountNavigationToggler, accountStyles.bottom)}>
                      {allowCreateAccount ? (
                        <Fragment>
                          <span className="body">Not registered?</span>
                          {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                          <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountRegister}`)}>
                            Create an account
                          </TertiaryButton>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span className="body">Not registered?</span>
                          {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                          <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountActivate}`)}>
                            Activate your account
                          </TertiaryButton>
                        </Fragment>
                      )}
                    </div>
                  )}
                </form>
              </React.Fragment>
            )}

            {!!sent && (
              <div className={accountStyles.accountInstructions}>
                <h3>Thanks!</h3>

                <br />

                <p>We've sent you an email with a link to reset your password.</p>

                <br />

                <p>If you don't find it, check your spam.</p>
              </div>
            )}
          </AccountPageLoaderLayer>
        </div>
      </div>
    </div>
  );
}
