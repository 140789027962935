import React, { PropsWithChildren } from 'react';
import { IConfirmationLayoutProps } from './confirmation-modal.models';
import { Button, colourString } from '@keplerco/core';
import styles from './confirmation-modal.module.css';

export function ConfirmationModalLayout(props: PropsWithChildren<IConfirmationLayoutProps>): JSX.Element {
  const submitButtonText = props.submitButtonText ?? 'Submit';
  const cancelButtonText = props.cancelButtonText ?? 'Cancel';
  const textAlign = props.textAlign ?? 'center';

  return (
    <div className="modalContent" style={{ textAlign }}>
      <header className={styles.confirmationModalHeader} style={{ paddingBottom: 15, textAlign: props.textAlign }}>
        {props.titleSize === 'small' ? <h4 style={{ textAlign: props.textAlign }}>{props.title}</h4> : props.titleSize === 'medium' ? <h3 style={{ textAlign: props.textAlign }}>{props.title}</h3> : <h2 style={{ textAlign: props.textAlign }}>{props.title}</h2>}
      </header>

      {!!props.subtitle && !props.children && <div>{props.subtitleSize === 'large' ? <h3 style={{ color: colourString('accent-2') }}>{props.subtitle}</h3> : <h6>{props.subtitle}</h6>}</div>}

      {!!props.children && <div>{props.children}</div>}

      <footer className={styles.footerStyling}>
        <Button grow square theme="dark" type="button" filled onClick={props.onClickCancel}>
          {cancelButtonText}
        </Button>

        <Button grow square type="button" filled isLoading={props.isLoading} onClick={!props.isLoading ? props.onClickSubmit : () => void 0}>
          {submitButtonText}
        </Button>

        {/* <Anchor onClick={props.onClickCancel}>{cancelButtonText}</Anchor> */}
      </footer>
    </div>
  );
}
