import React, { useEffect, useState } from 'react';
import { HorizontalBarChart } from '../../../components/charts/horizontal-bar-chart/horizontal-bar-chart';
import { useAppActions, useAppState } from '../../../overmind';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { ScoresSearchParams } from '../../../models/overmind/search-params';
import { OrganizationLevelType, ScoreType } from '../../../enums';
import { ScoreComparison } from '../../../enums/score-comparison';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { DataPoint } from '../../../components/charts/recharts.models';
import { removeDuplicateScores } from '../../../components/charts/recharts.helpers';

export function ActualExpectedProficiencyWidget({ roleSlug }: { roleSlug: string | undefined }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataPoints, setDataPoints] = useState<DataPoint[]>();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const request: ScoresSearchParams = {
        sortAscending: false,
        page: 1,
        pageSize: 99999,
        startDate: undefined,
        endDate: undefined,
        organizationLevel: OrganizationLevelType.Company,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        scoreType: ScoreType.RoleSkill,
        scoreTypeSlugs: !!roleSlug ? [roleSlug] : [],
        scoreComparison: ScoreComparison.RoleExpectedProficiency,
      };

      const response = await actions.analyticsGetScoresByScoreType(request);

      const uniqueResponse = removeDuplicateScores(response?.results ?? [], 'name');
      setDataPoints(
        uniqueResponse.map(entityScore => ({
          name: entityScore.name,
          'Actual proficiency': companyVariables.useLevels ? entityScore.score?.level : entityScore.score?.percentage,
          'Expected proficiency': entityScore.comparisonScore ? (companyVariables.useLevels ? entityScore.comparisonScore.level : entityScore.comparisonScore.percentage) : undefined,
        }))
      );
      setLoading(false);
    }

    getData();
  }, [companyVariables.slug]);

  return loading ? (
    <SkeletonLoader height="450px" />
  ) : (
    <div className="card">
      <h3>Actual vs expected proficiency</h3>
      {!!dataPoints ? <HorizontalBarChart dataPoints={dataPoints} fill={['baby-blue', 'bright-pink']} showLegend /> : <EmptyState />}
    </div>
  );
}
