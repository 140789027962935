import { AssessmentPreferencesResponse } from '../../../../../models/overmind/assessment-preferences';
import { IPerson } from '../../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';

export enum CascadingFocusPanelIds {
  SelectSkills = 'SelectSkills',
  ImportSkills = 'ImportSkills',
}

export enum ErrorMessage {
  NoSkills = 'No skills selected',
  ManagerReviewSkills = 'All selected non-managers must have at least one skill assigned.',
  PeerReviewSkills = 'All selected people must have at least one skill assigned.',
  SelfReviewSkills = 'All selected people must have at least one skill assigned.',
}

export interface ISkillsStepLayoutProps {
  assessmentSlug: string;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  preferences: AssessmentPreferencesResponse;
  people: IPerson[] | undefined;
  roleSlug: string | undefined;
  setPeople: (people: IPerson[]) => void;
  onBack: () => void;
  onNext: () => void;
}
