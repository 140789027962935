import React from 'react';
import { ThemeColours } from '@keplerco/core';
import { DataPoint } from '../recharts.models';
import styles from './ranked-performance-chart.module.css';
import { HorizontalBarChart } from '../horizontal-bar-chart/horizontal-bar-chart';
import { EmptyState } from '../../general/empty-state/empty-state';

interface IRankedPerformanceChartProps {
  title?: string;
  dataPoints: DataPoint[];
  fill?: ThemeColours;
}

export function RankedPerformanceChart({ title, dataPoints, fill }: IRankedPerformanceChartProps) {
  return (
    <div className="column" style={{ width: '100%' }}>
      {title && <h4 className={styles.chartTitle}>{title}</h4>}
      {!!dataPoints.length ? (
        <HorizontalBarChart fill={fill} dataPoints={dataPoints} />
      ) : (
        <div className={styles.emptyStateWrapper}>
          <EmptyState />
        </div>
      )}
    </div>
  );
}
