import React from 'react';
import { IconProps } from '@keplerco/core';

export function EmptyStateImage({ size = 120 }: IconProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 103 103" fill="none">
      <circle cx="51.7711" cy="51.601" r="51.1971" fill="var(--default)" fillOpacity="0.15" />
      <path
        d="M89.9708 57.7342C88.9338 63.4152 86.6288 68.7891 83.2274 73.4559C79.8259 78.1227 75.4156 81.9622 70.3248 84.6885C65.234 87.4148 59.5939 88.9578 53.8242 89.2025C48.0545 89.4472 42.304 88.3873 37.0006 86.1018L41.6531 75.306C45.3328 76.8917 49.3228 77.6271 53.3261 77.4573C57.3293 77.2875 61.2427 76.217 64.7749 74.3253C68.3072 72.4337 71.3672 69.7697 73.7273 66.5317C76.0874 63.2936 77.6867 59.5649 78.4062 55.6232L89.9708 57.7342Z"
        fill="var(--default)"
        fillOpacity="0.75"
      />
      <path
        d="M52.197 78.7193C37.5313 78.7193 25.5993 66.7872 25.5993 52.1215C25.5993 37.4558 37.5313 25.5237 52.197 25.5237C66.8627 25.5237 78.7973 37.4558 78.7973 52.1215C78.7973 66.7872 66.8652 78.7193 52.197 78.7193ZM52.197 26.9883C38.3382 26.9883 27.0664 38.2627 27.0664 52.119C27.0664 65.9754 38.3407 77.2498 52.197 77.2498C66.0534 77.2498 77.3302 65.9754 77.3302 52.119C77.3302 38.2627 66.0559 26.9883 52.197 26.9883Z"
        fill="var(--default)"
      />
      <path
        d="M52.197 90.5169C31.0249 90.5169 13.7993 73.2912 13.7993 52.1191C13.7993 30.9469 31.0249 13.7212 52.197 13.7212C73.3691 13.7212 90.5949 30.9469 90.5949 52.1191C90.5949 73.2912 73.3691 90.5169 52.197 90.5169ZM52.197 15.1907C31.8343 15.1907 15.2664 31.7587 15.2664 52.1215C15.2664 72.4843 31.8318 89.0523 52.197 89.0523C72.5623 89.0523 89.1278 72.4843 89.1278 52.1215C89.1278 31.7587 72.5598 15.1907 52.197 15.1907Z"
        fill="var(--default)"
      />
      <path d="M41.04 75.3354L35.5947 85.7736L36.8954 86.4521L42.3407 76.014L41.04 75.3354Z" fill="var(--default)" />
      <path d="M78.3151 54.6059L77.9592 56.0292L88.8493 58.7525L89.2052 57.3292L78.3151 54.6059Z" fill="var(--default)" />
      <path d="M28.729 62.897L18.2918 68.7948L19.0136 70.0721L29.4507 64.1742L28.729 62.897Z" fill="var(--default)" />
      <path d="M35.1297 18.6197L33.892 19.4073L40.2453 29.3916L41.483 28.604L35.1297 18.6197Z" fill="var(--default)" />
      <path d="M64.2759 62.8384H62.8089V41.055H55.6544V62.8384H54.1874V39.5879H64.2759V62.8384Z" fill="var(--default)" />
      <path d="M47.9402 62.8384H46.4731V47.757H54.9209V49.224H47.9402V62.8384Z" fill="var(--default)" />
      <path d="M40.2258 62.8384H38.7588V53.657H47.2066V55.1241H40.2258V62.8384Z" fill="var(--default)" />
    </svg>
  );
}
