import { Anchor, Button, PanelHeader } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { SearchList } from '../../../../../../components/lists/search-list';
import { ListItem } from '../../../../../../components/lists/list-item';
import { OrganizationLevelType, SortField } from '../../../../../../enums';
import classNames from 'classnames';
import styles from './import-role.module.css';
import { CompanyEntitySearchParams } from '../../../../../../models/overmind/search-params';
import { CompanyRoleListItemResponse, CompanyRoleListResponse } from '../../../../../../models/overmind/company-entity';
import { AstronautLoader } from '../../../../../../components/general/loading-state/loaders/astronaut-loader/astronaut-loader';

export function SelectRoleLayout({ selectedRole, onBack, setSelectedRole }: { selectedRole: CompanyRoleListItemResponse | undefined; onBack: () => void; setSelectedRole: (role: CompanyRoleListItemResponse | undefined) => void }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [roles, setRoles] = useState<CompanyRoleListResponse>();
  const [tempSelectedRole, setTempSelectedRole] = useState<CompanyRoleListItemResponse | undefined>(selectedRole);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const searchParams: CompanyEntitySearchParams = {
        search: search,
        page: activePageNumber,
        pageSize: 5,
        sortAscending: true,
        sortField: SortField.Name,
        departmentSlug: undefined,
        learnerSlug: undefined,
        teamSlug: undefined,
        companySlug: companyVariables.slug,
        organizationLevel: OrganizationLevelType.Company,
        searchGlobally: true,
        providerName: 'Esco',
      };

      const response = await actions.getCompanyRoles(searchParams);
      setRoles(response);

      setLoading(false);
    }

    getData();
  }, [search, activePageNumber, companyVariables.slug]);

  function handleImport() {
    if (!tempSelectedRole) return setError(true);
    setSelectedRole(tempSelectedRole);
  }

  return (
    <div className="panelContent">
      <PanelHeader supertitle="Roles" title="Roles" subtitle="Select a role to import into your competency framework." />

      <>
        <div className={classNames('card', styles.content)}>
          {loading && !roles ? (
            <AstronautLoader />
          ) : (
            <>
              <SearchList
                loading={loading}
                emptyState={!roles?.entities.length}
                onInput={search => setSearch(search)}
                paging={{
                  activePageNumber: activePageNumber ?? 1,
                  pageCount: roles?.totalPages ?? 0,
                  onPageChange: page => setActivePageNumber(page),
                }}
              >
                {roles?.entities.map(entity => {
                  return (
                    <ListItem
                      key={entity.slug}
                      selected={tempSelectedRole?.slug === entity.slug}
                      onClick={() => {
                        tempSelectedRole?.slug === entity.slug ? setTempSelectedRole(undefined) : setTempSelectedRole(entity);
                        setError(false);
                      }}
                    >
                      <div className="body" style={{ color: 'var(--accent-2)' }}>
                        {entity.name}
                      </div>

                      <small>{entity.description}</small>
                    </ListItem>
                  );
                })}
              </SearchList>
            </>
          )}
        </div>

        <div className={classNames('formErrorMessage', { invisible: !error })} style={{ marginTop: 'auto' }}>
          Please select a role.
        </div>
      </>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button type="button" onClick={handleImport}>
          Import
        </Button>
      </footer>
    </div>
  );
}
