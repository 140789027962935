import { Context } from '../..';
import { Employee } from '../../../models/view/employee';
import { FocusArea } from '../../../models/view/focus-area';
import { FocusAreasResource } from '../../../models/view/focus-areas-resource';
import { BaseSearchRequest } from '../../../models/overmind/search-request';
import { TeamListItem } from '../../../models/view/team-list-item';
import { TeamSearchResponse } from '../../../models/view/team-search-response';
import { Team } from '../../../models/view/team';
import * as base from '../base';
import { User } from '../../../models/user';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';

export async function sendTeamInviteReminder(): Promise<void> {
  const request: base.IRequest = { url: base.apiURL(`team`, `send-invite-reminders`), authenticated: true, method: 'POST' };
  await base.request(request);
}

export async function completeTeamBehaviouralSkills({ state }: Context): Promise<void> {
  const request: base.IRequest = { url: base.apiURL(`team`, `complete-team-behavioural-skills`), authenticated: true, method: 'POST' };
  const response: base.IResponse<User> = await base.request(request);

  state.user = response.data;
}

export async function completeFocusAreas({ state }: Context): Promise<void> {
  const request: base.IRequest = { url: base.apiURL(`team`, `complete-focus-areas`), authenticated: true, method: 'POST' };
  const response: base.IResponse<User> = await base.request(request);

  state.user = response.data;
}

export async function getTeamFocusAreas(): Promise<FocusAreasResource | undefined> {
  const request: base.IRequest = { url: base.apiURL(`team`, `get-team-focus-areas`), authenticated: true };
  const response: base.IResponse<FocusAreasResource> = await base.request(request);

  return response.data;
}

export async function saveFocusArea(context: Context, focusArea: FocusArea): Promise<void> {
  const request: base.IRequest = { url: base.apiURL(`team`, `submit-team-focus-area`), authenticated: true, method: 'POST', body: JSON.stringify(focusArea) };
  await base.request(request);
}

export async function getAdministrationTeams(_: Context, slug: string): Promise<Team[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(`team`, `get-teams`, slug), authenticated: true };
  const response: base.IResponse<Team[]> = await base.request(request);

  return response.data;
}

export async function getAssessmentsTeams(_: Context, slug: string): Promise<Team[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(`assessments`, `get-teams`, slug), authenticated: true };
  const response: base.IResponse<Team[]> = await base.request(request);

  return response.data;
}

export async function searchTeams(context: Context, SearchRequest: BaseSearchRequest): Promise<TeamSearchResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(`team`, `search-teams`, `${context.state.companyVariables.slug}${base.params(SearchRequest)}`) };
  const response: base.IResponse<TeamSearchResponse> = await base.request(request);
  return !!response.data ? { ...response.data, teams: response.data.teams.map((team: TeamListItem) => ({ ...team, dateCreated: new Date(team.dateCreated) })) } : undefined;
}

export async function getAvailableTeamChampions(context: Context, companySlug: string): Promise<Employee[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(`people`, `get-available-team-champions`, companySlug), authenticated: true };
  const response: base.IResponse<Employee[]> = await base.request(request);

  return response.data;
}

export async function saveTeam(context: Context, payload: { team: Team; companySlug: string }): Promise<Team | undefined> {
  const request: base.IRequest = { url: base.apiURL(`team`, `save-team`, payload.companySlug), authenticated: true, method: 'POST', body: JSON.stringify(payload.team) };
  const response: base.IResponse<Team> = await base.request(request);

  return response.data;
}

export async function getTeam(context: Context, data: { teamSlug: string; companySlug: string }): Promise<Team | undefined> {
  const request: base.IRequest = { url: base.apiURL(`team`, `get-team`, data.companySlug, data.teamSlug), authenticated: true };
  const response: base.IResponse<Team> = await base.request(request);

  return response.data;
}

export async function archiveTeam(context: Context, teamSlug: string) {
  const request: base.IRequest = { url: base.apiURL(`team`, `archive`, teamSlug), authenticated: true, method: 'PUT' };
  const response: base.IResponse<Team> = await base.request(request);

  if (!!response.error) {
    context.actions.addNotification({
      id: teamSlug,
      type: NotificationType.Error,
      priority: NotificationPriority.Toast,
      title: '',
      message: `Cannot archive team. Please contact support`,
      active: true,
    });
  } else
    context.actions.addNotification({
      id: Date.now().toString(),
      type: NotificationType.Success,
      priority: NotificationPriority.Toast,
      title: '',
      message: `Team archived successfully`,
      active: true,
    });
}
