import { Context } from '../..';
import { Learner, LearnerSearch } from '../../../models/view/learner';
import { PersonalDetails } from '../../../models/view/personal-details';
import { BaseSearchRequest } from '../../../models/overmind/search-request';
import { LearnerCertificateSearchResponse } from '../../../models/view/learner-certificate-search-response';
import * as base from '../base';

export async function searchLearners(context: Context, SearchRequest: BaseSearchRequest): Promise<LearnerSearch | undefined> {
  const queryParams = new URLSearchParams(SearchRequest as any).toString();

  const request: base.IRequest = { url: base.apiURL(`learner`, `search-learners?${queryParams}`), authenticated: true };
  const response: base.IResponse<LearnerSearch> = await base.request(request);

  return response.data;
}

export async function submitPersonalDetails(context: Context, personalDetails: PersonalDetails): Promise<void> {
  const request: base.IRequest = { url: base.apiURL(`learner`, `submit-personal-details`), authenticated: true, method: 'POST', body: JSON.stringify(personalDetails) };
  await base.request(request);
}

export async function getLearnerCertificateList(context: Context, SearchRequest: BaseSearchRequest): Promise<LearnerCertificateSearchResponse | undefined> {
  const queryParams = new URLSearchParams({ ...SearchRequest } as any).toString();

  const request: base.IRequest = { url: base.apiURL(`learnercertificate`, `search-certificates?${queryParams}`), authenticated: true };
  const response: base.IResponse<LearnerCertificateSearchResponse> = await base.request(request);

  return response.data;
}

export async function getLearnerBySlug(context: Context, learnerSlug: string): Promise<Learner | undefined> {
  const request: base.IRequest = {
    url: base.apiURL(`learner`, `${learnerSlug}`),
    authenticated: true,
  };

  const response: base.IResponse<Learner> = await base.request(request);

  return response.data;
}
