import { Anchor, FieldController, GridItemLayout, GridLayout, ListItemLayout, ListLayout, NameValidator, NumbersValidator, RadioButtonCard, TextField, ToggleCard, useMatchScreenWidth } from '@keplerco/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useAppActions, useAppState } from '../../../../../overmind';
import { AssessmentType } from '../../../../../enums/assessment-type';
import { AssessmentPreferencesRequest, AssessmentPreferencesResponse } from '../../../../../models/overmind/assessment-preferences';
import classNames from 'classnames';
import styles from './details-step.module.css';
import { DetailsStepSkeleton } from './details-step.skeleton';
import { IDetailsStepLayoutProps } from './details-step.models';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';
import layoutStyles from '../../../../../design/layout.module.css';
import { DetailsRequest } from '../assessments-wizard-peer.models';

export function DetailsStepLayout({ assessmentType, assessmentSlug, setAssessmentSlug, isLoading, setIsLoading, details, setDetails, preferences, setPreferences, onNext }: IDetailsStepLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const fieldValuesRef = useRef<HTMLFormElement>(null);
  const errorMessageRef = useRef<HTMLDivElement>(null);

  const [dirty, setDirty] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [fieldValues, setFieldValues] = useState<FieldValues>();

  const isMobile = useMatchScreenWidth('mobile');

  const { control, watch, setValue, trigger } = useForm<any>({ mode: 'onBlur' });

  useEffect(() => {
    watch(setFieldValues);
  }, []);

  useEffect(() => {
    setValue('name', details?.name);
    setValue('keplerPoints', details?.keplerPoints);
  }, [details]);

  useEffect(() => {
    if (assessmentType !== AssessmentType.PeerEndorsement) return;
    setErrorMessage(!preferences.allowSelfReview && !preferences.allowPeerReview && !preferences.allowManagerReview);
  }, [preferences.allowSelfReview, preferences.allowPeerReview, preferences.allowManagerReview]);

  if (isLoading) return <DetailsStepSkeleton />;

  return (
    <div className={styles.layout}>
      <EntitiesCardWidget title="Details" description=" Name your assessment, and choose how many Kepler Points you would like to award">
        <form ref={fieldValuesRef} id="assessmentDetails">
          <div style={{ display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr auto', gap: 15 }}>
            <FieldController
              name="name"
              control={control}
              rules={new NameValidator()}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Assessment name"
                  type="text"
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: !!fieldState.error,
                    message: fieldState.error?.message ?? 'Enter a name',
                  }}
                  responsive
                  onBlur={() => trigger('name')}
                />
              )}
            />

            <FieldController
              name="keplerPoints"
              control={control}
              rules={new NumbersValidator('Enter a number')}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Kepler Points"
                  type="number"
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: !!fieldState.error,
                    message: fieldState.error?.message ?? 'Enter a number',
                  }}
                  responsive
                  onBlur={() => trigger('keplerPoints')}
                />
              )}
            />
          </div>
        </form>
      </EntitiesCardWidget>

      <EntitiesCardWidget title="Preferences" description="Choose the preferences for this assessment.">
        {assessmentType === AssessmentType.PeerEndorsement ? (
          <div>
            <ListLayout>
              <ListItemLayout>
                <ToggleCard
                  id="allowSelfReview"
                  value={preferences.allowSelfReview!}
                  onChange={(_, value) => {
                    const nextState = { ...preferences, allowSelfReview: value };
                    setPreferences(nextState);
                  }}
                >
                  <div className={styles.toggleCardChildren}>
                    <label htmlFor="allowSelfReview" className="h4">
                      Self Assessment
                    </label>

                    {!isMobile && <span style={{ color: 'var(--accent-2' }}>Participants will assess themselves against assigned skills</span>}
                  </div>
                </ToggleCard>
              </ListItemLayout>

              <ListItemLayout>
                <ToggleCard
                  id="allowManagerReview"
                  value={preferences?.allowManagerReview ?? false}
                  onChange={(_, value) => {
                    const nextState = { ...preferences, allowManagerReview: value };
                    setPreferences(nextState);
                  }}
                >
                  <div className={styles.toggleCardChildren}>
                    <label htmlFor="allowManagerReview" className="h4">
                      Manager Assessment
                    </label>

                    {!isMobile && <span style={{ color: 'var(--accent-2' }}>The manager and the employee will be given a chance to rate the employee's skills</span>}
                  </div>
                </ToggleCard>
              </ListItemLayout>

              <ListItemLayout>
                <ToggleCard
                  id="allowPeerReview"
                  value={!!preferences?.allowPeerReview}
                  onChange={(_, value) => {
                    const nextState = { ...preferences, allowPeerReview: value };
                    setPreferences(nextState);
                  }}
                >
                  <div className={styles.toggleCardChildren}>
                    <label htmlFor="allowPeerReview" className="h4">
                      Peer Assessment
                    </label>

                    {!isMobile && <span style={{ color: 'var(--accent-2' }}>The entire peer group will rate one another on the assigned skills</span>}
                  </div>
                </ToggleCard>
              </ListItemLayout>
            </ListLayout>

            <div ref={errorMessageRef} className={classNames('formErrorMessage', { invisible: !dirty || !errorMessage })} style={{ marginTop: 15 }}>
              Activate at least one assessment toggle
            </div>
          </div>
        ) : (
          <Fragment>
            <div>
              <h4>Reassessment</h4>

              <div className="row">
                <ToggleCard
                  id="allowReassessment"
                  value={!!preferences?.allowReassessment}
                  onChange={(_, value) => {
                    const nextState = { ...preferences, allowReassessment: value };
                    setPreferences(nextState);
                  }}
                >
                  <label htmlFor="allowReassessment" className="h3">
                    Allow reassessment
                  </label>

                  {!isMobile && <span style={{ color: 'var(--accent-2' }}>Allow the learners to reset and retake the assessment by themselves</span>}
                </ToggleCard>
              </div>
            </div>

            <div>
              <h4>Assessment scores</h4>

              <div className="row">
                <div className="column">
                  <TextField
                    label="Minimum score"
                    type="number"
                    defaultValue={preferences.minScore}
                    min={0}
                    onChange={event => {
                      const nextState = { ...preferences, minScore: parseInt(event.target.value) };
                      setPreferences(nextState);
                    }}
                    responsive
                    validation={{
                      dirty: dirty,
                      invalid: preferences.minScore === undefined || preferences.minScore >= preferences.maxScore || preferences.minScore < 0,
                      message: 'Minimum score is required and must be less than maximum score and greater than 0',
                    }}
                  />
                </div>

                <div className="column">
                  <TextField
                    label="Maximum score"
                    type="number"
                    defaultValue={preferences.maxScore}
                    max={100}
                    onChange={event => {
                      const nextState = { ...preferences, maxScore: parseInt(event.target.value) };
                      setPreferences(nextState);
                    }}
                    responsive
                    validation={{
                      dirty: dirty,
                      invalid: preferences.maxScore === undefined || preferences.minScore >= preferences.maxScore || preferences.maxScore > 100,
                      message: 'Maximum score is required and must be greater than minimum score and less than 100',
                    }}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        )}

        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 20 }}>
          <h4>Do you want to force participants to complete of this assessment once activated?</h4>

          {!isMobile && <span style={{ color: 'var(--accent-2)', marginBottom: 10 }}>Force participants to complete an assessment, the next time someone logs in they will be required to complete the assessment before continuing.</span>}

          <GridLayout columnCount={isMobile ? 1 : 2}>
            <GridItemLayout>
              <RadioButtonCard
                id="forceAssessmentTrue"
                checked={!!preferences?.forceAssessment}
                onChange={() => {
                  const nextState = { ...preferences, forceAssessment: true };
                  setPreferences(nextState);
                }}
              >
                <div className="card">Yes, make this compulsory</div>
              </RadioButtonCard>
            </GridItemLayout>

            <GridItemLayout>
              <RadioButtonCard
                id="forceAssessmentFalse"
                checked={!preferences?.forceAssessment}
                onChange={() => {
                  const nextState = { ...preferences, forceAssessment: false };
                  setPreferences(nextState);
                }}
              >
                <div className="card">No, make optional</div>
              </RadioButtonCard>
            </GridItemLayout>
          </GridLayout>

          <span className={classNames({ invisible: !preferences.forceAssessment })} style={{ color: 'var(--accent-2)' }}>
            Great! We'll let people know to complete the assessment.
          </span>
        </div>
      </EntitiesCardWidget>

      <footer className={classNames('card', layoutStyles.wizardFooter)} style={{ justifyContent: 'flex-end' }}>
        <Anchor
          arrow
          onClick={async () => {
            setDirty(true);
            const isValid = await trigger(['name', 'keplerPoints']);

            if (!fieldValues || !isValid) {
              if (!!fieldValuesRef.current) fieldValuesRef.current.scrollIntoView(false);
              return;
            }

            if (!!errorMessage) {
              if (!!errorMessageRef.current) errorMessageRef.current.scrollIntoView(false);
              return;
            }

            // TODO: other assessmentTypes validation
            // if (assessmentType !== AssessmentType.PeerEndorsement && (preferences.minScore === un
            // defined || preferences.maxScore === undefined || preferences.minScore >= preferences.maxScore || preferences.minScore < 0 || preferences.maxScore > 100)) return;

            setIsLoading(true);

            const detailsRequest: DetailsRequest = {
              companySlug: companyVariables.slug!,
              assessmentType,
              slug: assessmentSlug,
              name: fieldValues?.name,
              keplerPointsAvailable: parseInt(fieldValues?.keplerPoints),
            };
            const response = await actions.postAssessmentDetails(detailsRequest);

            if (!response) {
              setIsLoading(false);
              return;
            }

            setAssessmentSlug(response);
            setDetails({
              name: fieldValues.name,
              keplerPoints: parseInt(fieldValues?.keplerPoints),
            });

            const preferencesRequest: AssessmentPreferencesRequest & AssessmentPreferencesResponse = {
              ...preferences,
              assessmentSlug: response,
              companySlug: companyVariables.slug!,
            };
            await actions.putAssessmentPreferences(preferencesRequest);
            setPreferences(preferences);

            onNext();
            setIsLoading(false);
          }}
        >
          Next
        </Anchor>
      </footer>
    </div>
  );
}
