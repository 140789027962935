import React, { Fragment } from 'react';
import { IProfileEmailBlockProps } from '../profile.models';
import { Chip, DeleteIcon, IconButton, safeCallback } from '@keplerco/core';
import styles from './profile-email-block.module.css';

export function ProfileEmailBlock(props: IProfileEmailBlockProps): JSX.Element {
  function generateChip() {
    if (props.isPrimary) return <Chip themeColour="g">Primary Email</Chip>;

    if (!props.verificationDate) return <Chip customTheme={{ backgroundColor: 'background', borderColor: 'borders', color: 'text' }}>Unverified</Chip>;

    return (
      <button style={{ margin: 0, backgroundColor: 'transparent', border: 'none' }} onClick={() => safeCallback(props.onMakePrimary, props.email)}>
        <Chip themeColour="r">Make Primary Email</Chip> {/* TODO: remove chip */}
      </button>
    );
  }

  return (
    <div className={styles.profileEmailBlock}>
      <h5 style={{ marginBottom: 5 }}>{props.email}</h5>

      {!props.hideChip && generateChip()}

      <div className={styles.profileEmailBlockIndicator}>
        {!!props.verificationDate ? (
          <Fragment>
            Verified
            <svg fill="none" viewBox="0 0 15 15" width={15} height={15}>
              <path fill="var(--baby-blue)" fillRule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0Zm7.072 3.21 4.318-5.398-.78-.624-3.682 4.601L4.32 7.116l-.64.768 3.392 2.827v-.001Z" clipRule="evenodd" />
            </svg>
          </Fragment>
        ) : (
          <Fragment>
            Not yet verified
            <svg fill="none" viewBox="0 0 15 15" width={15} height={15}>
              <path
                fill="var(--r)"
                d="M12.843 2.188C9.92-.729 5.12-.729 2.193 2.188c-2.924 2.918-2.924 7.706 0 10.624a7.482 7.482 0 0 0 10.575 0c2.925-2.918 3-7.706.075-10.624ZM9.62 10.642l-2.1-2.095-2.1 2.095-1.05-1.047 2.1-2.095-2.1-2.095 1.05-1.047 2.1 2.095 2.1-2.095 1.05 1.047-2.1 2.095 2.1 2.095-1.05 1.047Z"
              />
            </svg>
            <button className={styles.profileEmailBlockResendVerificationButton} onClick={() => safeCallback(props.onResendVerification, props.email)}>
              Resend verification
            </button>
          </Fragment>
        )}
      </div>

      {!props.isPrimary && (
        <span className={styles.profileEmailBlockDeleteButton}>
          <IconButton
            iconType="fill"
            onClick={() => {
              if (!props.isPrimary) safeCallback(props.onDelete, props.email);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      )}
    </div>
  );
}
