import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label, Rectangle, TooltipProps } from 'recharts';
import { colourString } from '@keplerco/core';
import { IScatterChartProps } from './scatter-chart.models';
import { NameType } from 'recharts/types/component/DefaultTooltipContent';
import styles from '../recharts-tooltip/recharts-tooltip.module.css';
import { useAppState } from '../../../overmind';
import { generateTicks } from '../horizontal-bar-chart/horizontal-bar-chart.helpers';

const X_INDEX = 0;
const Y_INDEX = 1;

interface IScatterChartTooltipProps extends TooltipProps<number, NameType> {
  useLevels: boolean;
  xTickFormatter?: (value: number) => string;
  yTickFormatter?: (useLevels: boolean, value: number) => string;
}

function ScatterChartTooltip({ active, payload, useLevels, xTickFormatter, yTickFormatter }: IScatterChartTooltipProps) {
  if (!active || !payload || payload.length === 0) return <></>;

  return (
    <div className={styles.tooltipWrapper}>
      {payload.map((item, index) => {
        let value;
        if (item.value !== undefined && index === X_INDEX && !!xTickFormatter) value = xTickFormatter(item.value);
        else if (item.value !== undefined && index === Y_INDEX && !!yTickFormatter) value = yTickFormatter(useLevels, item.value);
        else value = item.value;

        return (
          <div key={item.name} className={styles.tooltipText}>
            <small>
              {item.name}: {value}
            </small>
          </div>
        );
      })}
    </div>
  );
}

export function ScatterChartComponent({ dataPoints, useLevels, fill = 'primary', xTickFormatter, xTickCount, yTickFormatter }: IScatterChartProps) {
  const { companyVariables } = useAppState();
  
  const xValues = dataPoints.map(point => point[Object.keys(point)[0]] as number);
  const minX = Math.min(0, Math.floor(Math.min(...xValues))); 
  const maxX = Math.ceil(Math.max(...xValues));

  return (
    <ResponsiveContainer width="100%" height={500}>
      <ScatterChart margin={{ top: 24, right: 48, bottom: 24, left: 24 }}>
        <Scatter data={dataPoints} fill={colourString(fill)} shape={<Rectangle radius={10} height={10} width={10} />} />

        <CartesianGrid syncWithTicks stroke={colourString('default')} strokeWidth={0.3} strokeDasharray="3 3" />

        <XAxis 
          type="number" 
          dataKey={Object.keys(dataPoints[0])[0]} 
          stroke={colourString('text_1')} 
          strokeWidth={1} 
          height={50} 
          tick={{ fill: 'var(--text)', fontSize: '1rem', fontWeight: 700 }} 
          tickCount={xTickCount} 
          tickFormatter={xTickFormatter || (value => Math.round(value).toString())}
          domain={xTickFormatter ? [0, 3] : [minX, maxX]}
          allowDecimals={false}
          tickMargin={10}
        >
          <Label value={Object.keys(dataPoints[0])[0]} position="insideBottom" offset={-10} className="body" fill="var(--text)" />
        </XAxis>

        <YAxis
          type="number"
          dataKey={Object.keys(dataPoints[0])[1]}
          stroke={colourString('text_1')}
          strokeWidth={1}
          height={50}
          tick={{ fill: 'var(--text)', fontSize: '1rem' }}
          tickFormatter={value => (!!yTickFormatter ? yTickFormatter(useLevels, value) : value)}
          tickMargin={10}
          ticks={useLevels ? generateTicks(0, companyVariables.maxLevel) : generateTicks(0, 100)}
          domain={companyVariables.useLevels ? [0, companyVariables.maxLevel] : [0, 100]}
        >
          {/* TODO: this isn't centered */}
          <Label value={Object.keys(dataPoints[0])[1]} angle={-90} position="insideLeft" offset={-5} className="body" fill="var(--text)" />
        </YAxis>

        <Tooltip cursor={false} filterNull={false} content={<ScatterChartTooltip useLevels={useLevels} xTickFormatter={xTickFormatter} yTickFormatter={yTickFormatter} />} />
      </ScatterChart>
    </ResponsiveContainer>
  );
}
