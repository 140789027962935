import React, { useState, useEffect } from 'react';
import { IWeglotLanguageSwitcherProps, LanguageCode, LanguageOptionType } from './weglot-switcher.models';
import { FlagIcon } from '../../../design/icons/flag.icon';
import { ArrowIcon } from '@keplerco/core';
import CircleCheckIcon from '../../../design/icons/circle-check.icon';
import styles from './weglot-switcher.module.css';
import { CSSProperties } from 'styled-components';
import classNames from 'classnames';

const languageOptions: LanguageOptionType[] = [
  { code: 'zh', flag: <FlagIcon isoCode="CN" />, name: 'Chinese (Simplified)', abbreviation: 'ZHO' },
  { code: 'en', flag: <FlagIcon isoCode="GB" />, name: 'English', abbreviation: 'ENG' },
  { code: 'es', flag: <FlagIcon isoCode="ES" />, name: 'Spanish', abbreviation: 'SPA' },
  { code: 'fr', flag: <FlagIcon isoCode="FR" />, name: 'French', abbreviation: 'FRA' },
  { code: 'de', flag: <FlagIcon isoCode="DE" />, name: 'German', abbreviation: 'GER' },
  { code: 'ja', flag: <FlagIcon isoCode="JP" />, name: 'Japanese', abbreviation: 'JPN' },
  { code: 'ko', flag: <FlagIcon isoCode="KR" />, name: 'Korean', abbreviation: 'KOR' },
  { code: 'pt', flag: <FlagIcon isoCode="PT" />, name: 'Portuguese', abbreviation: 'POR' },
  { code: 'vi', flag: <FlagIcon isoCode="VN" />, name: 'Vietnamese', abbreviation: 'VIE' },
];

// TODO: refactor to use 'DropdownList' and be more consistent with other Dropdowns' UX
export default function WeglotLanguageSwitcher({ position = 'bottomLeft' }: IWeglotLanguageSwitcherProps) {
  const [currentLanguageCode, setCurrentLanguageCode] = useState<keyof LanguageCode>('en');
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if ((window as any).Weglot) {
      setCurrentLanguageCode((window as any).Weglot.getCurrentLang());
    }
  }, []);

  function onClickOption(lang: keyof LanguageCode) {
    if ((window as any).Weglot) {
      (window as any).Weglot.switchTo(lang);
      setCurrentLanguageCode(lang);
      setShowList(false);
    }
  }

  let containerCSSProperties: CSSProperties = {};
  if (position === 'bottomLeft') {
    containerCSSProperties = {
      position: 'fixed',
      bottom: 10,
      left: 40,
    };
  } else if (position === 'topNavigation') {
    containerCSSProperties = {
      position: 'relative',
      paddingRight: 10,
    };
  }

  let listCSSProperties: CSSProperties = {};
  if (position === 'bottomLeft') {
    listCSSProperties = {
      bottom: '100%',
    };
  } else if (position === 'topNavigation') {
    listCSSProperties = {
      top: '100%',
      marginTop: 10,
    };
  }

  return (
    <div className={styles.container} style={containerCSSProperties}>
      <button className={styles.button} onClick={() => setShowList(!showList)}>
        {languageOptions.find(option => option.code === currentLanguageCode)?.flag}
        {languageOptions.find(option => option.code === currentLanguageCode)?.abbreviation}
        <ArrowIcon tone="primary" size={15} rotation={showList ? 3 : 1} />
      </button>

      {showList && (
        <div className={styles.list} style={listCSSProperties}>
          <h5>Language</h5>

          {languageOptions.map(({ code, flag, name }: LanguageOptionType) => (
            <div key={code} className={classNames('body', styles.option)} onClick={() => onClickOption(code)}>
              {flag} {name}
              {currentLanguageCode === code && <CircleCheckIcon />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
