import React from 'react';
import { EntitiesIcon } from '../../../../../design/icons/entities.icon';
import classNames from 'classnames';
import styles from './entities-assigned.module.css';
import { IEntitiesAssignedWidgetProps } from './entities-assigned.models';
import { SkillAssessmentAssignee } from '../../../../../enums';
import { SkeletonOverlay } from '../../../../../components/general/loading-state/skeleton-overlay';

function getEntityName(entityType: SkillAssessmentAssignee, totalEntities: number): string {
  switch (entityType) {
    case SkillAssessmentAssignee.Company:
      return `Company`;
    case SkillAssessmentAssignee.Department:
      return `Department${totalEntities > 1 ? 's' : ''}`;
    case SkillAssessmentAssignee.Team:
      return `Team${totalEntities > 1 ? 's' : ''}`;
    default:
      return totalEntities !== 1 ? 'People' : 'Person';
  }
}

export function EntitiesAssignedWidget({ isLoading, totalEntities, entityType, totalPeople }: IEntitiesAssignedWidgetProps): React.ReactElement {
  return (
    <SkeletonOverlay isLoading={isLoading} width="100%" height="100%">
      <div className={classNames('card', styles.card)}>
        <EntitiesIcon size={80} />

        <div>
          {totalEntities} {getEntityName(entityType, totalEntities)}
        </div>

        {entityType !== SkillAssessmentAssignee.Learner && (
          <small>
            {totalPeople} {totalPeople !== 1 ? 'Participants' : 'Participant'}
          </small>
        )}
      </div>
    </SkeletonOverlay>
  );
}
