import React, { useState, useMemo, useCallback } from 'react';
import { Anchor, Button, Checkbox, Chip, ListItemLayout, ListLayout, PageHeader, search, SearchBar, useMatchScreenWidth } from '@keplerco/core';
import classNames from 'classnames';
import styles from './bulk-upload-result.module.css';
import { IBulkUploadResultModalProps } from './bulk-upload-result.modal.models';
import { UserBulkUploadRow } from '../../../../../../models/bulk-uploads/bulk-upload-dto';
import { useAppActions, useAppState } from '../../../../../../overmind';

export function BulkUploadResultModal({ data, onClose }: IBulkUploadResultModalProps) {
  const isMobile = useMatchScreenWidth('mobile');

  const state = useAppState();
  const actions = useAppActions();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const filteredData = useMemo(() => {
    const validRows = data.rows.filter(row => row.isValid);
    if (searchValue.trim()) {
      return search(validRows, searchValue, 'firstName', 'lastName', 'jobTitle', 'email');
    }
    return validRows;
  }, [data.rows, searchValue]);

  const handleSelectItem = useCallback((email: string) => {
    setSelectedItems(current => (current.includes(email) ? current.filter(item => item !== email) : [...current, email]));
  }, []);

  const handleSendActivationEmails = useCallback(() => {
    actions.sendActivationInvitations({ companySlug: state.companyVariables.slug!, emails: selectedItems });

    setTimeout(() => {
      onClose();
    }, 1000);
  }, [selectedItems, onClose]);

  return (
    <div className="modalContent">
      <PageHeader title="Upload Confirmation" subtitle="The following users have been added to your company. Would you like activation emails to be sent immediately?" />

      <div className={styles.searchContainer}>
        <SearchBar label="Search" onInput={e => setSearchValue((e.target as HTMLInputElement).value)} />
        <Anchor
          onClick={() => {
            if (!filteredData) return;
            const allEmails = filteredData.map(person => person.email);
            setSelectedItems(selectedItems.length === filteredData.length ? [] : allEmails);
          }}
        >
          {selectedItems.length === filteredData.length ? 'Deselect All' : 'Select All'}
        </Anchor>
      </div>

      <div className={styles.scrollableList}>
        <ListLayout>
          {filteredData.map(item => (
            <ListItem isMobile={isMobile} key={item.rowNumber} item={item} onSelect={handleSelectItem} selectedItems={selectedItems} />
          ))}
        </ListLayout>
      </div>

      <div className={styles.actionContainer}>
        <Anchor onClick={onClose}>Skip</Anchor>
        <Button type="button" filled onClick={handleSendActivationEmails} disabled={selectedItems.length === 0}>
          Send
        </Button>
      </div>
    </div>
  );
}

function ListItem({ item, onSelect, selectedItems, isMobile }: { item: UserBulkUploadRow; onSelect: (email: string) => void; selectedItems: string[]; isMobile: boolean }) {
  return (
    <ListItemLayout key={item.rowNumber}>
      <div onClick={() => onSelect(item.email)} className={classNames('card', styles.cardStyle, selectedItems.includes(item.email) && styles.cardStyleSelected)} style={{ cursor: 'pointer' }}>
        <Checkbox id={item.email} checked={selectedItems.includes(item.email)} onClick={e => e.preventDefault()}>
          <div className={styles.cardColumn}>{item.firstName}</div>
          {!isMobile && (
            <>
              <div className={styles.cardColumn}>{item.lastName}</div>
              <div className={styles.cardColumn}>{item.jobTitle}</div>
              <div className={styles.cardColumn} style={{ width: 250 }}>
                {item.email ? item.email : '[Email Address]'}
              </div>
            </>
          )}
          <div className={classNames(styles.rowItem, styles.flexEnd)} style={{ justifyContent: 'flex-end', paddingLeft: `15px` }}>
            <Chip themeColour="g">Uploaded</Chip>
          </div>
        </Checkbox>
      </div>
    </ListItemLayout>
  );
}
