import React, { useEffect, useState } from 'react';
import { Button, DropdownSelectItem, Pager } from '@keplerco/core';
import { useAppActions, useAppState } from '../../../overmind';
import { transformScore } from '../ranked-entities/ranked-entities.helpers';
import { DataPoint } from '../../../components/charts/recharts.models';
import { RankedPerformanceChart } from '../../../components/charts/ranked-performance-chart/ranked-performance-chart';
import { AssessmentSearchParams, FilteringSearchParams, PagingSearchParams, PermissionSearchParams, SortingSearchParams } from '../../../models/overmind/search-params';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { AnalyticsFilters, DEFAULT_FILTERING_SEARCH_PARAMS } from '../../../pages/analytics/analytics-filters/analytics-filters';
import classNames from 'classnames';
import { AnalyticsSortBy } from '../../../pages/analytics/analytics-filters/analytics-sort-by';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';
import { IAverageScoreSkillWidgetProps } from './average-score-skill.models';
import { SortByMenuOptions } from '../../../enums/sort-by-menu';
import { WidgetCard } from '../../../pages/analytics/analytics-filters/widget-card';
import { DownloadIcon } from '../../../design/icons/download.icon';

// TODO: add to consts
export const SORT_BY_ITEMS: DropdownSelectItem[] = [
  {
    value: SortByMenuOptions.AlphabeticalAtoZ,
    label: 'A to Z',
  },
  {
    value: SortByMenuOptions.AlphabeticalZtoA,
    label: 'Z to A',
  },
  {
    value: SortByMenuOptions.ScoreLowToHigh,
    label: 'Lowest to highest score',
  },
  {
    value: SortByMenuOptions.ScoreHighToLow,
    label: 'Highest to lowest score',
  },
];

export default function AverageScoreSkillWidget({ baseSearchParams, filtersItems }: IAverageScoreSkillWidgetProps) {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SortingSearchParams & PagingSearchParams>({
    sortBy: undefined,
    sortAscending: true,
    page: 1,
    pageSize: 10,
  });
  const [filteringSearchParams, setFilteringSearchParams] = useState<FilteringSearchParams>(DEFAULT_FILTERING_SEARCH_PARAMS);
  const [dataPoints, setDataPoints] = useState<DataPoint[]>([]);
  const [totalPages, setTotalPages] = useState(1);

  async function getData(request: AssessmentSearchParams & PermissionSearchParams & FilteringSearchParams & SortingSearchParams & PagingSearchParams) {
    setIsLoading(true);
    const response = await actions.getAssessmentAverageScores(request);
    setDataPoints(transformScore(response?.averages ?? [], companyVariables));
    setTotalPages(response?.totalPages ?? 1);
    setIsLoading(false);
  }

  useEffect(() => {
    getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams });
  }, [baseSearchParams]);

  return (
    <SkeletonOverlay isLoading={isLoading} width="100%">
      <WidgetCard title="Average score per skill" subtitle="The average score of each skill included in this assessment across all participants">
        <div style={{ display: 'flex', flexDirection: 'row', gap: 12, flexWrap: 'wrap' }}>
          <AnalyticsFilters
            departmentItems={filtersItems.departmentItems}
            teamItems={filtersItems.teamItems}
            roleItems={filtersItems.roleItems}
            skillItems={filtersItems.skillItems}
            countryItems={filtersItems.countryItems}
            filteringSearchParams={filteringSearchParams}
            setFilteringSearchParams={setFilteringSearchParams}
            applyFilters={() => {
              getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams });
            }}
            clearFilters={() => {
              setFilteringSearchParams(DEFAULT_FILTERING_SEARCH_PARAMS);
              getData({ ...baseSearchParams, ...searchParams, ...DEFAULT_FILTERING_SEARCH_PARAMS });
            }}
          />

          <AnalyticsSortBy
            items={SORT_BY_ITEMS}
            value={searchParams.sortBy}
            onChange={event => {
              const sortBy = event.target.value;
              setSearchParams({ ...searchParams, sortBy });
              getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams, sortBy });
            }}
          />

          <div style={{ marginLeft: 'auto' }}>
            <Button
              square
              theme="dark"
              type="button"
              isLoading={isDownloading}
              loaderText="Downloading..."
              onClick={async () => {
                setIsDownloading(true);
                await actions.getAssessmentAverageScoresCSV({ ...baseSearchParams, ...searchParams, ...filteringSearchParams });
                setIsDownloading(false);
              }}
            >
              <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <DownloadIcon />
                CSV
              </div>
            </Button>
          </div>
        </div>

        {!dataPoints.length ? (
          <EmptyState title="Hang tight!" subtitle="We are waiting for data." height="365px" />
        ) : (
          <React.Fragment>
            <RankedPerformanceChart dataPoints={dataPoints} fill="baby-blue" />

            <div className={classNames({ invisible: !totalPages || totalPages < 2 })}>
              <Pager
                activePageNumber={searchParams.page ?? 1}
                pageCount={totalPages}
                onPageChange={async page => {
                  if (page === searchParams.page) return;
                  setSearchParams({ ...searchParams, page });
                  getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams, page });
                }}
              />
            </div>
          </React.Fragment>
        )}
      </WidgetCard>
    </SkeletonOverlay>
  );
}
