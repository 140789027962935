import React, { PropsWithChildren } from 'react';
import { SkeletonLoader } from './loaders/skeleton-loader/skeleton-loader';
import classNames from 'classnames';

export function SkeletonOverlay({ isLoading, width, height, minHeight, children }: PropsWithChildren<{ isLoading: boolean; width?: number | string; height?: number | string; minHeight?: number | string }>): React.ReactElement {
  return (
    <div style={{ position: 'relative', width, height, minHeight }}>
      {/* TODO: backgroundColor */}
      <div className={classNames({ invisible: !isLoading })} style={{ position: 'absolute', backgroundColor: 'var(--cards)', zIndex: 9, width: '100%', height: '100%', borderRadius: 8 }}>
        {/* TODO: borderRadius */}
        <SkeletonLoader height="100%" borderRadius="8px" />
      </div>

      {children}
    </div>
  );
}
