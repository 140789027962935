import React from 'react';
import { UrgencyIndication } from '../../enums/analytics';
import { Chip } from '@keplerco/core';

export function UrgencyIndicationChip({ urgencyIndication }: { urgencyIndication: UrgencyIndication }): React.ReactElement {
  if (urgencyIndication === UrgencyIndication.ExceedsExpectation) {
    return <Chip themeColour="g">{UrgencyIndication.ExceedsExpectation}</Chip>;
  }

  if (urgencyIndication === UrgencyIndication.MeetsExpectation) {
    return <Chip themeColour="a">{UrgencyIndication.MeetsExpectation}</Chip>;
  }

  return <Chip themeColour="r">{UrgencyIndication.BelowExpectation}</Chip>;
}
