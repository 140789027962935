import React from 'react';
import { IContinueLearningProps } from './continue-learning.models';
import { LessonCard } from '../../components/cards/lesson-card/lesson-card';
import { CompletionStatus } from '../../enums';
import { ILessonCardFooterProps, ILessonCardProps } from '../../components/cards/lesson-card/lesson-card.models';
import { CaptureAdditionalLearningCard } from '../../components/cards/capture-additional-learning-card/capture-additional-learning-card';
import { PagePath } from '../../navigation/navigation.enums';
import { ChevronIcon, GridLayout } from '@keplerco/core';

export function ContinueLearningWidget(props: IContinueLearningProps): JSX.Element {
  return (
    <div>
      <h3>Continue Learning</h3>

      <br />

      <GridLayout columnMinWidth={200}>
        {props.lessons.map(lesson => {
          const footer: ILessonCardFooterProps = {
            text: lesson.courseCompletionStatus === CompletionStatus.NotStarted ? 'Start' : 'Continue',
            backgroundColour: 'primary',
            icon: <ChevronIcon size={15} tone="contrast-text" />,
            colour: 'contrast-text',
          };

          const lessonCardProps: ILessonCardProps = {
            thumbnailUrl: lesson.image,
            keplerPoints: lesson.completionStatus === CompletionStatus.Completed ? lesson.keplerPoints : lesson.keplerPointsAvailable,
            name: lesson.name,
            description: lesson.description,
            lessonUrl: lesson.url,
            playerType: lesson.playerType,
            completed: lesson.completionStatus === CompletionStatus.Completed,
            footer,
            courseSlug: lesson.courseSlug,
            path: PagePath.dashboard,
          };

          return <LessonCard key={lesson.name} {...lessonCardProps} />;
        })}

        <CaptureAdditionalLearningCard onClick={props.onClickCaptureAdditionalLearning} />
      </GridLayout>
    </div>
  );
}
