import React from 'react';
import { IImportSkillLayoutProps } from './manage-role.models';
import { ImportSkillWidget } from '../../../../widgets/import-skill/import-skill.widget';
import { PanelHeader } from '@keplerco/core';

export function ImportSkillPanel({ supertitle, onClose }: IImportSkillLayoutProps): JSX.Element {
  return (
    <div className="panelContent">
      <PanelHeader supertitle={supertitle} title="Import skills" subtitle="Search for global skills and add them to your company skills" divider />
      <ImportSkillWidget onCancel={onClose} onImport={onClose} />
    </div>
  );
}
