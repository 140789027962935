import React, { Fragment, useEffect, useState } from 'react';
import { Notification, NotificationType } from '../notifications.models';
import { useAppActions } from '../../overmind';
import { Anchor } from '@keplerco/core';
import classNames from 'classnames';
import { InfoIcon } from './info.icon';
import { AlertIcon } from './alert.icon';
import styles from './toast-hub.module.css';
import { Toast } from './toast';

function generateMessage(toast: Notification) {
  if (!!toast.toastHTML) {
    return <p className={styles.paragraph} dangerouslySetInnerHTML={toast.toastHTML}></p>;
  }

  if (!!toast.message) {
    return <p>{toast.message}</p>;
  }
}

export function ToastHub({ toasts }: { toasts: Notification[] }): JSX.Element {
  const actions = useAppActions();

  const [activeToasts, setActiveToasts] = useState<Notification[]>([]);

  useEffect(() => {
    if (Array.isArray(toasts)) setActiveToasts(toasts.filter(a => a.active));
  }, [toasts]);

  return (
    <Fragment>
      {activeToasts.map(toast => {
        return (
          <Toast
            key={toast.id}
            open={activeToasts[0].id === toast.id}
            type={toast.type}
            onClose={() => {
              setActiveToasts([...activeToasts.filter(y => y.id !== toast.id)]);
              actions.dismissNotification(toast.id);
            }}
          >
            {!!toast.toastIncludeIcon && (
              <figure className={styles.icon}>
                {toast.type === NotificationType.Info && <InfoIcon tone="text" />}
                {toast.type === NotificationType.Error && <InfoIcon tone="r" />}
                {toast.type === NotificationType.Warn && <AlertIcon tone="a" />}
                {toast.type === NotificationType.Success && <InfoIcon tone="g" />}
              </figure>
            )}

            <div className={classNames(styles.content, { [styles.hasIcon]: !!toast.toastIncludeIcon })}>
              {!!toast.title && <h4>{toast.title}</h4>}

              {generateMessage(toast)}

              {!!toast.toastButton && (
                <Anchor style={{ marginLeft: 'auto' }} arrow onClick={toast.toastButton.onClick}>
                  {toast.toastButton.text ? toast.toastButton.text : 'Continue'}
                </Anchor>
              )}
            </div>
          </Toast>
        );
      })}
    </Fragment>
  );
}
