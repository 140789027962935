import React from 'react';
import { Notification, NotificationType } from '../notifications.models';
import { KeplerPointType } from '../../enums';
import * as layouts from '../../widgets/layouts';
import { Button } from '@keplerco/core';

export function ModalLayoutContainer(props: Notification & { onAction: () => void }): JSX.Element {
  if (props.keplerPointType === KeplerPointType.CategoryCompleted) {
    return <layouts.CategoryCompletedModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.SkillsGapReport) {
    return <layouts.SkillsGapReportModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.JourneyCompleted) {
    return <layouts.JourneyCompletedModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.LoginStreak) {
    return <layouts.LoginStreakModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.LevelAchieved) {
    return <layouts.LevelAchievedModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.SkillScorePostAssessmentUpdated) {
    return <layouts.SkillScorePostAssessmentUpdatedModalLayout {...props} />;
  }

  if (props.keplerPointType === KeplerPointType.SkillScorePreAssessmentUpdated) {
    return <layouts.SkillScorePreAssessmentUpdatedModalLayout {...props} />;
  }

  if (props.type === NotificationType.MissionBriefing) {
    return <layouts.MissionBriefingModalLayout {...props} />;
  }

  return (
    <div className="modalContent" style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
      <header>
        <h2>{props.title}</h2>
      </header>

      <section>
        <h3>{props.message}</h3>
      </section>

      <Button type="button" filled onClick={props.onAction}>
        Okay, Got it.
      </Button>
    </div>
  );
}
