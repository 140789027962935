/* eslint-disable indent */
import React from 'react';
import { useAppState } from '../../../overmind';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import classNames from 'classnames';
import { CompletionStatus } from '../../../enums/completion-status';
import { TechnicalSkillsChips, technicalSkillStatus } from './landing.helpers';
import { PagePath } from '../../../navigation/navigation.enums';
import { AssessmentType } from '../../../enums/assessment-type';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { Chip, ChipWrapper } from '@keplerco/core';
import styles from './landing.module.css';
import KeplerNavlink from '../../../navigation/guards/kepler-navlink';
import { CompletionStatusChip } from '../../../components/chips/completion-status.chip';

export function AnalysisLandingPage(): JSX.Element {
  const subdomain = getSubdomain();
  const { skillAssessmentConfig } = useAppState();

  const technicalStatus = skillAssessmentConfig?.selfReviewCompletionStatus;
  const behavioralStatus = skillAssessmentConfig?.behavioralCompletionStatus;
  const peerReviewStatus = skillAssessmentConfig?.selfPeerReviewCompletionStatus;
  const careerPathSelectionStatus = skillAssessmentConfig?.careerPathSelectionCompletionStatus;
  const careerPathsStatus = skillAssessmentConfig?.careerPathCompletionStatus;

  function PeerEndorsementCard() {
    const linkTo =
      skillAssessmentConfig?.selfReviewCompletionStatus === CompletionStatus.NoActionRequired
        ? `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`
        : technicalStatus === CompletionStatus.Completed && peerReviewStatus === CompletionStatus.Completed
        ? `${PagePath.analysisBase}`
        : `${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}`;

    return (
      <KeplerNavlink to={linkTo} className={classNames(styles.card, { done: technicalStatus === CompletionStatus.Completed && peerReviewStatus === CompletionStatus.Completed })} style={{ borderLeft: 'solid 4px var(--blue)' }}>
        <ChipWrapper>
          <TechnicalSkillsChips hasPeerReview={peerReviewStatus === CompletionStatus.NotStarted} peerReviewStatus={skillAssessmentConfig?.selfPeerReviewCompletionStatus} technicalStatus={technicalStatus} />
        </ChipWrapper>
        <h3>Role-based skills analysis</h3>
        <small>{technicalSkillStatus(technicalStatus, true, peerReviewStatus)}</small>
      </KeplerNavlink>
    );
  }

  function QuestionnaireCard() {
    return (
      <KeplerNavlink to={`${PagePath.analysisBase}${PagePath.analysisBehavioural}`} className={classNames(styles.card, { [styles.completed]: behavioralStatus === CompletionStatus.Completed })} style={{ borderLeft: 'solid 4px var(--grape)' }}>
        <ChipWrapper>
          <CompletionStatusChip completionStatus={behavioralStatus} />
        </ChipWrapper>
        <h3>Behavioural skills analysis</h3>
        <small>These skills determine how well you interact with others.</small>
      </KeplerNavlink>
    );
  }

  function FieldsOfPracticeCard() {
    return (
      <React.Fragment>
        <KeplerNavlink to={`${PagePath.analysisBase}${PagePath.analysisCareerPaths}`} className={classNames(styles.card, { [styles.completed]: careerPathSelectionStatus === CompletionStatus.Completed })} style={{ borderLeft: 'solid 4px var(--lime)' }}>
          <ChipWrapper>
            <CompletionStatusChip completionStatus={careerPathSelectionStatus} />
          </ChipWrapper>
          <h3>Choose {subdomain.includes('lpi') ? 'your role' : 'a career pathway'}</h3>
          <small>Let us know where you are heading with your career</small>
        </KeplerNavlink>

        {careerPathSelectionStatus === CompletionStatus.Completed && (
          <KeplerNavlink
            to={`${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}`}
            className={classNames(styles.card, { [styles.completed]: careerPathSelectionStatus === CompletionStatus.Completed && careerPathsStatus === CompletionStatus.Completed })}
            style={{ borderLeft: 'solid 4px var(--yellow)' }}
          >
            <ChipWrapper>
              <CompletionStatusChip completionStatus={careerPathsStatus} />
            </ChipWrapper>
            <h3>{skillAssessmentConfig?.assessmentName ?? 'Skills assessment'}</h3>
            <small>Rate yourself based on your career skills.</small>
          </KeplerNavlink>
        )}
      </React.Fragment>
    );
  }

  return (
    <div className={styles.page}>
      <div className={styles.image}>
        <object data={themedAssetUrl('graphics/pie-graph.graphic.svg')} type="image/svg+xml">
          <img src={themedAssetUrl('graphics/pie-graph.graphic.svg')} alt="graph" />
        </object>
      </div>

      <div className={styles.content}>
        <ChipWrapper>
          <Chip themeColour="primary">{subdomain.includes('lpi') ? 'Capability map' : 'Skills analysis'}</Chip>
        </ChipWrapper>

        <h1>{subdomain.includes('lpi') ? 'Rate your skills' : 'Identify your strengths'}</h1>

        <h6>Your analysis will give us the insights we need to build your personalised development plan.</h6>

        <div className={styles.cards}>
          {skillAssessmentConfig?.assessmentType === AssessmentType.PeerEndorsement && <PeerEndorsementCard />}

          {skillAssessmentConfig?.assessmentType === AssessmentType.Questionnaire && <QuestionnaireCard />}

          {skillAssessmentConfig?.assessmentType === AssessmentType.FieldsOfPractice && <FieldsOfPracticeCard />}
        </div>
      </div>
    </div>
  );
}
