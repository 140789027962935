import { CompanyRoleListItemResponse, CompanySkillListItemResponse } from '../../../../../models/overmind/company-entity';
import { Proficiency } from '../../../../../models/proficiency';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { AIGenerateRoleRequest } from '../../../../../overmind/actions';

export enum RegenerateOption {
  Expand = 'Expand',
  Shorten = 'Shorten',
  Simplify = 'Simplify',
  Improve = 'Improve',
}

export interface IDetailsStepLayoutProps {
  path: PagePath;
  seniorities: Proficiency[];
  roleSlug: string | undefined;
  selectedRole: CompanyRoleListItemResponse | undefined;
  parentIsLoading: boolean;
  setSelectedRole: (role: CompanyRoleListItemResponse | undefined) => void;
  completeStep: (data: { role: AIGenerateRoleRequest; roleSlug: string; skills?: CompanySkillListItemResponse[] }) => void;
}

