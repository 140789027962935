import { KeplerPointType } from '../enums/kepler-point-type';
import { AwardBreakdown } from '../models/overmind/award-breakdown';

// TODO: Check if there is a better way of naming and organising these
export enum NotificationType {
  Activity,
  LoginStreak,
  Success,
  Warn,
  Error,
  MissionBriefing,
  Info,
}

export enum NotificationPriority {
  Toast = 0,
  Tile = 1,
  Modal = 2,
}

export interface Notification {
  id: string;
  active: boolean;
  title?: string;
  message?: string;
  slug?: string;
  amount?: number;
  level?: number;
  awardBreakdowns?: AwardBreakdown[];
  loginStreaks?: Date[];
  keplerPointType?: KeplerPointType;
  type: NotificationType; // notificationType on BE
  priority?: NotificationPriority; // notificationPriority on BE

  // FE
  toastHTML?: { __html: string };
  toastIncludeIcon?: boolean;
  toastButton?: {
    text?: string; 
    onClick: () => void; 
  };
}

export interface INotificationLayoutProps extends Notification {
  onAction: () => void;
}
