import React from 'react';
import { AssessmentType } from '../../../../enums/assessment-type';
import { QuestionnaireIcon } from '../../../../design/icons/questionnaire.icon';
import { FOPIcon } from '../../../../design/icons/fop.icon';
import { PeerIcon } from '../../../../design/icons/peer.icon';
import styles from './assessment-type.module.css';
import classNames from 'classnames';
import { SkeletonOverlay } from '../../../../components/general/loading-state/skeleton-overlay';
import { useAppActions, useAppState } from '../../../../overmind';

export function AssessmentTypeWidget({ assessmentSlug, assessmentType, isLoading }: { assessmentSlug: string; assessmentType: AssessmentType; isLoading: boolean }): React.ReactElement {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isChildLoading, setIsChildLoading] = React.useState<boolean>(true);
  const [details, setDetails] = React.useState<string>();

  React.useEffect(() => {
    async function getData() {
      if (assessmentType !== AssessmentType.PeerEndorsement) return setIsChildLoading(false);

      setIsChildLoading(true);
      const response = await actions.getAssessmentPreferences({ assessmentSlug, companySlug: companyVariables.slug! });

      const preferences: string[] = [];
      if (response?.allowManagerReview) preferences.push('Manager');
      if (response?.allowPeerReview) preferences.push('Peer');
      if (response?.allowSelfReview) preferences.push('Self');

      let details: string = '';
      preferences.forEach((preference, index) => {
        if (index === 0) return (details = details.concat(preference));
        if (index === preferences.length - 1) return (details = details.concat(` & ${preference}`));
        return (details = details.concat(`, ${preference}`));
      });
      setDetails(details);

      setIsChildLoading(false);
    }

    getData();
  }, [assessmentSlug, companyVariables.slug]);

  return (
    <SkeletonOverlay isLoading={isLoading || isChildLoading} width="100%" height="100%">
      <div className={classNames('card', styles.card)}>
        {assessmentType === AssessmentType.Questionnaire && (
          <React.Fragment>
            <QuestionnaireIcon />
            <div>Questionnaire Assessment</div>
          </React.Fragment>
        )}

        {assessmentType === AssessmentType.FieldsOfPractice && (
          <React.Fragment>
            <FOPIcon />
            <div>Field of Practice Assessment</div>
          </React.Fragment>
        )}

        {assessmentType === AssessmentType.PeerEndorsement && (
          <React.Fragment>
            <PeerIcon />
            <div>
              <div>Rating Assessment</div>
              <small>{details} Assessment</small>
            </div>
          </React.Fragment>
        )}
      </div>
    </SkeletonOverlay>
  );
}
