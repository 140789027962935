export enum CompletionStatus {
  NoActionRequired = 3,
  Completed = 2,
  InProgress = 1,
  NotStarted = 0,
}

export enum CompletionStatusDescription {
  NoActionRequired = 'No action required',
  Completed = 'Completed',
  InProgress = 'In progress',
  NotStarted = 'Not started',
}

export function getCompletionStatusDescription(completionStatus: CompletionStatus | undefined): string {
  if (completionStatus === CompletionStatus.NoActionRequired) {
    return CompletionStatusDescription.NoActionRequired;
  }

  if (completionStatus === CompletionStatus.InProgress) {
    return CompletionStatusDescription.InProgress;
  }

  if (completionStatus === CompletionStatus.Completed) {
    return CompletionStatusDescription.Completed;
  }

  return CompletionStatusDescription.NotStarted;
}
