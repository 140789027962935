import React, { useState } from 'react';
import { CascadingPanel, CascadingPanels, PageHeader, Panel, useCascadingPanelsConnector } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';
import { RoleComparisonList } from './role-comparison/role-comparison-list';
import { ComparisonToolWidget } from '../../../components/general/comparison-tool/comparison-tool.widget';
import { ComparisonToolCondition } from '../../../components/general/comparison-tool/comparison-tool.models';
import { PeopleComparisonList } from './people-comparison/people-comparison-list';
import { UniqueEntity } from '../../../models';
import { RoleFitComparisonWidget } from './role-fit-comparison/role-fit-comparison.widget';
import { RoleFitAverageWidget } from './role-fit-average/role-fit-average.widget';
import { ComparisonToolPanel } from '../../../components/general/comparison-tool/comparison-tool.panel';
import { SelectCohortLayout } from '../../../components/panels/select-cohort/select-cohort.layout';
import { CohortType } from '../../../components/panels/select-cohort/select-cohort.models';

enum CascadingFocusPanelIds {
  SelectCohort = 'SelectCohort',
  SelectPeople = 'SelectPeople',
}

export function ComparisonToolsPage(): JSX.Element {
  const [pendingRole, setPendingRole] = useState<UniqueEntity>();
  const [selectedRole, setSelectedRole] = useState<UniqueEntity>();
  const [pendingPeopleSlugs, setPendingPeopleSlugs] = useState<string[]>([]);
  const [selectedPeopleSlugs, setSelectedPeopleSlugs] = useState<string[]>([]);

  const [openPrimaryConditionPanel, setOpenPrimaryConditionPanel] = useState<boolean>(false);
  const primaryCondition: ComparisonToolCondition = {
    title: 'Select a role',
    subtitle: 'Choose a role to compare',
    chipLabel: selectedRole?.name,
    onClick: () => {
      setOpenPrimaryConditionPanel(true);
      setPendingRole(selectedRole);
    },
    panel: (
      <Panel
        open={openPrimaryConditionPanel}
        onClose={() => {
          setOpenPrimaryConditionPanel(false);
          setPendingRole(undefined);
          setPendingPeopleSlugs([]);
        }}
      >
        <ComparisonToolPanel
          supertitle="Analytics"
          title="Select role"
          subtitle="Please select a role from the list"
          list={<RoleComparisonList pendingRoleSlug={pendingRole?.slug} setPendingRole={setPendingRole} />}
          onBack={() => {
            setOpenPrimaryConditionPanel(false);
            setPendingRole(undefined);
            setSelectedRole(undefined);
            setPendingPeopleSlugs([]);
            setSelectedPeopleSlugs([]);
          }}
          onAdd={() => {
            setOpenPrimaryConditionPanel(false);
            setPendingRole(undefined);
            setSelectedRole(pendingRole);
            setPendingPeopleSlugs([]);
            setSelectedPeopleSlugs([]);
          }}
        />
      </Panel>
    ),
  };

  const { openPanelIds, next, previous, closeAll } = useCascadingPanelsConnector();
  const [selectedCohortType, setSelectedCohortType] = useState<CohortType>(CohortType.Department);
  const [selectedEntity, setSelectedEntity] = useState<UniqueEntity>();
  const secondaryCondition: ComparisonToolCondition = {
    title: 'Select people',
    subtitle: 'Choose people to compare',
    chipLabel: selectedPeopleSlugs.length > 0 ? `${selectedPeopleSlugs.length} ${selectedPeopleSlugs.length > 1 ? 'people' : 'person'} selected` : undefined,
    onClick: () => {
      next(CascadingFocusPanelIds.SelectCohort);
      setPendingPeopleSlugs(selectedPeopleSlugs);
    },
    panel: (
      <CascadingPanels
        openPanelIds={openPanelIds}
        onClosePanel={() => {
          previous();
          setPendingPeopleSlugs([]);
        }}
      >
        <CascadingPanel id={CascadingFocusPanelIds.SelectCohort}>
          <SelectCohortLayout
            supertitle="Analytics"
            title="Select cohort"
            subtitle="Filter the list of people"
            cohortTypes={[CohortType.Department, CohortType.Team, CohortType.People]}
            selectedCohortType={selectedCohortType}
            setSelectedCohortType={setSelectedCohortType}
            selectedEntity={selectedEntity}
            setSelectedEntity={setSelectedEntity}
            onBack={() => {
              closeAll();
              setPendingPeopleSlugs([]);
              setSelectedPeopleSlugs([]);
            }}
            onNext={() => next(CascadingFocusPanelIds.SelectPeople)}
          />
        </CascadingPanel>

        <CascadingPanel id={CascadingFocusPanelIds.SelectPeople}>
          <ComparisonToolPanel
            supertitle="Analytics"
            title="Select people"
            subtitle="Please select up to 5 people from the list"
            list={<PeopleComparisonList selectedCohortType={selectedCohortType} selectedEntity={selectedEntity} pendingPeopleSlugs={pendingPeopleSlugs} setPendingPeopleSlugs={setPendingPeopleSlugs} />}
            onBack={previous}
            onAdd={() => {
              closeAll();
              setPendingPeopleSlugs([]);
              setSelectedPeopleSlugs(pendingPeopleSlugs);
            }}
          />
        </CascadingPanel>
      </CascadingPanels>
    ),
  };

  return (
    <div className="wrapper stack" style={{ gap: 30 }}>
      <PageHeader
        breadcrumbs={[
          {
            name: 'Analytics',
            url: PagePath.analyticsBase,
          },
          {
            name: 'Comparison Tools',
            url: window.location.pathname,
          },
        ]}
        title="Comparison Tools"
      />

      <ComparisonToolWidget title="Compare people for a role" primaryCondition={primaryCondition} secondaryCondition={secondaryCondition} />

      <RoleFitAverageWidget roleSlug={selectedRole?.slug} peopleSlugs={selectedPeopleSlugs} />

      <RoleFitComparisonWidget roleSlug={selectedRole?.slug} peopleSlugs={selectedPeopleSlugs} />
    </div>
  );
}
