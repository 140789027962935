import React, { useEffect, useState } from 'react';
import { OverviewWidget } from './overview.widget';
import { SkillScoresRequest, SkillScoresSkillResponse } from '../../../models/overmind/analytics';
import { useAppActions, useAppState } from '../../../overmind';
import { DonutSlices, ISkillOverviewWidgetProps, OverviewWidgetChartData, OverviewWidgetDonutChartData } from './overview.models';
import { AVERAGE_SKILL_LEVEL, SKILL_LEVELS, KP_AWARDED, NON_LEARNER_COMPLETION_RATES, NON_LEARNER_SKILL_PERCENTAGE } from './help-information';
import { defaultLessonStatusCounts, defaultSkillLevels, generateDonutChartData, generateNumberChartData } from './overview.helpers';
import { AnalyticsSearchParams, SkillScoresSearchParams } from '../../../models/overmind/search-params';
import { OrganizationLevelType } from '../../../enums';
import { ResponseGroup } from '../../../enums/analytics';
import { filteredColours } from '../../../library/consts/filtered-colours';
import { ScoreComparison } from '../../../enums/score-comparison';

export function SkillOverviewWidget({ entity, skillSlug, skillSubTypeSlug, setSkillName }: ISkillOverviewWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { dateRange, companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [donutChartData, setDonutChartData] = useState<OverviewWidgetDonutChartData>();
  const [firstNumberDisplayChartData, setFirstNumberDisplayChartData] = useState<OverviewWidgetChartData>();
  const [secondNumberDisplayChartData, setSecondNumberDisplayChartData] = useState<OverviewWidgetChartData>();

  useEffect(() => {
    async function getSkillLevels(request: AnalyticsSearchParams) {
      const response = (await actions.analyticsGetSkillLevels(request)) ?? defaultSkillLevels;
      const slices: DonutSlices = response.percentages.map((skillLevel, index) => {
        const label = `${skillLevel.label}`;
        return { label: label, value: skillLevel.percentage * 1.0, colour: filteredColours[index % filteredColours.length] }; // NOTE: 1.0 is used to convert percentage to float
      });
      setDonutChartData(generateDonutChartData('Skill levels', 'Total people', response.peopleCount, slices, SKILL_LEVELS));
    }

    async function getLessonStatusCounts(request: AnalyticsSearchParams) {
      const response = (await actions.analyticsGetLessonStatusCounts(request)) ?? defaultLessonStatusCounts;
      const slices: DonutSlices = [
        { label: `Not started`, value: response.notStarted, colour: `default` },
        { label: `In Progress`, value: response.inProgress, colour: `blue` },
        { label: `Complete`, value: response.complete, colour: `secondary` },
      ];
      setDonutChartData(generateDonutChartData('Completion rates', 'Total courses', response.complete, slices, NON_LEARNER_COMPLETION_RATES));
    }

    async function getLevelsPercentagesAndPoints(startDate: string | undefined, endDate: string | undefined, organizationLevel: OrganizationLevelType, slug: string | undefined) {
      const request: AnalyticsSearchParams = {
        startDate,
        endDate,
        organizationLevel: OrganizationLevelType.Learner,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        skillSlug,
        skillSubTypeSlug: skillSubTypeSlug,
      };
      if (organizationLevel === OrganizationLevelType.Department) request.departmentSlug = slug;
      if (organizationLevel === OrganizationLevelType.Team) request.teamSlug = slug;
      if (organizationLevel === OrganizationLevelType.Learner) request.learnerSlug = slug;

      companyVariables.useLevels ? getSkillLevels(request) : getLessonStatusCounts(request);

      const response = await actions.analyticsGetKeplerPoints(request);
      setSecondNumberDisplayChartData(generateNumberChartData(`KP awarded`, Math.floor(response?.keplerPoints ?? 0), `number`, KP_AWARDED));
    }

    function generateSkillLevelNumberDisplayChartData(value: number | undefined) {
      return generateNumberChartData(`Average skill score`, value, 'number', AVERAGE_SKILL_LEVEL, 'skillLevel');
    }

    function generateSkillPercentageNumberDisplayChartData(value: number | undefined) {
      return generateNumberChartData(`Average skill score`, value, 'percentage', NON_LEARNER_SKILL_PERCENTAGE, 'skillPercentage');
    }

    async function getSkillLevel(startDate: string | undefined, endDate: string | undefined, organizationLevel: OrganizationLevelType, slug: string | undefined) {
      const searchParams: SkillScoresSearchParams = {
        startDate,
        endDate,
        organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        skillSlug,
        skillSubTypeSlug: skillSubTypeSlug,
        includeLearnerCount: false,
        scoreComparison: ScoreComparison.None,
      };
      if (organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = slug;
      if (organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = slug;
      if (organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug = slug;
      const request: SkillScoresRequest = {
        responseGroup: ResponseGroup.Skill,
        searchParams: searchParams,
      };
      const response = await actions.analyticsGetSkillScores(request);

      let temp: SkillScoresSkillResponse | undefined;
      if (!!response) {
        for (const item of response) {
          for (const skillType of item.skillTypes) {
            for (const skillSubType of skillType.skillSubTypes) {
              for (const skill of skillSubType.skills) {
                if (skill.slug === skillSlug) {
                  temp = skill;
                  break;
                }
              }
            }
          }
        }
      }

      setFirstNumberDisplayChartData(companyVariables.useLevels ? generateSkillLevelNumberDisplayChartData(temp?.score?.level) : generateSkillPercentageNumberDisplayChartData(temp?.score?.percentage));
      setSkillName(temp?.name ?? 'Skill');
    }

    async function getData() {
      if (!entity) return;

      setLoading(true);

      const startDate = dateRange?.from?.toJSON();
      const endDate = dateRange?.to?.toJSON();
      const organizationLevel = entity.organizationLevel;
      const slug = entity.entity?.slug;

      await getLevelsPercentagesAndPoints(startDate, endDate, organizationLevel, slug);
      await getSkillLevel(startDate, endDate, organizationLevel, slug);

      setLoading(false);
    }

    getData();
  }, [entity, companyVariables.slug]);

  return <OverviewWidget isPercentageData loading={loading} title="Skill overview" donutChartData={donutChartData} firstNumberDisplayChartData={firstNumberDisplayChartData} secondNumberDisplayChartData={secondNumberDisplayChartData} />;
}
