import React from 'react';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import styles from './recharts-tooltip.module.css';

interface IRechartsTooltipProps extends TooltipProps<ValueType, NameType> {
  hideName?: boolean;
  hideValue?: boolean;
  hideColour?: boolean;
  total?: number;
  isPercentageData?: boolean;
}

export function RechartsTooltip({ active, payload, hideName, hideValue, hideColour, total, isPercentageData }: IRechartsTooltipProps): JSX.Element {
  if (!active || !payload || payload.length === 0) return <></>;

  return (
    <div className={styles.tooltipWrapper}>
      {payload[0] && !hideName && <div key={String(payload[0].payload.name)}>{String(payload[0].payload.name)}</div>}

      {payload.map(item => {
        const itemName = String(item.name ?? 'Unknown');
        let labelText: string;

        if (isPercentageData) {
          labelText = hideValue ? itemName : `${Number(item.value).toFixed(2)}% ${itemName}`;
        } else {
          let percentage: string | undefined;
          if (total && typeof item.value === 'number' && total > 0) {
            percentage = ((item.value / total) * 100).toFixed(2);
          }
          labelText = hideValue ? itemName : percentage ? `${percentage}% ${itemName}` : `${itemName}: ${item.value ?? 'Not assessed'}`;
        }

        return (
          <div key={itemName} className={styles.tooltipText}>
            {!hideColour && (
              <svg width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="4" fill={item.color ?? item.payload.fill} />
              </svg>
            )}

            <small>{labelText}</small>
          </div>
        );
      })}
    </div>
  );
}
