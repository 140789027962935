import React from 'react';
import { CompletionStatus } from '../../enums';
import { Chip } from '@keplerco/core';
import { CompletionStatusDescription } from '../../enums/completion-status';

export function CompletionStatusChip({ completionStatus, label }: { completionStatus: CompletionStatus | undefined; label?: string }): React.ReactElement {
  if (completionStatus === CompletionStatus.InProgress) {
    return <Chip themeColour="a">{label ?? CompletionStatusDescription.InProgress}</Chip>;
  }

  if (completionStatus === CompletionStatus.Completed) {
    return <Chip themeColour="g">{label ?? CompletionStatusDescription.Completed}</Chip>;
  }

  return <Chip customTheme={{ backgroundColor: 'background', borderColor: 'borders', color: 'text' }}>{label ?? CompletionStatusDescription.NotStarted}</Chip>;
}
