import { Context } from '../..';
import * as base from '../base';
import { SaveCourseDto } from '../../../models/overmind/course-management';
import { CourseMappingsResponse } from '../../../models/overmind/learning-management';
import { BaseSearchRequest } from '../../../models';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';

const CONTROLLER = 'course-management';

export async function getCustomCourse(context: Context, payload: { companySlug: string; courseSlug: string }): Promise<SaveCourseDto | undefined> {
  const url: string = base.apiURL(CONTROLLER, `${payload.companySlug}/${payload.courseSlug}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<SaveCourseDto> = await base.request(request);
  return response.data;
}

export async function saveCourse(context: Context, payload: { companySlug: string; courseDto: SaveCourseDto }): Promise<string | undefined> {
  const url: string = base.apiURL(CONTROLLER, `${payload.companySlug}`);
  const request: base.IRequest = {
    url,
    method: 'POST',
    body: JSON.stringify(payload.courseDto),
    authenticated: true
  };
  const response: base.IResponse<string> = await base.request(request);

  context.actions.addNotification({
    id: Date.now().toString(),
    title: `Course ${!!payload.courseDto.slug ? 'updated' : 'saved'}`,
    message: `${payload.courseDto.name} has successfully been ${!!payload.courseDto.slug ? 'updated' : 'saved'}`,
    priority: NotificationPriority.Toast,
    type: NotificationType.Success,
    active: true,
  });

  return response.data;
}

export async function deleteCustomCourse(context: Context, payload: { companySlug: string; courseSlug: string }): Promise<void> {
  const url: string = base.apiURL(CONTROLLER, `${payload.companySlug}/${payload.courseSlug}`);
  const request: base.IRequest = {
    url,
    method: 'DELETE',
    authenticated: true
  };
  await base.request(request);
}

export async function searchLessons(context: Context, searchParams: BaseSearchRequest): Promise<CourseMappingsResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(`courses`, `search-lessons${base.params(searchParams)}`), authenticated: true };
  const response: base.IResponse<CourseMappingsResponse> = await base.request(request);
  return response.data;
}