import React, { Fragment, useEffect, useState } from 'react';
import { Anchor, AvatarIcon, Button, Chip, DropdownSelect, GridItemLayout, GridLayout, Modal, PageHeader, useMatchScreenWidth } from '@keplerco/core';
import { ConfirmationEndorsementsModalLayout } from '../rating-technical-peer-endorsement-modal/rating-confirm-endorsements-modal.layout';
import { EmptyState } from '../../../../../components/general/empty-state/empty-state';
import { CompletionStatus, FetchType } from '../../../../../enums';
import { filteredColours } from '../../../../../library/consts/filtered-colours';
import { Navigate, useNavigate } from 'react-router-dom';
import { PageLoaderLayer } from '../../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { RoutingMapResult } from '../../../../../models/routing-map';
import { TeamMemberReviewResource } from '../../../../../models/team-member-review-resource';
import { useAppActions, useAppState } from '../../../../../overmind';
import { AssessmentType } from '../../../../../enums/assessment-type';
import styles from './rating-technical-peer-endorsement-landing.module.css';
import classNames from 'classnames';
import KeplerNavlink from '../../../../../navigation/guards/kepler-navlink';

enum FilterStatus {
  All = 'All',
  Completed = 'Completed',
  NotCompleted = 'NotCompleted',
}

export function RatingTechnicalPeerEndorsementLandingPage(): JSX.Element {
  const navigate = useNavigate();
  const { skillAssessmentConfig } = useAppState();

  // TODO: add custom AnalysisRouter route guard
  if (skillAssessmentConfig?.assessmentType !== AssessmentType.PeerEndorsement) return <Navigate to={PagePath.analysisBase} />;

  const actions = useAppActions();

  const [team, setTeam] = useState<TeamMemberReviewResource[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<FilterStatus>(FilterStatus.All);

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.analysisRatingTechnicalPeerEndorsements, type: FetchType.PageFetching });
      const data = await actions.getTeamReviews();
      setTeam(data);
      actions.stopLoader(PagePath.analysisRatingTechnicalPeerEndorsements);
    }
    getData();
  }, []);

  const isMobile = useMatchScreenWidth('mobile');
  const isTablet = useMatchScreenWidth('tablet');
  let columns = 3;
  if (isMobile) columns = 1;
  else if (isTablet) columns = 2;

  const teamMembers = team?.filter(member => member.completionStatus !== CompletionStatus.NoActionRequired).sort((a, b) => a.firstName.localeCompare(b.firstName));

  async function onClickHandler(renderProps: RoutingMapResult) {
    actions.startLoader({ path: PagePath.analysisRatingTechnicalPeerEndorsements, type: FetchType.Sending });

    await actions.submitTeamReviews();
    actions.stopLoader(PagePath.analysisRatingTechnicalPeerEndorsements);
    const result = await actions.getSkillAssessmentConfiguration(skillAssessmentConfig!.assessmentSlug);

    if (result?.selfPeerReviewCompletionStatus === CompletionStatus.Completed && (result.selfReviewCompletionStatus === CompletionStatus.NoActionRequired || result.selfReviewCompletionStatus === CompletionStatus.Completed)) {
      actions.clearSkillAssessmentConfig();
      return;
    }

    return navigate(renderProps.next!);
  }
  function handleFilterChange(status: FilterStatus) {
    setFilterStatus(status);
    setShowModal(false);
  }

  const filteredTeamMembers = teamMembers?.filter(member => {
    if (filterStatus === FilterStatus.All) return true;
    if (filterStatus === FilterStatus.Completed) return member.hasCompletedReview;
    if (filterStatus === FilterStatus.NotCompleted) return !member.hasCompletedReview;
    return false;
  });

  function breadcrumbConfiguration() {
    if (skillAssessmentConfig?.selfReviewCompletionStatus === CompletionStatus.NoActionRequired) {
      return [
        {
          name: 'Endorse your team',
          url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`,
        },
      ];
    }

    return [
      { name: 'Skills analysis', url: PagePath.analysisBase },
      { name: 'Skill frequency', url: `${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}` },
      { name: 'Role-based skills analysis', url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}` },
      {
        name: 'Endorse your team',
        url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`,
      },
    ];
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.analysisRatingTechnicalPeerEndorsements}>
        <div className="pageWrapper wrapper">
          <PageHeader breadcrumbs={breadcrumbConfiguration()} title="Endorse your team" subtitle="Reviewing your team's work fosters collaboration and continuous improvement, helping them grow and building a stronger team." type="actions" divider>
            <div className="headerActions">
              <Button type="button" arrow onClick={() => setShowModal(!showModal)}>
                Submit
              </Button>
            </div>
          </PageHeader>

          {!!team && (
            <Fragment>
              <div style={{ marginTop: 30, marginBottom: 30, display: 'flex', alignItems: 'center', gap: 15 }}>
                <h3>Endorsements:</h3>

                <DropdownSelect
                  label="Filter by"
                  value={filterStatus.toString()}
                  items={[
                    {
                      label: 'All',
                      value: FilterStatus.All,
                      onClick: () => handleFilterChange(FilterStatus.All),
                    },
                    {
                      label: 'Completed',
                      value: FilterStatus.Completed,
                      onClick: () => handleFilterChange(FilterStatus.Completed),
                    },
                    {
                      label: 'Not Started',
                      value: FilterStatus.NotCompleted,
                      onClick: () => handleFilterChange(FilterStatus.NotCompleted),
                    },
                  ]}
                />
              </div>

              {!!filteredTeamMembers?.length ? (
                <GridLayout columnCount={columns}>
                  {filteredTeamMembers.map((member, index) => {
                    return (
                      <GridItemLayout key={member.userId}>
                        <UserNavlinkCard member={member} index={index} />
                      </GridItemLayout>
                    );
                  })}
                </GridLayout>
              ) : (
                <div style={{ padding: '30px 0 0 0' }}>
                  <EmptyState title="No team members" />
                </div>
              )}
            </Fragment>
          )}
        </div>
      </PageLoaderLayer>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <ConfirmationEndorsementsModalLayout
          title={`Have you endorsed everyone that you work closely with?`}
          team={team}
          handleShowCompleted={() => handleFilterChange(FilterStatus.Completed)}
          handleShowNotCompleted={() => handleFilterChange(FilterStatus.NotCompleted)}
          onClickSubmit={() =>
            onClickHandler({
              next: `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsements}`,
              previous: `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}`,
            })
          }
        />
      </Modal>
    </React.Fragment>
  );
}

function UserNavlinkCard({ member, index }: { member: TeamMemberReviewResource; index: number }) {
  return (
    <>
      <KeplerNavlink to={`${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsement.replace(':ratingSlug', member.userSlug)}`}>
        <div className={classNames('card', styles.card)}>
          {member.hasCompletedReview ? <Chip themeColour="g">Completed</Chip> : <Chip customTheme={{ backgroundColor: 'background', borderColor: 'borders', color: 'text' }}>Not Started</Chip>}

          <div className={styles.avatar}>
            <React.Fragment>{!!member.profileImageUrl ? <img src={member.profileImageUrl} /> : <AvatarIcon name={member} imageUrl={member.profileImageUrl} backgroundColour={filteredColours[index % filteredColours.length]} fontColour={'grc_14'} />}</React.Fragment>
          </div>

          <h4>
            {member.firstName} {member.lastName}
          </h4>

          <small style={{ marginBottom: 15 }}>&nbsp;{member.jobTitle}&nbsp;</small>

          {member.hasCompletedReview ? <Anchor style={{ marginTop: 'auto' }}>Change Endorsement</Anchor> : <Anchor style={{ marginTop: 'auto' }}>Start Endorsement</Anchor>}
        </div>
      </KeplerNavlink>
    </>
  );
}
