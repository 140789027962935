import React from 'react';
import '@keplerco/core/dist/styles.css';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { config } from './overmind';
import { createOvermind } from 'overmind';
import { Provider } from 'overmind-react';
import { App } from './App';
import { createRoot } from 'react-dom/client';
import { logAppVersionData } from './log-app-version-data';
import { createPortal } from 'react-dom';
import { Notifications } from './notifications/notifications';
import './polyfills';
import './shims';

const overmind = createOvermind(config);
const root = createRoot(document.getElementById('root')!);

root.render(
  <Provider value={overmind}>
    <BrowserRouter>
      <>
        <App />

        {createPortal(<Notifications />, window.document.body)}
      </>
    </BrowserRouter>
  </Provider>
);

// TODO: replace with Grafana Faro
// function sendToAnalytics(metric: Metric) {
//   const detailedMetric: DetailedMetricModel = {
//     slug: metric.id,
//     name: metric.name,
//     value: metric.value,

//     pathUrl: window.location.pathname,
//   };

//   const url = `${process.env.REACT_APP_KEPLER_API_ENDPOINT}webvitals/reportwebvital/save-detailed-metrics`;
//   const body = JSON.stringify(detailedMetric);

//   // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
//   if (!!navigator.sendBeacon) {
//     const headers = { type: 'application/json' };
//     const blob = new Blob([body], headers);
//     navigator.sendBeacon(url, blob);
//     return;
//   }

//   const params = new base.DefaultAuthenticatedParameters({ method: 'POST', body: body, keepalive: true });
//   fetch(url, params.toObject());
// }

// reportWebVitals(sendToAnalytics);

logAppVersionData();

