import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './steps.module.css';
import { IStepsProps } from './steps.models';
import CircleCheckIcon from '../../../../design/icons/circle-check.icon';
import { ChevronIcon, IconButton, useWindowResize } from '@keplerco/core';
import { checkOverflowingHorizontally, canGoTo, scrollLeft, scrollRight, scrollTo } from '../stepper.helpers';

export function Steps({ steps, activeStepIndex, goTo }: IStepsProps) {
  const stepsRef = useRef<HTMLDivElement>(null);

  const [isOverflowing, setIsOverflowing] = useState<boolean>(checkOverflowingHorizontally(stepsRef.current as HTMLElement));

  const [width] = useWindowResize();

  useEffect(() => {
    setIsOverflowing(checkOverflowingHorizontally(stepsRef.current as HTMLElement));
  }, [stepsRef.current, width]);

  useEffect(() => {
    if (!isOverflowing) return;
    scrollTo(stepsRef.current, activeStepIndex);
  }, [activeStepIndex]);

  return (
    <div className={classNames('card', styles.container)}>
      {isOverflowing && (
        <div className={styles.button} style={{ left: 0, backgroundColor: 'linear-gradient(90deg, var(--background) 30%, transparent 100%)' }}>
          <IconButton iconType="stroke" onClick={() => scrollLeft(stepsRef.current)}>
            <ChevronIcon rotation={3} strokeWidth={2} />
          </IconButton>
        </div>
      )}

      <div ref={stepsRef} className={styles.steps}>
        {steps.map((step, index) => {
          const previousStep = steps[index - 1];
          const disabled = !canGoTo(step, previousStep);

          return (
            <button
              key={step.label}
              className={classNames(styles.step, {
                [styles.active]: index === activeStepIndex,
              })}
              aria-disabled={disabled}
              onClick={() => {
                if (disabled) return;
                goTo(index);
              }}
            >
              <span className={classNames({ invisible: !step.completed })} style={{ position: 'absolute', left: 12 }}>
                <CircleCheckIcon />
              </span>
              <span>{`${index + 1}. ${step.label}`}</span>
            </button>
          );
        })}
      </div>

      {isOverflowing && activeStepIndex < steps.length - 1 && (
        <div className={styles.button} style={{ right: 0, backgroundColor: 'linear-gradient(270deg, var(--background) 30%, transparent 100%)' }}>
          <IconButton iconType="stroke" onClick={() => scrollRight(stepsRef.current)}>
            <ChevronIcon strokeWidth={2} />
          </IconButton>
        </div>
      )}
    </div>
  );
}
