export function xTickFormatter(value: number): string {
  switch (value) {
    case 0: {
      return 'Never';
    }

    case 1: {
      return 'Daily';
    }

    case 2: {
      return 'Weekly';
    }

    case 3: {
      return 'Monthly';
    }

    default: {
      return 'Never';
    }
  }
}

export function yTickFormatter(useLevels: boolean, value: number): string {
  return useLevels ? value.toString() : `${value}%`;
}
