import { Context } from '../..';
import { AssignCompanyEntityRequest, CreateCompanyEntityRequest, UpdateCompanyEntityRequest, ImportCompanyEntityRequest, CompanySkillListResponse, CompanySkillListItemResponse } from '../../../models/overmind/company-entity';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';
import * as base from '../base';

const CONTROLLER = `companyskill`;

/**
 * Search for Skills as a User within the User's Company.
 */
export async function getUserCompanySkills(context: Context, payload: CompanyEntitySearchParams): Promise<CompanySkillListResponse | undefined> {
  const url: string = base.apiURL(CONTROLLER, `get-skills${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanySkillListResponse> = await base.request(request);
  return response.data;
}

/**
 * Search for Skills as a User with Permissions or a User with System Admin within any Company.
 */
export async function getCompanySkills(context: Context, payload: CompanyEntitySearchParams): Promise<CompanySkillListResponse | undefined> {
  const url: string = base.apiURL(CONTROLLER, `get-skills`, `${payload.companySlug}${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanySkillListResponse> = await base.request(request);
  return response.data;
}

export async function getCompanySkill(context: Context, payload: { companySlug: string; skillSlug: string }): Promise<CompanySkillListItemResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `get-skill`, payload.companySlug, payload.skillSlug), authenticated: true };
  const response: base.IResponse<CompanySkillListItemResponse> = await base.request(request);
  return response.data;
}

export async function createCompanySkill(context: Context, payload: CreateCompanyEntityRequest): Promise<base.IResponse<string>> {
  const url: string = base.apiURL(CONTROLLER, `create-skill`, payload.companySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(payload) };
  const response: base.IResponse<string> = await base.request(request);

  if (!!response.error) {
    context.actions.addNotification({
      active: true,
      id: crypto.randomUUID(),
      type: NotificationType.Error,
      priority: NotificationPriority.Toast,
      title: 'Your changes have not been saved',
      message: response.error.detail,
      toastIncludeIcon: true,
    });
  } else
    context.actions.addNotification({
      active: true,
      id: crypto.randomUUID(),
      type: NotificationType.Success,
      priority: NotificationPriority.Toast,
      title: `Your changes have been saved`,
      toastIncludeIcon: true,
    });

  return response;
}

export async function updateCompanySkill(context: Context, payload: UpdateCompanyEntityRequest): Promise<base.IResponse<string>> {
  const url: string = base.apiURL(CONTROLLER, `update-skill`, payload.companySlug, payload.entitySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'PUT', body: JSON.stringify(payload) };
  const response: base.IResponse<string> = await base.request(request);

  if (!!response.error) {
    context.actions.addNotification({
      active: true,
      id: crypto.randomUUID(),
      type: NotificationType.Error,
      priority: NotificationPriority.Toast,
      title: 'Your changes have not been saved',
      message: response.error.detail,
      toastIncludeIcon: true,
    });
  } else
    context.actions.addNotification({
      active: true,
      id: crypto.randomUUID(),
      type: NotificationType.Success,
      priority: NotificationPriority.Toast,
      title: `Your changes have been saved`,
      toastIncludeIcon: true,
    });

  return response;
}

export async function importCompanySkill(context: Context, payload: ImportCompanyEntityRequest): Promise<base.IResponse<string>> {
  const url: string = base.apiURL(CONTROLLER, `import`, payload.companySlug, payload.entitySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'POST' };
  const response: base.IResponse<string> = await base.request(request);
  return response;
}

export async function assignCompanySkill(context: Context, payload: AssignCompanyEntityRequest): Promise<void> {
  const url: string = base.apiURL(CONTROLLER, `assign-skill`, context.state.companyVariables.slug!);
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(payload) };
  await base.request(request);
}

