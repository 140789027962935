import React, { useEffect, useState } from 'react';
import { AddTwoToneIcon, Anchor, Button, KebabMenu, PageHeader, Pager, Panel, Searchfield, Table, TableColumn, TableRow } from '@keplerco/core';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { SortField, OrganizationLevelType } from '../../../enums';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
import { useAppActions, useAppState } from '../../../overmind';
import styles from './all-skills.module.css';
import { CompanySkillListItemResponse, CompanySkillListResponse } from '../../../models/overmind/company-entity';
import { SaveSkillPanel } from './save-skill/save-skill.panel';
import { PagePath } from '../../../navigation/navigation.enums';

export function AllSkillsPage(): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedSkill, setSelectedSkill] = useState<CompanySkillListItemResponse | undefined>();
  const [data, setData] = useState<CompanySkillListResponse>();
  const [request, setRequest] = useState<CompanyEntitySearchParams>({
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 10,
    page: 1,
    organizationLevel: OrganizationLevelType.Company,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: false,
  });

  async function getSkillsData() {
    const response = await actions.getCompanySkills(request);
    setData(response);
  }

  useEffect(() => {
    async function initPage() {
      setIsLoading(true);
      await getSkillsData();
      setIsLoading(false);
    }
    initPage();
  }, [companyVariables.slug]);

  useEffect(() => {
    if (!data) return;
    getSkillsData();
  }, [request, companyVariables.slug]);

  async function handleSearch(search: string) {
    setIsSearching(true);
    setRequest(current => ({ ...current, page: 1, search: search }));
    setIsSearching(false);
  }

  async function handlePageChange(newPage: number) {
    setIsLoading(true);
    setRequest(current => ({ ...current, page: newPage }));
    setIsLoading(false);
  }

  function onClosePanel(): void {
    setIsOpen(false);
    setSelectedSkill(undefined);
  }

  return (
    <React.Fragment>
      <div className="wrapper stack" style={{ gap: 30 }}>
        <PageHeader
          breadcrumbs={[
            { name: 'Role & Skill Management', url: PagePath.roleSkillManagementBase },
            { name: 'All skills', url: window.location.pathname },
          ]}
          title="All skills"
          subtitle="A library of all the skills within your company"
        />
        <div className={styles.companySkillsContainer}>
          <div className={styles.companySkillsActions}>
            <Searchfield loading={isSearching} onInput={handleSearch} />

            <div className={styles.companySkillsActionsGroup}><Button type="button" square theme="dark" onClick={() => setIsOpen(true)}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <AddTwoToneIcon />
                Add skill
              </div>
            </Button>
            </div>
          </div>

          {isLoading || !!data?.entities.length ? (
            <React.Fragment>
              {isLoading ? (
                <SkeletonLoader height="500px" />
              ) : (
                <Table standAloneConfigColumn currentSortBy="skill" currentSortDirection={request.sortAscending ? 'Ascending' : 'Descending'} onSort={() => setRequest(currentState => ({ ...currentState, sortAscending: !currentState.sortAscending }))}>
                  {data?.entities.map(skill => (
                    <TableRow
                      key={skill.slug}
                      id={skill.slug!}
                      configCell={() => {
                        return (
                          <span key={skill.slug}>
                            <KebabMenu
                              items={[
                                {
                                  label: 'Edit',
                                  onClick: () => setSelectedSkill(skill),
                                },
                              ]}
                            />
                          </span>
                        );
                      }}
                    >
                      <TableColumn sortable id="skill" label="Skill">
                        <Anchor hovertype="opacity" textTransform="none" onClick={() => setSelectedSkill(skill)}>
                          {skill.name}
                        </Anchor>
                      </TableColumn>

                      <TableColumn id="description" label="Description" emptyText="No description provided">
                        <div className={styles.descriptionColumn}>{skill.description}</div>
                      </TableColumn>
                    </TableRow>
                  ))}
                </Table>
              )}
              {!!data && data.totalPages > 1 && (
                <PagerConnector defaultPageNumber={request.page} pageCount={data?.totalPages ?? 0} onPageChange={handlePageChange}>
                  {connector => <Pager {...connector} isLoading={isLoading} />}
                </PagerConnector>
              )}
            </React.Fragment>
          ) : (
            <EmptyState title="No skills found" />
          )}
        </div>
      </div>

      <Panel open={isOpen || !!selectedSkill} onClose={onClosePanel}>
        {(isOpen || !!selectedSkill) && <SaveSkillPanel selectedSkill={selectedSkill} onClose={onClosePanel} refreshData={getSkillsData} />}
      </Panel>
    </React.Fragment>
  );
}
