import React from 'react';
import { useNavigate } from 'react-router-dom';
import { themedAssetUrl } from '../../../../../library/helpers/themed-asset-url';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { Button, PageHeader, UnorderedList, UnorderedListItem, useMatchScreenWidth } from '@keplerco/core';
import { useAppState } from '../../../../../overmind';
import { CompletionStatus } from '../../../../../enums';
import styles from './rating-technical-peer-endorsement-interstitial.module.css';
import classNames from 'classnames';

export function RatingTechnicalPeerEndorsementInterstitialPage(): JSX.Element {
  const navigate = useNavigate();
  const { skillAssessmentConfig } = useAppState();

  const isMobile = useMatchScreenWidth('tablet');

  const heroImage = <img src={themedAssetUrl('graphics/endorse-team.graphic.png')} alt="" />;

  function breadcrumbConfiguration() {
    if (skillAssessmentConfig?.selfReviewCompletionStatus === CompletionStatus.NoActionRequired) {
      return [
        {
          name: 'Endorse your team',
          url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`,
        },
      ];
    }
    return [
      { name: 'Skills analysis', url: PagePath.analysisBase },
      { name: 'Skill frequency', url: `${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}` },
      { name: 'Role-based skills analysis', url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}` },
      {
        name: 'Endorse your team',
        url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`,
      },
    ];
  }

  return (
    <PageLoaderLayer path={PagePath.analysisRatingTechnical}>
      <div className={classNames('pageWrapper wrapper', styles.page)}>
        <PageHeader breadcrumbs={breadcrumbConfiguration()} />

        <section className={styles.section}>
          <figure className={styles.figure}>
            <div className={styles.image}>{heroImage}</div>
          </figure>

          <div className={styles.content}>
            <h1 className={styles.title}>Endorse your team's skills</h1>
            <h3 className={styles.subtitle} style={{ paddingBottom: 0 }}>
              Show your appreciation for your colleagues' skills and strengths by endorsing them. Here's how it works:
            </h3>

            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <UnorderedList>
                <UnorderedListItem>Choose which team members you’d like to endorse. You don’t need to complete endorsements for every colleague.</UnorderedListItem>

                <UnorderedListItem>Give your honest, objective feedback.</UnorderedListItem>

                <UnorderedListItem>Submit your endorsements anonymously.</UnorderedListItem>
              </UnorderedList>
            </div>

            <Button type="button" onClick={() => navigate(`${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsements}`)}>
              Endorse your team
            </Button>

            {isMobile && <div className={styles.image}>{heroImage}</div>}
          </div>
        </section>
      </div>
    </PageLoaderLayer>
  );
}
