import React from 'react';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { RechartsTooltip } from '../recharts-tooltip/recharts-tooltip';
import { useAppState } from '../../../overmind';
import { useMatchScreenWidth } from '@keplerco/core';
import { RechartsLegend } from '../recharts-legend/recharts-legend';
import { DataPoint } from '../recharts.models';
import { PolarAngleAxisTick } from './polar-angle-axis-tick';
import { DESKTOP_FONT_SIZE, MOBILE_FONT_SIZE } from '../../../library/consts/recharts';
import { generateFill } from '../recharts.helpers';

export function RadarChartComponent({ data }: { data: DataPoint[] | undefined }): JSX.Element {
  const { companyVariables } = useAppState();

  const isMobile = useMatchScreenWidth('mobile');

  return (
    <ResponsiveContainer height={isMobile ? 420 : 520} width="100%">
      <RadarChart outerRadius={isMobile ? 90 : 160} data={data} margin={{ top: 0, right: isMobile ? 10 : 20, left: isMobile ? 10 : 20, bottom: 0 }}>
        <PolarGrid stroke="var(--default)" strokeOpacity={0.3} />
        <PolarAngleAxis dataKey="name" stroke="var(--default)" strokeWidth={0.3} tick={props => <PolarAngleAxisTick {...props} isMobile={isMobile} />} tickSize={isMobile ? MOBILE_FONT_SIZE : DESKTOP_FONT_SIZE} tickLine={false} orientation="outer" cy={150} />
        <PolarRadiusAxis angle={90} domain={companyVariables.useLevels ? [companyVariables.minLevel, companyVariables.maxLevel] : [0, 100]} strokeWidth={0.3} strokeOpacity={0.2} tick={{ fill: 'var(--default)', fontSize: isMobile ? MOBILE_FONT_SIZE : DESKTOP_FONT_SIZE, fillOpacity: 0.5 }} />
        <Legend align="left" verticalAlign="top" layout="horizontal" content={<RechartsLegend />} />
        <Tooltip content={<RechartsTooltip />} />
        {Object.keys((data ?? [])[0])
          .filter(key => key !== 'name')
          .map((key, index) => (
            <Radar key={key} name={key} dataKey={key} stroke={generateFill(index, ['baby-blue', 'bright-pink'])} strokeWidth={3} fill={generateFill(index, ['baby-blue', 'bright-pink'])} fillOpacity={index === 0 ? 0.1 : 0} />
          ))}
      </RadarChart>
    </ResponsiveContainer>
  );
}
