import React, { useEffect, useState } from 'react';
import { useAppActions } from '../../../overmind';
import { ScoreOverviewCard } from './score-overview-card/score-overview-card';
import { AssessmentHighestLowestResponse } from '../../../models/overmind/assessment-analytics';
import { IScoreOverviewWidgetProps } from './score-overview.models';
import { FilteringSearchParams } from '../../../models/overmind/search-params';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';

const DEFAULT_SEARCH_PARAMS: FilteringSearchParams = {
  departmentSlugs: undefined,
  teamSlugs: undefined,
  learnerSlugs: undefined,
  roleSlugs: undefined,
  skillSlugs: undefined,
  countryIds: undefined,
};

export function ScoreOverviewWidget({ baseSearchParams }: IScoreOverviewWidgetProps): JSX.Element {
  const actions = useAppActions();

  const [isAverageDataLoading, setIsAverageDataLoading] = useState<boolean>(false);
  const [isHighestLowestDataLoading, setIsHighestLowestDataLoading] = useState<boolean>(false);
  const [averageData, setAverageData] = useState<{ level: number; percentage: number }>();
  const [highestLowestData, setHighestLowestData] = useState<AssessmentHighestLowestResponse>();

  useEffect(() => {
    async function getAverageData() {
      setIsAverageDataLoading(true);

      const averageResults = await actions.getAssessmentAverageSkill({ ...baseSearchParams, ...DEFAULT_SEARCH_PARAMS });
      setAverageData(averageResults);

      setIsAverageDataLoading(false);
    }

    async function getHighestLowestData() {
      setIsHighestLowestDataLoading(true);

      const highestLowestResults = await actions.getAssessmentHighestAndLowestSkill({ ...baseSearchParams, ...DEFAULT_SEARCH_PARAMS });
      setHighestLowestData(highestLowestResults);

      setIsHighestLowestDataLoading(false);
    }

    getAverageData();
    getHighestLowestData();
  }, [baseSearchParams]);

  return (
    <div className="row" style={{ width: '100%', alignItems: 'stretch', gap: 24 }}>
      <div className="col-fill col-mobile-12" style={{ display: 'flex' }}>
        <SkeletonOverlay isLoading={isAverageDataLoading} width="100%">
          <ScoreOverviewCard cardTitle="Assessment average score" cardSubtitle="The average score for all skills in this assessment" scoreTitle="Average skill level" score={averageData} />
        </SkeletonOverlay>
      </div>

      <div className="col-fill col-mobile-12" style={{ display: 'flex' }}>
        <SkeletonOverlay isLoading={isHighestLowestDataLoading} width="100%">
          <ScoreOverviewCard cardTitle="Strongest skill" cardSubtitle="The highest scoring skill in this assessment" scoreTitle={highestLowestData?.highest?.name} score={highestLowestData?.highest?.score} />
        </SkeletonOverlay>
      </div>

      <div className="col-fill col-mobile-12" style={{ display: 'flex' }}>
        <SkeletonOverlay isLoading={isHighestLowestDataLoading} width="100%">
          <ScoreOverviewCard cardTitle="Weakest skill" cardSubtitle="The lowest scoring skill in this assessment" scoreTitle={highestLowestData?.lowest?.name} score={highestLowestData?.lowest?.score} />
        </SkeletonOverlay>
      </div>
    </div>
  );
}
