import React from 'react';
import classNames from 'classnames';
import { PagePath } from '../../../../navigation/navigation.enums';
import { IPersonCardProps } from './person-card.models';
import { AvatarIcon } from '@keplerco/core';
import KeplerNavlink from '../../../../navigation/guards/kepler-navlink';
import styles from './person-card.module.css';

export function PersonCard(props: IPersonCardProps): JSX.Element {
  return (
    <KeplerNavlink to={`${PagePath.analyticsBase}${PagePath.analyticsPerson.replace(':companySlug', props.companySlug).replace(':personSlug', props.slug)}`} className={classNames('card', styles.card)}>
      <figure className={styles.avatar}>
        <AvatarIcon name={{ firstName: props.name.split(' ')[0], lastName: props.name.split(' ')[1] }} />
      </figure>

      <div className={classNames(styles.content, { [styles.list]: props.viewType === 'List' })}>
        <h5 className={styles.text}>{props.name}</h5>

        <p className={styles.text} style={{ color: 'var(--accent-2)' }}>
          {props.role}
        </p>

        <p className={styles.text} style={{ color: 'var(--secondary)', textTransform: 'uppercase' }}>
          {props.department}
        </p>
      </div>
    </KeplerNavlink>
  );
}
