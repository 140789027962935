import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { Button, Chip, DropdownSelect, DropdownSelectItem, Filters, formatDate, PageHeader, Pager, SearchBar, SkillLevel, SkillPercentage } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';
import { AssessmentType } from '../../../enums/assessment-type';
import { CompletionStatus } from '../../../enums';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import styles from './assessments.module.css';
import classNames from 'classnames';
import { assessmentTypeItems, formatAssessmentTypeLabel } from '../../assessments/your-assessments/your-assessments.helpers';
import { AssessmentAnalyticsSearchParams, AssessmentSummary } from '../../../models/overmind/assessment-analytics';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { AssessmentStatusChip } from '../../../components/chips/assessment-status.chip';

const pageSize = 10;

export const assessmentStatusItems: DropdownSelectItem[] = [
  { label: 'Closed', value: CompletionStatus.Completed.toString() },
  { label: 'Active', value: CompletionStatus.InProgress.toString() },
];

export const sortByItems: DropdownSelectItem[] = [
  { label: 'Name', value: 'Name' },
  { label: 'Most Recent', value: 'MostRecent' },
];

export default function AssessmentsPage() {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const keplerNavigate = useKeplerNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const [assessmentTypeDropdownItems] = useState<DropdownSelectItem[]>(assessmentTypeItems);
  const [assessmentStatusDropdownItems] = useState<DropdownSelectItem[]>(assessmentStatusItems);
  const [tagDropdownItems, setTagDropdownItems] = useState<DropdownSelectItem[]>([]);
  const [sortByDropdownItems] = useState<DropdownSelectItem[]>(sortByItems);

  const [data, setData] = useState<AssessmentSummary[]>();
  const [totalPages, setTotalPages] = useState<number>(1);

  const defaultSearchParams: AssessmentAnalyticsSearchParams = {
    companySlug: companyVariables.slug!,
    sortAscending: true,
    pageSize: pageSize,
    page: 1,
    search: undefined,
    assessmentType: undefined,
    completionStatus: undefined,
    tag: undefined,
    sortByOption: undefined,
    excludeDraft: true,
  };

  const [request, setRequest] = useState<AssessmentAnalyticsSearchParams>(defaultSearchParams);

  async function getData(params: AssessmentAnalyticsSearchParams) {
    const response = await actions.getAssessments(params);
    setData(response?.assessments);
    setTotalPages(response?.totalPages ?? 1);
    setIsLoading(false);
    setIsSearching(false);
    return response;
  }

  useEffect(() => {
    async function initPage() {
      setIsLoading(true);

      await getData(request);

      const tags = await actions.searchTags({
        companySlug: companyVariables.slug!,
        page: 1,
        pageSize: 99999,
        search: '',
      });

      if (tags) {
        setTagDropdownItems(tags?.tags.map(tag => ({ label: tag.tagName, value: tag.tagName })));
      }

      setIsInitialized(true);
    }

    initPage();
  }, []);

  async function onInputHandler(event: React.FormEvent<HTMLInputElement>) {
    const inputValue = (event.target as HTMLInputElement).value;
    setIsSearching(true);

    const newRequest = {
      ...request,
      search: inputValue.trim() || undefined,
      page: 1,
    };

    setRequest(newRequest);
    await getData(newRequest);
  }

  async function clearFilters() {
    setIsLoading(true);
    const newRequest = { ...defaultSearchParams };
    setRequest(newRequest);
    await getData(newRequest);
  }

  async function applyFilters() {
    setIsLoading(true);
    const newRequest = { ...request, page: 1 };
    setRequest(newRequest);
    await getData(newRequest);
  }

  async function onPageChangeHandler(pageNumber: number) {
    setIsLoading(true);
    const newRequest = { ...request, page: pageNumber };
    setRequest(newRequest);
    await getData(newRequest);
  }

  function generateDates(assessment: AssessmentSummary) {
    const dates = [];

    if (assessment.dateCompleted && !assessment.isActive) {
      dates.push(`Closed: ${formatDate(new Date(assessment.dateCompleted))}`);
    }
    if (assessment.dateActivated) {
      dates.push(`Activated: ${formatDate(new Date(assessment.dateActivated))}`);
    }

    return dates.length > 0 ? dates : null;
  }

  function renderDates(assessment: AssessmentSummary) {
    const dates = generateDates(assessment);
    if (!dates) return null;

    return (
      <div className={styles.datesContainer}>
        {dates.map(date => (
          <div className={classNames('caption', styles.dateText)} key={date}>
            {date}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="pageWrapper wrapper">
      <PageHeader
        breadcrumbs={[
          { name: 'Analytics', url: PagePath.analyticsBase },
          { name: 'Assessments', url: window.location.pathname },
        ]}
        title="Assessments"
      />

      <div className={styles.pageContent}>
        <div className={styles.searchRow}>
          <SearchBar label="Search" loading={isSearching} onInput={onInputHandler} />

          <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
            <DropdownSelect label="Type" value={request.assessmentType?.toString()} items={assessmentTypeDropdownItems} onChange={event => setRequest(prev => ({ ...prev, assessmentType: event.target.value ? parseInt(event.target.value) : undefined }))} />
            <DropdownSelect label="Status" value={request.completionStatus?.toString()} items={assessmentStatusDropdownItems} onChange={event => setRequest(prev => ({ ...prev, completionStatus: event.target.value ? parseInt(event.target.value) : undefined }))} />
            <DropdownSelect label="Tags" value={request.tag} items={tagDropdownItems} onChange={event => setRequest(prev => ({ ...prev, tag: event.target.value }))} />
            <DropdownSelect label="Sort by" value={request.sortByOption} items={sortByDropdownItems} onChange={event => setRequest(prev => ({ ...prev, sortBy: event.target.value }))} />
          </Filters>
        </div>

        {!isLoading && isInitialized ? (
          <>
            {!!data?.length ? (
              <div className={styles.pageContent} style={{ gap: '12px' }}>
                {data?.map(assessment => {
                  const borderLeftColor = assessment.isActive ? 'a_1' : assessment.isComplete ? 'g_1' : assessment.isArchived ? 'default_1' : 'borders';
                  return (
                    <div key={assessment.assessmentId} className={classNames('card', styles.card)} style={{ borderLeftColor: `var(--${borderLeftColor})` }}>
                      <div>
                        <div className={styles.headerContainer}>
                          <div className={styles.assessmentTypeDesktop}>{formatAssessmentTypeLabel(AssessmentType[assessment.assessmentType])}</div>
                          <div className={styles.statusContainer}>
                            <div className={styles.datesContainer}>{renderDates(assessment)}</div>
                            <AssessmentStatusChip assessment={assessment} />
                          </div>
                        </div>
                        <div className={styles.mobileTypeContainer}>
                          <div className={classNames('caption', styles.assessmentTypeMobile)}>{formatAssessmentTypeLabel(AssessmentType[assessment.assessmentType])}</div>
                        </div>
                        <div>
                          <h4 className={styles.assessmentName}>{assessment.name}</h4>
                        </div>
                        <div className={styles.assessmentDetailsContainer}>
                          <div className={styles.assessmentMetricsContainer}>
                            <div className={styles.skillContainer}>
                              {companyVariables.useLevels ? (
                                <SkillLevel title="Assessment Score" level={assessment.average?.level ?? null} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!assessment.average} />
                              ) : (
                                <SkillPercentage title="Assessment Score" percentage={assessment.average?.percentage ?? null} noPercentage={!assessment.average} />
                              )}
                            </div>

                            {assessment.tags.length > 0 && (
                              <div className={styles.tagsContainer}>
                                <div className={classNames('caption', styles.tagsCaption)}>Tags</div>
                                <div className={styles.tagsChipsContainer}>
                                  {assessment.tags.map(tag => (
                                    <Chip key={tag} customTheme={{ backgroundColor: 'background', borderColor: 'baby-blue', color: 'text' }}>
                                      {tag}
                                    </Chip>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={styles.viewButtonContainer}>
                            <Button arrow={false} type={'button'} onClick={() => keplerNavigate(`${PagePath.analyticsBase}${PagePath.analyticsAssessment.replace(':companySlug', companyVariables.slug!).replace(':assessmentSlug', assessment.slug)}`)}>
                              View
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <EmptyState />
            )}
          </>
        ) : (
          <div style={{ marginTop: 24 }}>
            <SkeletonLoader height="100vh" />
          </div>
        )}

        {totalPages > 1 && (
          <div className={styles.pagerContainer} style={{ marginTop: 24 }}>
            <PagerConnector pageCount={totalPages} onPageChange={onPageChangeHandler} defaultPageNumber={request.page ?? 1}>
              {connector => <Pager {...connector} />}
            </PagerConnector>
          </div>
        )}
      </div>
    </div>
  );
}
