import { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { PagePath } from '../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../overmind';

/**
 * This hook is required in every router that includes page paths with the `:companySlug` param.
 * It ensures that the `slug` property in the `companyVariables` property on the global state,
 * as well as the activeCompanySlug item in local storage are updated to match the `:companySlug` param
 * in the address bar.
 * @param routerBasePagePath The router's base page path
 */
export function useCompanySlugParamChange(routerBasePagePath: PagePath) {
  const match = useMatch(`${routerBasePagePath}/:companySlug/*`);
  const navigate = useNavigate();

  const actions = useAppActions();
  const { user, companyVariables } = useAppState();

  useEffect(() => {
    const companySlug = match?.params.companySlug;

    if (!companySlug || companySlug === companyVariables.slug) {
      return;
    }

    if (!user?.isSystemAdmin) {
      navigate(PagePath.errorRouting404);
      return;
    }
    
    actions.setActiveCompanySlug(companySlug);
  }, [match]);
}
