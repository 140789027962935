import { DataPoint } from '../../../components/charts/recharts.models';
import { EntityScore, EntityScores } from '../../../models/overmind/analytics';
import { UserScore } from '../../../models/overmind/assessment-analytics';
import { Score } from '../../../models/view/score';

function formatScore(score: Score | undefined, useLevels: boolean): number | string {
  return useLevels ? score?.level ?? 'Not assessed' : score?.percentage ?? 'Not assessed';
}

export function generateUniqueSkillNames(scores: UserScore[] | undefined): string[] {
  if (!scores?.length) return [];

  const uniqueSkillNames = new Set<string>();

  scores.forEach(score => {
    score.competencies.forEach(competency => {
      uniqueSkillNames.add(competency.name);
    });
  });

  return Array.from(uniqueSkillNames);
}

export function transformScores(scores: UserScore[] | undefined, uniqueSkillNames: string[], useLevels: boolean): DataPoint[] {
  if (!scores?.length) return [];

  return scores.map(score => {
    const dataPoint: DataPoint = { name: score.name, slug: score.slug };

    uniqueSkillNames.forEach(skillName => {
      dataPoint[skillName] = 'Not assessed';
    });

    score.competencies.forEach(competency => {
      dataPoint[competency.name] = formatScore(competency.score, useLevels);
    });

    return dataPoint;
  });
}

export function transformAverageScores(averageScores: EntityScores[] | undefined, overallAverageScore: Score | undefined, uniqueSkillNames: string[], useLevels: boolean): DataPoint[] {
  if (!averageScores?.length) return [];

  const dataPoint: DataPoint = { name: 'Average', slug: 'average' };

  uniqueSkillNames.forEach(skillName => {
    if (skillName === 'Average score') dataPoint[skillName] = formatScore(overallAverageScore, useLevels);
    else dataPoint[skillName] = 'Not assessed';
  });

  averageScores.forEach((score: EntityScore) => {
    dataPoint[score.name] = formatScore(score.score, useLevels);
  });

  return [dataPoint];
}
