import { ThemeColours } from '@keplerco/core';

type DataTypeValue = string | number | Date;

const ChipTypes: readonly string[] = ['complete', 'in progress', 'pending', 'active', 'inactive', 'not started'];

export function isNumber(value: DataTypeValue): boolean {
  const coercedValue = Number(value.toString());
  return !isNaN(parseInt(coercedValue as any)) && typeof coercedValue === 'number';
}

export function isDate(value: DataTypeValue): boolean {
  const checkRgx = /([a-z]+)(\s)([0-9]+)/gi;
  const matches = checkRgx.test(value.toString());

  if (matches) return false;

  try {
    new Date(value).toISOString();
  } catch (error) {
    return false;
  }

  return true;
}

export function isUrl(value: DataTypeValue): boolean {
  if (value.toString().includes(':')) return false;

  try {
    new URL(value.toString());
    return true;
  } catch (error) {
    return false;
  }
}

export function isString(value: DataTypeValue): boolean {
  return !isUrl(value) && !isDate(value) && !isNumber(value);
}

export function getDataType(value: DataTypeValue): IDataType {
  if (value === null || typeof value === 'undefined') return 'null';

  if (isNumber(value)) {
    return 'number';
  } else if (isDate(value)) {
    return 'date';
  } else if (isUrl(value)) {
    return 'url';
  }

  if (ChipTypes.includes(value.toString().trim().toLowerCase())) {
    return 'chip';
  }

  return 'string';
}

export function getChipTheme(value: string): { backgroundColor: ThemeColours; borderColor: ThemeColours; color: ThemeColours } {
  const formattedValue = value.toString().toLowerCase().trim();

  if (['active', 'complete'].includes(formattedValue)) {
    return { backgroundColor: 'g_2', borderColor: 'g_1', color: 'black' };
  }

  if (['in progress', 'pending'].includes(formattedValue)) {
    return { backgroundColor: 'a_2', borderColor: 'a_1', color: 'black' };
  }

  if ('inactive' === formattedValue) {
    return { backgroundColor: 'r_2', borderColor: 'r_1', color: 'black' };
  }

  return { backgroundColor: 'background', borderColor: 'borders', color: 'text' };
}
