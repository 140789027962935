import React from 'react';
import { useAppState } from '../../../../overmind';
import { useNavigate } from 'react-router-dom';
import { Anchor, Button, KeplerPoints, ProgressBar, SkillLevel, SkillPercentage } from '@keplerco/core';
import { PagePath } from '../../../../navigation/navigation.enums';
import { ICoreSkillsMapPlanetProps } from '../core-skills-map.models';
import styles from './core-skills-map-planet.module.css';
import { UrgencyIndicationChip } from '../../../../components/chips/urgency-indication.chip';
import { getSubdomain } from '../../../../library/helpers/get-subdomain';
import { comparisonScoreTitle } from '../../../../library/helpers/comparison-score-title';
import { ScoreComparison } from '../../../../enums/score-comparison';

export function CoreSkillsMapPlanet({ planet }: ICoreSkillsMapPlanetProps): JSX.Element {
  const { companyVariables } = useAppState();

  const subdomain = getSubdomain();

  const navigate = useNavigate();
  const to = `${PagePath.learningJourneyBase}${PagePath.learningJourneySkill.replace(':skillSlug', planet.slug)}`;

  return (
    <div className={styles.row}>
      <div className={styles.innerRow} style={{ transform: `translateX(${planet.offset}%)` }}>
        <div className={styles.cardRow}>
          <div className={styles.cardRowInner}>
            <h4 className={styles.cardInnerText}>{planet.name}</h4>

            {!!planet.urgencyIndication ? (
              <div className={styles.cardChip}>
                <UrgencyIndicationChip urgencyIndication={planet.urgencyIndication} />
              </div>
            ) : (
              <React.Fragment>
                <div className={styles.skillScoreContainer}>
                  <div className={styles.skillLevelIndicator}>
                    {companyVariables.useLevels ? (
                      <SkillLevel level={planet.learnerScore?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!planet.learnerScore} noLevelColour="black" title="Your proficiency" titleColour="black" labelColour="black" />
                    ) : (
                      <SkillPercentage percentage={planet.learnerScore?.percentage ?? 0} noPercentage={!planet.learnerScore} noPercentageColour="black" title="Skill percentage" titleColour="black" labelColour="black" />
                    )}
                  </div>

                  {companyVariables.scoreComparison !== ScoreComparison.None && (
                    <div className={styles.skillLevelIndicator}>
                      {companyVariables.useLevels ? (
                        <SkillLevel
                          level={planet.comparisonScore?.level ?? companyVariables.minLevel}
                          minLevel={companyVariables.minLevel}
                          maxLevel={companyVariables.maxLevel}
                          noLevel={!planet.comparisonScore}
                          noLevelColour="black"
                          noLevelText="Not captured"
                          title={comparisonScoreTitle(companyVariables.scoreComparison)}
                          titleColour="black"
                          labelColour="black"
                        />
                      ) : (
                        <SkillPercentage percentage={planet.comparisonScore?.percentage ?? 0} noPercentage={!planet.comparisonScore} noPercentageColour="black" noPercentageText="Not captured" title={comparisonScoreTitle(companyVariables.scoreComparison)} titleColour="black" labelColour="black" />
                      )}
                    </div>
                  )}
                </div>

                {!!planet.totalActivityCount && (
                  <div className={styles.progressBar}>
                    <ProgressBar value={planet.completedActivityCount} max={planet.totalActivityCount} theme="secondary" round />

                    <div className={styles.captions}>
                      <small style={{ color: 'black' }}>
                        {planet.completedActivityCount} of {planet.totalActivityCount} activities completed
                      </small>

                      <small style={{ marginLeft: !planet.totalActivityCount ? 'auto' : 'unset', color: 'black' }}>
                        <KeplerPoints trimWhiteSpace forceFontWeight="bold" points={planet.completedActivityCount === planet.totalActivityCount ? planet.keplerPointsEarned : planet.keplerPointsAvailable} />
                        {!!planet.totalActivityCount && planet.completedActivityCount === planet.totalActivityCount ? ' Earned' : ' Available'}
                      </small>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}

            <div className={styles.cardActions}>
              {planet.completedActivityCount < planet.totalActivityCount ? (
                <Button type="button" arrow onClick={() => navigate(to)}>
                  {!!planet.completedActivityCount ? 'Continue' : 'Start'}
                </Button>
              ) : (
                <Anchor style={{ padding: '8px 0' }} onClick={() => navigate(to)}>
                  {subdomain.includes('roche-deep') ? 'Completed' : 'View Skill'}
                </Anchor>
              )}
            </div>
          </div>
        </div>

        <img draggable={false} width={planet.width} src={planet.src} loading="lazy" alt="" onClick={() => navigate(to)} />
      </div>
    </div>
  );
}
