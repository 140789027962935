import React, { useState } from 'react';
import { PanelHeader, Tab, Tabs } from '@keplerco/core';
import { SaveSkillWidget } from './save-skill.widget';
import { CompanySkillListItemResponse } from '../../../../models/overmind/company-entity';
import { ImportSkillWidget } from '../../../../widgets/import-skill/import-skill.widget';
import classNames from 'classnames';

export function SaveSkillPanel({ onClose, selectedSkill, refreshData }: { onClose: () => void; selectedSkill: CompanySkillListItemResponse | undefined; refreshData: () => void }): JSX.Element {
  const [activeTabType, setActiveTabType] = useState<'Create' | 'Import'>('Create');

  return (
    <div className={classNames('panelContent', { panelForm: activeTabType === 'Create' })}>
      <PanelHeader supertitle="Skills" title={!!selectedSkill ? 'Edit skill' : 'Add a new skill'} subtitle={!!selectedSkill ? 'Change the name and description of this skill to suit your business requirements.' : 'Create a new skill or import from existing framework'}>
        {!selectedSkill && (
          <div style={{ marginTop: 15 }}>
            <Tabs hideMobile={false} static>
              <Tab active={activeTabType === 'Create'} id="Create" name="Create" onClick={() => setActiveTabType('Create')} />
              <Tab active={activeTabType === 'Import'} id="Import" name="Search and import" onClick={() => setActiveTabType('Import')} />
            </Tabs>
          </div>
        )}
      </PanelHeader>

      {activeTabType === 'Create' ? (
        <SaveSkillWidget
          selectedSkill={selectedSkill}
          onCancel={onClose}
          onSave={() => {
            onClose();
            refreshData();
          }}
        />
      ) : (
        <ImportSkillWidget
          onCancel={onClose}
          onImport={() => {
            onClose();
            refreshData();
          }}
        />
      )}
    </div>
  );
}
