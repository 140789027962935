import React from 'react';
import { IAverageProps } from './average.models';
import { useAppState } from '../../../overmind';
import styles from './average.module.css';
import { scoreLevelRAGColour, scorePercentageRAGColour, SkillLevel, SkillPercentage } from '@keplerco/core';
import classNames from 'classnames';
import { PagePath } from '../../../navigation/navigation.enums';

export function Average({ title, description, score, invisible, path, scoreIndicatorTitle = 'Average skill score', noScoreText }: IAverageProps): JSX.Element {
  const { companyVariables } = useAppState();

  return (
    <div className={classNames(styles.container, { [styles.invisible]: invisible })}>
      <h3>{title}</h3>

      {!!description && <p style={{ marginBottom: 10 }}>{description}</p>}

      {companyVariables.useLevels ? (
        <SkillLevel
          level={score?.level ?? companyVariables.minLevel}
          minLevel={companyVariables.minLevel}
          maxLevel={companyVariables.maxLevel}
          noLevel={!score}
          dotColour={path !== PagePath.dashboard ? scoreLevelRAGColour(score?.level ?? 0, companyVariables.minLevel, companyVariables.maxLevel) : 'baby-blue'}
          title={scoreIndicatorTitle}
          noLevelText={noScoreText}
        />
      ) : (
        <SkillPercentage percentage={score?.percentage ?? 0} noPercentage={!score} barColour={path !== PagePath.dashboard ? scorePercentageRAGColour(score?.percentage ?? 0) : 'baby-blue'} title={scoreIndicatorTitle} noPercentageText={noScoreText} />
      )}
    </div>
  );
}
