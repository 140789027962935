import React from 'react';
import { colourString, IconToneProps } from '@keplerco/core';

export default function StarsIcon({ size = 20, tone = 'contrast-text' }: IconToneProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M14.0191 6.5539L14.8217 4.35345L15.7089 6.71394C16.7373 9.45332 18.9001 11.6161 21.6395 12.6446L24 13.5318L21.7996 14.3343C18.9849 15.3604 16.7679 17.5773 15.7419 20.392L14.9393 22.5925L14.0521 20.232C13.0236 17.4926 10.8609 15.3298 8.12147 14.3014L5.76099 13.4141L7.96144 12.6116C10.7761 11.5855 12.9931 9.3686 14.0191 6.5539Z"
        fill={colourString(tone)}
      />
      <path
        d="M5.44797 3.18613L5.881 2L6.35874 3.27085C6.9118 4.74644 8.07674 5.90904 9.55234 6.46444L10.8232 6.94219L9.63706 7.37522C8.12146 7.92827 6.92827 9.12146 6.37522 10.6371L5.94219 11.8232L5.46444 10.5523C4.91139 9.07674 3.74644 7.91415 2.27085 7.35874L1 6.881L2.18613 6.44797C3.70173 5.89491 4.89491 4.70173 5.44797 3.18613Z"
        fill={colourString(tone)}
      />
      <path
        d="M5.60111 17.1184L5.87881 16.3559L6.18476 17.1725C6.54012 18.121 7.28851 18.8694 8.23694 19.2247L9.05358 19.5307L8.29107 19.8084C7.31675 20.1637 6.54954 20.931 6.19417 21.9053L5.91647 22.6678L5.61052 21.8511C5.25515 20.9027 4.50677 20.1543 3.55834 19.799L2.7417 19.493L3.50421 19.2153C4.47853 18.8599 5.24574 18.0927 5.60111 17.1184Z"
        fill={colourString(tone)}
      />
    </svg>
  );
}
