import { PageHeader } from '@keplerco/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppActions, useAppState } from '../../../overmind';
import { SkillAssessment } from '../../../models';
import { PagePath } from '../../../navigation/navigation.enums';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { AssessmentType } from '../../../enums/assessment-type';
import { PageSkeletonLoader } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-skeleton-loader';
import { ScoreOverviewWidget } from '../../../widgets/analytics/score-overview/score-overview.widget';
import { OrganizationLevelType } from '../../../enums';
import { AssessmentSearchParams, PermissionSearchParams } from '../../../models/overmind/search-params';
import analyticsStyles from '../../analytics/analytics.module.css';
import { YearsExperienceWidget } from '../../analytics/assessments/versus-score/years-experience.widget';
import { FrequencyUseWidget } from '../../analytics/assessments/versus-score/frequency-use.widget';
import { SunburstWidget } from '../../../widgets/analytics/sunburst/sunburst.widget';
import { SkillVersusAssessmentScoreWidget } from '../../analytics/assessments/skill-versus-assessment-score/skill-versus-assessment-score.widget';

export function YourAssessmentPage(): React.ReactElement {
  const { assessmentSlug } = useParams();

  const actions = useAppActions();
  const { user, companyVariables } = useAppState();

  const [isLoading, setIsLoading] = React.useState(true);
  const [assessment, setAssessment] = React.useState<SkillAssessment>();

  const permissionSearchParams: PermissionSearchParams | undefined = React.useMemo(() => {
    if (!companyVariables.slug || !user?.learnerSlug) return undefined;

    return {
      organizationLevel: OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: user.learnerSlug,
    };
  }, [companyVariables.slug, user?.learnerSlug]);
  const assessmentSearchParams: AssessmentSearchParams | undefined = React.useMemo(() => {
    if (!assessment || !companyVariables.slug) return undefined;

    return {
      assessmentType: assessment.assessmentType,
      assessmentSlug: assessment.slug,
    };
  }, [assessment]);

  React.useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const response = await actions.getAssessment({ companySlug: companyVariables.slug!, assessmentSlug: assessmentSlug! });
      setAssessment(response);
      setIsLoading(false);
    }

    getData();
  }, [assessmentSlug]);

  if (isLoading) return <PageSkeletonLoader />;

  return (
    <div className="wrapper pageWrapper" style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
      <PageHeader
        breadcrumbs={[
          { name: 'Your Assessments', url: PagePath.yourAssessmentsBase },
          { name: assessment?.name ?? 'Your Assessment', url: location.pathname },
        ]}
        title={assessment?.name ?? 'Your Assessment'}
        divider
      />

      {!!permissionSearchParams && !!assessmentSearchParams ? (
        <React.Fragment>
          <ScoreOverviewWidget baseSearchParams={{ ...permissionSearchParams, ...assessmentSearchParams }} />

          <SunburstWidget path={PagePath.yourAssessmentsAssessment} chartTitle="Your score for each skill included in this assessment:" permissionSearchParams={permissionSearchParams} assessmentSearchParams={assessmentSearchParams} />

          <SkillVersusAssessmentScoreWidget userFullName={`${user?.firstName} ${user?.lastName}`} baseSearchParams={{ ...permissionSearchParams, ...assessmentSearchParams }} />

          {assessmentSearchParams.assessmentType === AssessmentType.PeerEndorsement ? (
            <div className={analyticsStyles.oneOneGrid}>
              <YearsExperienceWidget baseSearchParams={{ ...permissionSearchParams, ...assessmentSearchParams }} />
              <FrequencyUseWidget baseSearchParams={{ ...permissionSearchParams, ...assessmentSearchParams }} />
            </div>
          ) : (
            <React.Fragment>{/* TODO: <SkillVSAssessmentScore /> */}</React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <EmptyState />
      )}
    </div>
  );
}
