import React, { useEffect, useState } from 'react';
import { ISkillAssessmentSchedulerWidgetProps, SaveOptions, SkillAssessmentFormValue } from './assessment-scheduling.models';
import { useForm } from 'react-hook-form';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { useAppActions } from '../../../../overmind';
import { ScheduleAction } from '../../../../enums/schedule-action';
import { Schedule } from '../../../../models/view/schedule';
import { ChevronIcon, FormControl, FormGroup, GridItemLayout, GridLayout, RadioButtonCard, RequiredValidator, safeCallback } from '@keplerco/core';
import { Datepicker } from '../../../../components/inputs/datepicker/datepicker';
import styles from './assessment-scheduling.module.css';

export function SkillAssessmentSchedulerWidget(props: ISkillAssessmentSchedulerWidgetProps): JSX.Element {
  const { control, handleSubmit, watch, setValue } = useForm();
  const [formValue, setFormValue] = useState<SkillAssessmentFormValue>();
  const actions = useAppActions();

  const [defaultStartDateValue, setDefaultStartDateValue] = useState<Date>(new Date());
  const [defaultEndDateValue, setDefaultEndDateValue] = useState<Date>(new Date());
  const [currentCreationSchedule, setCurrentCreationSchedule] = useState<Schedule>();
  const [currentCompletionSchedule, setCurrentCompletionSchedule] = useState<Schedule>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  async function fetchWidgetData() {
    let creationSchedule: Schedule | undefined, completionSchedule: Schedule | undefined;

    try {
      const [existingCreationSchedule, existingCompletionSchedule] = await Promise.all([
        actions.getScheduledAction({ attachedEntity: props.skillAssessmentId, scheduleType: ScheduleAction.AssessmentCreation }),
        actions.getScheduledAction({ attachedEntity: props.skillAssessmentId, scheduleType: ScheduleAction.AssessmentCompletion }),
      ]);

      creationSchedule = existingCreationSchedule;
      completionSchedule = existingCompletionSchedule;
    } catch (error) {
      console.log(error);
    }

    if (!creationSchedule) {
      const schedule = await actions.createScheduleAction({
        entityId: props.skillAssessmentId,
        isActive: false,
        scheduleActionType: ScheduleAction.AssessmentCreation,
      });

      setCurrentCreationSchedule(schedule);
    } else {
      setValue('startDate', creationSchedule.activateImmediately ? 'immediate' : 'future');
      setCurrentCreationSchedule(creationSchedule);
      if (!!creationSchedule.executionDate) setDefaultStartDateValue(new Date(creationSchedule.executionDate));
    }

    if (!completionSchedule) {
      const schedule = await actions.createScheduleAction({
        entityId: props.skillAssessmentId,
        isActive: false,
        scheduleActionType: ScheduleAction.AssessmentCompletion,
      });

      setCurrentCompletionSchedule(schedule);
    } else {
      setValue('endDate', completionSchedule.activateImmediately ? 'manual' : 'future');
      setCurrentCompletionSchedule(completionSchedule);
      if (!!completionSchedule.executionDate) setDefaultEndDateValue(new Date(completionSchedule.executionDate));
    }
  }

  useEffect(() => {
    watch((value: any) => {
      setFormValue(value);
      if (!!currentCreationSchedule && !!currentCompletionSchedule) saveSchedule({ saveEnd: true, saveStart: true }, value);
    });

    fetchWidgetData();
  }, []);

  async function saveSchedule(params: SaveOptions = { saveStart: true, saveEnd: true }, manualFormValues?: SkillAssessmentFormValue) {
    if (!isSaving) {
      setIsSaving(true);

      const savableFormValue = manualFormValues ?? formValue;
      if (!savableFormValue || !savableFormValue.startDate || !savableFormValue.endDate) return false;

      if (params.saveStart) {
        const startSchedule: Schedule = {
          ...currentCreationSchedule!,
        };

        if (savableFormValue.startDate === 'future') {
          startSchedule.activateImmediately = false;
          startSchedule.executionDate = defaultStartDateValue!.toISOString();
        } else {
          startSchedule.activateImmediately = true;
        }

        try {
          await actions.updateScheduleAction(startSchedule);
          if (!params.saveEnd) return true;
        } catch (error) {
          return false;
        }
      }

      if (params.saveEnd) {
        const endSchedule: Schedule = {
          ...currentCompletionSchedule!,
        };

        if (savableFormValue.endDate === 'future') {
          endSchedule.activateImmediately = false;
          endSchedule.executionDate = defaultEndDateValue!.toISOString();
        } else {
          endSchedule.activateImmediately = true;
        }

        try {
          await actions.updateScheduleAction(endSchedule);
          return true;
        } catch (error) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  if (isSaving) {
    return (
      <div>
        <SkeletonLoader height="30px" />
        <div style={{ height: 10 }} />
        <SkeletonLoader height="150px" />
        <div style={{ height: 20 }} />
        <SkeletonLoader height="30px" />
        <div style={{ height: 10 }} />
        <SkeletonLoader height="150px" />
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit(async () => {
        const success = await saveSchedule();
        setIsSaving(false);
        if (success) safeCallback(props.onStepComplete);
      })}
    >
      <FormGroup label="Start Assessment">
        <GridLayout columnCount={2}>
          <GridItemLayout>
            <FormControl
              name="startDate"
              control={control}
              rules={new RequiredValidator('Please choose when your assessment will activate')}
              render={({ field, fieldState }) => (
                <RadioButtonCard haserror={!!fieldState.error} id="immediate" {...field} value="immediate" checked={field.value === 'immediate'} onChange={e => field.onChange(e.target.value)}>
                  <div className="card">
                    <label htmlFor="startDate" className="h4" style={{ color: 'var(--accent-3)' }}>
                      Immediately
                    </label>

                    <div className="body">Set you assessment to release immediately after creation.</div>
                  </div>
                </RadioButtonCard>
              )}
            />
          </GridItemLayout>

          <GridItemLayout>
            <FormControl
              name="startDate"
              control={control}
              rules={new RequiredValidator('Please choose when your assessment will activate')}
              render={({ field, fieldState }) => (
                <RadioButtonCard haserror={!!fieldState.error} id="future" {...field} value="future" checked={field.value === 'future'} onChange={e => field.onChange(e.target.value)}>
                  <div className="card">
                    <label htmlFor="startDate" className="h4" style={{ color: 'var(--accent-3)' }}>
                      Specific Day
                    </label>

                    <div className="body">Set the release of your assessment at a specific time.</div>
                  </div>
                </RadioButtonCard>
              )}
            />
          </GridItemLayout>
        </GridLayout>

        {formValue?.startDate === 'future' && (
          <GridLayout columnCount={2}>
            <GridItemLayout>
              <Datepicker
                onDateSelected={date => {
                  setDefaultStartDateValue(date);
                  saveSchedule({ saveStart: true, saveEnd: false });
                }}
                defaultDate={defaultStartDateValue}
                minDate={new Date()}
                openAbove
              />
            </GridItemLayout>

            <GridItemLayout />
          </GridLayout>
        )}
      </FormGroup>

      <br />

      <FormGroup label="Close Assessment">
        <GridLayout columnCount={2}>
          <GridItemLayout>
            <FormControl
              name="endDate"
              control={control}
              rules={new RequiredValidator('Please choose when your assessment will close')}
              render={({ field, fieldState }) => (
                <RadioButtonCard haserror={!!fieldState.error} id="manual" {...field} value="manual" checked={field.value === 'manual'} onChange={e => field.onChange(e.target.value)}>
                  <div className="card">
                    <label htmlFor="endDate" className="h4" style={{ color: 'var(--accent-3)' }}>
                      Close Assessment Manually
                    </label>

                    <div className="body">Leave the assessment open till an admin user closes it.</div>
                  </div>
                </RadioButtonCard>
              )}
            />
          </GridItemLayout>

          <GridItemLayout>
            <FormControl
              name="endDate"
              control={control}
              rules={new RequiredValidator('Please choose when your assessment will close')}
              render={({ field, fieldState }) => (
                <RadioButtonCard haserror={!!fieldState.error} id="future" {...field} value="future" checked={field.value === 'future'} onChange={e => field.onChange(e.target.value)}>
                  <div className="card">
                    <label htmlFor="endDate" className="h4" style={{ color: 'var(--accent-3)' }}>
                      Specific Day
                    </label>

                    <div className="body">Set the closing of your assessment on a specific date.</div>
                  </div>
                </RadioButtonCard>
              )}
            />
          </GridItemLayout>
        </GridLayout>

        {formValue?.endDate === 'future' && (
          <GridLayout columnCount={2}>
            <GridItemLayout>
              <Datepicker
                onDateSelected={date => {
                  setDefaultEndDateValue(date);
                  saveSchedule({ saveStart: false, saveEnd: true });
                }}
                defaultDate={defaultEndDateValue}
                minDate={defaultStartDateValue}
                openAbove
              />
            </GridItemLayout>

            <GridItemLayout />
          </GridLayout>
        )}
      </FormGroup>

      {!!currentCompletionSchedule && !!currentCreationSchedule && (
        <footer className={styles.footer}>
          <button className={styles.button}>
            Next&nbsp;
            <ChevronIcon tone="primary" size={15} />
          </button>
        </footer>
      )}
    </form>
  );
}
