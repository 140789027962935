import React, { useState, useEffect } from 'react';
import { Anchor, HelpIcon, IconButton, Modal, Panel, SkillLevel, SkillPercentage, SunburstChart, TabSlide, TabSlider, colourString, useMatchScreenWidth } from '@keplerco/core';
import { SkillScoresRequest, SkillScoresSkillSubTypeResponse } from '../../../models/overmind/analytics';
import { getSunBurstData, sortByUrgencyIndication } from '../../../pages/your-skills/your-skills.helpers';
import { SkillLevelsExplainerPanelLayout } from '../../layouts/focus-panel-layouts/skill-levels-explainer-panel/skill-levels-explainer-panel.layout';
import { comparisonScoreTitle } from '../../../library/helpers/comparison-score-title';
import { ScoreComparison } from '../../../enums/score-comparison';
import { UrgencyIndicationChip } from '../../../components/chips/urgency-indication.chip';
import { useAppActions, useAppState } from '../../../overmind';
import { ResponseGroup } from '../../../enums/analytics';
import { SkillTypeName } from '../../../pages/your-skills/your-skills.models';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import classNames from 'classnames';
import styles from './sunburst.module.css';
import { YourSkillsModalLayout } from '../../../pages/your-skills/your-skills-modal/your-skills-modal.layout';
import { ISunburstWidgetProps } from './sunburst.models';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';
import { SkillScoresSearchParams } from '../../../models/overmind/search-params';
import { OrganizationLevelType } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';

export function SunburstWidget({ path, chartTitle, permissionSearchParams, assessmentSearchParams }: ISunburstWidgetProps) {
  const actions = useAppActions();
  const { companyVariables, dateRange } = useAppState();

  const [isLoading, setIsLoading] = useState(false);
  const [skillSubTypes, setSkillSubTypes] = useState<SkillScoresSkillSubTypeResponse[]>();
  const [selectedSkillSubType, setSelectedSkillSubType] = useState<SkillScoresSkillSubTypeResponse | undefined>(undefined);
  const [isLevelsModalOpen, setIsLevelsModalOpen] = useState(false);

  const isMobile = useMatchScreenWidth('mobile');

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const searchParams: SkillScoresSearchParams = {
        ...permissionSearchParams,
        ...assessmentSearchParams,
        startDate: dateRange?.from?.toJSON(),
        endDate: dateRange?.to?.toJSON(),
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
        scoreComparison: companyVariables.scoreComparison,
        includeLearnerCount: false,
      };
      const request: SkillScoresRequest = {
        responseGroup: ResponseGroup.Skill,
        searchParams,
      };
      const response = await actions.analyticsGetSkillScores(request);

      const nextSkillSubTypes: SkillScoresSkillSubTypeResponse[] = [];
      if (!!response && !!response[0] && !!response[0].skillTypes) {
        response[0].skillTypes.forEach(skillType => {
          skillType.skillSubTypes?.forEach(skillSubType => {
            if (!skillSubType.skills?.length) return;
            if (!skillSubType.name) {
              switch (skillType.name) {
                case SkillTypeName.Behavioural:
                  skillSubType.name = 'Behavioral skills';
                  break;
                case SkillTypeName.Technical:
                  skillSubType.name = 'Technical skills';
                  break;
                case SkillTypeName.Combined:
                  skillSubType.name = 'Combined skills';
                  break;
                default:
                  skillSubType.name = 'Other skills';
                  break;
              }
            }
            nextSkillSubTypes.push(skillSubType);
          });
        });
      }
      setSkillSubTypes(nextSkillSubTypes);

      setIsLoading(false);
    }

    getData();
  }, [permissionSearchParams, assessmentSearchParams, dateRange]);

  return (
    <SkeletonOverlay isLoading={isLoading} width="100%" height="100%">
      <div className={styles.skillsReportContainer}>
        {!skillSubTypes?.length ? (
          <div className={classNames(styles.skillsReportCard, 'card', styles.emptyState)}>
            <EmptyState />
          </div>
        ) : (
          <TabSlider>
            {skillSubTypes.map((skillType, index, array) => {
              const isFirst = index === 0;
              const isLast = index === array.length - 1;
              const sunBurstData = getSunBurstData(skillType.skills);

              return (
                <TabSlide name={skillType.name} key={skillType.slug}>
                  <div className={classNames(styles.skillsReportContentTabSlide, { isFirst, isLast })}>
                    <div className={classNames(styles.skillsReportCard, 'card')}>
                      {!isMobile && (
                        <div className={styles.skillsReportContentSunburst}>
                          <SunburstChart data={sunBurstData} scale={5} />
                        </div>
                      )}

                      <div className={styles.skillsReportContent}>
                        <div className={styles.skillsReportContentHeader}>
                          <h3 id={skillType.slug}>
                            {skillType.name}
                            {!!companyVariables.useLevels && (
                              <span>
                                <IconButton iconType="fill" onClick={() => setIsLevelsModalOpen(true)}>
                                  <HelpIcon />
                                </IconButton>
                              </span>
                            )}
                          </h3>

                          {!skillType.skills[0].urgencyIndication && (
                            <div className={styles.skillsReportContentScore}>
                              <div className={styles.skillsReportContentLevel}>
                                {companyVariables.useLevels ? (
                                  <SkillLevel level={skillType.score?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!skillType.score} title="Proficiency" />
                                ) : (
                                  <SkillPercentage percentage={skillType.score?.percentage ?? 0} noPercentage={!skillType.score} title="Proficiency" />
                                )}
                              </div>

                              {companyVariables.scoreComparison !== ScoreComparison.None && path === PagePath.yourSkills && (
                                <div className={styles.skillsReportContentLevel}>
                                  {companyVariables.useLevels ? (
                                    <SkillLevel level={skillType.comparisonScore?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!skillType.comparisonScore} title={comparisonScoreTitle(companyVariables.scoreComparison)} />
                                  ) : (
                                    <SkillPercentage percentage={skillType.comparisonScore?.percentage ?? 0} noPercentage={!skillType.comparisonScore} title={comparisonScoreTitle(companyVariables.scoreComparison)} />
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        <div className={styles.skillsReportContentList}>
                          <h4>{chartTitle}</h4>

                          <div className={styles.skillItemList}>
                            <table className={styles.table}>
                              <thead>
                                <tr>
                                  <th className={styles.td} style={{ color: 'var(--accent-2)' }}>
                                    Skill name
                                  </th>

                                  {!!skillType.skills[0].urgencyIndication ? (
                                    <th className={styles.td} />
                                  ) : (
                                    <React.Fragment>
                                      <th className={styles.td}>{permissionSearchParams.organizationLevel === OrganizationLevelType.Learner ? 'Your proficiency' : 'Proficiency'}</th>
                                      {companyVariables.scoreComparison !== ScoreComparison.None && path === PagePath.yourSkills && <th className={styles.td}>{comparisonScoreTitle(companyVariables.scoreComparison)}</th>}
                                    </React.Fragment>
                                  )}
                                </tr>
                              </thead>

                              <tbody>
                                {sunBurstData
                                  .sort((a, b) => (!!a.urgencyIndication && !!b.urgencyIndication ? sortByUrgencyIndication(a.urgencyIndication, b.urgencyIndication) : a.value - b.value))
                                  .slice(0, 4)
                                  .map(slice => (
                                    <tr key={slice.name}>
                                      <td className={styles.td}>
                                        <div className={styles.listItem}>
                                          <span className={styles.listItemColor} style={{ background: colourString(slice.color) }} />
                                          <span className={styles.listItemName}>{slice.name}</span>
                                        </div>
                                      </td>

                                      {!!slice.urgencyIndication ? (
                                        <td className={styles.td} style={{ textAlign: 'end' }}>
                                          <UrgencyIndicationChip urgencyIndication={slice.urgencyIndication} />
                                        </td>
                                      ) : (
                                        <React.Fragment>
                                          <td className={styles.td}>
                                            {companyVariables.useLevels ? (
                                              <SkillLevel level={slice.score?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!slice.score} />
                                            ) : (
                                              <SkillPercentage percentage={slice.score?.percentage ?? 0} noPercentage={!slice.score} />
                                            )}
                                          </td>

                                          {companyVariables.scoreComparison !== ScoreComparison.None && path === PagePath.yourSkills && (
                                            <td className={styles.td}>
                                              {companyVariables.useLevels ? (
                                                <SkillLevel level={slice.comparisonScore?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!slice.comparisonScore} noLevelText="Not captured" />
                                              ) : (
                                                <SkillPercentage percentage={slice.comparisonScore?.percentage ?? 0} noPercentage={!slice.comparisonScore} noPercentageText="Not captured" />
                                              )}
                                            </td>
                                          )}
                                        </React.Fragment>
                                      )}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className={styles.skillsReportContentActions}>
                          <Anchor onClick={() => setSelectedSkillSubType(skillType)}>View Skills Breakdown</Anchor>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabSlide>
              );
            })}
          </TabSlider>
        )}
      </div>

      {companyVariables.useLevels && (
        <Panel open={isLevelsModalOpen} onClose={() => setIsLevelsModalOpen(false)}>
          <SkillLevelsExplainerPanelLayout onClose={() => setIsLevelsModalOpen(false)} />
        </Panel>
      )}

      {!!selectedSkillSubType && (
        <Modal size="extralarge" open={!!selectedSkillSubType} onClose={() => setSelectedSkillSubType(undefined)}>
          <YourSkillsModalLayout skillSubType={selectedSkillSubType} />
        </Modal>
      )}
    </SkeletonOverlay>
  );
}
