import React, { Fragment, useEffect, useState } from 'react';
import { Chip, ListLayout, scoreLevelRAGColour, search, SkillLevel } from '@keplerco/core';
import { ListItem } from '../../../../../components/lists/list-item';
import { EmptyState } from '../../../../../components/general/empty-state/empty-state';
import { useAppState } from '../../../../../overmind';
import classNames from 'classnames';
import { CompanySkillListItemResponse } from '../../../../../models/overmind/company-entity';

export function SelectedSkillsWidget({
  showRoleSkills,
  learnerSlug,
  loading,
  query,
  roleSkills,
  setSelectedSkillsCount,
  selectedSkills,
  setSelectedSkills,
}: {
  showRoleSkills: boolean;
  learnerSlug: string | undefined;
  loading: boolean;
  query: string;
  roleSkills: CompanySkillListItemResponse[];
  setSelectedSkillsCount: (selectedSkillsCount: number) => void;
  selectedSkills: CompanySkillListItemResponse[];
  setSelectedSkills: (selectedSkills: CompanySkillListItemResponse[]) => void;
}): JSX.Element {
  const { companyVariables } = useAppState();

  const [searchedSelectedSkills, setSearchedSelectedSkills] = useState<CompanySkillListItemResponse[]>(selectedSkills);

  useEffect(() => {
    const nextSearchedSelectedSkills = search(selectedSkills, query, 'name');
    setSearchedSelectedSkills(nextSearchedSelectedSkills);
    setSelectedSkillsCount(nextSearchedSelectedSkills.length);
  }, [query, selectedSkills]);

  if (loading) return <Fragment />;

  if (!searchedSelectedSkills?.length) return <EmptyState />;

  return (
    <div style={{ maxHeight: 400, padding: 1, paddingRight: 10, overflowY: 'scroll' }}>
      <ListLayout>
        {searchedSelectedSkills?.map(searchedSelectedSkill => {
          const roleSkill = roleSkills.some(roleSkill => roleSkill.slug === searchedSelectedSkill.slug);
          return (
            <ListItem
              key={searchedSelectedSkill.slug}
              selected
              onClick={() => {
                const nextSelectedSkills = selectedSkills.filter(selectedSkill => (!!searchedSelectedSkill.slug && !!selectedSkill.slug ? searchedSelectedSkill.slug !== selectedSkill.slug : searchedSelectedSkill.name !== selectedSkill.name));
                setSelectedSkills(nextSelectedSkills);
              }}
            >
              <div style={{ display: 'grid', gridTemplateColumns: `1fr ${!!learnerSlug ? '140px' : ''} ${!!showRoleSkills ? 'auto' : ''}`, gap: 15, alignItems: 'center' }}>
                <div>
                  <div className="body" style={{ color: 'var(--accent-2)' }}>
                    {searchedSelectedSkill.name}
                  </div>

                  <small>{searchedSelectedSkill.description ?? 'No description provided'}</small>
                </div>

                {!!learnerSlug && (
                  <SkillLevel
                    level={searchedSelectedSkill.level ?? companyVariables.minLevel}
                    minLevel={companyVariables.minLevel}
                    maxLevel={companyVariables.maxLevel}
                    dotColour={scoreLevelRAGColour(searchedSelectedSkill.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel)}
                    noLevel={!searchedSelectedSkill.level}
                    title="Proficiency"
                  />
                )}

                {!!showRoleSkills && (
                  <div className={classNames({ invisible: !roleSkill })}>
                    <Chip customTheme={{ backgroundColor: 'background', borderColor: 'grape', color: 'text' }}>Role skill</Chip>
                  </div>
                )}
              </div>
            </ListItem>
          );
        })}
      </ListLayout>
    </div>
  );
}
