import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FetchType } from '../../../enums';
import { DigitalSignatureType } from '../../../enums/digital-signature-type';
import { useAppActions } from '../../../overmind';
import { Button, Checkbox, FormControl, RequiredValidator, Textfield } from '@keplerco/core';
import { IPdfReportModalProps } from './pdf-report.models';
import { Profile } from '../../../models/profile';

export function PDFReportModalLayout({ path, from, to, profile, onClick }: IPdfReportModalProps): JSX.Element {
  const actions = useAppActions();

  const { control, handleSubmit, getValues } = useForm<FieldValues>({ mode: 'onChange' });

  async function onSubmitHandler(values: any) {
    if (!profile) return;

    actions.startLoader({ path: path, type: FetchType.Sending });

    const request: Profile = {
      ...profile,
      phoneNumber: values.phoneNumber,
      cpdRegistrationNumber: values.cpdRegistrationNumber,
    };

    await actions.saveDigitalSignature(DigitalSignatureType.CpdActivityHonestyPledge);
    await actions.saveProfile(request);
    onClick();
  }

  return (
    <div className="modalContent">
      <header style={{ marginBottom: 30 }}>
        <h2>We'll need some info before we generate your report</h2>
      </header>

      <form id="saveDigitalSignature" onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="card">
          <FormControl
            name="phoneNumber"
            control={control}
            rules={new RequiredValidator('Enter your contact number')}
            defaultValue={profile?.phoneNumber ?? ''}
            render={({ field, fieldState }) => {
              return <Textfield {...field} label="Contact Number" haserror={!!fieldState.error} type="number" responsive />;
            }}
          />

          <FormControl
            name="cpdRegistrationNumber"
            control={control}
            rules={new RequiredValidator('Enter your Professional body membership number')}
            defaultValue={profile?.cpdRegistrationNumber ?? ''}
            render={({ field, fieldState }) => {
              return <Textfield {...field} label="Professional body membership number" haserror={!!fieldState.error} type="text" responsive />;
            }}
          />

          <FormControl
            name="confirmHonesty"
            control={control}
            rules={new RequiredValidator('Complete your declaration')}
            paddingBottom={0}
            render={({ field }) => {
              return (
                <Checkbox {...field} id="confirmHonesty" checked={getValues('confirmHonesty')}>
                  <span style={{ textAlign: 'start' }}>
                    I confirm that the information included in this report (including but not limited to learning related to Business, Personal, and Professional Ethics) is an accurate reflection of my CPD activities {!!from ? `from ${from} to ${to}` : `up to ${to}`}.
                  </span>
                </Checkbox>
              );
            }}
          />
        </div>

        <Button type="button" filled>
          Submit
        </Button>
      </form>
    </div>
  );
}
