import { colourString } from '@keplerco/core';
import React, { PropsWithChildren } from 'react';

export const WidgetCard = React.forwardRef<HTMLDivElement, PropsWithChildren<{ title: string; subtitle?: string } & React.HTMLAttributes<HTMLDivElement>>>(({ title, subtitle, children, style, ...props }, outerRef) => {
  const innerRef = React.useRef<HTMLInputElement>(null);
  React.useImperativeHandle(outerRef, () => innerRef.current!, []);

  return (
    <div {...props} ref={innerRef} className="card" style={{ display: 'flex', flexDirection: 'column', gap: 12, ...style }}>
      <div>
        <h3>{title}</h3>
        {!!subtitle && <p style={{ color: colourString('text_1') }}>{subtitle}</p>}
      </div>

      {children}
    </div>
  );
});

WidgetCard.displayName = 'WidgetCard';
