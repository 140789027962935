import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../../overmind';
import { ScatterChartComponent } from '../../../../components/charts/scatter/scatter-chart';
import { xTickFormatter, yTickFormatter } from './versus-score.helpers';
import { DataPoint } from '../../../../components/charts/recharts.models';
import { IVersusScoreWidgetProps } from './versus-score.models';
import { EmptyState } from '../../../../components/general/empty-state/empty-state';
import { SkeletonOverlay } from '../../../../components/general/loading-state/skeleton-overlay';
import { WidgetCard } from '../../analytics-filters/widget-card';

export function FrequencyUseWidget({ baseSearchParams }: IVersusScoreWidgetProps): React.ReactElement {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = useState(true);
  const [frequencyDataPoints, setFrequencyDataPoints] = useState<DataPoint[]>();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const response = await actions.getAssessmentAnalyticsScoreByFrequency(baseSearchParams);
      setFrequencyDataPoints(response?.map(item => ({ ['Frequency of use']: Number(item.label), ['Average skill score']: companyVariables.useLevels ? item.score.level : item.score.percentage })));
      setIsLoading(false);
    }

    getData();
  }, [baseSearchParams]);

  return (
    <SkeletonOverlay isLoading={isLoading} height="100%">
      <WidgetCard title="Frequency of use vs average skill score" subtitle="The average score for skills included in this assessment compared to how many years of experience the assessment participants have in the skill." style={{ height: '100%', justifyContent: 'space-between' }}>
        {!!frequencyDataPoints?.length ? (
          <ScatterChartComponent dataPoints={frequencyDataPoints} useLevels={companyVariables.useLevels} fill="baby-blue" xTickFormatter={xTickFormatter} xTickCount={4} yTickFormatter={yTickFormatter} />
        ) : (
          <EmptyState title="Hang tight!" subtitle="We are waiting for data." height={500} />
        )}
      </WidgetCard>
    </SkeletonOverlay>
  );
}
