import React, { useEffect, useState } from 'react';
import { Anchor, Button, CascadingPanel, CascadingPanels, Chip, KebabMenu, ListItemLayout, ListLayout, MenuItem, useCascadingPanelsConnector, useMatchScreenWidth } from '@keplerco/core';
import styles from './people-step.module.css';
import classNames from 'classnames';
import { SelectCohortLayout } from '../../../../../components/panels/select-cohort/select-cohort.layout';
import { SelectPeopleLayout } from './select-people/select-people.layout';
import { useAppActions, useAppState } from '../../../../../overmind';
import { SkillAssessmentAssignee } from '../../../../../enums';
import { IPerson } from '../../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';
import { CohortType } from '../../../../../components/panels/select-cohort/select-cohort.models';
import { UniqueEntity } from '../../../../../models';
import { CascadingFocusPanelIds, ErrorMessage, IPeopleStepLayoutProps } from './people-step.models';
import { StepSkeleton } from '../../../../../components/general/stepper/step/step.skeleton';
import { AstronautsIcon } from '../../../../../design/icons/astronauts.icon';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';
import layoutStyles from '../../../../../design/layout.module.css';

const CUSTOM_THEME = { backgroundColor: 'background', borderColor: 'baby-blue', color: 'text' };

export function PeopleStepLayout({ assessmentSlug, isLoading, setIsLoading, preferences, people, setPeople, setRoleSlug, onBack, onNext }: IPeopleStepLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [selectedCohortType, setSelectedCohortType] = useState<CohortType>(CohortType.Department);
  const [selectedEntity, setSelectedEntity] = useState<UniqueEntity>();
  const [selectedPeople, setSelectedPeople] = useState<IPerson[]>();
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  const isMobile = useMatchScreenWidth('mobile');

  const { openPanelIds, next, previous, closeAll } = useCascadingPanelsConnector();

  useEffect(() => {
    if (preferences.allowManagerReview || preferences.allowPeerReview) return setSelectedPeople(people);

    const nextPeople: IPerson[] | undefined = structuredClone(people);
    nextPeople?.forEach(person => (person.manager = false));
    setSelectedPeople(nextPeople);
  }, [preferences.allowSelfReview, preferences.allowManagerReview, preferences.allowPeerReview, people]);

  useEffect(() => {
    if (!errorMessage) return;
    generateErrorMessage();
  }, [selectedPeople, preferences]);

  function generateErrorMessage(): ErrorMessage | undefined {
    let errorMessage = undefined;

    if (!selectedPeople?.length) {
      errorMessage = ErrorMessage.NoPersonSelected;
      setErrorMessage(errorMessage);
      return errorMessage;
    }

    if (preferences.allowManagerReview && !selectedPeople.some(person => person.manager)) {
      errorMessage = ErrorMessage.NoManagerSelected;
      setErrorMessage(errorMessage);
      return errorMessage;
    }

    setErrorMessage(errorMessage);
    return errorMessage;
  }

  function generateKebabMenuItems(personId: string): MenuItem[] {
    return [
      {
        label: 'Remove person',
        onClick: () =>
          setSelectedPeople(currentState => {
            if (!currentState) return;
            const nextState = currentState.filter(person => person.id !== personId);
            return nextState;
          }),
      },
    ];
  }

  if (isLoading) return <StepSkeleton />;

  return (
    <React.Fragment>
      <EntitiesCardWidget title="People" description="Select the people you would like to be in the assessment">
        <div className="card action-card">
          <AstronautsIcon />

          <div className="action-card-content">
            <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
              <h3>Assign people</h3>
              {!!selectedPeople?.length && (
                <Chip customTheme={CUSTOM_THEME}>
                  {selectedPeople.length} {selectedPeople.length === 1 ? 'person' : 'people'}
                </Chip>
              )}
            </div>

            <p>Assign departments, teams, or individual people and match them with relevant skills for assessment.</p>
          </div>

          <Button type="button" onClick={() => next(CascadingFocusPanelIds.SelectEntity)}>
            Assign
          </Button>
        </div>

        {!!selectedPeople?.length && (
          <div style={{ marginTop: 24 }}>
            <h4>Assigned people</h4>

            <div style={{ marginTop: 12, height: 400, overflowY: 'auto' }}>
              <ListLayout>
                {selectedPeople?.map((person, index) => {
                  return (
                    <ListItemLayout key={index}>
                      {isMobile ? (
                        <div className={classNames('card', styles.listItem)} style={{ gridTemplateColumns: '30px 1fr' }}>
                          <KebabMenu items={generateKebabMenuItems(person.id)} />

                          <div>
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
                              <div style={{ color: 'var(--accent-2)' }}>
                                {person.firstName} {person.lastName}
                              </div>

                              <small>
                                {person.department.name} | {person.team.name}
                              </small>

                              <small>{person.email}</small>

                              {person.manager && (
                                <div>
                                  <Chip customTheme={CUSTOM_THEME}>Manager</Chip>{' '}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={classNames('card', styles.listItem)} style={{ gridTemplateColumns: '30px 1fr 1fr 100px' }}>
                          <KebabMenu items={generateKebabMenuItems(person.id)} />

                          <div>
                            <div style={{ color: 'var(--accent-2)' }}>
                              {person.firstName} {person.lastName}
                            </div>

                            <small>
                              {person.department.name} | {person.team.name}
                            </small>
                          </div>

                          <small>{person.email}</small>

                          {person.manager && <Chip customTheme={CUSTOM_THEME}>Manager</Chip>}
                        </div>
                      )}
                    </ListItemLayout>
                  );
                })}
              </ListLayout>
            </div>
          </div>
        )}

        <div className={classNames('formErrorMessage', { invisible: !errorMessage })}>{errorMessage}&nbsp;</div>
      </EntitiesCardWidget>

      <footer className={classNames('card', layoutStyles.wizardFooter)}>
        <Anchor arrow reverse onClick={onBack}>
          Back
        </Anchor>

        <Anchor
          arrow
          onClick={async () => {
            if (!selectedPeople) return;

            if (!!generateErrorMessage()) return;

            setIsLoading(true);

            await actions.saveSkillAssessmentAssignees({
              companySlug: companyVariables.slug!,
              assessmentAssignee: {
                assessmentGroup: SkillAssessmentAssignee.Learner,
                assessmentSlug: assessmentSlug,
                includeLearnersWithNoDepartment: false,
                assignedTeamChampionId: selectedPeople.find(p => p.manager)?.id,
                userIds: selectedPeople.map(person => person.id),
              },
            });

            setPeople(selectedPeople);
            onNext();
            setIsLoading(false);
          }}
        >
          Next
        </Anchor>
      </footer>

      <CascadingPanels openPanelIds={openPanelIds} onClosePanel={previous}>
        <CascadingPanel id={CascadingFocusPanelIds.SelectEntity}>
          <SelectCohortLayout
            supertitle="Assessment Management"
            title="Who do you want to assess?"
            subtitle="Select departments, teams, role or individual people to take part in the assessment."
            selectedCohortType={selectedCohortType}
            setSelectedCohortType={setSelectedCohortType}
            selectedEntity={selectedEntity}
            setSelectedEntity={setSelectedEntity}
            onBack={previous}
            onNext={() => next(CascadingFocusPanelIds.SelectPeople)}
          />
        </CascadingPanel>

        <CascadingPanel id={CascadingFocusPanelIds.SelectPeople}>
          <SelectPeopleLayout
            selectedCohortType={selectedCohortType}
            selectedEntity={selectedEntity}
            selectedPeople={selectedPeople ?? []}
            showManagerToggle={!!preferences.allowManagerReview}
            onBack={previous}
            onDone={people => {
              setRoleSlug(selectedCohortType === CohortType.Role ? selectedEntity?.slug : undefined);
              setSelectedPeople(people);
              closeAll();
            }}
          />
        </CascadingPanel>
      </CascadingPanels>
    </React.Fragment>
  );
}
