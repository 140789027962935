import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { YourAssessmentsPage } from '../../pages/assessments/your-assessments/your-assessments.page';
import { YourAssessmentPage } from '../../pages/assessments/your-assessments/your-assessment.page';
import { ErrorRedirect } from './routers.helpers';

export function YourAssessmentsRouter(): JSX.Element {
  return (
    <Routes>
      <Route path={PagePath.root} element={<YourAssessmentsPage />} />
      <Route path={PagePath.yourAssessmentsAssessment} element={<YourAssessmentPage />} />

      <Route path={PagePath.rootWildcard} element={<ErrorRedirect />} />
    </Routes>
  );
}
