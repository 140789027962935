import { DataPoint } from '../../../../components/charts/recharts.models';
import { UserScore } from '../../../../models/overmind/assessment-analytics';
import { Score } from '../../../../models/view/score';

function formatScore(score: Score | undefined, useLevels: boolean): number | undefined {
  return useLevels ? score?.level ?? undefined : score?.percentage ?? undefined;
}

export function transformScores(scores: UserScore[] | undefined, useLevels: boolean): DataPoint[] {
  if (!scores?.length) return [];
  const dataPoints: DataPoint[] = [];

  scores[0].competencies.forEach(competency => {
    if (competency.name.toLocaleLowerCase() === 'average score') return;
    const dataPoint: DataPoint = {name: competency.name};
    dataPoint['Skill score'] = formatScore(competency.score, useLevels);
    dataPoint['Assessment score'] = formatScore(competency.comparisonScore, useLevels);
    dataPoints.push(dataPoint);
  });

  return dataPoints;
}

