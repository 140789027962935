import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { FetchType } from '../../../enums';
import { useAppActions, useAppState } from '../../../overmind';
import { ReportType } from '../../../models/overmind/report-type';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { ListItemLayout, ListLayout, PageHeader, colourString, kebabCase, useMatchScreenWidth } from '@keplerco/core';
import classNames from 'classnames';
import styles from './report-templates.module.css';

export function AnalyticsReportTemplatesPage(): JSX.Element {
  const navigate = useNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [reportTypes, setReportTypes] = useState<ReportType[]>();

  const isTablet = useMatchScreenWidth('tablet');

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.analyticsReportTemplates, type: FetchType.PageFetching });
      const data = await actions.getAnalyticsReportTemplates(companyVariables.slug!);
      setReportTypes(data);

      actions.stopLoader(PagePath.analyticsReportTemplates);
    }

    getData();
  }, [companyVariables.slug]);

  return (
    <PageLoaderLayer path={PagePath.analyticsReportTemplates}>
      <div className="pageWrapper wrapper">
        <PageHeader
          breadcrumbs={[
            {
              name: 'Analytics',
              url: `${PagePath.analyticsBase}`,
            },
            {
              name: 'Report templates',
              url: `${PagePath.analyticsBase}${PagePath.analyticsReportTemplates}`,
            },
          ]}
          title="Report templates"
        />

        <br />

        {!!reportTypes && reportTypes.length > 0 ? (
          <ListLayout>
            {reportTypes.map(reportType => (
              <ListItemLayout key={reportType.reportType} onClick={() => navigate(`${PagePath.analyticsBase}${PagePath.analyticsReportTemplateName.replace(':companySlug', companyVariables.slug!).replace(':reportSlug', reportType.reportType).replace(':reportName', reportType.name)}`)}>
                <div className="card">
                  <div className="cardListItemContentLayout" id={`${kebabCase(reportType.name)}-report-template`}>
                    <div className={classNames('cardListItemBodyLayout', 'alignBaseline', isTablet ? 'directionColumn' : 'directionRow')} style={{ width: '100%' }}>
                      <h3 className={styles.name}>{reportType.name}</h3>

                      <p style={{ color: colourString(`accent-2`) }}>{reportType.description}</p>
                    </div>
                  </div>
                </div>
              </ListItemLayout>
            ))}
          </ListLayout>
        ) : (
          <EmptyState title="No report templates found" />
        )}
      </div>
    </PageLoaderLayer>
  );
}
