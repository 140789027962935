import { Anchor, Button, PanelHeader, GridItemLayout, GridLayout } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { SearchList } from '../../lists/search-list';
import { ListItem } from '../../lists/list-item';
import { OrganizationLevelType, SortField } from '../../../enums';
import classNames from 'classnames';
import styles from './select-cohort.module.css';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
import { BaseSearchRequest } from '../../../models';
import { PeopleIcon } from '../../../design/icons/people.icon';
import { RoleIcon } from '../../../design/icons/role.icon';
import { TeamIcon } from '../../../design/icons/team-icon';
import { CohortData, CohortType, ISelectCohortLayoutProps } from './select-cohort.models';
import { AstronautLoader } from '../../general/loading-state/loaders/astronaut-loader/astronaut-loader';
import { EntitiesIcon } from '../../../design/icons/entities.icon';

export function SelectCohortLayout({ supertitle, title, subtitle, cohortTypes = [CohortType.Department, CohortType.Team, CohortType.Role, CohortType.People], selectedCohortType, setSelectedCohortType, selectedEntity, setSelectedEntity, onBack, onNext }: ISelectCohortLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [cohortData, setCohortData] = useState<CohortData>();

  async function getDepartments(search: string | undefined) {
    const departmentRequest: BaseSearchRequest = { search, sortAscending: true, sortField: SortField.Name, pageSize: 15 };
    const response = await actions.searchDepartments(departmentRequest);
    setCohortData(!!response ? { entities: response.departments.map(department => ({ slug: department.slug, name: department.name, type: CohortType.Department })), totalPages: response.totalPages, totalCount: response.totalCount } : undefined);
  }

  async function getTeams(search: string | undefined) {
    const request: BaseSearchRequest = { search, sortAscending: true, sortField: SortField.Name, pageSize: 15 };
    const response = await actions.searchTeams(request);
    setCohortData(!!response ? { entities: response.teams.map(team => ({ slug: team.teamSlug, name: team.teamName, type: CohortType.Team })), totalPages: response.totalPages, totalCount: response.totalPages } : undefined);
  }

  async function getRoles(search: string | undefined, page: number) {
    const searchParams: CompanyEntitySearchParams = {
      search,
      page,
      pageSize: 5,
      sortAscending: true,
      sortField: SortField.Name,
      departmentSlug: undefined,
      learnerSlug: undefined,
      teamSlug: undefined,
      companySlug: companyVariables.slug,
      organizationLevel: OrganizationLevelType.Company,
      searchGlobally: false,
    };
    const response = await actions.getCompanyRoles(searchParams);
    setCohortData(!!response ? { entities: response.entities.map(entity => ({ slug: entity.slug, name: entity.name, type: CohortType.Role })), totalPages: response.totalPages, totalCount: response.totalCount } : undefined);
  }

  async function getCohortData(search: string | undefined, page: number) {
    switch (selectedCohortType) {
      case CohortType.Department: {
        await getDepartments(search);
        break;
      }

      case CohortType.Team: {
        await getTeams(search);
        break;
      }

      case CohortType.Role: {
        await getRoles(search, page);
        break;
      }

      default:
        break;
    }
  }

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      await getCohortData(search, activePageNumber);
      setIsLoading(false);
    }

    getData();
  }, [companyVariables.slug]);

  return (
    <div className="panelContent">
      <PanelHeader supertitle={supertitle} title={title} subtitle={subtitle} />

      <section className={classNames('card', styles.card)}>
        <GridLayout columnMinWidth={190}>
          {cohortTypes.includes(CohortType.Department) && (
            <GridItemLayout>
              <button
                className={classNames('card', styles.cohort, {
                  [styles.selected]: selectedCohortType === CohortType.Department,
                })}
                onClick={async () => {
                  setIsLoading(true);
                  setSelectedCohortType(CohortType.Department);
                  await getDepartments(search);
                  setIsLoading(false);
                }}
              >
                <EntitiesIcon />

                <div>
                  <h4>Department</h4>
                  <small>Search within a department</small>
                </div>
              </button>
            </GridItemLayout>
          )}

          {cohortTypes.includes(CohortType.Team) && (
            <GridItemLayout>
              <button
                className={classNames('card', styles.cohort, {
                  [styles.selected]: selectedCohortType === CohortType.Team,
                })}
                onClick={async () => {
                  setIsLoading(true);
                  setSelectedCohortType(CohortType.Team);
                  await getTeams(search);
                  setIsLoading(false);
                }}
              >
                <TeamIcon />

                <div>
                  <h4>Team</h4>
                  <small>Search within a team</small>
                </div>
              </button>
            </GridItemLayout>
          )}

          {cohortTypes.includes(CohortType.Role) && (
            <GridItemLayout>
              <button
                className={classNames('card', styles.cohort, {
                  [styles.selected]: selectedCohortType === CohortType.Role,
                })}
                onClick={async () => {
                  setIsLoading(true);
                  setSelectedCohortType(CohortType.Role);
                  await getRoles(search, activePageNumber);
                  setIsLoading(false);
                }}
              >
                <RoleIcon />

                <div>
                  <h4>Role</h4>
                  <small>Choose a role in the company</small>
                </div>
              </button>
            </GridItemLayout>
          )}

          {cohortTypes.includes(CohortType.People) && (
            <GridItemLayout>
              <button
                className={classNames('card', styles.cohort, {
                  [styles.selected]: selectedCohortType === CohortType.People,
                })}
                onClick={() => {
                  setSelectedCohortType(CohortType.People);
                  setSelectedEntity(undefined);
                  setCohortData({ entities: [], totalPages: 0, totalCount: 0 });
                  onNext();
                }}
              >
                <PeopleIcon />

                <div>
                  <h4>People</h4>
                  <small>Search all people</small>
                </div>
              </button>
            </GridItemLayout>
          )}
        </GridLayout>

        {isLoading ? (
          <AstronautLoader />
        ) : (
          <React.Fragment>
            {selectedCohortType !== CohortType.People && (
              <SearchList
                loading={isSearching}
                onInput={async search => {
                  setIsSearching(true);
                  setSearch(search);
                  setActivePageNumber(1);
                  await getCohortData(search, 1);
                  setIsSearching(false);
                }}
                paging={{
                  activePageNumber: activePageNumber ?? 1,
                  pageCount: cohortData?.totalPages ?? 0,
                  onPageChange: async page => {
                    setIsSearching(true);
                    setActivePageNumber(page);
                    await getCohortData(search, page);
                    setIsSearching(false);
                  },
                }}
                emptyState
              >
                {cohortData?.entities.map(entity => {
                  return (
                    <ListItem key={entity.slug} selected={selectedEntity?.slug === entity.slug} onClick={() => setSelectedEntity(entity)}>
                      <h6 style={{ color: 'var(--accent-2)' }}>{entity.name}</h6>
                    </ListItem>
                  );
                })}
              </SearchList>
            )}
          </React.Fragment>
        )}
      </section>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button type="button" onClick={onNext}>
          Next
        </Button>
      </footer>
    </div>
  );
}
