import React, { useEffect, useState } from 'react';
import { OverviewWidget } from './overview.widget';
import { useAppActions, useAppState } from '../../../overmind';
import { DonutSlices, IEngagementOverviewWidgetProps, OverviewWidgetChartData, OverviewWidgetDonutChartData } from './overview.models';
import { LEARNER_COMPLETION_RATES, LEARNER_COURSES_COMPLETE, LEARNER_COURSES_IN_PROGRESS, NON_LEARNER_COMPLETION_RATES, NON_LEARNER_COURSES_COMPLETE, NON_LEARNER_COURSES_IN_PROGRESS } from './help-information';
import { defaultLessonStatusCounts, generateDonutChartData, generateNumberChartData } from './overview.helpers';
import { AnalyticsSearchParams } from '../../../models/overmind/search-params';
import { OrganizationLevelType } from '../../../enums';

export function CourseEngagementWidget(props: IEngagementOverviewWidgetProps): JSX.Element {
  const { entity, isLearner = false } = props;

  const actions = useAppActions();
  const { dateRange, companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [donutChartData, setDonutChartData] = useState<OverviewWidgetDonutChartData>();
  const [firstNumberDisplayChartData, setFirstNumberDisplayChartData] = useState<OverviewWidgetChartData>();
  const [secondNumberDisplayChartData, setSecondNumberDisplayChartData] = useState<OverviewWidgetChartData>();

  useEffect(() => {
    async function getData() {
      if (!entity) {
        setLoading(false);
        return;
      }

      const request: AnalyticsSearchParams = {
        startDate: dateRange?.from?.toJSON(),
        endDate: dateRange?.to?.toJSON(),
        organizationLevel: entity.organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
      };
      if (entity.organizationLevel === OrganizationLevelType.Department) request.departmentSlug = entity.entity?.slug;
      if (entity.organizationLevel === OrganizationLevelType.Team) request.teamSlug = entity.entity?.slug;
      if (entity.organizationLevel === OrganizationLevelType.Learner) request.learnerSlug = entity.entity?.slug;
      const response = (await actions.analyticsGetLessonStatusCounts(request)) ?? defaultLessonStatusCounts;

      const totalCourses = response.notStarted + response.inProgress + response.complete;
      const slices: DonutSlices = [
        { label: `Not started`, value: response.notStarted, colour: `default` },
        { label: `In Progress`, value: response.inProgress, colour: `blue` },
        { label: `Complete`, value: response.complete, colour: `secondary` },
      ];
      setDonutChartData(generateDonutChartData('Completion Rates', 'Total courses', totalCourses, slices, !isLearner ? NON_LEARNER_COMPLETION_RATES : LEARNER_COMPLETION_RATES));

      setFirstNumberDisplayChartData(generateNumberChartData(`Courses in progress`, response.inProgress, `number`, !isLearner ? NON_LEARNER_COURSES_IN_PROGRESS : LEARNER_COURSES_IN_PROGRESS));
      setSecondNumberDisplayChartData(generateNumberChartData(`Courses completed`, response.complete, `number`, !isLearner ? NON_LEARNER_COURSES_COMPLETE : LEARNER_COURSES_COMPLETE));

      setLoading(false);
    }

    getData();
  }, [entity, companyVariables.slug]);

  return <OverviewWidget loading={loading} title="Course engagement" donutChartData={donutChartData} firstNumberDisplayChartData={firstNumberDisplayChartData} secondNumberDisplayChartData={secondNumberDisplayChartData} />;
}
