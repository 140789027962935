import { removeDuplicateScores } from '../../../components/charts/recharts.helpers';
import { DataPoint } from '../../../components/charts/recharts.models';
import { EntityScores } from '../../../models/overmind/analytics';
import { CompanyVariables } from '../../../models/view/company-variables';

export function transformScore(scores: EntityScores[], companyVariables: CompanyVariables): DataPoint[] {
  return scores
    .filter(roleSkillScore => !!roleSkillScore.score)
    .map(roleSkillScore => {
      const roleSkillScoresObj: DataPoint = { name: roleSkillScore.name };
      roleSkillScoresObj['Score'] = companyVariables.useLevels ? roleSkillScore.score?.level : roleSkillScore.score?.percentage;
      return roleSkillScoresObj;
    });
}

export function transformScores(scores: EntityScores[], companyVariables: CompanyVariables): DataPoint[] {
  const uniqueScores = removeDuplicateScores(scores, 'name');

  return uniqueScores
    .filter(score => !!score.scores[0].score)
    .map(personScore => {
      const personScoresObj: DataPoint = { name: personScore.name };

      personScore.scores.forEach(score => {
        personScoresObj[score.name] = !!score?.score ? (companyVariables.useLevels ? score?.score.level : score?.score.percentage) : undefined;
      });

      return personScoresObj;
    });
}

