import { useEffect, useState } from 'react';
import { FetchStatus } from '../../enums';
import { useAppActions } from '../../overmind';

const time = 1000 * 120; // 1000ms (1s) * 120 = 2 minutes

export function useDataSyncTimeout(): void {
  const actions = useAppActions();

  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(FetchStatus.Inactive);

  useEffect(() => {
    async function syncData() {
      setFetchStatus(FetchStatus.Active);
      await actions.syncCourseContent();
      setTimeout(() => setFetchStatus(FetchStatus.Inactive), time);
    }

    if (fetchStatus === FetchStatus.Inactive && document.hasFocus()) syncData();
  }, [fetchStatus]);
}
