import { Pager, SearchBar } from '@keplerco/core';
import React, { Fragment, PropsWithChildren } from 'react';
import { ISearchListProps } from './lists.models';
import { ListChildren } from './list-children';
import styles from './lists.module.css';

export function SearchList({ loading, emptyState, onInput, actions, paging, children }: PropsWithChildren<ISearchListProps>): JSX.Element {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
      <div className={styles.actions} style={{ gridTemplateColumns: !!actions ? '1fr auto' : '1fr' }}>
        <SearchBar loading={loading} label="Search" onInput={event => onInput((event.target as HTMLInputElement).value)} responsive />
        {actions}
      </div>

      {!loading && (
        <Fragment>
          {!!paging ? (
            <Fragment>
              <ListChildren emptyState={!!emptyState}>{children}</ListChildren>

              {paging.pageCount > 1 && <Pager {...paging} />}
            </Fragment>
          ) : (
            // TODO: move scroll container into ListChildren to avoid unnecessary bottom space
            <div style={{ maxHeight: 400, padding: 1, paddingRight: 10, overflowY: 'scroll' }}>
              <ListChildren emptyState={!!emptyState}>{children}</ListChildren>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}
