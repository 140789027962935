import React, { Fragment } from 'react';
import { INotificationLayoutProps } from '../../../../notifications/notifications.models';
import confettiLottie from '../../../../design/lottie/confetti.lottie.json';
import { LottieAsset } from '../../../../design/lottie/lottie-asset/lottie-asset';
import { Button, KeplerPoints } from '@keplerco/core';

export function JourneyCompletedModalLayout(props: INotificationLayoutProps): JSX.Element {
  const totalCoursesKP = (props.awardBreakdowns ?? []).reduce((a, b) => a + b.amount, 0);
  const totalEarnedKP = (props.amount ?? 0) + totalCoursesKP;

  return (
    <Fragment>
      <div className="dialogBackground">
        <LottieAsset loop autoplay data={confettiLottie} />
      </div>

      <div className="modalContent">
        <div className="badgeIconContainer">
          <object data={'/badges/badge_KP.svg'} type="image/svg+xml">
            <img src={'/badges/badge_KP.svg'} alt="KP" />
          </object>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'center', textAlign: 'center' }}>
          <h2>WooHoo!</h2>

          <h3>
            You've earned a total of
            <span className="h2">
              <KeplerPoints trimWhiteSpace forceFontWeight="bold" points={totalEarnedKP} />
            </span>
            for the completion of <strong style={{ color: 'var(--text)' }}>{props.title}</strong>
          </h3>

          <Button type={'button'} chubby filled arrow={false} onClick={props.onAction}>
            Great, thanks!
          </Button>
        </div>
      </div>
    </Fragment>
  );
}
