import { colourString, ThemeColours } from '@keplerco/core';
import { EntityScores } from '../../models/overmind/analytics';

// TODO: BE to review data
export function removeDuplicateScores(scores: EntityScores[], key: keyof EntityScores): EntityScores[] {
  const uniqueItems = new Set();
  return scores.filter(item => {
    const keyValue = item[key];
    if (uniqueItems.has(keyValue)) return false;
    else {
      uniqueItems.add(keyValue);
      return true;
    }
  });
}

export function generateFill(index: number, fill?: ThemeColours | ThemeColours[]): string {
  if (!!fill) {
    return Array.isArray(fill) ? colourString(fill[index]) : colourString(fill);
  }

  const graphColourNumber = index > 13 ? index - 13 : index + 1;
  return colourString(`grc_${graphColourNumber}`);
}
