import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../../overmind';
import { RoleFitSearchParams } from '../../../../models/overmind/search-params';
import { RoleFitContent } from '../role-fit-content/role-fit-content';
import { DataPoint } from '../../../../components/charts/recharts.models';
import { removeDuplicateScores } from '../../../../components/charts/recharts.helpers';

export function RoleFitComparisonWidget({ roleSlug, peopleSlugs }: { roleSlug: string | undefined; peopleSlugs: string[] }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [dataPoints, setDataPoints] = useState<DataPoint[]>();

  useEffect(() => {
    async function getData() {
      if (!roleSlug || !peopleSlugs.length) return;

      setLoading(true);

      const searchParams: RoleFitSearchParams = {
        companySlug: companyVariables.slug!,
        roleSlug,
        learnerSlugs: peopleSlugs,
      };
      const response = await actions.analyticsGetRoleFitComparison(searchParams);

      const uniqueResponse = removeDuplicateScores(response ?? [], 'name');
      setDataPoints(
        uniqueResponse?.map(skill => {
          const skillDataPoint: DataPoint = { name: skill.name };
          skill.scores.forEach(score => (skillDataPoint[score.name] = !!score.score ? (companyVariables.useLevels ? score.score.level : score.score.percentage) : undefined));
          return skillDataPoint;
        })
      );

      setLoading(false);
    }

    getData();
  }, [roleSlug, peopleSlugs, companyVariables.slug]);

  return <RoleFitContent title="Role-fit comparison" roleSlug={roleSlug} peopleSlugs={peopleSlugs} data={dataPoints} isLoading={loading} />;
}
