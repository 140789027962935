import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { FetchType } from '../../../../../../enums';
import { IManagePersonDetailsLayoutProps } from '../manage-person-panels.models';
import { PagePath } from '../../../../../../navigation/navigation.enums';
import { Department, SavableEmployee, Team } from '../../../../../../models';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { useForm } from 'react-hook-form';
import * as store from '../manage-person-panels.store';
import { Button, DropdownSearch, DropdownSelect, DropdownSelectItem, EmailValidator, FieldController, LettersValidator, RequiredValidator, TextField } from '@keplerco/core';
import { LANGUAGES } from '../../../../../../library/consts/languages';
import { PeriodOfExperience, PeriodOfExperienceDescription } from '../../../../../../enums/period-of-experience';
import { FlagIcon } from '../../../../../../design/icons/flag.icon';
import { Country } from '../../../../../onboarding/onboarding.models';

const periodsOfExperience: DropdownSelectItem[] = [
  { label: PeriodOfExperienceDescription.ZeroToTwo, value: PeriodOfExperience.ZeroToTwo.toString() },
  { label: PeriodOfExperienceDescription.TwoToFour, value: PeriodOfExperience.TwoToFour.toString() },
  { label: PeriodOfExperienceDescription.FourToSeven, value: PeriodOfExperience.FourToSeven.toString() },
  { label: PeriodOfExperienceDescription.SevenPlus, value: PeriodOfExperience.SevenPlus.toString() },
];
const languages: DropdownSelectItem[] = LANGUAGES.map(language => ({ value: language }));

// TODO: cleanup get-employee and save-employee models
export function ManagePersonDetailsLayout({ person, onSubmit }: IManagePersonDetailsLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [, dispatch] = useReducer(store.reducer, store.initialState);

  const { control, handleSubmit, reset, setValue } = useForm<any>({ mode: 'onBlur' });

  const [departments, setDepartments] = useState<Department[]>();
  const [teams, setTeams] = useState<Team[]>();
  const [countries, setCountries] = useState<Country[]>();
  const [departmentSlug, setDepartmentSlug] = useState<string>();

  function languagesArrayToString(languages: string[] | undefined): string | undefined {
    if (!languages || !languages.length) return undefined;
    if (languages.length === 1) return languages[0];
    return languages.join(',');
  }

  function languagesStringToArray(languages: string | undefined): string[] | undefined {
    if (!languages) return undefined;
    return languages.split(',');
  }

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });

      const departmentsResponse = await actions.getAdministrationDepartments(companyVariables.slug!);
      setDepartments(departmentsResponse?.sort((a, b) => a.name.localeCompare(b.name)));

      const teamsResponse = await actions.getAdministrationTeams(companyVariables.slug!);
      setTeams(teamsResponse?.sort((a, b) => a.teamName.localeCompare(b.teamName)));

      const countriesResponse = await actions.getCountries();
      setCountries(countriesResponse?.sort((a, b) => a.name.localeCompare(b.name)));

      if (!!person) {
        reset({
          firstName: person.firstName,
          lastName: person.lastName,
          email: person.email,
          jobTitle: person.jobTitle,
          periodOfExperience: person.periodOfExperience.toString(),
          departmentSlug: person.departmentSlug,
          teamId: person.teamId?.toString(),
          countryId: person.countryId.toString(),
          languages: languagesArrayToString(person.languages),
        });
        setDepartmentSlug(person.departmentSlug);
      }

      actions.stopLoader(PagePath.administrationPeople);
    }

    getData();
  }, [person]);

  return (
    <div className="card">
      <h4>User Details</h4>

      <form
        id="savePerson"
        onSubmit={handleSubmit(async values => {
          actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.Sending });

          const request: SavableEmployee = {
            id: person?.id,
            ...values,
            periodOfExperience: !!values.periodOfExperience ? parseInt(values.periodOfExperience) : values.periodOfExperience,
            countryId: !!values.countryId ? parseInt(values.countryId) : values.countryId,
            languages: languagesStringToArray(values.languages),
          };
          const response = await actions.savePerson(request);

          if (!!response?.id) {
            dispatch({ type: store.ManagePersonPermissionsActionTypes.SetPerson, payload: person });
            onSubmit(response.id);
          }

          actions.stopLoader(PagePath.administrationPeople);
        })}
      >
        <div className="row">
          <div className="column">
            <FieldController
              control={control}
              name="firstName"
              rules={new LettersValidator()}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="First name"
                  type="text"
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: fieldState.invalid,
                    message: fieldState.error?.message ?? 'Please enter a first name',
                  }}
                  responsive
                />
              )}
            />
          </div>

          <div className="column">
            <FieldController
              control={control}
              name="lastName"
              rules={new LettersValidator()}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Last name"
                  type="text"
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: fieldState.invalid,
                    message: fieldState.error?.message ?? 'Please enter a last name',
                  }}
                  responsive
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <FieldController
              control={control}
              name="email"
              rules={new EmailValidator()}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Email"
                  type="email"
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: fieldState.invalid,
                    message: fieldState.error?.message ?? 'Please enter an email',
                  }}
                  responsive
                />
              )}
            />
          </div>

          <div className="column">
            <FieldController
              control={control}
              name="jobTitle"
              rules={new RequiredValidator()}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Job title"
                  type="text"
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: fieldState.invalid,
                    message: fieldState.error?.message ?? 'Please enter a job title',
                  }}
                  responsive
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <FieldController
              control={control}
              name="periodOfExperience"
              rules={new RequiredValidator()}
              render={({ field, fieldState }) => (
                <DropdownSelect
                  {...field}
                  label="Years of experience"
                  items={periodsOfExperience}
                  validation={{
                    dirty: fieldState.isDirty || !!fieldState.error,
                    invalid: fieldState.invalid,
                    message: fieldState.error?.message ?? 'Please select years of experience',
                  }}
                  responsive
                />
              )}
            />
          </div>
        </div>

        {!!departments?.length && (
          <Fragment>
            <div className="row">
              <div className="column">
                <FieldController
                  control={control}
                  name="departmentSlug"
                  rules={new RequiredValidator()}
                  render={({ field, fieldState }) => (
                    <DropdownSelect
                      {...field}
                      label="Department"
                      items={departments.map(department => ({
                        value: department.slug,
                        label: department.name,
                        onClick: () => {
                          setValue('teamId', undefined);
                          setDepartmentSlug(department.slug);
                        },
                      }))}
                      validation={{
                        dirty: fieldState.isDirty || !!fieldState.error,
                        invalid: fieldState.invalid,
                        message: fieldState.error?.message ?? 'Please select a department',
                      }}
                      responsive
                    />
                  )}
                />
              </div>
            </div>

            {!!teams?.length && (
              <div className="row">
                <div className="column">
                  <FieldController
                    control={control}
                    name="teamId"
                    rules={new RequiredValidator()}
                    render={({ field, fieldState }) => (
                      <DropdownSelect
                        {...field}
                        label="Team"
                        items={teams
                          .filter(team => {
                            const department = departments.find(temp => temp.slug === departmentSlug);
                            return team.departmentId === department?.id;
                          })
                          .map(team => ({
                            value: team.id.toString(),
                            label: team.teamName,
                          }))}
                        validation={{
                          dirty: fieldState.isDirty || !!fieldState.error,
                          invalid: fieldState.invalid,
                          message: fieldState.error?.message ?? 'Please select a team',
                        }}
                        responsive
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </Fragment>
        )}

        {!!countries?.length && (
          <div className="row">
            <div className="column">
              <FieldController
                control={control}
                name="countryId"
                rules={new RequiredValidator()}
                render={({ field, fieldState }) => (
                  <DropdownSearch
                    {...field}
                    label="Country"
                    items={countries.map(country => ({
                      value: country.id!.toString(),
                      label: country.name,
                      icon: <FlagIcon isoCode={country.iso} />,
                    }))}
                    validation={{
                      dirty: fieldState.isDirty || !!fieldState.error,
                      invalid: fieldState.invalid,
                      message: fieldState.error?.message ?? 'Please select a country',
                    }}
                    responsive
                  />
                )}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="column">
            <FieldController control={control} name="languages" render={({ field }) => <DropdownSearch {...field} label="Languages" items={languages} responsive multiple />} />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
          <Button type="button">Submit</Button>
        </div>
      </form>
    </div>
  );
}
