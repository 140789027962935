import React from 'react';
import { LegendProps } from 'recharts';
import styles from './recharts-legend.module.css';

interface ILegendProps extends LegendProps {
  verticalLegend?: boolean;
}

export function RechartsLegend(props: ILegendProps) {
  const { payload } = props;

  return (
    <div className={`${styles.legend} ${props.verticalLegend ? styles.verticalLegend : ''}`}>
      {payload?.map(item => (
        <div key={item.value} className={styles.legendItem}>
          <svg width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill={item.color} />
          </svg>
          <span className="body">{item.value}</span>
        </div>
      ))}
    </div>
  );
}
