import React, { useEffect, useState } from 'react';
import { useAppActions } from '../../../overmind';
import { DataPoint } from '../../../components/charts/recharts.models';
import { HorizontalBarChart } from '../../../components/charts/horizontal-bar-chart/horizontal-bar-chart';
import { AssessmentSearchParams, FilteringSearchParams, PermissionSearchParams } from '../../../models/overmind/search-params';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';
import { WidgetCard } from '../../../pages/analytics/analytics-filters/widget-card';

const DEFAULT_SEARCH_PARAMS: FilteringSearchParams = {
  departmentSlugs: undefined,
  teamSlugs: undefined,
  learnerSlugs: undefined,
  roleSlugs: undefined,
  skillSlugs: undefined,
  countryIds: undefined,
};

export function ParticipantsWidget({ baseSearchParams }: { baseSearchParams: AssessmentSearchParams & PermissionSearchParams }) {
  const actions = useAppActions();

  const [isLoading, setIsLoading] = useState(false);
  const [dataPoints, setDataPoints] = useState<DataPoint[]>();
  const [domain, setDomain] = useState<number[]>();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const response = await actions.getAssessmentParticipants({ ...baseSearchParams, ...DEFAULT_SEARCH_PARAMS });
      const formattedData = response?.map(datum => {
        const transformed: DataPoint = { name: datum.name };

        datum.counts.forEach(({ label, count }) => {
          transformed[label] = count;
        });

        return transformed;
      });

      setDataPoints(formattedData);

      const max = Math.max(...(response ?? []).map(datum => datum.total));
      setDomain([0, max + 5]);

      setIsLoading(false);
    }

    getData();
  }, [baseSearchParams]);

  return (
    <SkeletonOverlay isLoading={isLoading} width="100%" height="100%">
      <WidgetCard title="Participants" style={{ height: '100%' }}>
        {!dataPoints?.length ? <EmptyState /> : <HorizontalBarChart dataPoints={dataPoints} domain={domain} fill={['default', 'baby-blue', 'apple']} xLabel="Number of participants" stacked showLegend />}
      </WidgetCard>
    </SkeletonOverlay>
  );
}
