import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAppActions, useAppState } from '../../../../overmind';
import { SkillAssessment } from '../../../../models';
import { FetchStatus, FetchType, SkillAssessmentAssignee } from '../../../../enums';
import { KeplerState } from '../../../../models/kepler-state';
import { WidgetSkeleton } from '../widget.skeleton';
import { PagePath } from '../../../../navigation/navigation.enums';
import { AssessmentType } from '../../../../enums/assessment-type';
import { Anchor, FormControl, ListItemLayout, ListLayout, NameValidator, NumbersValidator, RadioButtonCard, RequiredValidator, Textfield, safeCallback } from '@keplerco/core';
import styles from './assessment-details.module.css';

interface ISkillAssessmentDetailsWidgetProps {
  onSkillAssessmentSlugCreated: (slug: string) => void;
  onStepComplete: (slug: string) => void;
  assessmentDraftDetails?: SkillAssessment | null;
  onRadioChange: (option: any) => void;
  slug?: string;
}

export function SkillAssessmentDetailsWidget({ onRadioChange, onSkillAssessmentSlugCreated, onStepComplete, assessmentDraftDetails, slug: assessmentSlug }: ISkillAssessmentDetailsWidgetProps): JSX.Element {
  const { handleSubmit, control, setValue, watch } = useForm<any>();
  const params = useParams<any>();
  const actions = useAppActions();
  const isDraft = assessmentDraftDetails !== null;
  const formValues = watch();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const initialFormValuesRef = React.useRef<any>();

  React.useEffect(() => {
    if (assessmentDraftDetails) {
      let assessmentType: string = 'questionnaire';

      if (assessmentDraftDetails.assessmentType === AssessmentType.PeerEndorsement) assessmentType = 'peerEndorsement';
      else if (assessmentDraftDetails.assessmentType === AssessmentType.Questionnaire) assessmentType = 'questionnaire';
      else if (assessmentDraftDetails.assessmentType === AssessmentType.FieldsOfPractice) assessmentType = 'fieldsOfPractice';

      const initialValues = {
        assessmentName: assessmentDraftDetails.name,
        points: assessmentDraftDetails.keplerPointsAvailable,
        assessmentType,
      };

      initialFormValuesRef.current = initialValues;

      setValue('assessmentName', initialValues.assessmentName);
      setValue('points', initialValues.points);
      setValue('assessmentType', initialValues.assessmentType);
    }
  }, [assessmentDraftDetails, setValue]);

  React.useEffect(() => {
    onRadioChange(formValues.assessmentType);
  }, [formValues.assessmentType]);

  async function onSubmit(formData: any) {
    if (isSaving) return;

    setIsSaving(true);
    actions.startLoader({ path: PagePath.assessmentManagementCreate, type: FetchType.Custom });

    let typeOfAssessment: AssessmentType = AssessmentType.PeerEndorsement;
    if (formValues.assessmentType === 'questionnaire') typeOfAssessment = AssessmentType.Questionnaire;
    else if (formValues.assessmentType === 'peerEndorsement') typeOfAssessment = AssessmentType.PeerEndorsement;
    else if (formValues.assessmentType === 'fieldsOfPractice') typeOfAssessment = AssessmentType.FieldsOfPractice;

    const skillAssessmentModel: SkillAssessment = {
      assessmentType: typeOfAssessment,
      companySlug: params.companySlug!,
      name: formData.assessmentName,
      keplerPointsAvailable: parseInt(formData.points),
      includeTechnicalSkills: true,
      includeManagerSkillAdd: true,
      includeLearnerSkillAdd: false,
      includeLearnerSkillFrequency: true,
      includeManagePeerAssessment: true,
      includeLearnerPeerAssessment: true,
      assignementType: SkillAssessmentAssignee.Learner,
      slug: assessmentSlug ?? params.slug ?? '',
      includeBehavioralSkills: true,
      questionnaireSlugs: [],
      includeCareerPaths: typeOfAssessment === AssessmentType.FieldsOfPractice,
      careerPathSlugs: [],
      allowReassessment: false,
    };

    const result = await actions.updateSkillAssessment(skillAssessmentModel);

    if (!!result) {
      safeCallback(onSkillAssessmentSlugCreated, result);
    }

    actions.stopLoader(PagePath.assessmentManagementCreate);
    setIsSaving(false);

    if (params.slug || !!formValues.name) {
      return void 0;
    } else if (!!result) {
      onStepComplete(result);
    }
  }

  function handleSaveClick() {
    handleSubmit(onSubmit)();
  }

  const { fetchState } = useAppState<KeplerState>();

  return fetchState[PagePath.assessmentManagementCreate].status === FetchStatus.Active && fetchState[PagePath.assessmentManagementCreate].type === FetchType.Custom ? (
    <WidgetSkeleton />
  ) : (
    <form autoComplete="off" id="assessmentDetails" onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 15 }}>
        <div>
          <h4 className={styles.controlLabel}>Assessment Name</h4>

          <FormControl
            name="assessmentName"
            control={control}
            rules={new NameValidator()}
            render={({ field, fieldState }) => {
              return <Textfield {...field} haserror={!!fieldState.error} responsive disabled={!!assessmentSlug} />;
            }}
          />
        </div>

        <div>
          <h4 className={styles.controlLabel}>Points</h4>

          <FormControl
            name="points"
            control={control}
            rules={new NumbersValidator()}
            render={({ field, fieldState }) => {
              return <Textfield {...field} haserror={!!fieldState.error} type="text" disabled={!!assessmentSlug} />;
            }}
          />
        </div>
      </div>

      <h4 className={styles.controlLabel}>Assessment Type</h4>

      <ListLayout>
        <ListItemLayout>
          <FormControl
            paddingBottom={0}
            name="assessmentType"
            control={control}
            rules={new RequiredValidator('Must select an assessment type')}
            render={({ field }) => (
              <RadioButtonCard
                disabled={isDraft || !!assessmentSlug}
                id="peerEndorsement"
                {...field}
                value="peerEndorsement"
                checked={field.value === 'peerEndorsement'}
                onChange={e => {
                  field.onChange(e.target.value);
                }}
              >
                <div className="card">
                  <label htmlFor="peerEndorsement" className="subtitle toggleCardLabel" style={{ color: 'var(--accent-3)' }}>
                    Peer Assessment
                  </label>

                  <div className="body">Allow a group of people to endorse each other's skills.</div>
                </div>
              </RadioButtonCard>
            )}
          />
        </ListItemLayout>

        <ListItemLayout>
          <FormControl
            paddingBottom={0}
            name="assessmentType"
            control={control}
            render={({ field }) => (
              <RadioButtonCard
                disabled={isDraft || !!assessmentSlug}
                id="questionnaire"
                {...field}
                value="questionnaire"
                checked={field.value === 'questionnaire'}
                onChange={e => {
                  field.onChange(e.target.value);
                }}
              >
                <div className="card">
                  <label htmlFor="questionnaire" className="subtitle toggleCardLabel" style={{ color: 'var(--accent-3)' }}>
                    Questionnaire Based Assessment
                  </label>

                  <div className="body">Answer scenario based questions in a multiple choice format.</div>
                </div>
              </RadioButtonCard>
            )}
          />
        </ListItemLayout>

        <ListItemLayout>
          <FormControl
            paddingBottom={0}
            name="assessmentType"
            control={control}
            render={({ field }) => (
              <RadioButtonCard
                disabled={isDraft || !!assessmentSlug}
                id="fieldsOfPractice"
                {...field}
                value="fieldsOfPractice"
                checked={field.value === 'fieldsOfPractice'}
                onChange={e => {
                  field.onChange(e.target.value);
                }}
              >
                <div className="card">
                  <label htmlFor="fieldsOfPractice" className="subtitle toggleCardLabel" style={{ color: 'var(--accent-3)' }}>
                    Fields of Practice Assessment
                  </label>

                  <div className="body">Allows people to compare their skills against regulatory expectations. Ideal for job roles that have regulatory Continuous Professional Development (CPD) requirements.</div>
                </div>
              </RadioButtonCard>
            )}
          />
        </ListItemLayout>
      </ListLayout>

      {(!!formValues.name || !assessmentSlug) && (
        <footer style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>
          <Anchor arrow onClick={handleSaveClick}>
            {isDraft ? 'Update' : 'Next'}
          </Anchor>
        </footer>
      )}
    </form>
  );
}
