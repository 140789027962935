import React, { useEffect, useMemo, useState } from 'react';
import { IAssessmentRatingsWidget } from './assessment-ratings.models';
import { useAppActions, useAppState } from '../../../overmind';
import { LearnerSkillRatingsResponse, SkillLevel } from '../../../models/overmind/assessment-analytics';
import { LearnerAssessmentSearchParams } from '../../../models/overmind/search-params';
import { SearchBar, Table, TableColumn, TableRow } from '@keplerco/core';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { WidgetCard } from '../../../pages/analytics/analytics-filters/widget-card';

interface TransformedRating {
  name: string;
  skillSlug: string;
  score: number;
  selfRating: SkillLevel | undefined;
  managerRating: SkillLevel | undefined;
  [key: string]: number | string | undefined;
}

export default function AssessmentRatingsWidget({ assessmentSlug, learnerSlug }: IAssessmentRatingsWidget) {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const [ratings, setRatings] = useState<TransformedRating[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const request = useMemo(() => ({
    learnerSlug,
    companySlug: companyVariables.slug!,
    search: undefined,
  }), [learnerSlug, companyVariables.slug]);

  async function getData(params: LearnerAssessmentSearchParams) {
    const result = await actions.getAssessmentAnalyticsRatings({ assessmentSlug, params });
    setRatings(transformData(result));
    setIsSearching(false);
    setIsLoading(false);
  }

  function transformData(data: LearnerSkillRatingsResponse[] | undefined): TransformedRating[] {
    if (!data) return [];

    return data.map(rating => {
      const transformed: TransformedRating = {
        name: rating.skillName,
        skillSlug: rating.skillSlug,
        score: companyVariables.useLevels ? rating.score.level : rating.score.percentage,
        selfRating: rating.selfRating?.skillLevel,
        managerRating: rating.managerRating?.skillLevel,
      };

      if (rating.peerRatings && rating.peerRatings.length > 0) {
        rating.peerRatings.forEach(peerRating => {
          transformed[`peer_${peerRating.fullName}`] = peerRating.skillLevel;
        });
      }

      return transformed;
    });
  }

  useEffect(() => {
    setIsLoading(true);
    getData(request);
  }, [request]);

  async function handleSearch(input: string) {
    setIsSearching(true);
    getData({ ...request, search: input });
  }

  return (
    <SkeletonOverlay isLoading={isLoading} width="100%">
      <WidgetCard title="Assessment Ratings" subtitle="Rating for each skill by each participant in this assessment">
        <SearchBar onInput={event => handleSearch((event.target as HTMLInputElement).value)} label={'Search'} loading={isSearching} />
        {!!ratings?.length && (
          <Table emptyState={<EmptyState />}>
            {ratings.map(rating => {
              return (
                <TableRow key={rating.skillSlug} id={rating.skillSlug}>
                  {Object.entries(rating)
                    .filter(([key]) => key !== 'skillSlug')
                    .map(([key, value]) => {
                      let label = key;
                      if (key === 'name') label = 'Skill';
                      else if (key === 'score') label = 'Skill Score';
                      else if (key === 'selfRating') label = 'Self Rating';
                      else if (key === 'managerRating') label = 'Manager Rating';
                      else if (key.startsWith('peer_')) {
                        label = key.substring(5);
                      }

                      return (
                        <TableColumn
                          key={`${rating.skillSlug}-${key}`}
                          id={key}
                          label={label}
                          heatmap={
                            key === 'score'
                              ? companyVariables.useLevels
                                ? {
                                  score: value as number,
                                  min: companyVariables.minLevel,
                                  max: companyVariables.maxLevel,
                                }
                                : {
                                  score: value as number,
                                  min: 0,
                                  max: 100,
                                }
                              : undefined
                          }
                        >
                          {value}
                        </TableColumn>
                      );
                    })}
                </TableRow>
              );
            })}
          </Table>
        )}
      </WidgetCard>
    </SkeletonOverlay>
  );
}

