import React, { useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { IEntityLayoutProps } from './entity.models';
import { CourseEngagementWidget } from '../../../widgets/analytics/overview/course-engagement.widget';
import { DailyActivityWidget, SkillScoreOverTimeWidget } from '../../../widgets';
import { OrganizationLevelType } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';
import { EntityAverageWidget } from '../../../components/general/average/entity-average/entity-average.widget';
import { SkillsBreakdownWidget } from '../../../widgets/analytics/skills-breakdown/skills-breakdown.widget';
import { PageHeader, capitalCase } from '@keplerco/core';
import { HeatmapWidget } from '../../../widgets/analytics/heatmap/heatmap.widget';
import RankedEntitiesWidget from '../../../widgets/analytics/ranked-entities/ranked-entities.widget';
import { Daterange } from '../../../components/inputs/daterange/daterange.component';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import analyticsStyles from '../analytics.module.css';
import classNames from 'classnames';

export function EntityLayout(props: IEntityLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { dateRangeOutput, companyVariables } = useAppState();

  const [entity] = useState<PermissionsEntity>({
    organizationLevel: props.type === 'department' ? OrganizationLevelType.Department : OrganizationLevelType.Team,
    entity: {
      slug: props.slug,
      name: props.name,
    },
  });

  return (
    <div className={classNames('pageWrapper wrapper', analyticsStyles.page)}>
      <PageHeader
        breadcrumbs={[
          {
            name: 'Analytics',
            url: `${PagePath.analyticsBase}`,
          },
          {
            name: `${capitalCase(props.type)}s`,
            url: `${PagePath.analyticsBase}${props.parentPath.replace(':companySlug', companyVariables.slug!)}`,
          },
          {
            name: props.name,
            url: `${PagePath.analyticsBase}${props.path.replace(':companySlug', companyVariables.slug!)}/${props.slug}`,
          },
        ]}
        title={props.name}
        type="actions"
      >
        <div className="headerActions">
          <Daterange defaultValue={dateRangeOutput} onDaterangeChange={actions.setGlobalDateRange} />
        </div>
      </PageHeader>

      <div className={analyticsStyles.oneTwoGrid}>
        <EntityAverageWidget entity={entity} />
        <SkillsBreakdownWidget entity={entity} path={props.path} />
      </div>

      <HeatmapWidget entity={entity} />

      <RankedEntitiesWidget rankingLabel="people" entity={entity} />

      <CourseEngagementWidget entity={entity} isLearner={false} />

      <SkillScoreOverTimeWidget entity={entity} />

      <DailyActivityWidget entity={entity} />
    </div>
  );
}
