import { IconProps } from '@keplerco/core';
import React from 'react';

export function QuestionnaireIcon({ size = 80 }: IconProps): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 80 80" fill="none">
      <circle cx="40.0001" cy="40" r="39.3921" stroke="var(--blue)" strokeWidth="1.21586" />
      <mask id="mask0_3078_16305" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="7" y="7" width="66" height="66">
        <circle cx="40" cy="40" r="33" fill="var(--black)" />
      </mask>
      <g mask="url(#mask0_3078_16305)">
        <circle cx="39.8282" cy="39.8282" r="32.8282" fill="var(--white)" />
        <circle cx="39.8282" cy="39.8282" r="32.8282" fill="var(--blue)" />
        <path
          d="M13.4868 58.5464H27.1698L31.6572 60.5118L35.5943 60.8608L37.5156 55.015L34.2075 53.8861L32.2138 59.5087L31.2012 59.0873L33.1163 53.5653L29.4214 52.754C28.8302 54.5087 28.1289 56.2068 27.5472 57.9961L30.7138 58.4804C31.0755 57.4647 31.4339 56.449 31.7956 55.4332C32.1761 54.3641 32.5566 53.2981 32.9339 52.2289C32.1603 51.8673 31.2987 51.493 30.5472 51.1C31.6949 51.2226 32.9622 51.4522 34.1163 51.6283C34.3804 51.6691 34.283 51.6377 34.4276 51.2163C35.7389 47.3673 36.937 43.3328 40.8017 41.2951C44.3268 39.4366 48.135 39.1347 49.3708 34.575C50.2261 31.4178 48.4966 28.6003 45.4778 27.5091C44.9149 27.3236 43.5281 27.2009 42.9589 27.3456C45.0029 28.66 45.8111 30.4713 45.7419 32.8423C45.5658 38.707 33.4088 37.8454 30.6918 50.4773C31.3993 50.6723 32.9434 50.7761 33.7484 50.8641L34.0786 50.8987L38.1037 53.0622C39.0345 50.0182 40.3238 46.4365 43.3929 45.0403C47.6413 43.1001 51.8519 42.273 53.4651 37.1473C54.594 33.5625 53.9934 29.3613 51.8833 26.2387C49.657 22.9431 46.1224 21.289 42.2042 22.701C38.8615 23.9116 37.2012 27.0563 35.6792 30.0594C35.393 30.6223 35.3961 30.4619 36.0282 30.7889C36.8898 31.2354 37.7515 31.6726 38.6382 32.0593C40.0816 29.5436 39.7357 29.1946 42.5187 27.5563C39.4684 27.2701 38.8741 29.9179 37.7169 32.1191C37.1603 32.0373 35.0251 31.7323 34.6446 31.5499C33.6698 31.0845 32.5723 30.5751 31.6258 30.0688C31.3522 29.921 31.7075 30.0279 31.7861 30.0342C32.8459 30.1474 33.8301 30.3361 34.9056 30.4053C35.3836 29.6663 35.3427 29.8204 34.2452 29.7323C33.4748 29.6663 32.5503 29.6537 31.8081 29.4933C33.566 22.3331 39.0219 21.9588 45.2136 22.0029C63.1033 22.0658 49.6117 22.0029 67.5045 22.0029"
          stroke="var(--black)"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  );
}
