import React, { useEffect, useState } from 'react';
import { FetchType } from '../../../../../../enums';
import { UniqueEntity } from '../../../../../../models';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { ManagePersonPermissionFocusPanel } from './manage-person-permission-focus-panel';
import { IManagePersonPermissionsCascadingPanelsProps, ManagePersonPermissionsCascadingPanelIds } from '../manage-person-panels.models';
import { ManagePersonPermissionsFocusPanel } from './manage-person-permissions-focus-panel/manage-person-permissions-focus-panel';
import { PagePath } from '../../../../../../navigation/navigation.enums';
import { CascadingPanel, CascadingPanels, useCascadingPanelsConnector } from '@keplerco/core';

export function ManagePersonPermissionsCascadingPanels(props: IManagePersonPermissionsCascadingPanelsProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const { openPanelIds, next, previous, close, closeTo } = useCascadingPanelsConnector();

  const [company, setCompany] = useState<UniqueEntity>();

  useEffect(() => {
    next(ManagePersonPermissionsCascadingPanelIds.Sections);

    async function getData() {
      actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });
      const data = await actions.getCompany(companyVariables.slug!);
      if (!!data) setCompany({ name: data.companyName, slug: data.companySlug });
      actions.stopLoader(PagePath.administrationPeople);
    }

    getData();
  }, [companyVariables.slug]);

  return (
    <CascadingPanels
      openPanelIds={openPanelIds}
      onClosePanel={id => {
        if (id !== ManagePersonPermissionsCascadingPanelIds.Sections) return closeTo(ManagePersonPermissionsCascadingPanelIds.Sections);
        close(id);
        props.onCancel();
      }}
    >
      <CascadingPanel id={ManagePersonPermissionsCascadingPanelIds.Sections}>
        <ManagePersonPermissionsFocusPanel
          person={props.person}
          permissions={props.permissions}
          onClickSection={section => next(section)}
          onCancel={() => {
            close(ManagePersonPermissionsCascadingPanelIds.Sections);
            props.onCancel();
          }}
        />
      </CascadingPanel>

      <CascadingPanel id={ManagePersonPermissionsCascadingPanelIds.Administration}>
        <ManagePersonPermissionFocusPanel
          name="Administration"
          company={company}
          person={props.person}
          permission={props.permissions?.administration}
          onCancel={() => previous()}
          onSave={() => {
            props.onSave();
            previous();
          }}
        />
      </CascadingPanel>

      <CascadingPanel id={ManagePersonPermissionsCascadingPanelIds.Analytics}>
        <ManagePersonPermissionFocusPanel
          name="Analytics"
          company={company}
          person={props.person}
          permission={props.permissions?.analytics}
          onCancel={() => previous()}
          onSave={() => {
            props.onSave();
            previous();
          }}
        />
      </CascadingPanel>

      <CascadingPanel id={ManagePersonPermissionsCascadingPanelIds.AssessmentManagement}>
        <ManagePersonPermissionFocusPanel
          name="Assessment Management"
          company={company}
          person={props.person}
          permission={props.permissions?.assessments}
          onCancel={() => previous()}
          onSave={() => {
            props.onSave();
            previous();
          }}
        />
      </CascadingPanel>

      <CascadingPanel id={ManagePersonPermissionsCascadingPanelIds.LearningManagement}>
        <ManagePersonPermissionFocusPanel
          name="Learning Management"
          company={company}
          person={props.person}
          permission={props.permissions?.learningManagement}
          onCancel={() => previous()}
          onSave={() => {
            props.onSave();
            previous();
          }}
        />
      </CascadingPanel>

      <CascadingPanel id={ManagePersonPermissionsCascadingPanelIds.RoleSkillManagement}>
        <ManagePersonPermissionFocusPanel
          name="Role & Skill Management"
          company={company}
          person={props.person}
          permission={props.permissions?.roleSkillManagement}
          onCancel={() => previous()}
          onSave={() => {
            props.onSave();
            previous();
          }}
        />
      </CascadingPanel>
    </CascadingPanels>
  );
}
