import React, { useEffect, useState } from 'react';
import { IStepperProps } from './stepper.models';
import { Steps } from './steps/steps';
import { FauxChild } from '@keplerco/core';
import { IStepProps } from './step/step.models';

// TODO: how should we handle loading?
export function Stepper({ setStepCount, activeStepIndex, goTo, children }: React.PropsWithChildren<IStepperProps>) {
  const [steps, setSteps] = useState<React.PropsWithChildren<IStepProps>[]>();

  useEffect(() => {
    const fauxChildren: FauxChild<IStepProps>[] = (Array.isArray(children) ? children : [children]) as any;
    const steps = fauxChildren.map(fauxChild => fauxChild.props).filter(step => !!step.label && !!step.children);
    setSteps(steps);
    setStepCount(steps.length);
  }, [children]);

  return (
    <React.Fragment>
      <Steps steps={steps ?? []} activeStepIndex={activeStepIndex} goTo={goTo} />
      {!!steps?.length && steps[activeStepIndex].children}
    </React.Fragment>
  );
}
