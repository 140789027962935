import { PageHeader } from '@keplerco/core';
import React from 'react';
import { PagePath } from '../../../../navigation/navigation.enums';
import { useParams } from 'react-router-dom';
import { useAppActions, useAppState } from '../../../../overmind';
import { Learner, SkillAssessment } from '../../../../models';
import { PageSkeletonLoader } from '../../../../components/general/loading-state/loader-layers/page-loader-layer/page-skeleton-loader';
import { EmptyState } from '../../../../components/general/empty-state/empty-state';
import { SunburstWidget } from '../../../../widgets/analytics/sunburst/sunburst.widget';
import { AssessmentSearchParams, PermissionSearchParams } from '../../../../models/overmind/search-params';
import { OrganizationLevelType } from '../../../../enums';
import { ScoreOverviewWidget } from '../../../../widgets/analytics/score-overview/score-overview.widget';
import { AssessmentType } from '../../../../enums/assessment-type';
import AverageScoreSkillWidget from '../../../../widgets/analytics/average-score-skill/average-score-per-skill.widget';
import { AnalyticsFiltersItems } from './assessment.models';
import { FlagIcon } from '../../../../design/icons/flag.icon';
import analyticsStyles from '../../analytics.module.css';
import { YearsExperienceWidget } from '../versus-score/years-experience.widget';
import { FrequencyUseWidget } from '../versus-score/frequency-use.widget';
import { SkillVersusAssessmentScoreWidget } from '../skill-versus-assessment-score/skill-versus-assessment-score.widget';
import AssessmentRatingsWidget from '../../../../widgets/analytics/assessment-ratings/assessment-ratings.widget';

export function AssessmentPersonPage(): React.ReactElement {
  const { assessmentSlug, personSlug } = useParams();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [assessment, setAssessment] = React.useState<SkillAssessment>();
  const [person, setPerson] = React.useState<Learner>();
  const [filtersItems, setFiltersItems] = React.useState<AnalyticsFiltersItems>({
    departmentItems: [],
    teamItems: [],
    roleItems: [],
    skillItems: [],
    countryItems: [],
  });

  const permissionSearchParams: PermissionSearchParams | undefined = React.useMemo(() => {
    if (!companyVariables.slug || !person?.slug) return undefined;

    return {
      organizationLevel: OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: person.slug,
    };
  }, [companyVariables.slug, person?.slug]);
  const assessmentSearchParams: AssessmentSearchParams | undefined = React.useMemo(() => {
    if (!assessment || !companyVariables.slug) return undefined;

    return {
      assessmentType: assessment.assessmentType,
      assessmentSlug: assessment.slug,
    };
  }, [assessment]);

  React.useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const assessmentResponse = await actions.getAssessment({ companySlug: companyVariables.slug!, assessmentSlug: assessmentSlug! });
      setAssessment(assessmentResponse);

      const personData = await actions.getLearnerBySlug(personSlug!);
      setPerson(personData);

      setIsLoading(false);
    }

    getData();
  }, [assessmentSlug, personSlug]);

  React.useEffect(() => {
    async function getFiltersItems() {
      if (!assessmentSlug || !companyVariables.slug) return;

      const [departmentsResponse, teamsResponse, rolesResponse, skillsResponse, countriesResponse] = await Promise.all([
        actions.getAssessmentAnalyticsDepartments(assessmentSlug),
        actions.getAssessmentAnalyticsTeams(assessmentSlug),
        actions.getAssessmentAnalyticsRoles(assessmentSlug),
        actions.getAssessmentAnalyticsSkills(assessmentSlug),
        actions.getCountries(), // TODO: does this need an assessment analytics API?
      ]);

      setFiltersItems({
        departmentItems: departmentsResponse?.map(department => ({ label: department.name, value: department.slug! })) ?? [],
        teamItems: teamsResponse?.map(team => ({ label: team.name, value: team.slug! })) ?? [],
        roleItems: rolesResponse?.map(role => ({ label: role.name, value: role.slug! })) ?? [],
        skillItems: skillsResponse?.map(skill => ({ label: skill.name, value: skill.slug! })) ?? [],
        countryItems:
          countriesResponse?.map(country => ({
            label: country.name,
            value: String(country.id),
            icon: <FlagIcon isoCode={country.iso} />,
          })) ?? [],
      });
    }

    getFiltersItems();
  }, [assessmentSlug, companyVariables.slug]);

  if (isLoading) return <PageSkeletonLoader />;

  return (
    <div className="wrapper stack" style={{ gap: 24 }}>
      <PageHeader
        title={person?.fullName ?? 'Person'}
        breadcrumbs={[
          { name: `Analytics`, url: PagePath.analysisBase },
          { name: `Assessments`, url: `${PagePath.analyticsBase}${PagePath.analyticsAssessments.replace(':companySlug', companyVariables.slug!)}` },
          { name: assessment?.name ?? `Assessment`, url: `${PagePath.analyticsBase}${PagePath.analyticsAssessment.replace(':companySlug', companyVariables.slug!).replace(':assessmentSlug', assessmentSlug!)}` },
          { name: person?.fullName ?? 'Person', url: location.pathname },
        ]}
        divider
      />

      {!!permissionSearchParams && !!assessmentSearchParams ? (
        <React.Fragment>
          <ScoreOverviewWidget baseSearchParams={{ ...permissionSearchParams, ...assessmentSearchParams }} />

          <SunburstWidget path={PagePath.analyticsAssessmentPerson} chartTitle="Your score for each skill included in this assessment:" permissionSearchParams={permissionSearchParams} assessmentSearchParams={assessmentSearchParams} />

          {assessmentSearchParams.assessmentType === AssessmentType.Questionnaire && <AverageScoreSkillWidget baseSearchParams={{ ...permissionSearchParams, ...assessmentSearchParams }} filtersItems={filtersItems} />}

          {assessmentSearchParams.assessmentType !== AssessmentType.Questionnaire && <SkillVersusAssessmentScoreWidget userFullName={person?.fullName ?? 'Person'} baseSearchParams={{ ...permissionSearchParams, ...assessmentSearchParams }} />}

          {assessmentSearchParams.assessmentType === AssessmentType.PeerEndorsement && <AssessmentRatingsWidget assessmentSlug={assessmentSearchParams.assessmentSlug} learnerSlug={permissionSearchParams.learnerSlug!} />}

          {assessmentSearchParams.assessmentType === AssessmentType.PeerEndorsement && (
            <div className={analyticsStyles.oneOneGrid}>
              <YearsExperienceWidget baseSearchParams={{ ...permissionSearchParams, ...assessmentSearchParams }} />
              <FrequencyUseWidget baseSearchParams={{ ...permissionSearchParams, ...assessmentSearchParams }} />
            </div>
          )}
        </React.Fragment>
      ) : (
        <EmptyState />
      )}
    </div>
  );
}
