import React from 'react';
import { INotificationLayoutProps } from '../../../../notifications/notifications.models';
import { themedAssetUrl } from '../../../../library/helpers/themed-asset-url';
import { Button, KeplerPoints, UnorderedList, UnorderedListItem } from '@keplerco/core';

export function MissionBriefingModalLayout(props: INotificationLayoutProps): JSX.Element {
  return (
    <React.Fragment>
      <div className="dialogBackground" style={{ zIndex: 2 }}>
        <object data={themedAssetUrl('backgrounds/stars.background.svg')} type="image/svg+xml">
          <img src={themedAssetUrl('backgrounds/stars.background.svg')} alt="" />
        </object>
      </div>

      <div className="modalContent" style={{ position: 'relative', zIndex: 3 }}>
        <div className="badgeIconContainer">
          <object data="/badges/badge_MissionBriefing.svg" type="">
            <img src="/badges/badge_MissionBriefing.svg" alt="" />
          </object>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'center' }}>
          <h2 style={{ textAlign: 'center' }}>
            Mission Briefing
          </h2>

          <UnorderedList>
            <UnorderedListItem>The closer the planet, the more vital the skills that you develop</UnorderedListItem>

            <UnorderedListItem>Travel between planets learning new skills</UnorderedListItem>

            <UnorderedListItem>
              Earn
              <KeplerPoints forceFontWeight="bold" />
              for completing challenges
            </UnorderedListItem>
          </UnorderedList>

          <Button chubby type={'button'} filled arrow={false} onClick={props.onAction}>
            Let's do this!
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
