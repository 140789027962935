import React from 'react';
import { DropdownSelect, DropdownSelectItem } from '@keplerco/core';

export function AnalyticsSortBy({ items, value, onChange }: { items: DropdownSelectItem[]; value?: string | number | undefined; onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined }): React.ReactElement {
  return (
    <div style={{ width: 150 }}>
      {/* TODO: defaultValue */}
      <DropdownSelect label="Sort by" items={items} value={value} onChange={onChange} responsive />
    </div>
  );
}
