import React from 'react';
import { SkeletonLoader } from '../../loading-state/loaders/skeleton-loader/skeleton-loader';

export function StepSkeleton(): JSX.Element {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 24, width: '100%' }}>
      <SkeletonLoader height="600px" />
      <SkeletonLoader height="50px" />
    </div>
  );
}

