import { Chip } from '@keplerco/core';
import React from 'react';

export function AssessmentStatusChip({ assessment }: { assessment: { isActive?: boolean; isComplete?: boolean; isArchived?: boolean } }): React.ReactElement {
  if (assessment.isActive) {
    return <Chip themeColour="a">Active</Chip>;
  }

  if (assessment.isComplete) {
    return <Chip themeColour="g">Closed</Chip>;
  }

  if (assessment.isArchived) {
    return <Chip>Archived</Chip>;
  }

  return <Chip customTheme={{ backgroundColor: 'background', borderColor: 'borders', color: 'text' }}>Draft</Chip>;
}
