import { AssessmentPreferencesResponse } from '../../../../../models/overmind/assessment-preferences';
import { IPerson } from '../../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';

export enum CascadingFocusPanelIds {
  SelectEntity = 'SelectEntity',
  SelectPeople = 'SelectPeople',
}

export enum ErrorMessage {
  NoManagerSelected = 'Please select a manager',
  NoPersonSelected = 'Please select at least one person.',
}

export interface IPeopleStepLayoutProps {
  assessmentSlug: string;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  preferences: AssessmentPreferencesResponse;
  people: IPerson[] | undefined;
  setPeople: (people: IPerson[]) => void;
  setRoleSlug: (roleSlug: string | undefined) => void;
  onBack: () => void;
  onNext: () => void;
}