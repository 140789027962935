import React, { useEffect, useState } from 'react';
import { colourString, ThemeColours } from '@keplerco/core';
import { useAppActions } from '../../../overmind';
import { DonutSlices } from '../../../widgets/analytics/overview/overview.models';
import { DonutChart } from '../../../components/charts/donut/donut.chart';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { AssessmentSearchParams, FilteringSearchParams, PermissionSearchParams } from '../../../models/overmind/search-params';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';
import { WidgetCard } from '../analytics-filters/widget-card';

type DonutChartData = {
  donutLabel: string;
  slices: DonutSlices;
  total?: number;
};

const DEFAULT_SEARCH_PARAMS: FilteringSearchParams = {
  departmentSlugs: undefined,
  teamSlugs: undefined,
  learnerSlugs: undefined,
  roleSlugs: undefined,
  skillSlugs: undefined,
  countryIds: undefined,
};

export default function AssessmentCompletionWidget({ baseSearchParams }: { baseSearchParams: AssessmentSearchParams & PermissionSearchParams }) {
  const actions = useAppActions();

  const [isLoading, setIsLoading] = useState(true);
  const [donutChartData, setDonutChartData] = useState<DonutChartData>();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const response = await actions.assessmentTotalCompletions({ ...baseSearchParams, ...DEFAULT_SEARCH_PARAMS });
      if (response) {
        const slices: DonutSlices = [
          { label: `Not started`, value: response?.notStartedCount, colour: `default` },
          { label: `In Progress`, value: response?.inProgressCount, colour: `baby-blue` },
          { label: `Complete`, value: response?.completedCount, colour: `g` },
        ];

        setDonutChartData({
          donutLabel: 'Assessment Completion',
          slices,
          total: response.completedCount + response.inProgressCount + response.notStartedCount,
        });
      } else setDonutChartData(undefined);

      setIsLoading(false);
    }

    getData();
  }, [baseSearchParams]);

  return (
    <SkeletonOverlay isLoading={isLoading} width="100%" height="100%">
      <WidgetCard title="Assessment completion" style={{ height: '100%' }}>
        {donutChartData ? (
          <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', height: '100%', paddingBottom: 24 }}>
            <DonutChart dataPoints={donutChartData?.slices.map(slice => ({ name: slice.label, value: slice.value })) ?? []} fill={donutChartData?.slices.map(slice => slice.colour)} label="Participants" total={donutChartData?.total} showLegend />
            <div style={{ display: 'flex', alignItems: 'center', gap: 30, justifyContent: 'space-evenly', flex: '1 1 0' }}>
              {donutChartData?.slices.map(slice => (
                <React.Fragment key={slice.label}>
                  <IconProgressMap fill={slice.colour} value={slice.value} label={slice.label} />
                </React.Fragment>
              ))}
            </div>
          </div>
        ) : (
          <EmptyState />
        )}
      </WidgetCard>
    </SkeletonOverlay>
  );
}

function IconProgressMap({ value, fill, label }: { value: number; fill: ThemeColours; label: string }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61" fill="none">
        <g filter="url(#filter0_i_363_27252)" data-figma-bg-blur-radius="6.96496">
          <circle cx="30.6918" cy="30.7255" r="30.2102" transform="rotate(90 30.6918 30.7255)" fill={colourString(fill)} />
        </g>
        <text x="50%" y="53%" dominantBaseline="middle" textAnchor="middle" fill="var(--text)" fontWeight="bold">
          {value}
        </text>
      </svg>
      <div className="caption" style={{ marginTop: '8px' }}>
        {label}
      </div>
    </div>
  );
}
