import { PageHeader, Tabs, Tab } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { PagePath } from '../../../../navigation/navigation.enums';
import { AssessmentProgressLayout } from './assessment-progress/assessment-progress.layout';
import { AssessmentDetailsLayout } from './assessment-details/assessment-details.layout';
import { AssessmentResultsLayout } from './assessment-results/assessment-results.layout';
import { useParams } from 'react-router-dom';
import { useAppActions, useAppState } from '../../../../overmind';
import { SkillAssessment } from '../../../../models';
import { PageSkeletonLoader } from '../../../../components/general/loading-state/loader-layers/page-loader-layer/page-skeleton-loader';
import { EmptyState } from '../../../../components/general/empty-state/empty-state';
import { AssessmentSearchParams, PermissionSearchParams } from '../../../../models/overmind/search-params';
import { OrganizationLevelType } from '../../../../enums';
import { extractHighestOrganizationLevel } from '../../../../library/helpers/permissions/extract-highest-organization-level';
import { FlagIcon } from '../../../../design/icons/flag.icon';
import { AnalyticsFiltersItems } from './assessment.models';

enum AssessmentAnalyticsTab {
  Details = 'Details',
  Progress = 'Progress',
  Results = 'Results',
}

export function AssessmentPage(): JSX.Element {
  const { assessmentSlug } = useParams();
  const actions = useAppActions();
  const { companyVariables, permissions, user } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<AssessmentAnalyticsTab>(AssessmentAnalyticsTab.Details);
  const [assessmentData, setAssessmentData] = useState<SkillAssessment>();
  const [filtersItems, setFiltersItems] = useState<AnalyticsFiltersItems>({
    departmentItems: [],
    teamItems: [],
    roleItems: [],
    skillItems: [],
    countryItems: [],
  });

  const organizationLevel: OrganizationLevelType = React.useMemo(
    () => (user?.isSystemAdmin ? OrganizationLevelType.Company : extractHighestOrganizationLevel(permissions?.analytics.organizationLevels)?.organizationLevel ?? OrganizationLevelType.Learner),
    [user?.isSystemAdmin, permissions?.analytics.organizationLevels]
  );
  const baseSearchParams: (AssessmentSearchParams & PermissionSearchParams) | undefined = React.useMemo(() => {
    if (!assessmentData || !companyVariables.slug) return undefined;

    return {
      assessmentType: assessmentData.assessmentType,
      assessmentSlug: assessmentData.slug,
      organizationLevel,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
    };
  }, [assessmentData, organizationLevel, companyVariables.slug]);

  useEffect(() => {
    async function getAssessmentData() {
      if (!assessmentSlug || !companyVariables.slug) return;

      setIsLoading(true);
      const response = await actions.getAssessment({
        companySlug: companyVariables.slug,
        assessmentSlug,
      });
      setAssessmentData(response);
      setIsLoading(false);
    }

    getAssessmentData();
  }, [assessmentSlug, companyVariables.slug!]);

  useEffect(() => {
    async function getFiltersItems() {
      if (!assessmentSlug || !companyVariables.slug) return;

      const [departmentsResponse, teamsResponse, rolesResponse, skillsResponse, countriesResponse] = await Promise.all([
        actions.getAssessmentAnalyticsDepartments(assessmentSlug),
        actions.getAssessmentAnalyticsTeams(assessmentSlug),
        actions.getAssessmentAnalyticsRoles(assessmentSlug),
        actions.getAssessmentAnalyticsSkills(assessmentSlug),
        actions.getCountries(), // TODO: does this need an assessment analytics API?
      ]);

      setFiltersItems({
        departmentItems: departmentsResponse?.map(department => ({ label: department.name, value: department.slug! })) ?? [],
        teamItems: teamsResponse?.map(team => ({ label: team.name, value: team.slug! })) ?? [],
        roleItems: rolesResponse?.map(role => ({ label: role.name, value: role.slug! })) ?? [],
        skillItems: skillsResponse?.map(skill => ({ label: skill.name, value: skill.slug! })) ?? [],
        countryItems:
          countriesResponse?.map(country => ({
            label: country.name,
            value: String(country.id),
            icon: <FlagIcon isoCode={country.iso} />,
          })) ?? [],
      });
    }

    getFiltersItems();
  }, [assessmentSlug, companyVariables.slug]);

  if (isLoading) return <PageSkeletonLoader />;

  return (
    <div className="wrapper stack" style={{ gap: 24 }}>
      <PageHeader
        title={assessmentData?.name ?? 'Assessment'}
        breadcrumbs={[
          { name: `Analytics`, url: PagePath.analysisBase },
          { name: `Assessments`, url: `${PagePath.analyticsBase}${PagePath.analyticsAssessments.replace(':companySlug', companyVariables.slug!)}` },
          { name: `${assessmentData?.name ?? 'Assessment'}`, url: location.pathname },
        ]}
      />

      <div style={{ width: '100%' }}>
        <Tabs static hideMobile={false}>
          <Tab id={AssessmentAnalyticsTab.Details} name={AssessmentAnalyticsTab.Details} active={activeTab === AssessmentAnalyticsTab.Details} onClick={() => setActiveTab(AssessmentAnalyticsTab.Details)} />
          <Tab id={AssessmentAnalyticsTab.Progress} name={AssessmentAnalyticsTab.Progress} active={activeTab === AssessmentAnalyticsTab.Progress} onClick={() => setActiveTab(AssessmentAnalyticsTab.Progress)} />
          <Tab id={AssessmentAnalyticsTab.Results} name={AssessmentAnalyticsTab.Results} active={activeTab === AssessmentAnalyticsTab.Results} onClick={() => setActiveTab(AssessmentAnalyticsTab.Results)} />
        </Tabs>
      </div>

      {!!assessmentData && !!baseSearchParams ? (
        <React.Fragment>
          {activeTab === AssessmentAnalyticsTab.Details && <AssessmentDetailsLayout assessment={assessmentData} baseSearchParams={baseSearchParams} />}

          {activeTab === AssessmentAnalyticsTab.Progress && <AssessmentProgressLayout baseSearchParams={baseSearchParams} departmentItems={filtersItems.departmentItems} teamItems={filtersItems.teamItems} roleItems={filtersItems.roleItems} countryItems={filtersItems.countryItems} />}

          {activeTab === AssessmentAnalyticsTab.Results && <AssessmentResultsLayout filtersItems={filtersItems} baseSearchParams={baseSearchParams} />}
        </React.Fragment>
      ) : (
        <EmptyState />
      )}
    </div>
  );
}
