import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppActions } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { LoginInput } from '../../../models/view/login-input';
import { AccountPageLoaderLayer } from '../../../components/general/loading-state/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { FetchType } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';
import { Button, EmailValidator, FormControl, Textfield, useMatchScreenWidth } from '@keplerco/core';
import accountStyles from '../account.module.css';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import classNames from 'classnames';
import { TertiaryButton } from '../../../components/general/tertiary-button/tertiary-button';

export function ActivateAccountPage(): JSX.Element {
  const navigate = useNavigate();

  const actions = useAppActions();

  const { control, handleSubmit } = useForm({ reValidateMode: 'onChange' });

  const isMobile = useMatchScreenWidth('mobile');

  const [sent, setSent] = useState(false);

  return (
    <div className={accountStyles.accountBackground} style={{ backgroundImage: `url(${themedAssetUrl('backgrounds/pattern.background.svg')})` }}>
      <div className={accountStyles.accountPage}>
        <div className={classNames('card', accountStyles.accountCard)}>
          <AccountPageLoaderLayer path={PagePath.accountActivate}>
            {!isMobile && (
              <div className={accountStyles.accountNavigationToggler}>
                <span className="body">Already have an account?</span>
                {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountLogin}`)}>
                  Sign in
                </TertiaryButton>
              </div>
            )}

            <h1>Activate your account</h1>

            {!!sent && (
              <div className={accountStyles.accountInstructions}>
                <h3>Thank you, please check your email for instructions to activate your account</h3>
              </div>
            )}

            {!sent && (
              <React.Fragment>
                <h3>Enter your company email address and we'll send you instructions to activate your account.</h3>

                <form
                  id="activateAccount"
                  className={accountStyles.accountForm}
                  autoComplete="off"
                  onSubmit={handleSubmit(async fieldValues => {
                    actions.startLoader({ path: PagePath.accountActivate, type: FetchType.PageFetching });
                    setSent(await actions.requestAccountActivation(fieldValues as LoginInput));
                    actions.stopLoader(PagePath.accountActivate);
                  })}
                >
                  <FormControl paddingBottom={0} control={control} name="email" rules={new EmailValidator('Enter your email')} render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Email" responsive />} />

                  <Button type="button" fullWidthMobile>
                    Send Instructions
                  </Button>

                  {isMobile && (
                    <div className={classNames(accountStyles.accountNavigationToggler, accountStyles.bottom)}>
                      <span className="body">Already have an account?</span>
                      {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                      <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountLogin}`)}>
                        Sign in
                      </TertiaryButton>
                    </div>
                  )}
                </form>
              </React.Fragment>
            )}
          </AccountPageLoaderLayer>
        </div>
      </div>
    </div>
  );
}
