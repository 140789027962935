import { Anchor, Button, PanelHeader } from '@keplerco/core';
import React from 'react';
import { IComparisonToolPanelProps } from './comparison-tool.models';

export function ComparisonToolPanel({ supertitle, title, subtitle, list, onBack, onAdd }: Required<Omit<IComparisonToolPanelProps, 'onClose'>>): JSX.Element {
  return (
    <div className="panelContent" style={{ gap: 30 }}>
      <PanelHeader supertitle={supertitle} title={title} subtitle={subtitle} divider />

      <div>{list}</div>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button type="button" onClick={onAdd}>
          Add
        </Button>
      </footer>
    </div>
  );
}
