import React from 'react';
import { themedAssetUrl } from '../../../../library/helpers/themed-asset-url';
import { getSubdomain } from '../../../../library/helpers/get-subdomain';
import { Button, PanelHeader, KeplerPoints, UnorderedList, UnorderedListItem } from '@keplerco/core';

// TODO: I don't think we're actually displaying this?
export function KeplerExplainerFocusPanelLayout(props: { onClick?: () => void }): JSX.Element {
  return (
    <React.Fragment>
      {getSubdomain() === 'app' && (
        <div className="dialogBackground" style={{ zIndex: 2 }}>
          <object data={themedAssetUrl('backgrounds/stars.background.svg')} type="image/svg+xml">
            <img src={themedAssetUrl('backgrounds/stars.background.svg')} alt="" />
          </object>
        </div>
      )}

      <div className="panelContent" style={{ zIndex: 3, position: 'relative', gap: 30 }}>
        <PanelHeader title="Kepler points explained" />

        <div>
          <h3>
            Kepler Points(
            <KeplerPoints forceFontWeight="bold" />
            )are used to give an indication of your progress on the Kepler platform.
          </h3>

          <UnorderedList>
            <UnorderedListItem>The first set of KP that you achieve are for completing your self-assessment, and these KP are based on your current skill-set and skill rating.</UnorderedListItem>

            <UnorderedListItem>Then, after every activity on the Kepler platform that you complete, you are awarded more KP. We'll also give you KP for completing daily and weekly goals, and completing challenges and study streaks.</UnorderedListItem>
          </UnorderedList>
        </div>

        <footer className="panelFooter">
          <Button type="button" filled arrow={false} onClick={props.onClick}>
            Great, thanks!
          </Button>
        </footer>
      </div>
    </React.Fragment>
  );
}
