import React from 'react';
import styles from './astronaut-loader.module.css';

export function AstronautLoader(): React.ReactElement {
  return (
    <div className={styles.loader}>
      <object data={'/graphics/loading-state.graphic.svg'} type="image/svg+xml">
        <img src={'graphics/loading-state.graphic.svg'} alt="" />
      </object>

      <h4>Loading...</h4>
    </div>
  );
}
