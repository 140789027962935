import { UniqueEntity } from '../../../models';

export enum CohortType {
  Department = 'Department',
  Team = 'Team',
  Role = 'Role',
  People = 'People',
}

export interface CohortData {
  entities: UniqueEntity[];
  totalPages: number;
  totalCount: number;
}

export interface ISelectCohortLayoutProps {
  supertitle?: string;
  title: string;
  subtitle?: string;
  cohortTypes?: CohortType[];
  selectedCohortType: CohortType;
  setSelectedCohortType: (cohortType: CohortType) => void;
  selectedEntity: UniqueEntity | undefined;
  setSelectedEntity: (entity: UniqueEntity | undefined) => void;
  onBack: () => void;
  onNext: () => void;
}
