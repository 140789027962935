import React from 'react';
import { Chip } from '@keplerco/core';
import styles from './assessment-configuration.module.css';
import classNames from 'classnames';
import { SkeletonOverlay } from '../../../../../components/general/loading-state/skeleton-overlay';

const CUSTOM_THEME = { backgroundColor: 'background', borderColor: 'baby-blue', color: 'text' };

export function AssessmentConfigurationWidget({ isLoading, forcedCompletion, allowReassessment }: { isLoading: boolean; allowReassessment: boolean; forcedCompletion: boolean }): JSX.Element {
  return (
    <SkeletonOverlay isLoading={isLoading} width="100%" height="100%">
      <div className={classNames('card', styles.card)}>
        <div className={styles.chips}>
          <Chip customTheme={CUSTOM_THEME}>{allowReassessment ? 'Multiple attempts' : 'Single attempt'}</Chip>
          <Chip customTheme={CUSTOM_THEME}>{forcedCompletion ? 'Blocked progress' : 'Flexible completion'}</Chip>
        </div>

        <div>Configuration</div>
      </div>
    </SkeletonOverlay>
  );
}
