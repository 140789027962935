import { DropdownSelectItem, ThemeColours } from '@keplerco/core';
import { AssessmentType } from '../../../enums/assessment-type';
import { CompletionStatus } from '../../../enums';

export const assessmentTypeItems: DropdownSelectItem[] = [
  { label: 'Peer Endorsement', value: AssessmentType.PeerEndorsement.toString() },
  { label: 'Questionnaire', value: AssessmentType.Questionnaire.toString() },
  { label: 'Fields Of Practice', value: AssessmentType.FieldsOfPractice.toString() },
];

export const statusItems: DropdownSelectItem[] = [
  { value: CompletionStatus.NotStarted.toString(), label: 'Not Started' },
  { value: CompletionStatus.InProgress.toString(), label: 'In Progress' },
  { value: CompletionStatus.Completed.toString(), label: 'Completed' },
];

export const sortByOptions: DropdownSelectItem[] = [
  { value: 'AlphabeticalAtoZ', label: 'A to Z' },
  { value: 'AlphabeticalZtoA', label: 'Z to A' },
  { value: 'ScoreLowest', label: 'Lowest score' },
  { value: 'ScoreHighest', label: 'Highest score' },
  { value: 'MostRecentAssigned', label: 'Most recent assigned' },
  { value: 'LeastRecentAssigned', label: 'Least recent assigned' },
];


export function getBorderColorByCompletionStatus(status: CompletionStatus): ThemeColours {
  switch (status) {
    case CompletionStatus.Completed:
      return 'g_1';
    case CompletionStatus.InProgress:
      return 'a_1';
    case CompletionStatus.NotStarted:
      return 'borders';
    default:
      return 'default_1';
  }
}

export function formatAssessmentTypeLabel(label: string): string {
  return label.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}
