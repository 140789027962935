import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { LearnerSkillAssessment } from '../../../models/skill-assessment-config';
import { Anchor, Button, colourString, DropdownSelect, Filters, formatDate, Modal, PageHeader, Pager, SearchBar, SkillLevel, SkillPercentage } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';
import { AssessmentType } from '../../../enums/assessment-type';
import { AssessmentsSearchRequest } from '../../../models/overmind/search-request';
import { CompletionStatus } from '../../../enums';
import { assessmentTypeItems, formatAssessmentTypeLabel, getBorderColorByCompletionStatus, sortByOptions, statusItems } from './your-assessments.helpers';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { ConfirmationModalLayout } from '../../../widgets/layouts';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import styles from './your-assessments.module.css';
import classNames from 'classnames';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';
import { Profile } from '../../../models/profile';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { CompletionStatusChip } from '../../../components/chips/completion-status.chip';

const PAGE_SIZE = 10;

function sortAssessments(assessments: LearnerSkillAssessment[]): LearnerSkillAssessment[] {
  return [...assessments].sort((a, b) => {
    if (a.isActive) {
      if (!b.isActive) return -1;
      if (!a.score && !!b.score) return -1;
      if (!!a.score && !b.score) return 1;
      if (a.allowReassessment && !b.allowReassessment) return -1;
      if (!a.allowReassessment && b.allowReassessment) return 1;
      return 0;
    }
    if (!a.isActive) {
      if (!!b.isActive) return 1;
      if (!a.score && !!b.score) return -1;
      if (!!a.score && !b.score) return 1;
      if (a.allowReassessment && !b.allowReassessment) return -1;
      if (!a.allowReassessment && b.allowReassessment) return 1;
      return 0;
    }
    return 0;
  });
}

export function YourAssessmentsPage() {
  const keplerNavigate = useKeplerNavigate();
  const actions = useAppActions();
  const { companyVariables, user } = useAppState();
  const subdomain = getSubdomain();

  const defaultSearchParams = useMemo<AssessmentsSearchRequest>(
    () => ({
      companySlug: companyVariables.slug,
      sortAscending: true,
      pageSize: 99999,
      page: 1,
      learnerSlug: user?.learnerSlug,
      search: undefined,
      assessmentType: undefined,
      completionStatus: undefined,
      sortBy: undefined,
    }),
    [companyVariables.slug, user?.learnerSlug]
  );

  const [assessments, setAssessments] = useState<LearnerSkillAssessment[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [profile, setProfile] = useState<Profile | undefined>(undefined);
  const [reassessment, setReassessment] = useState<LearnerSkillAssessment | undefined>(undefined);

  const [request, setRequest] = useState<AssessmentsSearchRequest>(defaultSearchParams);

  const currentAssessments = useMemo(() => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return assessments.slice(startIndex, endIndex);
  }, [assessments, currentPage]);

  async function fetchAssessments(params: AssessmentsSearchRequest) {
    const response = await actions.getLearnerAssessments(params);
    if (!response) return;

    const sortedAssessments = sortAssessments(response);
    setAssessments(sortedAssessments);
    setTotalPages(Math.ceil(sortedAssessments.length / PAGE_SIZE));
    setIsLoading(false);
    setIsSearching(false);
    return response;
  }

  useEffect(() => {
    async function initPage() {
      setIsLoading(true);
      const profile = await actions.getProfile();
      setProfile(profile);

      await fetchAssessments(request);
    }

    initPage();
  }, []);

  async function handleSearch(event: FormEvent<HTMLInputElement>) {
    setIsSearching(true);
    const searchValue = (event.target as HTMLInputElement).value.trim() || undefined;

    const updatedRequest = {
      ...request,
      search: searchValue,
      page: 1,
    };

    setCurrentPage(1);
    setRequest(updatedRequest);
    await fetchAssessments(updatedRequest);

    setIsSearching(false);
  }

  async function clearFilters() {
    setIsLoading(true);
    setCurrentPage(1);
    setRequest(defaultSearchParams);
    await fetchAssessments(defaultSearchParams);
  }

  async function applyFilters() {
    setIsLoading(true);
    setCurrentPage(1);
    setRequest({ ...request, page: 1 });
    await fetchAssessments({ ...request, page: 1 });
  }

  // TODO BE needs to return these dates
  function generateDates(assessment: LearnerSkillAssessment) {
    const dates = [];

    if (assessment.dateCompleted && !assessment.isActive) {
      dates.push(`Completed: ${formatDate(new Date(assessment.dateCompleted))}`);
    }
    if (assessment.dateAssigned) {
      dates.push(`Assigned: ${formatDate(new Date(assessment.dateAssigned))}`);
    }

    return dates.length > 0 ? dates : null;
  }

  function renderButton(assessment: LearnerSkillAssessment) {
    switch (assessment.completionStatus) {
      case CompletionStatus.NotStarted:
        return (
          <Button
            onClick={async () => {
              const result = await actions.getSkillAssessmentConfiguration(assessment.assessmentSlug);
              if (!!result) keplerNavigate(PagePath.analysisBase);
            }}
            type={'button'}
          >
            Start
          </Button>
        );
      case CompletionStatus.InProgress:
        return (
          <Button
            onClick={async () => {
              const result = await actions.getSkillAssessmentConfiguration(assessment.assessmentSlug);
              if (!!result) keplerNavigate(PagePath.analysisBase);
            }}
            type={'button'}
          >
            Continue
          </Button>
        );
      case CompletionStatus.Completed:
        return assessment.allowReassessment ? (
          <>
            <Anchor onClick={() => setReassessment(assessment)}>Retake</Anchor>
            <Button onClick={() => keplerNavigate(`${PagePath.yourAssessmentsBase}${PagePath.yourAssessmentsAssessment.replace(':assessmentSlug', assessment.assessmentSlug)}`)} type={'button'}>
              View Results
            </Button>
          </>
        ) : (
          <Button onClick={() => keplerNavigate(`${PagePath.yourAssessmentsBase}${PagePath.yourAssessmentsAssessment.replace(':assessmentSlug', assessment.assessmentSlug)}`)} type={'button'}>
            View Results
          </Button>
        );
      case CompletionStatus.NoActionRequired:
        return <Button onClick={() => undefined} type={'button'} />;
      default:
        return null;
    }
  }

  function renderDates(assessment: LearnerSkillAssessment) {
    const dates = generateDates(assessment);
    if (!dates) return null;

    return (
      <div className={styles.datesContainer}>
        {dates.map(date => (
          <div className={classNames('caption', styles.dateText)} key={date}>
            {date}
          </div>
        ))}
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="pageWrapper wrapper">
        <PageHeader
          breadcrumbs={[
            {
              name: subdomain.includes('lpi') ? 'My Assessments' : 'Your Assessments',
              url: PagePath.yourAssessmentsBase,
            },
          ]}
          title={subdomain.includes('lpi') ? 'My Assessments' : 'Your Assessments'}
        />

        <div className="row" style={{ marginTop: 20, display: 'flex', gap: 15, marginBottom: 20 }}>
          <SearchBar label="Search" loading={isSearching} onInput={handleSearch} />

          <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
            <DropdownSelect
              label="Type"
              value={request.assessmentType?.toString()}
              items={assessmentTypeItems}
              onChange={event =>
                setRequest(current => ({
                  ...current,
                  assessmentType: event.target.value ? parseInt(event.target.value) : undefined,
                  page: 1,
                }))
              }
            />
            <DropdownSelect
              label="Sort by"
              items={sortByOptions}
              value={request.sortBy}
              onChange={event =>
                setRequest(current => ({
                  ...current,
                  sortBy: event.target.value,
                  page: 1,
                }))
              }
            />
            <DropdownSelect
              label="Status"
              items={statusItems}
              value={request.completionStatus?.toString()}
              onChange={event =>
                setRequest(current => ({
                  ...current,
                  completionStatus: event.target.value ? parseInt(event.target.value) : undefined,
                  page: 1,
                }))
              }
            />
          </Filters>
        </div>
        <SkeletonOverlay isLoading={isLoading} minHeight="100vh">
          {!!assessments.length ? (
            <div className={styles.pageContent}>
              {currentAssessments.map(assessment => {
                return (
                  <div
                    key={assessment.assessmentSlug}
                    className={classNames('card', styles.card)}
                    style={{ borderLeftColor: colourString(getBorderColorByCompletionStatus(assessment.completionStatus)) }}
                    onClick={() => {
                      keplerNavigate(`${PagePath.yourAssessmentsBase}${PagePath.yourAssessmentsAssessment.replace(':assessmentSlug', assessment.assessmentSlug)}`);
                    }}
                  >
                    <div>
                      <div className={styles.headerContainer}>
                        <div className={styles.assessmentTypeDesktop}>{formatAssessmentTypeLabel(AssessmentType[assessment.assessmentType])}</div>
                        <div className={styles.statusContainer}>
                          <div className={styles.datesContainer}>{renderDates(assessment)}</div>
                          <CompletionStatusChip completionStatus={assessment.completionStatus} />
                        </div>
                      </div>
                      <div>
                        <h4 className={styles.assessmentName}>{assessment.assessmentName}</h4>
                      </div>

                      <div className={styles.assessmentDetailsContainer}>
                        <div className={styles.assessmentMetricsContainer}>
                          <div className={styles.skillContainer}>
                            {companyVariables.useLevels ? (
                              <SkillLevel title="Assessment Score" level={assessment.score?.level ?? 0} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!assessment.score} />
                            ) : (
                              <SkillPercentage title="Assessment Score" percentage={assessment.score?.percentage ?? 0} noPercentage={!assessment.score} />
                            )}
                          </div>
                        </div>
                        <div className={styles.viewButtonContainer}>{renderButton(assessment)}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {totalPages > 1 && (
                <PagerConnector
                  pageCount={totalPages}
                  defaultPageNumber={currentPage}
                  onPageChange={tempPage => {
                    if (tempPage === currentPage) return;
                    setCurrentPage(tempPage);
                  }}
                >
                  {connector => <Pager {...connector} />}
                </PagerConnector>
              )}
            </div>
          ) : (
            <EmptyState />
          )}
        </SkeletonOverlay>
      </div>
      <Modal open={!!reassessment} onClose={() => setReassessment(undefined)}>
        {!!reassessment && (
          <ConfirmationModalLayout
            title="Confirm Reassessment"
            subtitle={`Are you sure you want to redo ${reassessment?.assessmentName}? If you proceed, your previous score will be overwritten with your new score...`}
            submitButtonText="Confirm"
            onClickSubmit={async () => {
              if (!profile) {
                setIsLoading(true);
                return actions.addNotification({
                  active: true,
                  id: crypto.randomUUID(),
                  type: NotificationType.Error,
                  priority: NotificationPriority.Toast,
                  title: 'There was an error triggering reassessment',
                  message: 'The system was unable to retrieve your email address. Please refresh the page and try again.',
                });
              }

              await actions.resetAssessment({
                assessmentSlug: reassessment.assessmentSlug,
                userIds: [profile.email],
              });
              actions.getSkillAssessmentConfiguration(reassessment.assessmentSlug);
              setIsLoading(false);
            }}
            onClickCancel={() => setReassessment(undefined)}
          />
        )}
      </Modal>
    </React.Fragment>
  );
}
