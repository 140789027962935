import React from 'react';
import { IVersusScoreWidgetProps } from './versus-score.models';
import { useAppActions, useAppState } from '../../../../overmind';
import { DataPoint } from '../../../../components/charts/recharts.models';
import { SkeletonOverlay } from '../../../../components/general/loading-state/skeleton-overlay';
import { ScatterChartComponent } from '../../../../components/charts/scatter/scatter-chart';
import { EmptyState } from '../../../../components/general/empty-state/empty-state';
import { yTickFormatter } from './versus-score.helpers';
import { WidgetCard } from '../../analytics-filters/widget-card';

export function YearsExperienceWidget({ baseSearchParams }: IVersusScoreWidgetProps): React.ReactElement {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = React.useState(true);
  const [yearsExperienceDataPoints, setYearsExperienceDataPoints] = React.useState<DataPoint[]>();

  React.useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const response = await actions.getAssessmentAnalyticsScoreByYear(baseSearchParams);
      setYearsExperienceDataPoints(response?.map(item => ({ ['Years experience']: Number(item.label), ['Average skill score']: companyVariables.useLevels ? item.score.level : item.score.percentage })));
      setIsLoading(false);
    }

    getData();
  }, [baseSearchParams]);

  return (
    <SkeletonOverlay isLoading={isLoading} height="100%">
      <WidgetCard title="Years of experience vs average skill score" subtitle="The average score for skills included in this assessment compared to how often the skill is used." style={{ height: '100%', justifyContent: 'space-between' }}>
        {!!yearsExperienceDataPoints?.length ? <ScatterChartComponent dataPoints={yearsExperienceDataPoints} useLevels={companyVariables.useLevels} fill="pink" yTickFormatter={yTickFormatter} /> : <EmptyState title="Hang tight!" subtitle="We are waiting for data." height={500} />}
      </WidgetCard>
    </SkeletonOverlay>
  );
}
