import { useEffect, useState } from 'react';
import { scrollToTop } from '../../../library/helpers/scroll-to-top';
import { StepperConnector } from './stepper.models';

// TODO: next(), previous() and goTo() don't have context of disabled steps
export function useStepperConnector(): StepperConnector {
  const [stepCount, setStepCount] = useState<number>(0);
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  useEffect(scrollToTop, [activeStepIndex]);

  return {
    setStepCount,
    activeStepIndex,
    previous: () => {
      setActiveStepIndex(currentState => {
        if (currentState === 0) return currentState;
        return currentState - 1;
      });
    },
    next: () => {
      setActiveStepIndex(currentState => {
        if (currentState === stepCount - 1) return currentState;
        return currentState + 1;
      });
    },
    goTo: (stepIndex: number) => {
      if (stepIndex === activeStepIndex || stepIndex < 0 || stepIndex > stepCount - 1) return;
      setActiveStepIndex(stepIndex);
    },
  };
}
