import React, { useEffect, useState } from 'react';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { Anchor, Button, CheckboxCard, ListItemLayout, ListLayout, PanelHeader, colourString, safeCallback } from '@keplerco/core';

interface IColumnConfiguratorWidgetProps {
  columns: ColumnConfiguration[];
  defaultSelectionColumns?: ColumnConfiguration[];
  onCancel?: () => void;
  onSave?: (selection: ColumnConfiguration[]) => void;
  onReset?: () => void;
}

export function ColumnConfiguratorWidget(props: IColumnConfiguratorWidgetProps): JSX.Element {
  const [selectionColumns, setSelectionColumns] = useState(props.defaultSelectionColumns);

  useEffect(() => {
    setSelectionColumns([...props.columns.filter(col => col.required || col.selected)]);
  }, [props.columns]);

  return (
    <div className="panelContent">
      <PanelHeader title="Configure fields" subtitle="Select which fields you want to view" type="actions" divider>
        <div className="headerActions">
          <Anchor onClick={props.onReset} type="span">
            Reset visible columns
          </Anchor>
        </div>
      </PanelHeader>

      <section>
        <ListLayout>
          {props.columns?.map(col => {
            const checked: boolean = !!col.required || !!selectionColumns?.find(i => i.key === col.key && col.label === col.label);

            return (
              <ListItemLayout key={col.key ?? col.label}>
                <CheckboxCard
                  id={col.key ?? col.label!}
                  alignCheckbox="end"
                  disabled={col.required}
                  checked={checked}
                  onChange={() => {
                    if (col.required) return;

                    if (checked && !!selectionColumns) {
                      setSelectionColumns([...selectionColumns.filter(i => !(i.key === col.key && col.label === col.label))]);
                    } else setSelectionColumns([...(selectionColumns ?? []), col]);
                  }}
                >
                  <div className="card" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div>{col.label ?? col.key}</div>

                    <small style={{ color: colourString('text_1') }}>{col.required ? 'This column cannot be hidden' : checked ? 'This column is visible' : 'This column is hidden'}</small>
                  </div>
                </CheckboxCard>
              </ListItemLayout>
            );
          })}
        </ListLayout>
      </section>

      <footer className="panelFooter">
        <Anchor
          onClick={() => {
            setSelectionColumns([...props.columns.filter(col => col.required || col.selected)]);
            safeCallback(props.onCancel);
          }}
          type="span"
        >
          Discard
        </Anchor>

        <Button
          onClick={() =>
            safeCallback(props.onSave, [
              ...props.columns.map(col => {
                const isSelected = !!selectionColumns?.find(s => s.key === col.key && s.label === col.label);
                return {
                  ...col,
                  selected: isSelected,
                };
              }),
            ])
          }
          type="button"
          filled
        >
          Save Changes
        </Button>
      </footer>
    </div>
  );
}
