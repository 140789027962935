import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ErrorSignalTypes } from '../../enums/error-signal-types';
import { useAppActions } from '../../overmind';
import { PagePath } from '../../navigation/navigation.enums';
import { Button } from '@keplerco/core';
import styles from './error.module.css';
import classNames from 'classnames';

export function ErrorPage(): JSX.Element {
  const { type = ErrorSignalTypes.Unknown, code } = useParams<{ type: ErrorSignalTypes; code: string }>();
  const [searchParams] = useSearchParams();

  const actions = useAppActions();

  const returnTo = searchParams.get('returnTo');

  useEffect(() => {
    actions.setSideNavVisible(false);

    return () => actions.setSideNavVisible(true);
  }, []);

  if (type === ErrorSignalTypes.Routing) {
    return (
      <section className={styles.errorPageBackground}>
        <div className={styles.errorPageContainer}>
          <div className={styles.errorPageContent}>
            <h1 className={styles.errorPageHeadingText}>Oops!</h1>

            <h3 className={styles.errorPageSubtitleText}>
              We're sorry, something has
              <br /> gone wrong.
            </h3>

            <Button type="a" href={returnTo ?? PagePath.root} filled arrow={false}>
              Take me home
            </Button>
          </div>

          <figure className={styles.errorPageFigure}>
            <object data="/404.svg" type="image/svg+xml">
              <img src="/404.svg" alt="Cow" />
            </object>
          </figure>
        </div>
      </section>
    );
  } else {
    return (
      <section className={styles.errorPageBackground}>
        <div className={styles.errorPageContainer}>
          <div className={styles.errorPageContent}>
            <h1 className={styles.errorPageHeadingText}>Well this is awkward...</h1>

            <h3 className={styles.errorPageSubtitleText}>{code === 'api' ? 'Something seems to have gone wrong with our servers.' : `We're sorry, we can't seem to find the page you're looking for.`}</h3>

            <p className={styles.errorPageBodyText}>Please try again, or if this keeps happening, let us know and we'll look into it.</p>

            <Button type="a" href={returnTo ?? PagePath.root} filled arrow={false}>
              Take me home
            </Button>
          </div>

          <figure className={classNames(styles.errorPageFigure, 'wide')}>
            <object data="/404_UFO.svg" type="image/svg+xml">
              <img src="/404_UFO.svg" alt="Cow" />
            </object>
          </figure>
        </div>
      </section>
    );
  }
}
