/* eslint-disable indent */
import { Anchor, Button, CheckboxCard, PanelHeader, Toggle, useMatchScreenWidth } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { ListItem } from '../../../../../../components/lists/list-item';
import { ActivationStatus, OrganizationLevelType } from '../../../../../../enums';
import { useAppActions, useAppState } from '../../../../../../overmind';
import styles from './select-people.module.css';
import { PagingSearchParams, SearchingSearchParams, SortingSearchParams } from '../../../../../../models/overmind/search-params';
import { IPerson } from '../../../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';
import { CohortType } from '../../../../../../components/panels/select-cohort/select-cohort.models';
import { UniqueEntity } from '../../../../../../models';
import { SearchList } from '../../../../../../components/lists/search-list';
import { ISelectPeopleLayoutProps } from './select-people.models';
import { AstronautLoader } from '../../../../../../components/general/loading-state/loaders/astronaut-loader/astronaut-loader';

// TODO: refactor Roles/Assessments so that we can use the same Select People Layout
export function SelectPeopleLayout({ selectedCohortType, selectedEntity, selectedPeople, showManagerToggle, onBack, onDone }: ISelectPeopleLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [request, setRequest] = useState<SearchingSearchParams & SortingSearchParams & PagingSearchParams>({
    search: undefined,
    sortAscending: true,
    page: 1,
    pageSize: 99999, // TODO: get correct size
  });
  const [peopleToSelect, setPeopleToSelect] = useState<IPerson[]>(selectedPeople);
  const [people, setPeople] = useState<IPerson[]>();

  const isMobile = useMatchScreenWidth('mobile');

  async function getDepartmentPeople(request: SearchingSearchParams & SortingSearchParams & PagingSearchParams, entity: UniqueEntity | undefined) {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug, departmentSlug: entity?.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          ...employee,
          selected: peopleToSelect.some(personToSelect => personToSelect.id === employee.id),
          manager: peopleToSelect.some(personToSelect => personToSelect.id === employee.id && personToSelect.manager),
          skills: [],
        })) ?? []
    );
    return;
  }
  async function getTeamPeople(request: SearchingSearchParams & SortingSearchParams & PagingSearchParams, entity: UniqueEntity | undefined) {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug, teamSlug: entity?.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          ...employee,
          selected: peopleToSelect.some(personToSelect => personToSelect.id === employee.id),
          manager: peopleToSelect.some(personToSelect => personToSelect.id === employee.id && personToSelect.manager),
          skills: [],
        })) ?? []
    );
    return;
  }
  async function getRolePeople(request: SearchingSearchParams & SortingSearchParams & PagingSearchParams, entity: UniqueEntity | undefined) {
    const assigneesResponse = await actions.getCompanyRoleAssignees({
      ...request,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      organizationLevel: OrganizationLevelType.Company,
      roleSlug: entity!.slug!,
    });
    const employeesResponse = await actions.searchPeople({ ...request, companySlug: companyVariables.slug });
    const people: IPerson[] = [];
    assigneesResponse?.assignees?.forEach(assignee => {
      const person = employeesResponse?.employees.find(employee => employee.email === assignee.email);
      if (!person) return;
      people.push({
        ...person,
        selected: peopleToSelect.some(personToSelect => personToSelect.id === person.id),
        manager: peopleToSelect.some(personToSelect => personToSelect.id === person.id && personToSelect.manager),
        skills: [],
      });
    });
    setPeople(people);
    return;
  }

  async function getPeople(request: SearchingSearchParams & SortingSearchParams & PagingSearchParams) {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          ...employee,
          selected: peopleToSelect.some(personToSelect => personToSelect.id === employee.id),
          manager: peopleToSelect.some(personToSelect => personToSelect.id === employee.id && personToSelect.manager),
          skills: [],
        })) ?? []
    );
  }

  async function getPeopleData(request: SearchingSearchParams & SortingSearchParams & PagingSearchParams) {
    switch (selectedCohortType) {
      case CohortType.Department: {
        await getDepartmentPeople(request, selectedEntity);
        break;
      }

      case CohortType.Team: {
        await getTeamPeople(request, selectedEntity);
        break;
      }

      case CohortType.Role: {
        await getRolePeople(request, selectedEntity);
        break;
      }

      case CohortType.People: {
        await getPeople(request);
        break;
      }

      default: {
        break;
      }
    }
  }

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      await getPeopleData(request);
      setIsLoading(false);
    }

    getData();
  }, [selectedCohortType, selectedEntity, companyVariables.slug]);

  return (
    <div className="panelContent">
      <PanelHeader supertitle="Assessments" title={`${selectedEntity?.name ?? 'Select People'}`} subtitle="Select who you would like to take part in the assessment" />

      <section className="card" style={{ display: 'flex', flexDirection: 'column' }}>
        {isLoading ? (
          <AstronautLoader />
        ) : (
          <SearchList
            loading={isSearching}
            onInput={async search => {
              setIsSearching(true);
              const nextRequest = { ...request, search, page: 1 };
              setRequest(nextRequest);
              await getPeopleData(nextRequest);
              setIsSearching(false);
            }}
            actions={
              <div style={{ width: 120 }}>
                <CheckboxCard
                  id="selectAll"
                  checked={!people?.some(person => !person.selected) && !!people?.length}
                  onChange={() => {
                    const allSelected = people?.every(person => person.selected);

                    setPeople(currentState => {
                      if (!currentState) return;
                      const nextState: IPerson[] = structuredClone(currentState);
                      nextState.forEach(nextPerson => (nextPerson.selected = !allSelected));
                      return nextState;
                    });

                    setPeopleToSelect(currentState => {
                      if (allSelected) return [];

                      const nextState: IPerson[] = structuredClone(currentState);
                      people?.forEach(person => {
                        const nextPerson = nextState.find(temp => temp.id === person.id);
                        if (!nextPerson) nextState.push({ ...person, selected: true });
                      });
                      return nextState;
                    });
                  }}
                >
                  <div className="card">Select all</div>
                </CheckboxCard>
              </div>
            }
            emptyState
          >
            {people?.map(person => {
              return (
                <ListItem
                  key={person.id.toString()}
                  selected={person.selected}
                  onClick={() => {
                    const selected = !person.selected;
                    const manager = selected ? person.manager : false;

                    setPeople(currentState => {
                      if (!currentState) return;
                      const nextState: IPerson[] = structuredClone(currentState);
                      const nextPerson = nextState.find(temp => temp.id === person.id);
                      if (!!nextPerson) {
                        nextPerson.selected = selected;
                        nextPerson.manager = manager;
                      }
                      return nextState;
                    });

                    setPeopleToSelect(currentState => {
                      let nextState: IPerson[] = structuredClone(currentState);
                      selected
                        ? nextState.push({
                            ...person,
                            selected,
                            manager,
                          })
                        : (nextState = nextState.filter(temp => temp.id !== person.id));
                      return nextState;
                    });
                  }}
                >
                  <React.Fragment>
                    {showManagerToggle ? (
                      <div style={{ display: 'grid', columnGap: 15, rowGap: 5, alignItems: 'center', gridTemplateColumns: isMobile ? 'auto' : '1fr 1fr auto' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: isMobile ? 5 : 0 }}>
                          <div style={{ color: 'var(--accent-2)' }}>
                            {person.firstName} {person.lastName}
                          </div>

                          <small>
                            {person.department.name} | {person.team.name}
                          </small>
                        </div>

                        <small>{person.email}</small>

                        <div className={styles.toggle} onClick={event => event.stopPropagation()}>
                          <Toggle
                            id="manager"
                            name="manager"
                            title="manager"
                            value={person.manager ?? false}
                            toggleBackgroundColour="primary"
                            toggleForegroundColour="white"
                            onChange={() => {
                              const manager = !person.manager;

                              setPeople(currentState => {
                                if (!currentState) return;
                                const nextState: IPerson[] = structuredClone(currentState);
                                nextState.forEach(nextPerson => {
                                  if (nextPerson.id !== person.id) {
                                    nextPerson.manager = false;
                                    return;
                                  }

                                  nextPerson.selected = true;
                                  nextPerson.manager = manager;
                                });
                                return nextState;
                              });

                              setPeopleToSelect(currentState => {
                                const nextState: IPerson[] = structuredClone(currentState);
                                nextState.forEach(temp => {
                                  temp.manager = false;
                                });

                                const nextPerson = nextState.find(temp => temp.id === person.id);
                                if (!!nextPerson) {
                                  nextPerson.manager = manager;
                                } else {
                                  nextState.push({
                                    ...person,
                                    manager: true,
                                    selected: true,
                                  });
                                }

                                return nextState;
                              });
                            }}
                          >
                            <label htmlFor="manager" className="body">
                              Manager
                            </label>
                          </Toggle>
                        </div>
                      </div>
                    ) : (
                      <div style={{ display: 'grid', columnGap: 15, rowGap: 5, alignItems: 'center', gridTemplateColumns: isMobile ? 'auto' : '1fr 1fr' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: isMobile ? 5 : 0 }}>
                          <div className="body" style={{ color: 'var(--accent-2)' }}>
                            {person.firstName} {person.lastName}
                          </div>

                          <small>
                            {person.department.name} | {person.team.name}
                          </small>
                        </div>

                        <small>{person.email}</small>
                      </div>
                    )}
                  </React.Fragment>
                </ListItem>
              );
            })}
          </SearchList>
        )}
      </section>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button type="button" onClick={() => onDone(peopleToSelect)}>
          Done
        </Button>
      </footer>
    </div>
  );
}
