import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FetchStatus, FetchType } from '../../../../../enums';
import { KeplerState } from '../../../../../models/kepler-state';
import { useAppState } from '../../../../../overmind';
import { PageSkeletonLoader } from './page-skeleton-loader';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { colourString } from '@keplerco/core';
import styles from './page-loader-layer.module.css';

export function PageLoaderLayer({ path, backgroundColor = colourString('background'), skeletonBackgroundColor, children }: PropsWithChildren<{ path: PagePath; backgroundColor?: string; skeletonBackgroundColor?: string }>): JSX.Element {
  const loaderRef = useRef<HTMLDivElement>(null);

  const { fetchState } = useAppState<KeplerState>();

  const time = useRef<NodeJS.Timeout | undefined>(undefined);
  const [forceUpdate, setForceUpdate] = useState<number>(0);

  const init = useRef<NodeJS.Timeout | undefined>(undefined);

  function showLoader(loader: HTMLDivElement) {
    loader.classList.remove(styles.hidden);
  }

  function hideLoader(loader: HTMLDivElement) {
    loader.classList.add(styles.hidden);
  }

  // If the initial status is inactive, hide the loader after a certain amount of time.
  // Because the "hidden" class is added by default, without this an infinite loader would occur if no action is triggered by the page parent to stop the loader.
  useEffect(() => {
    if (fetchState[path].status === FetchStatus.Inactive)
      init.current = setTimeout(() => {
        const loader = loaderRef.current;

        if (!!loader) hideLoader(loader);
      }, 1000);
  }, [fetchState[path].status]);

  // If an init timeout exists and the status changes because and action was triggered by the parent component to start the loader, cancel hiding the loader.
  useEffect(() => {
    if (!!init.current && fetchState[path].status === FetchStatus.Active) clearTimeout(init.current);
  }, [fetchState[path].status]);

  useEffect(() => {
    if (fetchState[path].type !== FetchType.PageFetching) return;

    if (fetchState[path].status === FetchStatus.Active) {
      time.current = setTimeout(() => {
        time.current = undefined;
        setForceUpdate(latest => latest + 1);
      }, 1000);

      setForceUpdate(latest => latest + 1);

      const loader = loaderRef.current;

      if (!!loader) showLoader(loader);
    }
  }, [fetchState[path]]);

  useEffect(() => {
    if (fetchState[path].type !== FetchType.PageFetching) return;

    if (fetchState[path].status === FetchStatus.Inactive && !time.current) {
      const loader = loaderRef.current;

      if (!!loader) hideLoader(loader);
    }
  }, [fetchState[path].status, time, forceUpdate]);

  return (
    <div style={{ width: '100%', minHeight: 'calc(100vh - 90px)' }}>
      <div style={{ position: 'relative' }}>
        <div ref={loaderRef} className={classNames(styles.loader, { [styles.hidden]: process.env.NODE_ENV === 'development' })} style={{ backgroundColor }}>
          <PageSkeletonLoader skeletonBackgroundColor={skeletonBackgroundColor} />
        </div>

        {children}
      </div>
    </div>
  );
}
