import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../../overmind';
import { AssessmentSearchParams, FilteringSearchParams, PagingSearchParams, PermissionSearchParams, SearchingSearchParams, SortingSearchParams } from '../../../../models/overmind/search-params';
import { DataPoint } from '../../../../components/charts/recharts.models';
import { HorizontalBarChart } from '../../../../components/charts/horizontal-bar-chart/horizontal-bar-chart';
import { transformScores } from './skill-versus-assessment-score.helpers';
import { EmptyState } from '../../../../components/general/empty-state/empty-state';
import { Pager, SearchBar } from '@keplerco/core';
import { SkeletonOverlay } from '../../../../components/general/loading-state/skeleton-overlay';
import { AnalyticsSortBy } from '../../analytics-filters/analytics-sort-by';
import { WidgetCard } from '../../analytics-filters/widget-card';
import { SORT_BY_ITEMS } from '../../../../widgets/analytics/average-score-skill/average-score-per-skill.widget';
import { DEFAULT_FILTERING_SEARCH_PARAMS } from '../../analytics-filters/analytics-filters';
import classNames from 'classnames';

interface UserAssessmentScoresSearchParams extends AssessmentSearchParams, PermissionSearchParams, SearchingSearchParams, SortingSearchParams, PagingSearchParams, FilteringSearchParams {}

export function SkillVersusAssessmentScoreWidget({ userFullName, baseSearchParams }: { userFullName?: string; baseSearchParams: AssessmentSearchParams & PermissionSearchParams }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataPoints, setDataPoints] = useState<DataPoint[]>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<UserAssessmentScoresSearchParams>({
    ...DEFAULT_FILTERING_SEARCH_PARAMS,
    ...baseSearchParams,
    sortAscending: false,
    sortBy: undefined,
    search: undefined,
    page: 1,
    pageSize: 10,
  });

  async function fetchData(searchParams: UserAssessmentScoresSearchParams): Promise<void> {
    if (!searchParams.assessmentSlug || !searchParams.learnerSlug || !searchParams.companySlug) return;

    const scores = await actions.getAssessmentCompetencyScores(searchParams);

    setDataPoints(transformScores(scores?.people, companyVariables.useLevels));
    setTotalPages(scores?.totalPages ?? 0);
  }

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      await fetchData(searchParams);
      setIsLoading(false);
    }

    getData();
  }, [searchParams.assessmentSlug, searchParams.learnerSlug, searchParams.companySlug]);

  return (
    <SkeletonOverlay isLoading={isLoading} width="100%">
      <WidgetCard title="Skill score vs assessment score" subtitle={`${!!userFullName ? `${userFullName}'s` : 'Your'} score for each skill included in this assessment compared to assessment score.`}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
          <SearchBar
            label="Search"
            loading={isSearching}
            onInput={async event => {
              setIsSearching(true);
              const search = (event.target as HTMLInputElement).value;
              setSearchParams({ ...searchParams, search, page: 1 });
              await fetchData({ ...searchParams, search, page: 1 });
              setIsSearching(false);
            }}
          />

          <AnalyticsSortBy
            items={SORT_BY_ITEMS}
            value={searchParams.sortBy}
            onChange={async event => {
              setIsLoading(true);
              const sortBy = event.target.value;
              setSearchParams({ ...searchParams, sortBy });
              await fetchData({ ...searchParams, sortBy });
              setIsLoading(false);
            }}
          />
        </div>

        {!dataPoints?.length ? (
          <EmptyState />
        ) : (
          <React.Fragment>
            <HorizontalBarChart dataPoints={dataPoints} fill={['baby-blue', 'bright-pink']} showLegend />

            <div className={classNames({ invisible: totalPages < 2 })}>
              <Pager
                pageCount={totalPages}
                activePageNumber={searchParams.page ?? 1}
                onPageChange={async page => {
                  setIsLoading(true);
                  if (page === searchParams.page) return;
                  setSearchParams({ ...searchParams, page });
                  fetchData({ ...searchParams, page });
                  setIsLoading(false);
                }}
              />
            </div>
          </React.Fragment>
        )}
      </WidgetCard>
    </SkeletonOverlay>
  );
}

