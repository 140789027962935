import React, { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAppActions, useAppState } from '../../../../overmind';
import { EmptyState } from '../../empty-state/empty-state';
import { SkeletonLoader } from '../../loading-state/loaders/skeleton-loader/skeleton-loader';
import { OrganizationLevelType, ScoreType } from '../../../../enums';
import styles from '../average.module.css';
import { ExceedsExpectationsIcon } from './exceeds-expectations.icon';
import { MeetsExpectationsIcon } from './meets-expectations.icon';
import { BelowExpectationsIcon } from './below-expectations.icon';
import { IRoleAverageWidgetProps } from '../average.models';
import { Average } from '../average';
import { extractPermission } from '../../../../library/helpers/permissions/extract-permission';
import { PermissionType } from '../../../../enums/permission-type';
import { extractHighestOrganizationLevel } from '../../../../library/helpers/permissions/extract-highest-organization-level';
import { EntityScore } from '../../../../models/overmind/analytics';
import { ScoresSearchParams } from '../../../../models/overmind/search-params';
import { ScoreComparison } from '../../../../enums/score-comparison';

export function RoleAverageWidget({ roleSlug }: IRoleAverageWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { user, permissions, companyVariables, dateRange } = useAppState();

  const permission = extractPermission(permissions, PermissionType.Analytics);
  const highestOrganizationLevel = extractHighestOrganizationLevel(permission?.organizationLevels);

  const [loading, setLoading] = useState<boolean>(true);
  const [roleScore, setRoleScore] = useState<EntityScore>();

  useEffect(() => {
    async function getData() {
      if (!user?.isSystemAdmin && (!highestOrganizationLevel || highestOrganizationLevel.organizationLevel !== OrganizationLevelType.Company)) return;

      setLoading(true);

      const request: ScoresSearchParams = {
        sortAscending: true,
        page: 1,
        pageSize: 1,
        startDate: dateRange?.from?.toJSON(),
        endDate: dateRange?.to?.toJSON(),
        organizationLevel: OrganizationLevelType.Company,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        scoreType: ScoreType.Role,
        scoreTypeSlugs: !!roleSlug ? [roleSlug] : [],
        scoreComparison: ScoreComparison.RoleExpectedProficiency,
      };
      const response = await actions.analyticsGetScoresByScoreType(request);
      setRoleScore(response?.results[0]);

      setLoading(false);
    }

    getData();
  }, [roleSlug, companyVariables.slug]);

  // TODO
  if (loading) return <SkeletonLoader height="350px" width="450px" />;

  return (
    <div className={classNames('card', [styles.card])}>
      {!roleScore ? (
        <EmptyState title="No averages found" />
      ) : (
        <Fragment>
          <Average title="Role average" description="The role average is the average of all the people in this role" score={roleScore.score} />

          <div className={styles.divider} />

          <Average title="Expected proficiency" score={roleScore.comparisonScore} scoreIndicatorTitle="Average skill expectation" noScoreText="Not captured" />

          {!!roleScore.score && !!roleScore.comparisonScore && (
            <Fragment>
              <div className={styles.divider} />
              <div className={styles.container} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {companyVariables.useLevels ? (
                  <Fragment>
                    {roleScore.score.level > roleScore.comparisonScore.level && (
                      <Fragment>
                        <ExceedsExpectationsIcon />
                        <h5>Exceeding expectation</h5>
                      </Fragment>
                    )}

                    {roleScore.score.level === roleScore.comparisonScore.level && (
                      <Fragment>
                        <MeetsExpectationsIcon />
                        <h5>Meeting expectation</h5>
                      </Fragment>
                    )}

                    {roleScore.score.level < roleScore.comparisonScore.level && (
                      <Fragment>
                        <BelowExpectationsIcon />
                        <h5>Below expectation</h5>
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {roleScore.score.percentage > roleScore.comparisonScore.percentage && (
                      <Fragment>
                        <ExceedsExpectationsIcon />
                        <h5>Exceeding expectation</h5>
                      </Fragment>
                    )}

                    {roleScore.score.percentage === roleScore.comparisonScore.percentage && (
                      <Fragment>
                        <MeetsExpectationsIcon />
                        <h5>Meeting expectation</h5>
                      </Fragment>
                    )}

                    {roleScore.score.percentage < roleScore.comparisonScore.percentage && (
                      <Fragment>
                        <BelowExpectationsIcon />
                        <h5>Below expectation</h5>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
}
