import { PermissionType } from '../../../enums/permission-type';
import { Permission, Permissions } from '../../../models/overmind/permissions';

export function extractPermission(permissions: Permissions | undefined, permissionType: PermissionType): Permission | undefined {
  if (!permissions) return undefined;

  switch (permissionType) {
    case PermissionType.Administration: {
      return permissions.administration;
    }

    case PermissionType.Analytics: {
      return permissions.analytics;
    }

    case PermissionType.Assessments: {
      return permissions.assessments;
    }

    case PermissionType.LearningManagement: {
      return permissions.learningManagement;
    }

    case PermissionType.RoleSkillManagement: {
      return permissions.roleSkillManagement;
    }

    default: {
      return undefined;
    }
  }
}
