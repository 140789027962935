import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { AllAssessmentsPage } from '../../pages/assessments/all-assessments/all-assessments.page';
import { CreateSkillAssessmentsCMSPage } from '../../pages/assessments/create-skills-assessment/create-skill-assessment.cms.layout';
import { SkillAssessmentAssigneesPage } from '../../pages/assessments/skill-assessment-assignees/skill-assessment-assignees.page';
import { ActiveCompanySlugRedirect } from './routers.helpers';
import { useCompanySlugParamChange } from '../../library/hooks/useCompanySlugParamChange';
import { AssessmentsWizardPage } from '../../pages/assessments/wizard/assessments-wizard.page';
import { AssessmentsWizardPeerPage } from '../../pages/assessments/wizard/peer/assessments-wizard-peer.page';
import { AssessmentsWizardQuestionnairePage } from '../../pages/assessments/wizard/assessments-wizard-questionnaire.page';
import { AssessmentsWizardFOPPage } from '../../pages/assessments/wizard/assessments-wizard-fop.page';

export function AssessmentsRouter(): JSX.Element {
  useCompanySlugParamChange(PagePath.assessmentManagementBase);

  return (
    <Routes>
      {/* TODO: cleanup routing */}
      <Route path={PagePath.assessmentManagementAllAssessments} element={<AllAssessmentsPage />} />
      <Route path={PagePath.assessmentManagementCreate} element={<CreateSkillAssessmentsCMSPage />} />
      <Route path={PagePath.assessmentManagementUpdate} element={<CreateSkillAssessmentsCMSPage />} />
      <Route path={PagePath.assessmentManagementAssignees} element={<SkillAssessmentAssigneesPage />} />

      <Route path={PagePath.assessmentManagementWizard} element={<AssessmentsWizardPage />} />
      <Route path={PagePath.assessmentManagementWizardPeer} element={<AssessmentsWizardPeerPage />} />
      <Route path={PagePath.assessmentManagementWizardPeerUpdate} element={<AssessmentsWizardPeerPage />} />
      <Route path={PagePath.assessmentManagementWizardQuestionnaire} element={<AssessmentsWizardQuestionnairePage />} />
      <Route path={PagePath.assessmentManagementWizardFOP} element={<AssessmentsWizardFOPPage />} />

      <Route path={PagePath.rootWildcard} element={<ActiveCompanySlugRedirect landingRedirectTo={`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementAllAssessments}`} />} />
    </Routes>
  );
}
