import React, { useEffect, useReducer } from 'react';
import { useAppActions, useAppState } from '../../overmind';
import * as store from './your-skills.store';
import { PageLoaderLayer } from '../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PageHeader } from '@keplerco/core';
import { CompletionStatus, FetchType, OrganizationLevelType } from '../../enums';
import { PagePath } from '../../navigation/navigation.enums';
import { AssessmentType } from '../../enums/assessment-type';
import { getSubdomain } from '../../library/helpers/get-subdomain';
import classNames from 'classnames';
import styles from './your-skills.module.css';
import { SunburstWidget } from '../../widgets/analytics/sunburst/sunburst.widget';
import { PermissionSearchParams } from '../../models/overmind/search-params';

export function YourSkillsPage() {
  const actions = useAppActions();
  const { user, companyVariables, skillAssessmentConfig } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const subdomain = getSubdomain();
  const pageTitle = subdomain.includes('lpi') ? 'My Skills Report' : 'Your Skills';

  const baseSearchParams: PermissionSearchParams | undefined = React.useMemo(() => {
    if (!companyVariables.slug) return undefined;

    return {
      organizationLevel: OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: user?.learnerSlug,
    };
  }, [user?.learnerSlug, companyVariables.slug]);

  useEffect(() => {
    actions.startLoader({ path: PagePath.yourSkills, type: FetchType.PageFetching });
    actions.getForcedSkillAssessmentConfiguration();
    actions.stopLoader(PagePath.yourSkills);
  }, []);

  useEffect(() => {
    if (!skillAssessmentConfig) return;

    const shouldIncludeAwaitingAssessment = !!skillAssessmentConfig.assessmentSlug && skillAssessmentConfig.assessmentResultReady && skillAssessmentConfig.assessmentType === AssessmentType.PeerEndorsement && skillAssessmentConfig.teamPeerReviewCompletionStatus !== CompletionStatus.Completed;

    dispatch({
      type: store.YourSkillsActionTypes.SetIncludeAwaitingAssessment,
      payload: shouldIncludeAwaitingAssessment,
    });
  }, [skillAssessmentConfig]);

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.yourSkills}>
        <div className={styles.skillsReportPageContainer}>
          <div className="wrapper stack" style={{ pointerEvents: 'all' }}>
            <PageHeader breadcrumbs={[{ name: pageTitle, url: PagePath.yourSkills }]} title={pageTitle} />

            <br />

            {!!baseSearchParams && <SunburstWidget path={PagePath.yourSkills} chartTitle="We've identified these areas for growth:" permissionSearchParams={baseSearchParams} />}

            {state.includeAwaitingAssessment && (
              <div className={classNames(styles.skillsReportRoleBasedTabSlide, 'isLast')}>
                <div className={classNames(styles.skillsReportRoleBasedCard, 'card')}>
                  <h3>Team assessment in progress</h3>
                  <p>
                    <u>Some of your peers</u> are still busy with their assessments.
                  </p>
                  <p>Your results will be available once everyone has completed the process.</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </PageLoaderLayer>
    </React.Fragment>
  );
}
